import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { StyledButton } from 'uiComponents/Button';
import { Button } from 'uiComponents/Button/Button';

export const SignInFormWrapper = styled.div`
  margin-bottom: 2em;

  ${media.md`
    margin-bottom: 96px;
  `}

  & ${StyledButton} {
    margin-bottom: 2em;
    margin-top: 4px;

    ${media.md`
      margin-top: 32px;
    `}
  }
`;

export const StyledSigninButton = styled(Button)`
  ${media.tablet`
    &:hover {
      background-color: ${({ isLoading, theme }) => isLoading ? theme.colors.orange : '#fff' };
      color: ${({ theme }) => theme.colors.orange};
    }
  `}
`;
