import React from 'react';
import { useSelector } from 'react-redux';

import { getSolutionName } from 'store/solution/selectors';

import { Container, Row } from 'styled-bootstrap-grid';

import { getUserRole } from 'store/user/selectors';
import userConstants from 'constants/userConstants';

import customView from './customView';

import watchIcon from 'assets/images/watch';
import downloadIcon from 'assets/images/download';

import * as styles from './styles';

const info = [
  {
    fieldName: 'howDoWeDoIt',
    title: 'Как мы это делаем?',
    text: <>
      <div>Что измеряется?</div>
      <div>Как работает?</div>
      <div>Кто участвует в опросе?</div>
    </>,
    icon: watchIcon,
    linkText: 'Подробнее',
  },
  {
    fieldName: 'questionnaire',
    title: 'Анкета',
    text: 'В анкете отображены вопросы, которые будут заданы целевой аудитории (кроме дополнительных)',
    icon: watchIcon,
    linkText: 'Смотреть анкету',
  },
  {
    fieldName: 'methodology',
    title: 'Методика',
    text: 'Ознакомьтесь с методикой проекта',
    icon: watchIcon,
    linkText: 'Смотреть методику',
  },
  {
    fieldName: 'report',
    title: 'Отчёт',
    text: 'Результаты опроса вы получите в виде оформленного отчета',
    icon: downloadIcon,
    linkText: 'Скачать пример отчета',
  },
];

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const InfoList = () => {
  const solutionName = useSelector(getSolutionName);
  const role = useSelector(getUserRole);
  const CustomView = customView[solutionName];

  const getLink = () => ({
    howDoWeDoIt: `/how-do-we-do-it/${solutionName}`,
    questionnaire: `/questionnaire/${solutionName}`,
    methodology: `/methodology/${solutionName}`,
    report: `/api/solution/${solutionName}/report-example`,
  });

  const renderColumn = (item, index) => {
    const {
      title,
      text,
      icon,
      fieldName,
      linkText,
    } = item;

    return (
      <styles.InfoBlockItem
        key={index}
        xs={12}
        sm={12}
        md={6}
        xl={3}
      >
        <styles.InfoBlockTitle>
          {title}
        </styles.InfoBlockTitle>
        <styles.InfoBlockText>
          {text}
        </styles.InfoBlockText>
        <styles.InfoBlockLinkWrapper>
          <styles.InfoBlockIcon src={icon} />
          {
            fieldName === 'report'
              ? (
                <styles.InfoBlockDownloadLink
                  dowload
                  href={getLink()[fieldName]}
                >
                  {linkText}
                </styles.InfoBlockDownloadLink>
              )
              : (
                <styles.InfoBlockLink
                  target={'_blank'}
                  to={getLink()[fieldName]}
                >
                  {linkText}
                </styles.InfoBlockLink>
              )
          }
        </styles.InfoBlockLinkWrapper>
      </styles.InfoBlockItem>
    );
  };
  if (role === userConstants.roles.manager) {
    return null;
  }

  if (CustomView) {
    return (
      <CustomView />
    );
  }

  return (
    <styles.InfoBlockStyles>
      <Container>
        <Row>
          {info.map((infoItem, index) => renderColumn(infoItem, index))}
        </Row>
      </Container>
    </styles.InfoBlockStyles>
  );
};
