import _get from 'lodash/get';
import React from 'react';

import { getDate, getTime } from 'helpers/date';

import { divideNumberIntoDigits } from 'helpers/divideNumberIntoDigits';
import { TYPES, TYPES_QUESTIONS_NAME } from 'constants/questions';

import { InterviewTableValueInner } from 'components/SurveyReview/styles';

export const SurveyReviewFieldsList = {
  name: {
    fieldName: 'Название:',
    value: (name) => name,
  },
  status: {
    fieldName: 'Статус заказа:',
    value: (status) => status,
  },
  id: {
    fieldName: 'ID заказа:',
    value: (id) => id ? `#${id}` : '-',
  },
  companyName: {
    fieldName: 'Компания:',
    value: (companyName) => companyName,
    stylesProps: {
      withoutBorder: true,
    },
  },
  clientName: {
    fieldName: 'Имя фамилия:',
    value: (client) => (client
      ? `${client.firstName || ''} ${client.lastName || ''}`
      : ''),
    stylesProps: {
      withoutBorder: true,
    },
  },
  clientPhone: {
    fieldName: 'Телефон:',
    value: (client) => _get(client, 'phone', ''),
    stylesProps: {
      withoutBorder: true,
    },
  },
  clientEmail: {
    fieldName: 'Email:',
    value: (client) => _get(client, 'email', ''),
    stylesProps: {
      withoutBorder: true,
    },
  },
  targetCompanyName: {
    fieldName: 'Компания:',
    value: (targetCompanyName) => targetCompanyName,
  },
  manager: {
    fieldName: 'Менеджер:',
    value: (status) => status ? { id: status.id, name: status.email } : { id: 0, name: 'Не назначен' },
  },
  solution: {
    fieldName: 'Тип опроса:',
    value: (solution) => solution.name,
  },
  createdDate: {
    fieldName: 'Дата создания:',
    value: (createdDate) => new Date(createdDate).toLocaleDateString(),
  },
  completedDate: {
    fieldName: 'Дата исполнения:',
    value: (completedDate) => completedDate ? new Date(completedDate).toLocaleDateString('ru-RU') : '-',
  },
  price: {
    fieldName: 'Стоимость:',
    value: (price) => `${divideNumberIntoDigits(price)} рублей`,
  },
  result: {
    fieldName: 'Результаты:',
    link: '/',
    type: 'download',
    userValue: 'Скачать отчёт',
    managerValue: 'Загрузить отчёт',
  },
  description: {
    fieldName: 'Описание:',
  },
  files: {
    fieldName: (solutionName) => {
      if (solutionName === 'AIDA' || solutionName === 'BHT') {
        return 'Видеоролик:';
      }
      return 'Файлы';
    },
  },
  filesWithBypass: {
    source: 'files',
    fieldName: (solutionName) => {
      if (solutionName === 'AIDA' || solutionName === 'BHT') {
        return 'Видеоролик:';
      }
      return 'Файлы:';
    },
    bypassIf: (rankingElementsList) => (
      !rankingElementsList.length
      || rankingElementsList.some((rankingElement) => (
        _get(rankingElement, 'file.id')
      ))
    ),
  },
  period: {
    fieldName: 'Период:',
    value: (period) => period,
  },
  characteristics: {
    fieldName: 'Характеристики',
    value: (characteristics) => characteristics,
  },
  geography: {
    fieldName: 'География:',
    value: (geography) => geography.name,
  },
  audience: {
    fieldName: 'Охват:',
    value: (audience) => audience.name,
  },
  companyTurnover: {
    fieldName: 'Оборот:',
    value: (companyTurnover) => companyTurnover.name,
  },
  healthMeasuringCount: {
    fieldName: 'Количество замеров:',
    value: (healthMeasuringCount) => healthMeasuringCount,
  },
  healthMeasuringPeriod: {
    fieldName: 'Периодичность измерения:',
    value: (healthMeasuringPeriod) => healthMeasuringPeriod,
  },
  names: {
    fieldName: 'Наименования:',
    value: (names) => names,
  },
  competitors: {
    fieldName: 'Марки конкурентов:',
    value: (competitor) => competitor,
  },
  statements: {
    fieldName: 'Утверждение/высказывание:',
    value: (statement) => statement,
  },
  concept: {
    fieldName: 'Идея/концепция:',
    value: (concept) => concept,
  },
  productPrice: {
    fieldName: 'Цена:',
    value: (price) => price,
  },
  productDescription: {
    fieldName: 'Продукт/услуга:',
    value: (productDescription) => productDescription,
  },
  productName: {
    fieldName: 'Марка:',
    value: (productName) => productName,
  },
  additionalFile: {
    fieldName: 'Файлы:',
  },
  rankingType: {
    fieldName: 'Тип элементов:',
    value: (rankingType) => TYPES_QUESTIONS_NAME[rankingType] || TYPES_QUESTIONS_NAME[TYPES.text],
  },
  rankingItems: {
    fieldName: 'Элементы:',
    value: (rankingElementsList) => (
      rankingElementsList.map((rankingElement, index) => (
        <InterviewTableValueInner key={`ranking-element-${index}`}>{rankingElement.text}</InterviewTableValueInner>
      ))
    ),
    bypassIf: (rankingElementsList) => (
      !rankingElementsList.length
      || rankingElementsList.some((rankingElement) => (
        _get(rankingElement, 'file.id')
      ))
    ),
  },
  rankingFiles: {
    fieldName: () => 'Элементы:',
    source: 'files',
    bypassIf: (rankingFiles) => (
      !rankingFiles.length
      || rankingFiles.some((rankingElement) => rankingElement.hasOwnProperty('text'))
    ),
  },
  firstName: {
    fieldName: 'Имя:',
  },
  lastName: {
    fieldName: 'Фамилия:',
  },
  email: {
    fieldName: 'Email:',
  },
  phone: {
    fieldName: 'Телефон:',
    stylesProps: {
      withoutBorder: true,
    },
  },
  consultationTheme: {
    fieldName: 'Тема консультации:',
  },
  consultationDate: {
    fieldName: 'Дата консультации:',
    source: 'consultationTime',
    value: (dateTime) => getDate(dateTime),
    stylesProps: {
      marginTop: true,
    },
  },
  consultationTime: {
    fieldName: 'Время:',
    source: 'consultationTime',
    value: (dateTime) => getTime(dateTime),
    stylesProps: {
      marginBottom: true,
      withoutBorder: true,
    },
  },
};

export default SurveyReviewFieldsList;
