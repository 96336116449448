import prefixString from 'helpers/string/prefixString';

const prefix = 'solution';

export const GET_SOLUTION_DETAILS = prefixString(prefix, 'GET_SOLUTION_DETAILS');
export const SET_SOLUTION_DETAILS = prefixString(prefix, 'SET_SOLUTION_DETAILS');
export const UPDATE_SOLUTION_DATA = prefixString(prefix, 'UPDATE_SOLUTION_DATA');
export const SUBMIT_SOLUTION_SETUP_FORM = prefixString(prefix, 'SUBMIT_SOLUTION_SETUP_FORM');

export const UPDATE_FIELD_NAME_UPLOAD_FILES = prefixString(prefix, 'UPDATE_FIELD_NAME_UPLOAD_FILES');
export const RESET_SOLUTION_CREATE_DATA = prefixString(prefix, 'RESET_SOLUTION_CREATE_DATA');
export const UPDATE_SOLUTION_CREATE_DATA = prefixString(prefix, 'UPDATE_SOLUTION_CREATE_DATA');
export const UPLOAD_FILE_SOLUTION_DATA = prefixString(prefix, 'UPLOAD_FILE_SOLUTION_DATA');
export const UPLOAD_FILE_SOLUTION_DATA_SUCCESS = prefixString(prefix, 'UPLOAD_FILE_SOLUTION_DATA_SUCCESS');
export const DELETE_FILE_SOLUTION_DATA = prefixString(prefix, 'DELETE_FILE_SOLUTION_DATA');
export const SET_SUBMITTED_SOLUTION_DATA = prefixString(prefix, 'SET_SUBMITTED_SOLUTION_DATA');
export const SET_SUBMITTED_SOLUTION_SUCCESS = prefixString(prefix, 'SET_SUBMITTED_SOLUTION_SUCCESS');

export const FETCH_SURVEY_DATA = prefixString(prefix, 'FETCH_SURVEY_DATA');
export const UPDATE_SURVEY_DATA = prefixString(prefix, 'UPDATE_SURVEY_DATA');
export const UPDATE_SURVEY_STATUS = prefixString(prefix, 'UPDATE_SURVEY_STATUS');
export const UPDATE_SURVEY_MANAGER = prefixString(prefix, 'UPDATE_SURVEY_MANAGER');
export const GET_SURVEY_MANAGERS = prefixString(prefix, 'GET_SURVEY_MANAGERS');
export const GET_SURVEY_STATUSES = prefixString(prefix, 'GET_SURVEY_STATUSES');
export const GET_COMPANY_NAMES = prefixString(prefix, 'GET_COMPANY_NAMES');
export const GET_COMPANY_NAMES_SUCCESS = prefixString(prefix, 'GET_COMPANY_NAMES_SUCCESS');
export const GET_SOLUTION_LIST = prefixString(prefix, 'GET_SOLUTION_LIST');

export const FETCH_SURVEY_LIST = prefixString(prefix, 'FETCH_SURVEY_LIST');
export const UPDATE_SURVEY_LIST = prefixString(prefix, 'UPDATE_SURVEY_LIST');
export const SET_SURVEY_LIST = prefixString(prefix, 'SET_SURVEY_LIST');
export const SET_SURVEY_LIST_FILTER = prefixString(prefix, 'SET_SURVEY_LIST_FILTER');
export const UPLOAD_SURVEY_REPORT_FILE = prefixString(prefix, 'UPLOAD_SURVEY_REPORT_FILE');
export const DELETE_SURVEY_REPORT_FILE = prefixString(prefix, 'DELETE_SURVEY_REPORT_FILE');

export const UPLOADING_REPORT_FILE = prefixString(prefix, 'UPLOADING_REPORT_FILE');
export const SET_UPLOADING_REPORT_FILE = prefixString(prefix, 'SET_UPLOADING_REPORT_FILE');

export const SET_SOLUTION_SETTING_EXAMPLE_TYPE = prefixString(prefix, 'SET_SOLUTION_SETTING_EXAMPLE_TYPE');
export const ATTACH_REPORT_EXAMPLE_FILE = prefixString(prefix, 'ATTACH_REPORT_EXAMPLE_FILE');
export const DELETE_REPORT_EXAMPLE_FILE = prefixString(prefix, 'DELETE_REPORT_EXAMPLE_FILE');
