import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'styled-bootstrap-grid';

import generateUUID from 'helpers/generateUUID';

import * as solutionSelectors from 'store/solution/selectors';
import { getValidationErrors } from 'store/errors/selectors';

import { validateField } from 'services/validation';

import { AudienceFormWrapper } from 'components/AudienceForm/styled';
import { FormBlockTitle } from 'components/SurveySetup/FormBlockTitle/FormBlockTitle';
import { AuthorizedField } from 'components/AuthorizedField/AuthorizedField';
import * as solutionConstants from 'components/SurveySetup/forms/solutionConfig';

import { MultiSelect } from 'uiComponents/MultiSelect';
import FormField from 'uiComponents/Form/Field';

const solutionDetailsField = {
  geographyId: 'geographies',
  audienceId: 'audiences',
};

const messages = {
  geographyId: {
    label: 'География',
    labelBHT: 'География',
    placeholder: 'Выберите географию',
  },
  audienceId: {
    label: 'Количество интервью',
    labelBHT: 'Количество интервью в одну волну',
    placeholder: 'Выберите охват',
  },
};

const AudienceForm = ({ onChange, data = {}, optionalDescription, labelKey }) => {
  const solutionDetails = useSelector(solutionSelectors.getSolutionDetails);

  const solutionName = useSelector(solutionSelectors.getSolutionName);

  const validationErrors = useSelector(getValidationErrors);

  const isSubmittedForm = useSelector(solutionSelectors.isSubmittedForm);

  const solutionDataConfig = solutionConstants.solutionsConfig.find(((item) => item.name === solutionName));

  const [ defaultSelectData, setDefaultSelectData ] = useState({});

  const selectHandler = (selectData, dataKey) => {
    onChange({
      [dataKey]: Number(selectData.id),
    });

    setDefaultSelectData((prevState) => ({
      ...prevState,
      [dataKey]: selectData,
    }));
  };

  const isValidOption = (optionName) => {
    if (isSubmittedForm) {
      return (
        Object.values(
          validateField(
            data[optionName],
            [ solutionDataConfig.createSurveyRules[optionName] ],
          ),
        ).includes(false)
      );
    }

    return optionName in validationErrors;
  };

  return (
    <AudienceFormWrapper>
      <Row>
        <Col>
          <FormBlockTitle
            title="Выборка"
            subtitle="Выберите количество респондентов и географию опроса:"
          />
        </Col>
      </Row>
      <Row>
        {Object.keys(data).map((dataKey) => (
          <Col
            key={generateUUID()}
            col={12}
            md={6}
          >
            <FormField>
              <label htmlFor={dataKey}>
                {
                  labelKey ?
                    messages[dataKey][labelKey] :
                    messages[dataKey].label
                }
              </label>
              <AuthorizedField>
                <MultiSelect
                  name={dataKey}
                  error={isValidOption(dataKey)}
                  isSearchable={false}
                  defaultValue={defaultSelectData[dataKey]}
                  getOptionValue={(option) => option.id}
                  getOptionLabel={(option) => option.name}
                  options={solutionDetails[solutionDetailsField[dataKey]]}
                  placeholder={messages[dataKey].placeholder}
                  onChange={(selectData) => selectHandler(selectData, dataKey)}
                />
              </AuthorizedField>
            </FormField>
          </Col>
        ))}
      </Row>
      {optionalDescription && (
        <Row>
          <Col>
            <p>{optionalDescription}</p>
          </Col>
        </Row>
      )}
    </AudienceFormWrapper>
  );
};

export default AudienceForm;
