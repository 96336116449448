import React from 'react';
import { Row, Col } from 'styled-bootstrap-grid';

import { connect } from 'react-redux';

import * as userActions from 'store/user/actions';
import * as errorsActions from 'store/errors/actions';
import * as errorsSelectors from 'store/errors/selectors';

import getErrorMessage from 'helpers/errors/getErrorMessage';

import FormField from 'uiComponents/Form/Field';
import { Input } from 'uiComponents/Input/inputStyles';
import { RouterLink } from 'uiComponents/Link';

import { SignInFormWrapper, StyledSigninButton } from 'components/SignInForm/styled';

const mapStateToProps = (state) => ({
  validationErrors: errorsSelectors.getValidationErrors(state),
  requestErrors: errorsSelectors.getRequestErrors(state),
});

const mapDispatchToProps = (dispatch) => ({
  submitForm: (signInData) => dispatch(userActions.signIn(signInData)),
  clearValidationErrors: () => dispatch(errorsActions.clearValidationErrors()),
  clearRequestErrors: () => dispatch(errorsActions.clearRequestErrors()),
});

@connect(mapStateToProps, mapDispatchToProps)
class SignInForm extends React.Component {
  static defaultProps = {
    validationErrors: {},
  };

  state ={
    login: '',
    password: '',
  };

  // todo: Вынести в обёртку
  componentWillUnmount() {
    this.props.clearRequestErrors();
  }

  handleInputChange = ({ target }) => {
    this.setState({
      ...this.state,
      [target.name]: target.value,
    });
  };

  submitForm = () => {
    this.props.submitForm(this.state);
  };

  render() {
    const {
      requestErrors,
      validationErrors,
      isAuthorizing,
    } = this.props;

    const loginError =
      'login' in validationErrors
      || requestErrors.includes('InvalidLoginOrPassword')
      || requestErrors.includes('EmailNotConfirmed');

    const passwordError =
      'password' in validationErrors
      || requestErrors.includes('InvalidLoginOrPassword');

    return (
      <SignInFormWrapper>
        <Row>
          <Col
            col={12}
            md={6}
            xl={4}
          >
            <FormField>
              <label htmlFor="login">Введите E-Mail</label>
              <Input
                id={'login'}
                type={'email'}
                name={'login'}
                placeholder={'E-Mail'}
                defaultValue={this.state.email}
                onChange={this.handleInputChange}
                error={loginError}
              />
              {requestErrors.length > 0 && (
                <p>
                  {requestErrors.map((requestError) => (
                    <span key={requestError}>{getErrorMessage(requestError)}</span>
                  ))}
                </p>
              )}
            </FormField>
          </Col>
          <Col
            col={12}
            md={6}
            xl={4}
          >
            <FormField>
              <label htmlFor="password">Введите пароль</label>
              <Input
                id={'password'}
                type={'password'}
                name={'password'}
                placeholder={'Пароль'}
                defaultValue={this.state.password}
                onChange={this.handleInputChange}
                error={passwordError}
              />
            </FormField>
          </Col>
        </Row>
        <Row>
          <Col
            col={12}
            md={6}
            xl={4}
          >
            <StyledSigninButton
              isLoading={isAuthorizing}
              onClick={this.submitForm}
            >
              Войти
            </StyledSigninButton>
          </Col>
        </Row>
        <Row>
          <Col
            col={12}
            md={6}
            xl={4}
          >
            <RouterLink to={'/password-recovery'}>
              Забыли пароль?
            </RouterLink>
          </Col>
        </Row>
      </SignInFormWrapper>
    );
  }
}

export default SignInForm;
