import React from 'react';
import styled from 'styled-components';
import Icon from 'uiComponents/Icon';

const SelectWrapper = styled.div`
  position: relative;
  & select {
    appearance: none;
    width: 100%;
    padding: 14px 16px;

    border: 1px solid ${(props) => props.error ? props.theme.colors.orange : props.theme.colors.darkGrey};
    border-radius: 6px;

    background: white;

    color: black;
    font-size: 16px;
    line-height: 20px;
  }
  & svg {
    width: 1em;
    height: 1em;
    position: absolute;
    margin-top: -.5em;
    top: 50%;
    right: 1em;
    pointer-events: none;
  }
`;

const Select = ({ data, placeholder, error, requestError, ...props }) => {
  if (!data) {
    return null;
  }
  const hasValue = props.hasOwnProperty('value');

  return (
    <SelectWrapper error={error}>
      <Icon
        name={'arrow'}
        fill={'black'}
      />
      <select {...props}>
        {placeholder && (
          <option
            {...(hasValue && props.value == null ? { selected: 'select' } : {})}
            hidden
          >{placeholder}</option>
        )}
        {data.map((option) => (
          <option
            key={option.id}
            value={option.id}
            {...(hasValue && option.id === props.value ? { selected: 'selected' } : {})}
          >
            {option.name}
          </option>
        ))}
      </select>
    </SelectWrapper>
  );
};

export default Select;
