import React from 'react';

import { useLocation } from 'react-router-dom';

import { useSelector } from 'react-redux';

import * as userSelectors from 'store/user/selectors';

import {
  managerMenuConfiguration,
  userMenuConfiguration,
} from 'components/layouts/MainLayout/Header/menuConfig';
import {
  StyledMobileMenuWrapper,
  StyledMobileLink,
  StyledMobileListLinks,
  UserMenuListLinks,
} from 'components/layouts/MainLayout/Header/MenuItems/styled';

import { scrollInToView } from 'helpers/scrollInToView';

export const MenuItems = (props) => {
  const {
    isShowUserMenu,
    handleClickMobileLink,
    menuItems,
  } = props;

  const location = useLocation();

  const isManager = useSelector(userSelectors.isManager);

  const isHomePage = location.pathname === '/';

  const getDropdownMenuConfig = () => {
    if (isManager) {
      return managerMenuConfiguration;
    }

    return userMenuConfiguration;
  };

  const renderMenuItem = (item) => (
    <StyledMobileLink
      key={item.title}
      to={item.link}
      onClick={handleClickMobileLink}
      scroll={(el) => scrollInToView(el, item)}
    >
      {item.title}
    </StyledMobileLink>
  );

  return (
    <StyledMobileMenuWrapper>
      <StyledMobileListLinks
        isManager={isManager}
        isHomePage={isHomePage}
        isShowUserMenu={isShowUserMenu}
      >
        {menuItems.map(renderMenuItem)}
      </StyledMobileListLinks>

      {isHomePage && (
        <UserMenuListLinks isShowUserMenu={isShowUserMenu}>
          {getDropdownMenuConfig().map(renderMenuItem)}
        </UserMenuListLinks>
      )}
    </StyledMobileMenuWrapper>
  );
};
