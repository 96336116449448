import React from 'react';
import { StyledHowItWorkItem } from './styled';
import { StyledSubtitle } from 'pages/HomePage/HowItWorkItem/styled';

export const HowItWorkItem = (props) => {
  const {
    title,
    subtitle,
    col,
  } = props;
  return (
    <StyledHowItWorkItem col={col}>
      <div className="info">
        {title && (
          <div className="info__title">{title}</div>
        )}
        <StyledSubtitle>{subtitle}</StyledSubtitle>
      </div>
    </StyledHowItWorkItem>
  );
};
