import React from 'react';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import { PageTitle } from 'uiComponents/Titles';

export const MethodologyACSIPage = () => (
  <Container>
    <Row>
      <Col col={12}>
        <PageTitle title={'Методология'} />
      </Col>
    </Row>
    <Row>
      <Col col={12}>
        <div className="block">
          <p className="text">
            <strong>Оценка удовлетворенности и лояльности клиентов</strong>
              с использованием <strong>индекса ACSI </strong>
              (American Customer Satisfaction Index) основан на эконометрической модели, связывающей
              оценку удовлетворенности, соответствие ожиданий от сервиса и реальной работы компании и
              сравнительную оценку положения компании в конкурентной среде в единый
            <strong> индикатор-индекс лояльности.</strong>
          </p>

          <p className="text">
              Метод был разработан в 1994 году Национальным Центром Исследований Качества Сервиса
              (National Quality Research Center) в США на основе модели SCSB. Модель особенно популярна на
              американском рынке, где принята как государственный стандарт. Модель принята на уровне
              государства также в Индии, Сингапуре, ОАЭ, Южной Африке и странах Латинской Америки.
          </p>
        </div>

        <div className="block">
          <h3 className="title">Методика оценки лояльности клиентов ACSI включает три шага:</h3>

          <h4 className="subtitle">1. Непосредственное измерение индекса ACSI</h4>

          <p className="text">Потребителям предлагается ответить на три вопроса, оценив по шкале от 1 до 10:</p>

          <ul className="list">
            <li className="list__item"><strong>Удовлетворённость</strong> сотрудничеством с компанией</li>
            <li className="list__item"><strong>Соответствие</strong> сервиса компании <strong>ожиданиям</strong></li>
            <li className="list__item">
              <strong>Сравнительное</strong> положение компании среди
              <strong>конкурентов</strong>
            </li>
          </ul>

          <p className="text">
              Индекс ACSI рассчитывается как средневзвешенное значение средних оценок по описанным выше
              компонентам:
          </p>

          <h4
            className="subtitle"
            style={{ textAlign: 'center' }}
          >
            ACSI = (Удовлетворенность + Соответсвие Ожиданиям
              + Сравнение с Конкурентами -3) / 27 * 100
          </h4>

          <h4 className="subtitle">2. Качественная диагностика</h4>

          <p className="text">
              Для формирования рекомендаций по улучшению работы сервиса компании участникам
              исследования задается вопрос: «Если бы вы могли изменить что-либо одно в работе компании,
              что бы вы сделали?»
          </p>

          <p className="text">
              Ответы на этот вопрос позволяют компаниям определить наиболее важные факторы, влияющие
              на формирование потребительской лояльности.
          </p>

          <p className="text">
            <strong>3. Оценка точек взаимодействия</strong> помогает компании понять слабые и сильные стороны своего
              сервиса и сформировать рекомендации по улучшению своей операционной работы.
          </p>

          <p className="text">
              Для этого участников исследования просят оценить удовлетворенность отдельными элементами
              сервиса компании.
          </p>
        </div>
      </Col>
    </Row>
  </Container>
);
