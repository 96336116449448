import styled from 'styled-components';
import { H2, PageTitle } from 'uiComponents/Titles';
import { media } from 'styled-bootstrap-grid';

export const StyledPage = styled('div')`
  color: #202020;
  padding-bottom: 96px;

  & ${PageTitle} {
    margin-bottom: 36px;
  }

  ${H2} {
    margin-bottom: 0;

    font-size: 44px;
    line-height: 54px;
  }

  .block {
    margin-bottom: 36px;
  }

  .title {
    margin-top: 0;
    margin-bottom: 36px;
    font-weight: bold;
    font-size: 26px;
    line-height: 32px;
  }

  .subtitle {
    margin-bottom: 20px;

    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
  }

  .text {
    margin-bottom: 20px;

    font-size: 16px;
    line-height: 20px;
  }

  .link {
    font-size: 16px;
    line-height: 20px;

    text-decoration:none;
    color: ${({ theme }) => theme.colors.orange};
  }

  .MethodologyTable {

  }
  .MethodologyRow {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;

    h4 {
      margin: 0 64px 0 0;
      flex: 0 0 258px;
      ${media.xs`
        margin-right: 32px;
      `}
    }

    p {
      margin: 0;
    }

    ${media.xl`
      flex-direction: row;
    `}
  }
`;
