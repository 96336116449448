import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

export const StyledFormBlockTitle = styled('div')`
  margin-bottom: 24px;

  ${media.md`
    margin-bottom: 48px;
  `}

  & .subtitle-tooltip {
    margin-top: 14px!important;

    max-width: 290px;

    ${media.sm`
      max-width: 400px;
    `}

    ${media.tablet`
      max-width: 40%;
    `}

    ${media.desktop`
      max-width: 33%;
    `}

    ${media.xl`
      max-width: 29%;
    `}

    ${media.xxl`
      max-width: 500px;
    `}
  }
`;

export const TextTitle = styled('h4')`
  margin-top: 0;
  margin-bottom: 16px;

  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;

  ${media.tablet`
    font-size: 26px;
    line-height: 32px;
  `}
`;

export const Subtitle = styled('p')`
  margin: 0;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: ${(props) => props.theme.colors.black};
`;

export const TextHint = styled('span')`
  padding-left: 24px;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: ${(props) => props.theme.colors.orange};
`;
