import React from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { UserInfoItem, UserInfoLabel, UserInfoListStyles, UserInfoValue } from 'components/UserInfo/styles';
import { Input } from 'uiComponents/Input/inputStyles';
import { PhoneInput } from 'uiComponents/Input';
import { profileDataSelector } from 'store/user/selectors';

const getListLabel = (profileDataFieldKey) => {
  switch (profileDataFieldKey) {
    case 'id':
      return 'ID';
    case 'companyName':
      return 'Компания';
    case 'email':
      return 'E-mail';
    case 'lastName':
      return 'Фамилия';
    case 'firstName':
      return 'Имя';
    case 'phoneNumber':
      return 'Телефон';
    default:
      return '';
  }
};

export const UserInfoList = (props) => {
  const { isEdit, onSubmit } = props;

  const { handleSubmit, register } = useForm();

  const profileData = useSelector(profileDataSelector);

  const renderUserInfoValue = (fieldKey) => {
    if (!isEdit) {
      if (fieldKey === 'id' || fieldKey === 'companyName') {
        return (
          <UserInfoValue>
            {profileData[fieldKey]}
          </UserInfoValue>
        );
      }

      if (fieldKey === 'phoneNumber') {
        return (
          <PhoneInput
            ref={register({ required: true })}
            name={fieldKey}
            defaultValue={profileData[fieldKey]}
          />
        );
      }

      return (
        <Input
          ref={register({ required: true })}
          name={fieldKey}
          placeholder={getListLabel(fieldKey)}
          defaultValue={profileData[fieldKey]}
        />);
    }
    return (
      <UserInfoValue>
        {profileData[fieldKey]}
      </UserInfoValue>
    );
  };

  const renderUserInfoItems = () => Object.keys(profileData).map((fieldKey) => {
    if (fieldKey === 'role' || fieldKey === 'resendActivationEmailToken') {return null;}

    return (
      <UserInfoItem
        isEdit={isEdit}
        key={fieldKey}
      >
        <UserInfoLabel>
          {`${getListLabel(fieldKey)} :`}
        </UserInfoLabel>
        {renderUserInfoValue(fieldKey)}
      </UserInfoItem>
    );
  });

  return (
    <React.Fragment>
      <UserInfoListStyles>
        <form
          onSubmit={handleSubmit(onSubmit)}
          id="profile-form"
        >
          {renderUserInfoItems()}
        </form>
      </UserInfoListStyles>
    </React.Fragment>
  );
};
