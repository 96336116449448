import { takeLatest, takeEvery, put, call, select, throttle } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import omit from 'lodash/omit';
import _get from 'lodash/get';
import * as dayjs from 'dayjs';

import { TYPES } from 'constants/questions';
import { SOLUTIONS } from 'constants/solutions';
import { SOLUTIONS_CUSTOM_ERROR_HANDLER } from 'helpers/solutions';

import { getRequestErrors } from 'store/errors/selectors';
import { getUserRole } from 'store/user/selectors';
import { validation } from 'store/user/saga';

import * as solutionSelectors from 'store/solution/selectors';
import * as solutionActions from 'store/solution/actions';
import * as errorsActions from 'store/errors/actions';
import * as solutionTypes from 'store/solution/types';

import * as solutionApi from 'api/solution';
import * as surveyApi from 'api/survey';

function* uploadFileSolutionData(action) {
  const {
    payload: {
      files,
      requestName,
      name,
      solutionName,
    },
  } = action;

  const namesFieldUploadingFiles = [
    ...yield select(solutionSelectors.getNamesFieldUploadingFiles),
    name,
  ];

  yield put(solutionActions.uploadFieldNameUploadFiles(namesFieldUploadingFiles));

  let uploadedFileIds;

  const formData = new FormData();

  for (let i = 0; i < files.length; i++) {
    uploadedFileIds = yield select(solutionSelectors.uploadingFileIds);

    formData.append('file', files[i].file);
    if (solutionName) {
      formData.append('solutionCode', solutionName);
    }

    try {
      const { result, success, error } = yield call(
        surveyApi.fileUpload,
        formData,
        requestName,
      );

      if (success) {
        yield put(solutionActions.uploadFileSolutionDataSuccess({
          name: name,
          fileIds: {
            ...uploadedFileIds,
            [files[i]._id]: {
              id: result.id,
              _id: files[i]._id,
            },
          },
        }));
      }

      if (error) {
        yield put(errorsActions.setRequestError(error));
      }
    } catch (e) {
      console.warn(e);
    }
  }

  const findIndex = namesFieldUploadingFiles.findIndex((item) => item === name);
  const newNamesFieldUploadingFiles = [
    ...namesFieldUploadingFiles.slice(0, findIndex),
    ...namesFieldUploadingFiles.slice(findIndex + 1),
  ];

  yield put(solutionActions.uploadFieldNameUploadFiles(newNamesFieldUploadingFiles));
}

function* fetchSurveyData(action) {
  const {
    surveyId,
    isGlobalLoading,
  } = action.payload;

  yield put(errorsActions.clearRequestErrors());

  try {
    const role = yield select(getUserRole);

    const { result, success, error } = yield call(
      surveyApi.getSurveyData,
      surveyId,
      role.toLowerCase(),
      isGlobalLoading,
    );

    if (success) {
      yield put(solutionActions.updateSolutionData(result.solution));
      yield put(solutionActions.updateSurveyData(result));
    }

    if (error === 'SurveyNotFound') {
      yield put(push('/not-found'));
    }

    if (error) {
      yield put(errorsActions.setRequestError(error));
      yield put(solutionActions.updateSolutionData(result.solution));
    }
  } catch (e) {
    console.warn(e);
  }
}

function* uploadSurveyReportFile(action) {
  yield put(solutionActions.uploadingReportFile(true));

  const surveyId = yield select(solutionSelectors.getSurveyId);
  const file = action.payload;

  const formData = new FormData();
  formData.append('file', file);
  formData.append('surveyId', surveyId);

  try {
    const { success, error } = yield call(surveyApi.attachFileReport, formData);

    if (success) {
      yield fetchSurveyData({ payload: { surveyId, isGlobalLoading: false } });

      yield put(solutionActions.uploadingReportFile(false));
    }

    if (error) {
      yield put(errorsActions.setRequestError(error));

      yield put(solutionActions.uploadingReportFile(false));
    }
  } catch (e) {
    console.warn(e);
  }
}

function* deleteSurveyReportFile(action) {
  try {
    yield put(solutionActions.uploadingReportFile(true));
    const { success, error } = yield call(surveyApi.deleteFileReport, { fileId: action.payload });

    if (success) {
      yield fetchSurveyData({
        payload: {
          surveyId: yield select(solutionSelectors.getSurveyId),
          isGlobalLoading: false,
        },
      });

      yield put(solutionActions.uploadingReportFile(false));
    }

    if (error) {
      yield put(errorsActions.setRequestError(error));

      yield put(solutionActions.uploadingReportFile(false));
    }
  } catch (e) {
    console.warn(e);
  }
}

function* uploadingReportFile(action) {
  yield put(solutionActions.setUploadingReportFile(action.payload));
}

function* fetchSolutionDetails(action) {
  if (!_get(action, 'subOption.withoutClearErrors', false)) {
    yield put(errorsActions.clearRequestErrors());
  }

  try {
    const { result, success, error } = yield call(
      solutionApi.getSolutionDetails,
      action.payload.solutionName || '',
      action.subOption || {},
    );

    if (success) {
      yield put(solutionActions.setSolutionDetails({
        details: result,
        solutionData: action.payload,
      }));
    }

    if (error) {
      yield put(errorsActions.setRequestError(error));
    }

  } catch (e) {
    console.warn(e);
  }
}

function* submitSolutionForm(action) {
  const {
    payload,
  } = action;

  yield put(errorsActions.clearRequestErrors());
  yield put(solutionActions.setSubmittedSolutionData(true));

  try {
    const solutionCreateData = {
      ...yield select(solutionSelectors.getSolutionCreateData),
      solutionCode: yield select(solutionSelectors.getSolutionName) || '',
    };

    const additionalQuestions = yield select(solutionSelectors.getAdditionalQuestions);

    if (additionalQuestions) {
      solutionCreateData.additionalQuestions = additionalQuestions
        .filter((question) => question.text.trim().length !== 0)
        .map((question) => ({ text: question.text }));
    }

    const characteristics = yield select(solutionSelectors.getCharacteristics);
    const checkedCharacteristics = yield select(solutionSelectors.getCheckedCharacteristics);

    if (characteristics) {
      solutionCreateData.characteristics = characteristics
        .filter((question) => question && question.text && question.text.trim().length !== 0)
        .map((question) => question.text);
    }

    if (checkedCharacteristics) {
      solutionCreateData.characteristics.unshift(
        ...checkedCharacteristics
          .filter((checkedCharacteristicItem) => checkedCharacteristicItem.checked)
          .map((checkedCharacteristicItem) => checkedCharacteristicItem.text),
      );
    }

    const competitors = yield select(solutionSelectors.getCompetitors);

    if (competitors) {
      solutionCreateData.competitors = competitors
        .filter((question) => question.text.trim().length !== 0)
        .map((question) => question.text);
    }

    const statements = yield select(solutionSelectors.getStatements);

    if (statements) {
      solutionCreateData.statements = statements
        .filter((question) => question.text.trim().length !== 0)
        .map((question) => question.text);
    }

    const productPrice = yield select(solutionSelectors.getProductPrice);

    if (productPrice || productPrice === '') {
      solutionCreateData.productPrice = productPrice ? productPrice.trim() : '-';
    }

    const names = yield select(solutionSelectors.getProductNames);

    if (names) {
      solutionCreateData.names = names
        .filter((question) => question.text.trim().length !== 0)
        .map((question) => question.text);
    }

    const uploadingFileIds = yield select(solutionSelectors.uploadingFileIds);

    if (uploadingFileIds) {
      solutionCreateData.fileIds = Object.keys(uploadingFileIds).map((key) => (
        uploadingFileIds[key].id
      ));
    }

    const additionalFileId = yield select(solutionSelectors.additionalFileId);

    if (additionalFileId) {
      if (Object.keys(additionalFileId).length !== 0) {
        solutionCreateData.additionalFileId = additionalFileId[Object.keys(additionalFileId)[0]].id;
      } else {
        delete solutionCreateData.additionalFileId;
      }
    }

    const questionsList = yield select(solutionSelectors.getCreatedQuestions);

    if (questionsList) {
      solutionCreateData.questions = questionsList.reduce((
        newQuestionsList,
        {
          answerOptions,
          ...questionItemFields
        },
      ) => {

        if (questionItemFields.text) {

          if (questionItemFields.questionType === TYPES.open) {
            newQuestionsList.push(questionItemFields); // remove answerOptions from open question
          } else {
            const validAnswers = answerOptions.filter((answerOptionItem) => (
              Boolean(_get(answerOptionItem, 'text.length', 0))
            ));

            if (validAnswers.length >= 2) {
              newQuestionsList.push({
                ...questionItemFields,
                answerOptions: validAnswers,
              });
            }
          }
        }

        return newQuestionsList;
      }, []);
    }

    const rankingItems = yield select(solutionSelectors.getRankingItems);

    if (rankingItems) {
      solutionCreateData.rankingItems = rankingItems;
    }

    const validationErrors = yield call(
      validation,
      solutionCreateData,
      payload.rules,
    );

    if (rankingItems) {
      solutionCreateData.rankingItems = rankingItems.reduce((rankingList, rankingElement) => {
        if (solutionCreateData.rankingType === TYPES.text && rankingElement[TYPES.text]) {
          rankingList.push({
            text: rankingElement[TYPES.text],
          });
        } else {
          const localIdOfFile = _get(rankingElement, [ TYPES.image, '_id' ]);
          const fileId = _get(uploadingFileIds, [ localIdOfFile, 'id' ], null);

          if (fileId !== null) {
            rankingList.push({
              fileId: _get(uploadingFileIds, [ localIdOfFile, 'id' ], null),
            });
          }
        }

        return rankingList;
      }, []);
    }

    const uploadFileError = yield select(getRequestErrors);

    if (solutionCreateData.solutionCode === SOLUTIONS.Consultation) {
      const currentConsultationDate = dayjs(_get(solutionCreateData, 'dateState.value')).format('YYYY.MM.DD');
      solutionCreateData.consultationTime = (
        `${currentConsultationDate}T${solutionCreateData.consultationTime}:00`
      );
    }

    if (!Object.keys(validationErrors).length && !uploadFileError.length) {
      const { success, error, errors } = yield call(surveyApi.create, solutionCreateData);

      if (success) {
        yield put(solutionActions.setSubmittedSolutionData(false));
        yield put(solutionActions.setSubmittedSolutionSuccess(true));
        yield put(solutionActions.deleteFileSolutionData([]));
      }

      if (error) {
        yield put(errorsActions.setRequestError(error));
        if (SOLUTIONS_CUSTOM_ERROR_HANDLER[solutionCreateData.solutionCode]) {
          yield SOLUTIONS_CUSTOM_ERROR_HANDLER[solutionCreateData.solutionCode](error, undefined, solutionCreateData);
        }
      } else if (errors) {
        yield put(errorsActions.setRequestErrorsList(errors));
        if (SOLUTIONS_CUSTOM_ERROR_HANDLER[solutionCreateData.solutionCode]) {
          yield SOLUTIONS_CUSTOM_ERROR_HANDLER[solutionCreateData.solutionCode](error, errors, solutionCreateData);
        }
      }
    }

  } catch (e) {
    console.warn(e);
  }
}

function* fetchSurveyList({ payload = {} }) {
  const { isGlobalLoading } = payload;

  yield put(errorsActions.clearRequestErrors());

  try {
    const filters = yield select(solutionSelectors.getSurveyListFilters);

    const role = yield select(getUserRole);

    const { result, success, error } = yield call(
      surveyApi.getSurveyList,
      filters,
      role.toLowerCase(),
      isGlobalLoading,
    );

    if (success) {
      yield put(solutionActions.setSurveyList({
        items: result.surveys,
        total: result.total,
        totalFound: result.total,
      }));
    }

    if (error) {
      yield put(errorsActions.setRequestError(error));
    }

  } catch (e) {
    console.warn(e);
  }
}

function* fetchListSurveyAfterFiltering({ payload = {} }) {
  const { isGlobalLoading } = payload;

  yield put(errorsActions.clearRequestErrors());

  try {
    const filters = yield select(solutionSelectors.getSurveyListFilters);
    const total = yield select(solutionSelectors.getSurveyListTotal);

    const role = yield select(getUserRole);

    const { result, success, error } = yield call(
      surveyApi.getSurveyList,
      filters,
      role.toLowerCase(),
      isGlobalLoading,
    );

    if (success) {
      yield put(solutionActions.setSurveyList({
        items: result.surveys,
        total,
        totalFound: result.total,
      }));
    }

    if (error) {
      yield put(errorsActions.setRequestError(error));
    }

  } catch (e) {
    console.warn(e);
  }

}

function* updateSurveyStatus({ payload }) {
  yield put(errorsActions.clearRequestErrors());

  const {
    id,
    name,
    surveyId,
    isGlobalLoading,
  } = payload;

  try {
    const { success, error } = yield call(surveyApi.updateStatus, {
      surveyId,
      statusId: id,
    });

    if (success) {
      yield put(solutionActions.fetchSurveyData({ surveyId, isGlobalLoading }));

      yield put(solutionActions.updateSurveyData({ status: { id, name } }));
    }

    if (error) {
      yield put(errorsActions.setRequestError(error));
    }

  } catch (e) {
    console.warn(e);
  }
}

function* updateSurveyManager({ payload }) {
  yield put(errorsActions.clearRequestErrors());

  const {
    id,
    surveyId,
  } = payload;

  try {
    const { success, error } = yield call(surveyApi.updateManager, {
      surveyId,
      managerId: id,
    });

    if (success) {
      yield put(solutionActions.updateSurveyData({
        manager: omit(payload, [ 'name', 'surveyId' ]),
      }));
    }

    if (error) {
      yield put(errorsActions.setRequestError(error));
    }

  } catch (e) {
    console.warn(e);
  }
}

function* getSurveyManagers() {
  yield put(errorsActions.clearRequestErrors());

  try {
    const { result, success, error } = yield call(surveyApi.getManagers);

    const { managers } = result;

    if (success) {
      yield put(solutionActions.updateSurveyData({ managers }));
    }

    if (error) {
      yield put(errorsActions.setRequestError(error));
    }

  } catch (e) {
    console.warn(e);
  }
}

function* getSurveyStatuses() {
  yield put(errorsActions.clearRequestErrors());

  try {
    const { result, success, error } = yield call(surveyApi.getStatuses);

    const { statuses } = result;

    if (success) {
      yield put(solutionActions.updateSurveyData({ statuses }));
    }

    if (error) {
      yield put(errorsActions.setRequestError(error));
    }

  } catch (e) {
    console.warn(e);
  }
}

function* getCompanyNames() {
  yield put(errorsActions.clearRequestErrors());

  try {
    const { result, success, error } = yield call(surveyApi.getCompanyNames);

    if (success) {
      yield put(solutionActions.getCompanyNamesSuccess({
        companies: result.companies,
      }));
    }

    if (error) {
      yield put(errorsActions.setRequestError(error));
    }
  } catch (e) {
    console.warn(e);
  }
}

function* getSolutionList() {
  yield put(errorsActions.clearRequestErrors());

  try {
    const { result, success, error } = yield call(surveyApi.getSolutionList);

    if (success) {
      yield put(solutionActions.updateSurveyList({
        solutionList: result.solutions,
      }));
    }

    if (error) {
      yield put(errorsActions.setRequestError(error));
    }
  } catch (e) {
    console.warn(e);
  }
}

function* attachReportExampleFile(action) {
  yield put(solutionActions.uploadingReportFile(true));

  const solutionName = yield select(solutionSelectors.getExamplesSurveyTypeSelector);
  const file = action.payload;

  const formData = new FormData();
  formData.append('file', file);
  formData.append('SolutionCode', solutionName);

  try {
    const { success, error } = yield call(surveyApi.attachReportExampleFile, formData);

    if (success) {
      yield fetchSolutionDetails({ payload: { solutionName, isGlobalLoading: false } });

      yield put(solutionActions.uploadingReportFile(false));
    }

    if (error) {
      yield put(errorsActions.setRequestError(error));

      yield put(solutionActions.uploadingReportFile(false));
    }
  } catch (e) {
    console.warn(e);
  }
}

function* deleteReportExampleFile(action) {
  try {
    yield put(solutionActions.uploadingReportFile(true));
    const { success, error } = yield call(surveyApi.deleteReportExampleFile, { fileId: action.payload });

    if (success) {
      yield fetchSolutionDetails({
        payload: {
          solutionName: yield select(solutionSelectors.getExamplesSurveyTypeSelector),
          isGlobalLoading: false,
        },
      });

      yield put(solutionActions.uploadingReportFile(false));
    }

    if (error) {
      yield put(errorsActions.setRequestError(error));

      yield put(solutionActions.uploadingReportFile(false));
    }
  } catch (e) {
    console.warn(e);
  }
}

export default function* solutionSaga() {
  yield takeLatest(solutionTypes.GET_SOLUTION_DETAILS, fetchSolutionDetails);
  yield takeLatest(solutionTypes.SUBMIT_SOLUTION_SETUP_FORM, submitSolutionForm);
  yield takeLatest(solutionTypes.FETCH_SURVEY_DATA, fetchSurveyData);
  yield takeLatest(solutionTypes.FETCH_SURVEY_LIST, fetchSurveyList);
  yield takeEvery(solutionTypes.UPLOAD_FILE_SOLUTION_DATA, uploadFileSolutionData);
  yield takeEvery(solutionTypes.UPLOAD_SURVEY_REPORT_FILE, uploadSurveyReportFile);
  yield takeEvery(solutionTypes.DELETE_SURVEY_REPORT_FILE, deleteSurveyReportFile);
  yield throttle(600, solutionTypes.UPLOADING_REPORT_FILE, uploadingReportFile);
  yield takeLatest(solutionTypes.SET_SURVEY_LIST_FILTER, fetchListSurveyAfterFiltering);
  yield takeLatest(solutionTypes.UPDATE_SURVEY_STATUS, updateSurveyStatus);
  yield takeLatest(solutionTypes.UPDATE_SURVEY_MANAGER, updateSurveyManager);
  yield takeLatest(solutionTypes.GET_SURVEY_MANAGERS, getSurveyManagers);
  yield takeLatest(solutionTypes.GET_SURVEY_STATUSES, getSurveyStatuses);
  yield takeLatest(solutionTypes.GET_COMPANY_NAMES, getCompanyNames);
  yield takeLatest(solutionTypes.GET_SOLUTION_LIST, getSolutionList);

  yield takeLatest(solutionTypes.ATTACH_REPORT_EXAMPLE_FILE, attachReportExampleFile);
  yield takeLatest(solutionTypes.DELETE_REPORT_EXAMPLE_FILE, deleteReportExampleFile);
}
