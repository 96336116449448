import styled from 'styled-components';

import {
  Row,
  Col,
  media,
} from 'styled-bootstrap-grid';

import arrowDown from 'assets/images/arrowDown.png';

export const StyledFilterHeader = styled(Row)`
  display: none;

  margin-bottom: 16px;

  ${media.tablet`
    display: block;
  `}
`;

export const StyledFilterItemHeader = styled(Col)`
  .filter-control {
    font-weight: normal;
    font-size: 16px;
    line-height: 19px
  }

  .filter-control__button {
    padding: 0;
    margin: 0;

    border: none;

    background-color: transparent;

    &:hover {
      cursor: pointer;
    }
  }

  .filter-control__hide-button {
    position: relative;

    margin-right: 8px;
    padding-left: 26px;

    font-weight: 600;
    color: ${(props) => props.theme.colors.orange};

    &::after {
      content: '';

      position: absolute;
      top: 6px;
      left: 0;

      transform: ${({ isOpenFilterBlock }) => isOpenFilterBlock
    ? 'rotate(180deg)'
    : 'rotate(0)'};

      display: block;

      width: 10px;
      height: 6px;

      background-image: url(${arrowDown});
      background-repeat: no-repeat;
      background-position: center;

      transition: transform .3s;
    }
  }

  .filter-control__reset-button {
    color: ${(props) => props.theme.colors.darkGrey};
  }
`;

export const StyledFilterBlockWrapper = styled.div`
  display: none;

  margin-right: -15px;
  margin-left: -15px;
  padding: 0;
  margin-bottom: 78px;

  height: ${({ isOpenFilterBlock }) => isOpenFilterBlock
    ? 'auto'
    : 0};

  opacity: ${({ isOpenFilterBlock }) => isOpenFilterBlock
    ? 1
    : 0};

  background-color: ${(props) => props.theme.colors.lightGrey};

  border-radius: 6px;

  transition: padding .3s, height .3s, opacity .2s;

  ${media.tablet`
    display: block;
  `}

  ${media.xxl`
    margin-right: -55px;
    margin-left: -55px;

    padding: ${({ isOpenFilterBlock }) => isOpenFilterBlock
    ? '40px'
    : 0};
  `}
`;

export const StyledFilterBlock = styled(Row)`
  margin: 0;
  padding: 24px 0;

  ${media.xxl`
    padding: 0;
  `}
`;

export const StyledFilterItem = styled(Col)`
  margin: 24px 0;

  .label {
    margin-bottom: 16px;

    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: ${(props) => props.theme.colors.black};
  }

  .react-select__value-container {
    padding: 10px 0 10px 15px;
  }
`;
