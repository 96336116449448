import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { StyledConfirmAction } from './styled';
import { ConfirmButton } from 'components/ConfirmAction/styled';

const ConfirmAction = (props) => {
  const {
    isShow,
    message,
    subMessage,
    onConfirmedAction,
    onNotConfirmedAction,
  } = props;

  const onConfirmed = () => {
    onConfirmedAction();
  };

  const onNotConfirmed = () => {
    onNotConfirmedAction();
  };

  const handleClickWindow = (e) => {
    if (!e.target.closest('.inner-popup')) {
      onNotConfirmedAction();
    }
  };

  useEffect(() => {
    isShow && window.addEventListener('click', handleClickWindow);

    return () => {
      window.removeEventListener('click', handleClickWindow);
    };
  }, [ isShow ]);

  if (!isShow) {
    return null;
  }

  return (
    <StyledConfirmAction>
      <div className="inner-popup">
        <div className="message">{message}</div>
        {subMessage && (
          <div className="subMessage">{subMessage}</div>
        )}
        <div className="buttons">
          <ConfirmButton
            onClick={onNotConfirmed}
            viewType={'ghost'}
          >Отмена</ConfirmButton>
          {onConfirmedAction && (
            <ConfirmButton
              onClick={onConfirmed}
            >Подтвердить</ConfirmButton>
          )}
        </div>
      </div>
    </StyledConfirmAction>
  );
};

ConfirmAction.defaultProps = {
  isShow: false,
  message: 'Подтвердите действие',
  subMessage: '',
  onNotConfirmedAction: () => 1,
};

ConfirmAction.propTypes = {
  isShow: PropTypes.bool,
  message: PropTypes.string,
  subMessage: PropTypes.string,
  onConfirmedAction: PropTypes.func,
  onNotConfirmedAction: PropTypes.func,
};

export default ConfirmAction;
