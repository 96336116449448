import '@babel/polyfill';
import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import { BaseCSS, GridThemeProvider } from 'styled-bootstrap-grid';
import { Normalize } from 'styled-normalize';
import { Provider } from 'react-redux';
import configureStore, { history } from 'store';
import { ThemeProvider } from 'styled-components';
import { theme } from './theme';
import GlobalStyles from './globalStyles';

const gridTheme = {
  container: {
    padding: 15,
    maxWidth: {
      xxl: 1200,
      xl: 1200,
      md: 768,
    },
  },
};

export const store = configureStore();

ReactDOM.render(
  <Fragment>
    <BaseCSS />
    <Normalize />
    <GlobalStyles />
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <GridThemeProvider gridTheme={gridTheme}>
          <App history={history} />
        </GridThemeProvider>
      </ThemeProvider>
    </Provider>
  </Fragment>,
  document.getElementById('root'),
);
