/* eslint @typescript-eslint/no-unused-vars: 0 */
/* eslint no-unused-vars: 0 */
import React from 'react';
import { Route } from 'react-router-dom';

import { ProtectedRoute } from './ProtectedRoute';

export const renderRoute = (props, key) => {
  const {
    children,
    layout: Layout,
    ...restProps
  } = props;

  if (restProps.isProtected) {
    return (
      <ProtectedRoute
        {...restProps}
        layout={Layout}
        key={key}
      />
    );
  }

  return (
    <Route
      {...restProps}
      render={() => <Layout {...restProps}>
        {restProps.render()}
      </Layout>}
      key={key}
    />
  );
};
