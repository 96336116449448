import styled from 'styled-components';
import { H2, H4, PageTitle } from 'uiComponents/Titles';
import { Input } from 'uiComponents/Input/inputStyles';
import { StyledButton } from 'uiComponents/Button';
import { media } from 'styled-bootstrap-grid';

export const SurveySetupPageWrapper = styled.div`
  & ${H4} {
    margin-bottom: 2em;
  }

  & ${H2} {
    font-weight: 800;
  }

  & ${Input} {
    margin-bottom: 1em;
  }

  & ${StyledButton} {
    margin-bottom: 1em;

    ${media.md`
      margin-bottom: 0;
    `}
  }

  ${PageTitle} {
    margin-bottom: 60px;
  }
`;

export const BigContainer = styled.div`
  padding: 78px 0 60px;
  margin: 0 auto;
  max-width: 1280px;
  background-color: ${({ theme }) => theme.colors.lightGrey};

  ${media.xs`
    padding: 60px 0;
  `}
  ${media.md`
    padding: 78px 34px 64px;
    margin: 0 15px 96px;
    border-radius: 6px;
  `}
  ${media.lg`
    padding: 78px 0 64px;
    margin: 0 auto 96px;
    border-radius: 6px;
  `}
`;

export const CommonData = styled.ul`
  padding: 0;
  margin: 0 0 2em;
  list-style: none;
  ${media.md`
    margin-bottom: 0;
  `}
  ${media.lg`
     margin-bottom: 32px;
  `}
`;

export const CommonDataItem = styled.li`
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;

  ${media.lg`
    font-size: 26px;
    line-height: 32px;
  `}

  &:not(:last-of-type) {
     margin-bottom: 1em;
  }
  & span:first-of-type {
    display: inline-block;
    margin-right: .5em;
    color: ${({ theme }) => theme.colors.black}
  }
  & span:last-of-type {
    color: ${({ theme }) => theme.colors.orange}
  }
`;

export const CommonDataText = styled.p`
  color: ${({ theme }) => theme.colors.darkGrey};
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  ${media.md`
    margin-top: 32px;
  `}
  ${media.lg`
    margin-top: 56px;
  `}

  ${media.xl`
    width: 300px;
  `}
`;
