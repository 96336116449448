import React from 'react';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import { PageTitle } from 'uiComponents/Titles';

export const HowDoWeDoItBHTPage = () => (
  <Container>
    <Row>
      <Col col={12}>
        <PageTitle
          className="HowDoWeDo-title"
          title="Как мы это делаем?"
        />
      </Col>
    </Row>
    <Row>
      <Col col={12}>
        <div className="block">
          <h3 className="title">Что?</h3>

          <p className="text">
            <strong>Периодический мониторинг</strong> основных составляющих <strong>здоровья (ценности) марки </strong>
            в конкурентном окружении – <strong>знания, использования, предпочтения, доверия,
            функционального превосходства и эмоциональной ценности, </strong>
            а также <strong>знания рекламной коммуникации</strong> компании.
          </p>
        </div>

        <div className="block">
          <h3 className="title">Как?</h3>

          <p className="text">
            <strong>Периодический опрос</strong> (ежемесячный, квартальный, полугодовой) клиентов компании
            по структурированной, стандартной анкете.
            Метод предполагает <strong>случайную выборку </strong>
            не менее 50 респондентов <strong>на каждую волну.</strong>
          </p>
        </div>
        <div className="block">
          <h3 className="title">Кто?</h3>

          <p className="text">
            Пользователи продукта или услуги, люди принимающие решения
            о использовании сервиса или продукта, руководители маркетинга и PR.
          </p>

          <p className="text">Возможно два варианта:</p>

          <p className="text">
            1. <strong>Панель В2В Ресерч</strong> - тогда категория должна быть достаточно популярной
            <strong> как минимум 20% малого и среднего бизнеса пользовались</strong> подобными продуктами
            (услугами) и способны дать свою оценку
            <br />
            2. <strong>Собственная база Клиента</strong>. Мы готовим ссылку, Вы рассылаете ее
            своим клиентам и получаете готовый отчет.
          </p>
        </div>
      </Col>
    </Row>
  </Container>
);
