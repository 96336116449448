import styled from 'styled-components';

const Main = styled.div`
  height: 100%;
  
  display: flex;
  flex-direction: column;
  & main {
    position: relative;
  
    flex: 1 0 auto;
  }
`;

export default Main;
