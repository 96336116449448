import styled from 'styled-components';
import { H2 } from 'uiComponents/Titles';
import { Link } from 'react-router-dom';

export const ErrorContainer = styled.div`
  padding: 64px 0 96px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ErrorImage = styled.img`
  margin-bottom: 60px;
  text-align: center;
`;

export const ErrorTitle = styled(H2)`
  margin-bottom: 36px;
`;

export const ErrorText = styled.div`
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: ${(props) => props.theme.colors.darkGrey};
`;

export const ErrorLink = styled(Link)`
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: ${(props) => props.theme.colors.orange};
  text-decoration: none;
`;
