import React from 'react';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import { PageTitle } from 'uiComponents/Titles';
import { Link } from 'react-router-dom';

export const MethodologyBrandPage = () => (
  <Container>
    <Row>
      <Col col={12}>
        <PageTitle title="Методология" />
      </Col>
    </Row>
    <Row>
      <Col col={12}>
        <div className="block">

          <p className="text">
            Формально считается, что <strong>идею о ценности марки</strong> сформулировал
            в 1996 году Давид Аакер (Aaker, David A. (1991), Managing Brand Equity. New York: The Free Press).
          </p>

          <p className="text">
            С тех пор измерение здоровья (ценности) марки стало обязательным
            компонентом системы управления маркетингом практически всех компаний потребительского рынка.
          </p>

          <p className="text">
            Сектор экономики, работающий напрямую с бизнесами принял ценности марки позже,
            но с не меньшим энтузиазмом (см.например &nbsp;
            <a
              href="https://hbr.org/2007/03/hidden-wealth-in-b2b-brands"
              target="_blank"
            >
              https://hbr.org/2007/03/hidden-wealth-in-b2b-brands</a> )
          </p>

          <p className="text">
            Серьезные исследования показали, что компании, работающие напрямую с бизнесом,
            могут значительно улучшить свои экономические показатели ( в том числе и увеличение цены на свою продукцию)
            не только за счет технологического превосходства, но и за счет правильного управления своей маркой.
          </p>

          <p className="text">
            В нашей <strong>модели</strong> для измерения здоровья марки мы используем следующие индикаторы,
            напрямую связанные с маркетинговой стратегией марки:
          </p>

        </div>

        <div className="block">

          <div className="MethodologyTable">
            <div className="MethodologyRow">
              <h4 className="subtitle">Знание</h4>

              <p className="text">
                Как можно больше людей в целевой аудитории должны знать марку
              </p>
            </div>
            <div className="MethodologyRow">
              <h4 className="subtitle">Использование</h4>

              <p className="text">
                Как можно больше людей в целевой аудитории должны использовать марку
              </p>
            </div>
            <div className="MethodologyRow">
              <h4 className="subtitle">Предпочтение</h4>

              <p className="text">
                Как можно больше людей в целевой аудитории должны выбирать марку вместо конкурентов
              </p>
            </div>
            <div className="MethodologyRow">
              <h4 className="subtitle">Соответствие потребностям (функциональное превосходство)</h4>

              <p className="text">
                Марка должна соответствовать потребностям целевой аудитории лучше, чем конкуренты
              </p>
            </div>

            <div className="MethodologyRow">
              <h4 className="subtitle">Эмоциональная связь</h4>

              <p className="text">
                Марка должна создавать устойчивую эмоциональную связь с целевой аудиторией
              </p>
            </div>

          </div>

        </div>

        <div className="block">

          <p className="text">
            На основе этих показателей мы рассчитываем единый индекс ценности марки и анализируем ее
            <strong> позиции в конкурентном окружении.</strong>
          </p>

          <p className="text">
            Дополнительно, мы оцениваем
            <strong> имидж марки </strong>
            (устойчивый эмоционально-окрашенный образ, формирующийся в сознании целевых групп).
          </p>

          <p className="text">
            В программах <Link to={'/create/BHT'}><strong>мониторинга здоровья марки</strong></Link> мы
            также измеряем знание рекламы марки и ее отдельных коммуникационных кампаний.
          </p>
        </div>
      </Col>
    </Row>
  </Container>
);
