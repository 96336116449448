import React from 'react';
import { StyledFooter } from './styled';

import { Container } from 'styled-bootstrap-grid';
import { authSelector } from 'store/app/selectors';
import { useSelector } from 'react-redux';

import { getCurrentYear } from 'helpers/date';

import {
  TERMS_OF_USE,
  PRIVACY_POLICY,
} from '../../../../constants/staticDocs';

import {
  StyledFooterRow,
  StyledLogo,
  StyledCol,
  StyledFooterText,
} from './styled';

export const Footer = () => {
  const isAuthenticated = useSelector(authSelector);

  return (
    <StyledFooter>
      <Container>
        <StyledFooterRow>
          <StyledCol
            xs={2}
            sm={2}
            md={4}
            lg={3}
          >
            <StyledLogo to={isAuthenticated ? '/surveys' : '/'} />
          </StyledCol>
          <StyledCol
            xs={8}
            sm={9}
            md={5}
            lg={3}
            xsOffset={1}
            smOffset={1}
            mdOffset={2}
            lgOffset={1}
          >
            <StyledFooterText colorType="vivid">
              e-mail: <a href="mailto:info@b2bresearch.io">info@b2bresearch.io</a>
            </StyledFooterText>
            <StyledFooterText colorType="vivid">
              тел. <a href="tel:+7(495) 638-56-61">+7(495) 638-56-61</a>
            </StyledFooterText>
            <StyledFooterText colorType="vivid">
              111397, г.&nbsp;Москва, Зеленый&nbsp;проспект, д.20, 10&nbsp;этаж, помещ.&nbsp;I, ком.11a
            </StyledFooterText>
          </StyledCol>
          <StyledCol
            xs={8}
            sm={8}
            md={5}
            lg={3}
            xsOffset={3}
            smOffset={3}
            mdOffset={6}
            lgOffset={1}
          >
            <StyledFooterText>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={PRIVACY_POLICY}
              >
                Политика конфиденциальности
              </a>
            </StyledFooterText>
            <StyledFooterText>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={TERMS_OF_USE}
              >
                Соглашение об обработке персональных данных
              </a>
            </StyledFooterText>
            <StyledFooterText>
              b2bresearch.io © {getCurrentYear()}
            </StyledFooterText>
          </StyledCol>
        </StyledFooterRow>
      </Container>
    </StyledFooter>
  );
};
