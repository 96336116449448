export const SOLUTIONS = {
  NPS: 'NPS',
  ACSI: 'ACSI',
  AIDA: 'AIDA',
  BTH: 'BHT',
  Brand: 'Brand',
  Concept: 'Concept',
  CR: 'CR',
  ISO: 'ISO',
  Name: 'Name',
  Questions10: 'Questions10',
  Questions15: 'Questions15',
  Ranging: 'Ranging',
  Consultation: 'Consultation',
};

export const SOLUTIONS_LANG = {
  [SOLUTIONS.NPS]: 'NPS',
  [SOLUTIONS.ACSI]: 'ACSI',
  [SOLUTIONS.AIDA]: 'Реклама AIDA',
  [SOLUTIONS.BTH]: 'Мониторинг здоровья марки',
  [SOLUTIONS.Brand]: 'Здоровье марки',
  [SOLUTIONS.Concept]: 'Идея или концепция',
  [SOLUTIONS.CR]: 'Корпоративная репутация',
  [SOLUTIONS.ISO]: 'ISO',
  [SOLUTIONS.Name]: 'Название',
  [SOLUTIONS.Questions10]: '10 вопросов',
  [SOLUTIONS.Questions15]: '15 вопросов',
  [SOLUTIONS.Ranging]: 'Ранжирование',
  [SOLUTIONS.Consultation]: 'Консультация',
};

export const SOLUTION_FIELDS = {
  competitors: {
    name: 'competitors',
    limit: 7,
  },
  statements: {
    name: 'statements',
    limit: 10,
  },
  characteristics: {
    name: 'characteristics',
    limit: 10,
  },
  names: {
    name: 'names',
    limit: 4,
  },
  questions: {
    name: 'questions',
    limit: 10,
  },
};

export const SOLUTIONS_SETTINGS = Object.freeze({
  [SOLUTIONS.Concept]: {
    allowedFormats: [ 'bmp', 'jpg', 'jpeg', 'gif', 'png', 'tiff', 'pdf' ],
  },
  [SOLUTIONS.AIDA]: {
    allowedFormats: [ 'avi', 'mp4', 'mpeg', 'ogg', 'qt', 'webm', 'flv', 'mkv' ],
  },
  [SOLUTIONS.Ranging]: {
    allowedFormats: [ 'bmp', 'jpg', 'jpeg', 'gif', 'png', 'tiff' ],
  },
  [SOLUTIONS.Consultation]: {
    allowedFormats: [ 'doc', 'docx', 'rtf', 'txt', 'pdf' ],
  },
  [SOLUTIONS.BTH]: {
    allowedFormats: [ 'avi', 'mp4', 'mpeg', 'ogg', 'qt', 'webm', 'flv', 'mkv' ],
  },
});

export const CUSTOM_SOLUTIONS_NAMES_LIST = Object.freeze({
  [SOLUTIONS.Consultation]: (item) => (
    item.consultationTheme || item.name
  ),
});
