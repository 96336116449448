import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import CreateForm from 'components/SurveySetup/forms/CreateForm';
import { solutionsConfig } from 'components/SurveySetup/forms/solutionConfig';
import { StyledWrapperFormBlock } from 'components/SurveySetup/styled';
import { FormBlockTitle } from 'components/SurveySetup/FormBlockTitle/FormBlockTitle';
import { AuthorizedField } from 'components/AuthorizedField/AuthorizedField';
import AudienceForm from 'components/AudienceForm';

import FormField from 'uiComponents/Form/Field';
import { StyledTextArea } from 'uiComponents/TeaxtArea';

import * as solutionActions from 'store/solution/actions';
import * as solutionSelectors from 'store/solution/selectors';
import { getValidationErrors } from 'store/errors/selectors';

import { Col, Row } from 'styled-bootstrap-grid';
import AdditionalQuestion from 'components/AdditionalQuestion';
import { DynamicFieldsBlock } from 'components/SurveySetup/DynamicFieldsBlock';
import generateUUID from 'helpers/generateUUID';
import { BlockWithDefaultField } from 'components/SurveySetup/DynamicFieldsBlock/BlockWithDefaultField';

const mapStateToProps = (state) => ({
  isSubmittedForm: solutionSelectors.isSubmittedForm(state),
  validationErrors: getValidationErrors(state),
});

const mapDispatchToProps = (dispatch) => ({
  updateSolutionCreateData: (fieldData) => dispatch(solutionActions.updateSolutionCreateData(fieldData)),
});

@connect(mapStateToProps, mapDispatchToProps)
class BrandForm extends CreateForm {
  constructor(props) {
    super(props);
    this.state = this.solutionDataConfig.initialFormState;
    this.props.updateSolutionCreateData(this.solutionDataConfig.initialFormState);
  }

  solution = this.props.match.params.solution;

  solutionDataConfig = solutionsConfig.find(((item) => item.name === this.solution));

  render() {
    const {
      productName,
      productDescription,
      period,
      audienceId,
      geographyId,
    } = this.state;

    return (
      <>
        <Row>
          <Col col={12}>
            <StyledWrapperFormBlock>
              <FormBlockTitle
                title="Марка"
                subtitle="Укажите, пожалуйста, название марки, здоровье которой Вы бы хотели измерить"
              />
              <AuthorizedField>
                <FormField>
                  <StyledTextArea
                    id="productName"
                    name="productName"
                    placeholder="Введите марку"
                    defaultValue={productName}
                    onChange={this.inputOnChange}
                    error={this.isValidField('productName')}
                  />
                </FormField>
              </AuthorizedField>
            </StyledWrapperFormBlock>
          </Col>
        </Row>
        <Row>
          <Col col={12}>
            <StyledWrapperFormBlock>
              <FormBlockTitle
                title="Продукт/услуга"
                subtitle="Опишите, пожалуйста, какой основной продукт или услугу предлагает марка, здоровье которой
                  Вы&nbsp;бы&nbsp;хотели измерить?"
                textHint="Зачем это нужно?"
                dataTipFor="productDescription"
                dataTip={(
                  <>
                    Респондентам будет предложено ответить на вопросы:
                    «Какая марка /НАЗВАНИЕ ПРОДУКТА ИЛИ УСЛУГИ/ первой приходит Вам на память?»
                    «Какие еще марки /НАЗВАНИЕ ПРОДУКТА ИЛИ УСЛУГИ/ Вы знаете?» и другие.
                  </>
                )}
              />
              <AuthorizedField>
                <FormField>
                  <StyledTextArea
                    id="productDescription"
                    name="productDescription"
                    placeholder="Например, оборудование для неразрушающего контроля или услуги по подбору персонала"
                    defaultValue={productDescription}
                    onChange={this.inputOnChange}
                    error={this.isValidField('productDescription')}
                  />
                </FormField>
              </AuthorizedField>
            </StyledWrapperFormBlock>
          </Col>
        </Row>
        <Row>
          <Col col={12}>
            <DynamicFieldsBlock
              blockTitle={{
                title: 'Марки конкурентов',
                subtitle: `
                  Перечислите, пожалуйста, не более 7 марок-конкурентов.
                  Пожалуйста, указывайте каждую новую марку на новой строке.
                `,
              }}
              keyName="text"
              name="competitors"
              confirmActionMessage="Вы уверены, что хотите удалить марку?"
              placeholder="Введите марку конкурента"
              addButtonText="Добавить строку"
              defaultState={[
                { text: '', id: generateUUID() },
              ]}
              component={BlockWithDefaultField}
            />
          </Col>
        </Row>
        <Row>
          <Col col={12}>
            <StyledWrapperFormBlock>
              <FormBlockTitle
                title="Период"
                subtitle="Укажите, пожалуйста, за какой период для Вас было бы актуально получить информацию
                  о пользовании продуктом или услугами?"
                textHint="Зачем это нужно?"
                dataTipFor="period"
                dataTip={(
                  <>
                    Респондентам будет предложено ответить на вопрос:
                    «/НАЗВАНИЕ ПРОДУКТА ИЛИ УСЛУГИ/ каких марок Вы использовали за /ПЕРИОД ВРЕМЕНИ/?»
                  </>
                )}
              />
              <AuthorizedField>
                <FormField>
                  <StyledTextArea
                    id="period"
                    name="period"
                    placeholder="Например, последние полгода или последние три месяца"
                    defaultValue={period}
                    onChange={this.inputOnChange}
                    error={this.isValidField('period')}
                  />
                </FormField>
              </AuthorizedField>
            </StyledWrapperFormBlock>
          </Col>
        </Row>
        <Row>
          <Col col={12}>
            <DynamicFieldsBlock
              blockTitle={{
                title: 'Добавьте утверждение/высказывание',
                subtitle: `
                 Укажите, пожалуйста,
                 утверждения или высказывания,
                 с помощью которых Вы бы хотели оценить имидж марки и марок-конкурентов.
                 Пожалуйста, указывайте каждое новое утверждение на новой строке.
                 `,
                textHint: 'Зачем это нужно?',
                dataTipFor: 'statements',
                dataTip: (
                  <>
                    Респондентам будет предложено ответить на вопрос:
                    «Пожалуйста, для каждого утверждения выберите марки, которые в наибольшей степени ему соответствуют»
                  </>
                ),
              }}
              placeholder="Например, Марка N помогает мне решить любые бизнес-задачи
               или Марка N постоянно развивает и совершенствует свой продукт"
              keyName="text"
              name="statements"
              confirmActionMessage="Вы уверены, что хотите удалить утверждение/высказывание?"
              addButtonText="Добавить строку"
              textToField="Утверждение/высказывание"
              maxField={10}
              defaultState={[
                { text: '', id: generateUUID() },
              ]}
              component={BlockWithDefaultField}
            />
          </Col>
        </Row>
        <Row>
          <Col col={12}>
            <AudienceForm
              onChange={this.setAudienceData}
              data={{
                audienceId,
                geographyId,
              }}
              optionalDescription="Мы будем проводить опрос среди тех компаний, которые пользуются
               указанными вами продуктами или услугами."
            />
          </Col>
        </Row>
        <AdditionalQuestion />
      </>
    );
  }
}

export default withRouter(BrandForm);
