import React from 'react';

import { MainLayout } from 'components/layouts/MainLayout';
import { AuthorizedLayout } from 'components/layouts/AuthorizedLayout';
import { ErrorComponent } from 'components/ErrorComponent';

import HomePage from 'pages/HomePage';
import SolutionsPage from 'pages/SolutionsPage';
import ProfilePage from 'pages/ProfilePage';
import RegistrationPage from 'pages/RegistrationPage';
import SignInPage from 'pages/SignInPage';
import AccountActivationPage from 'pages/AccountActivationPage';
import PasswordRecoveryPage from 'pages/PassordRecoveryPage';
import PasswordResetPage from 'pages/PasswordResetPage';
import SurveysListPage from 'pages/SurveysListPage';
import CreatePage from 'pages/CreatePage';
import SurveySetupPage from 'pages/SurveySetupPage';
import SurveyReviewPage from 'pages/SurveyReviewPage';
import ExpenseListPage from 'pages/ExpenseList';
import HowDoWeDoItPage from 'pages/how-do-we-do-it';
import QuestionnairePage from 'pages/questionnaire-page';
import MethodologyPage from 'pages/methodology-pages';
import RegistrationConfirmationPage from 'pages/RegistrationConfirmationPage';
import PasswordRecoveryConfirmationPage from 'pages/PasswordRecoveryConfirmationPage';
import SurveyExamplesSettingsPage from 'pages/SurveyExamplesSettingsPage';

export const routesConfig = [
  {
    name: 'home',
    path: '/',
    exact: true,
    isProtected: false,
    layout: MainLayout,
    render: () => <HomePage />,
  },
  {
    name: 'solutions',
    path: '/solutions',
    exact: true,
    isProtected: true,
    layout: AuthorizedLayout,
    render: () => <SolutionsPage />,
    breadcrumbText: 'Решения',
  },
  {
    name: 'profile',
    path: '/profile',
    exact: true,
    isProtected: true,
    layout: AuthorizedLayout,
    render: () => <ProfilePage />,
    breadcrumbText: 'Профиль',
  },
  {
    name: 'registration',
    path: '/registration',
    exact: true,
    isProtected: false,
    layout: MainLayout,
    render: () => <RegistrationPage />,
    breadcrumbText: 'Регистрация',
  },
  {
    name: 'signIn',
    path: '/sign-in',
    exact: true,
    isProtected: false,
    layout: MainLayout,
    render: () => <SignInPage />,
    breadcrumbText: 'Авторизация',
  },
  {
    name: 'activation',
    path: '/activation',
    exact: true,
    isProtected: false,
    layout: MainLayout,
    render: () => <AccountActivationPage />,
    breadcrumbText: 'Авторизация',
  },
  {
    name: 'passwordRecovery',
    path: '/password-recovery',
    exact: true,
    isProtected: false,
    layout: MainLayout,
    render: () => <PasswordRecoveryPage />,
    breadcrumbText: 'Восстановление пароля',
  },
  {
    name: 'passwordReset',
    path: '/reset-password',
    exact: true,
    isProtected: false,
    layout: MainLayout,
    render: () => <PasswordResetPage />,
    breadcrumbText: 'Авторизация',
  },
  {
    name: 'userSurveysList',
    path: '/surveys',
    exact: true,
    isProtected: true,
    layout: AuthorizedLayout,
    render: () => <SurveysListPage />,
  },
  {
    name: 'create',
    path: '/create',
    isProtected: true,
    exact: true,
    layout: AuthorizedLayout,
    render: () => <CreatePage />,
  },
  {
    name: 'create',
    path: '/create/:solution',
    isProtected: false,
    exact: false,
    layout: AuthorizedLayout,
    render: () => <SurveySetupPage />,
  },
  {
    name: 'surveyReview',
    path: '/surveys/:id',
    exact: false,
    isProtected: true,
    layout: AuthorizedLayout,
    render: () => <SurveyReviewPage />,
  },
  {
    name: 'surveyExamplesSettings',
    path: '/settings/surveys/examples',
    exact: false,
    isProtected: true,
    layout: AuthorizedLayout,
    render: () => <SurveyExamplesSettingsPage />,
    breadcrumbText: 'Пример отчетности',
  },
  {
    name: 'userPollList',
    path: '/expense',
    exact: true,
    isProtected: true,
    layout: AuthorizedLayout,
    render: () => <ExpenseListPage />,
    breadcrumbText: 'Список расходов',
  },
  {
    name: 'howDoWeDoItPage',
    path: '/how-do-we-do-it/:solutionName',
    exact: false,
    isProtected: false,
    layout: AuthorizedLayout,
    render: () => <HowDoWeDoItPage />,
    breadcrumbText: 'Как мы это делаем?',
  },
  {
    name: 'questionnaire',
    path: '/questionnaire/:solutionName',
    exact: true,
    isProtected: false,
    layout: AuthorizedLayout,
    render: () => <QuestionnairePage />,
    breadcrumbText: 'Анкета',
  },
  {
    name: 'methodology',
    path: '/methodology/:solutionName',
    exact: true,
    isProtected: false,
    layout: AuthorizedLayout,
    render: () => <MethodologyPage />,
    breadcrumbText: 'Методология',
  },
  {
    name: 'registration-confirmation',
    path: '/registration-confirmation',
    exact: true,
    isProtected: false,
    layout: MainLayout,
    render: () => <RegistrationConfirmationPage />,
    breadcrumbText: 'Регистрация',
  },
  {
    name: 'password-recovery-confirmation',
    path: '/password-recovery-confirmation',
    exact: true,
    isProtected: false,
    layout: MainLayout,
    render: () => <PasswordRecoveryConfirmationPage />,
    breadcrumbText: 'Восстановление пароля',
  },
  {
    name: 'not-found',
    path: '/not-found',
    isProtected: false,
    layout: MainLayout,
    render: () => <ErrorComponent />,
  },
  {
    layout: MainLayout,
    render: () => <ErrorComponent />,
  },
];
