import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _debounce from 'lodash/debounce';

import * as solutionActions from 'store/solution/actions';
import * as userSelectors from 'store/user/selectors';

import { Col } from 'styled-bootstrap-grid';

import {
  StyledSearchBlock,
  StyledSearchBox,
  Title,
} from 'pages/SurveysListPage/SearchBlock/styled';

export const SearchBlock = () => {
  const dispatch = useDispatch();

  const isManager = useSelector(userSelectors.isManager);

  const [ inputValue, setInputValue ] = useState('');

  const debounceSearch = useCallback(_debounce((value) => {
    dispatch(solutionActions.setSurveyListFilter({
      filterData: { search: value },
      isGlobalLoading: false,
    }));
  }, 500), []);

  const handleChange = (e) => {
    const value = e.target.value;

    setInputValue(value);

    debounceSearch(value);
  };

  return (
    <StyledSearchBlock>
      <Col
        col={12}
        lg={8}
        md={6}
      >
        <Title>{isManager ? 'Список заказов' : 'Мои опросы'}</Title>
      </Col>
      {isManager && (
        <Col
          lg={4}
          md={6}
        >
          <StyledSearchBox
            placeholder="Поиск"
            onChange={handleChange}
            value={inputValue}
          />
        </Col>
      )}
    </StyledSearchBlock>
  );
};
