import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const RouterLink = styled(Link)`
  color: ${(props) => props.theme.colors.orange};
  text-decoration: none;
`;

export const DownloadLink = styled('a')`
  color: ${(props) => props.disabled ? props.theme.colors.borderGrey : props.theme.colors.orange};
  text-decoration: none;
`;
