import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { PasswordResetFormWrapper } from 'components/PasswordResetPageForm/styled';

export const PasswordResetPageWrapper = styled.div`
  & ${PasswordResetFormWrapper} {
    margin-bottom: 2em;
    ${media.md`
        margin-bottom: 96px;
    `}
  }
`;
