import styled from 'styled-components';
import { StyledButton } from 'uiComponents/Button';

export const StyledDatePicker = styled.div`
  position: absolute;
  z-index: 1;

  padding: 22px 13px 10px;

  display: ${({ isVisibleCalendar }) => isVisibleCalendar ? 'initial' : 'none'};

  min-width: 270px;

  top: calc(100% + 8px);
  left: 50%;

  transform: translateX(-50%);

  border: 1px solid #e1e1e1;
  border-radius: 4px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, .1);
  background: #fff;

  ${StyledButton} {
    margin-bottom: 10px;
    padding: 7px 35px;

    font-weight: 600;
    font-size: 14px;
    line-height: 22px;

    &:last-child {
      margin-bottom: 0;

      border-color: transparent;
    }
  }
//  Day picker css
  .DayPicker {
    width: 100%;
  }

  .DayPicker-Month {
    margin: 0;
  }

  .DayPicker-NavBar {
    display: none;
  }

  .DayPicker-Day {
    margin: 5px 0;
    padding: 3px 8px;

    border-radius: 0;
    border-top: 10px solid transparent;

    font-size: 14px;
    line-height: 17px;
    color: #000;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background-color: ${({ theme }) => theme.colors.borderGrey};
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    color: #000;

    background-color: ${({ theme }) => theme.colors.borderGrey};
  }

  .DayPicker-Day--disabled {
    opacity: .4;

    pointer-events: none;
  }

  .DayPicker-Day--today {
    color: ${({ theme }) => theme.colors.orange};
  }

  .DayPicker-Weekday {
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    color: #8C8C8C;
  }
`;
