import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Checkbox from 'uiComponents/Checkbox/contolled';

import { getCheckedCharacteristics } from 'store/solution/selectors';
import { updateSolutionCreateData } from 'store/solution/actions';

import {
  BlockWithCheckBoxListContainer,
  BlockWithCheckBoxListItem,
} from './styled';

export const BlockWithCheckBoxList = () => {
  const dispatch = useDispatch();
  const checkedCharacteristics = useSelector(getCheckedCharacteristics);

  const updateField = (value, id) => {
    dispatch(updateSolutionCreateData({
      checkedCharacteristics: checkedCharacteristics.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            checked: value,
          };
        }

        return item;
      }),
    }));
  };

  if (!checkedCharacteristics || !checkedCharacteristics.length) {
    return null;
  }

  return (
    <BlockWithCheckBoxListContainer>
      {checkedCharacteristics.map(({ id, text, checked }) => (
        <BlockWithCheckBoxListItem key={id}>
          <Checkbox
            id={id}
            className={'checkBoxList'}
            labelText={text}
            checked={checked}
            onChange={(value) => updateField(value, id)}
          />
        </BlockWithCheckBoxListItem>
      ))}
    </BlockWithCheckBoxListContainer>
  );
};
