import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Col, Row } from 'styled-bootstrap-grid';

import { TYPES } from 'constants/questions';

import generateUUID from 'helpers/generateUUID';

import AudienceForm from 'components/AudienceForm';

import CreateForm from 'components/SurveySetup/forms/CreateForm';
import { solutionsConfig } from 'components/SurveySetup/forms/solutionConfig';
import { DynamicFieldsBlock } from 'components/SurveySetup/DynamicFieldsBlock';
import { BlockRankedFields } from 'components/SurveySetup/DynamicFieldsBlock/BlockRankedFields';
import { BlockWithDefaultField } from 'components/SurveySetup/DynamicFieldsBlock/BlockWithDefaultField';
import { BlockWithCheckBoxList } from 'components/SurveySetup/DynamicFieldsBlock/BlockWithCheckBoxList';
import { FormBlockTitle } from 'components/SurveySetup/FormBlockTitle/FormBlockTitle';

import * as solutionActions from 'store/solution/actions';
import * as solutionSelectors from 'store/solution/selectors';
import { getValidationErrors } from 'store/errors/selectors';

const mapStateToProps = (state) => ({
  isSubmittedForm: solutionSelectors.isSubmittedForm(state),
  validationErrors: getValidationErrors(state),
});

const mapDispatchToProps = (dispatch) => ({
  updateSolutionCreateData: (fieldData) => dispatch(solutionActions.updateSolutionCreateData(fieldData)),
});

@connect(mapStateToProps, mapDispatchToProps)
class RangingForm extends CreateForm {
  typeField = 'rankingType';

  constructor(props) {
    super(props);
    this.state = this.solutionDataConfig.initialFormState;
    this.props.updateSolutionCreateData(this.solutionDataConfig.initialFormState);
  }

  solution = this.props.match.params.solution;

  solutionDataConfig = solutionsConfig.find(((item) => item.name === this.solution));
  render() {
    const {
      audienceId,
      geographyId,
    } = this.state;

    return (
      <>
        <Row>
          <Col col={12}>
            <DynamicFieldsBlock
              keyName={this.state[this.typeField] === TYPES.text ? TYPES.text : TYPES.image}
              addButtonText="Добавить элемент"
              maxField={10}
              maxAnswers={10}
              component={BlockRankedFields}
              placeholder="Введите элемент"
              defaultType={TYPES.text}
              name={'rankingItems'}
              nameField={'rankingItems'}
              confirmActionMessage="Вы уверены, что хотите удалить элемент?"
              toggleType={this.toggleType}
              typeField={this.typeField}
              type={this.state[this.typeField]}
              defaultState={[
                {
                  item: '',
                  id: generateUUID(),
                },
                {
                  item: '',
                  id: generateUUID(),
                },
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col col={12}>
            <FormBlockTitle
              title="Добавьте дополнительные характеристики"
              subtitle="Выберите интересующие Вас характеристики для ранжирования"
            />
            <BlockWithCheckBoxList
              keyName="checked"
            />
            <DynamicFieldsBlock
              keyName="text"
              name="characteristics"
              confirmActionMessage="Вы уверены, что хотите удалить характеристику?"
              addButtonText="Добавить характеристику"
              maxField={3}
              placeholder="Введите характеристику"
              defaultState={[
                { text: '', id: generateUUID() },
              ]}
              component={BlockWithDefaultField}
            />
          </Col>
        </Row>
        <Row>
          <Col col={12}>
            <AudienceForm
              onChange={this.setAudienceData}
              data={{
                audienceId,
                geographyId,
              }}
              optionalDescription="Мы будем проводить опрос репрезентативной выборки всех компаний малого
               и среднего бизнеса. Напишите нам, если вы хотите добавить какие-либо еще критерии отбора."
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(RangingForm);
