import styled from 'styled-components';

import { H4 } from 'uiComponents/Titles';

import FormField from 'uiComponents/Form/Field';

import {
  media,
  Row,
} from 'styled-bootstrap-grid';

export const StyledDynamicFieldsBlock = styled.div`
  margin-bottom: 60px;

  ${media.md`
    margin-bottom: 64px;

    ${({ withoutMargin }) => withoutMargin && (`
       margin-bottom: 0;
    `)}
  `}

  & ${H4} {
    margin-bottom: 48px;
  }
  & ${FormField} {
    margin-bottom: 1em;
  }

  ${({ withoutMargin }) => withoutMargin && (`
      margin-bottom: 0;
  `)}
`;

export const DeleteIcon = styled.img`
  cursor: pointer;
`;

export const LabelRow = styled(Row)`
  & label {
    margin: 0;
  }
  & ${DeleteIcon} {
    ${media.md`
      display: none;
    `}
  }
  margin-bottom: 1em;
`;
export const LabelQuestionRow = styled(Row)`
  & label {
    margin: 0;
    font-size: 26px;
  }
`;
export const LabelAnswerRow = styled(Row)`
  label {
    margin: 0;
    font-size: 18px;
    margin-top: 48px;
    margin-bottom: 8px;
  }
`;

export const TextAreaRow = styled(Row)`
  align-items: center;

  & ${DeleteIcon} {
    display: ${(props) => props.showDeleteIcon ? 'block' : 'none'};
    ${media.md`
      display: block;
    `}
  }
`;

export const TypeQuestionsRow = styled(Row)`
  & > div {
      display: flex;

      margin-top: 16px;
      margin-bottom: 48px;
  }
  & label {
    line-height: initial;

    font-size: 16px;
    font-weight: 100;

    margin-bottom: 0px;

    cursor: pointer;
  }
`;

export const TypeQuestionsItem = styled.input`
  margin-left: 0px;

  visibility: hidden;

  padding: 0;
`;

export const TypeQuestionsLabel = styled.label`
  color: ${(props) => props.active ? '#FF3D00' : '#AEAEAE'};

  border-bottom: ${(props) => props.active ? '1px solid #FF3D00' : 'none'};

  cursor: pointer;
`;

export const TypeQuestionsHead = styled.div`
  margin-right: 15px;
  float: left;
`;

export const FormBelow = styled.div`
  & > div {
    display: flex;
    & > div {
      flex: 1;
    }
  }
`;
