import React from 'react';
import { StyledSolutionCard } from './styled';

import { Link } from 'react-router-dom';

export const SolutionCard = (props) => {
  const {
    solutionName,
    description,
    link,
  } = props;

  return (
    <StyledSolutionCard>
      <div className="name">{solutionName}</div>
      <div className="description">{description}</div>
      <Link
        to={link}
        className="link"
      >
        Начать
      </Link>
    </StyledSolutionCard>
  );
};
