import * as dayjs from 'dayjs';

export const addZeroToDateParts = (dateTimePart) => (
  String(dateTimePart).length !== 2
    ? `0${dateTimePart}`
    : dateTimePart
);

export const getCurrentYear = () => (
  dayjs().year()
);

const getMonth = (date) => (
  date.getMonth() + 1
);

export const getDate = (dateTime) => {
  const date = new Date(dateTime);

  return `${addZeroToDateParts(date.getDate())}.${addZeroToDateParts(getMonth(date))}.${date.getFullYear()}`;
};

export const getTime = (dateTime) => {
  const date = new Date(dateTime);

  return `${addZeroToDateParts(date.getHours())}:${addZeroToDateParts(date.getMinutes())}`;
};

export const getFormattedDate = ({ from, to }, placeholder = '') => {
  let dateFrom;
  let dateTo;

  if (!from) {
    dateFrom = '';
  } else {
    dateFrom = dayjs(from).format('DD.MM.YY');
  }

  if (!to) {
    dateTo = '';
  } else {
    dateTo = dayjs(to).format('DD.MM.YY');
  }

  if (!from && !to) {
    return placeholder;
  }

  return `${dateFrom} - ${dateTo}`;
};

export const getYearsList = (listLength, date = new Date()) => {
  const currentYear = date.getFullYear();
  const yearsList = [];

  for (let i = currentYear; i <= currentYear + listLength; i++) {
    yearsList.push(i);
  }

  return yearsList;
};
