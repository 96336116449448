import styled, { css } from 'styled-components';
import { Col, media, Row } from 'styled-bootstrap-grid';
import { Link } from 'react-router-dom';

import logo from 'assets/images/logo-light.png';
import mobileLogo from 'assets/images/mobileLogo.png';

const textCss = css`
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: ${(props) => props.colorType === 'vivid'
    ? props.theme.colors.lightGrey
    : props.theme.colors.darkGrey};
`;

export const StyledFooter = styled('div')`
  padding: 78px 0;

  color: ${(props) => props.theme.colors.lightGrey};;

  background-color: ${(props) => props.theme.colors.black};

  ${media.md`
    padding: 78px 0 96px;
  `}
`;

export const StyledFooterRow = styled(Row)`
  flex-wrap: wrap;

  ${media.xl`
    flex-wrap: nowrap;
  `}
`;

export const StyledLogo = styled(Link)`
  width: 36px;
  height: 36px;

  display: inline-block;

  background-image: url('${mobileLogo}');
  background-size: cover;

  ${media.md`
    background-image: url('${logo}');
    width: 226px;
    height: 48px;
  `}
`;

export const StyledCol = styled(Col)`
  &:nth-child(2){
    margin-bottom: 48px;
  }

  ${media.tablet`
    &:nth-child(2) {
      margin-bottom: 60px;
    }
  `}
  ${media.desktop`
    &:nth-child(2) {
      margin-bottom: 0;
    }
  `}
`;

export const StyledFooterText = styled('div')`
  ${textCss};

  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`;
