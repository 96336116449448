/* eslint-disable max-len */
import React from 'react';
import { theme } from '../../theme';

export const getSvgPath = (name, { fill, ...props }) => {
  const fillColor = fill || theme.colors.borderGrey;
  switch (name) {
    case 'delete': {
      return (
        <path
          {...props}
          d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z"
          fill={fillColor}
        />
      );
    }
    case 'cross-delete': {
      return ([
        <path
          key="cross-delete-path-1"
          d="M1 1L16 16"
          stroke="#D2D2D2"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />,
        <path
          key="cross-delete-path-2"
          d="M16 1L1 16"
          stroke="#D2D2D2"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />,
      ]
      );
    }
    case 'add': {
      return (
        <path
          {...props}
          d="M14 0C6.3 0 0 6.3 0 14C0 21.7 6.3 28 14 28C21.7 28 28 21.7 28 14C28 6.3 21.7 0 14 0ZM21 15.75H15.75V21H12.25V15.75H7V12.25H12.25V7H15.75V12.25H21V15.75Z"
          fill={fillColor}
        />
      );
    }
    case 'attach': {
      return (
        <path
          {...props}
          d="M8.25 12.5938H5.71875C5.66902 12.5938 5.62133 12.6135 5.58617 12.6487C5.551 12.6838 5.53125 12.7315 5.53125 12.7812V13.9062C5.53125 13.956 5.551 14.0037 5.58617 14.0388C5.62133 14.074 5.66902 14.0938 5.71875 14.0938H8.25V16.625C8.25 16.6747 8.26975 16.7224 8.30492 16.7576C8.34008 16.7927 8.38777 16.8125 8.4375 16.8125H9.5625C9.61223 16.8125 9.65992 16.7927 9.69508 16.7576C9.73025 16.7224 9.75 16.6747 9.75 16.625V14.0938H12.2812C12.331 14.0938 12.3787 14.074 12.4138 14.0388C12.449 14.0037 12.4688 13.956 12.4688 13.9062V12.7812C12.4688 12.7315 12.449 12.6838 12.4138 12.6487C12.3787 12.6135 12.331 12.5938 12.2812 12.5938H9.75V10.0625C9.75 10.0128 9.73025 9.96508 9.69508 9.92992C9.65992 9.89475 9.61223 9.875 9.5625 9.875H8.4375C8.38777 9.875 8.34008 9.89475 8.30492 9.92992C8.26975 9.96508 8.25 10.0128 8.25 10.0625V12.5938ZM17.0297 5.76641C17.1703 5.90703 17.25 6.09687 17.25 6.29609V20.75C17.25 21.1648 16.9148 21.5 16.5 21.5H1.5C1.08516 21.5 0.75 21.1648 0.75 20.75V1.25C0.75 0.835156 1.08516 0.5 1.5 0.5H11.4539C11.6531 0.5 11.8453 0.579688 11.9859 0.720313L17.0297 5.76641ZM15.5203 6.64062L11.1094 2.22969V6.64062H15.5203Z"
          fill={fillColor}
        />
      );
    }
    case 'arrow': {
      return (
        <path
          {...props}
          d="M3.24943 6.53243L7.21333 10.6758C7.58572 11.0651 8.23981 11.1102 8.67428 10.7765C8.71456 10.7456 8.75213 10.7119 8.78666 10.6758L12.7505 6.53243C13.1229 6.14317 13.0726 5.55713 12.6382 5.22348C12.4504 5.07927 12.2112 5 11.9639 5L4.0361 5C3.46388 5 3 5.41561 3 5.9283C3 6.1499 3.08847 6.36418 3.24943 6.53243Z"
          fill={fillColor}
        />
      );
    }
    case 'eye': {
      return (
        <path
          {...props}
          d="M10.3979 4.99895C10.3979 6.32491 9.32239 7.39981 7.99565 7.39981C6.66893 7.39981 5.59341 6.32491 5.59341 4.99895C5.59341 3.67298 6.66893 2.59809 7.99565 2.59809C9.32239 2.59808 10.3979 3.67299 10.3979 4.99895ZM8 0C6.62633 0.00635641 5.20248 0.355208 3.85461 1.0231C2.85384 1.53943 1.87853 2.2679 1.03192 3.16705C0.616107 3.62603 0.0857467 4.29063 0 4.99973C0.0101333 5.61396 0.641733 6.37212 1.03192 6.83242C1.82583 7.69625 2.77573 8.40439 3.85461 8.9769C5.11156 9.61325 6.50283 9.97962 8 10C9.37497 9.99355 10.7985 9.64067 12.1449 8.9769C13.1457 8.46057 14.1215 7.73157 14.9681 6.83242C15.3839 6.37344 15.9142 5.70882 16 4.99973C15.9899 4.38549 15.3583 3.62731 14.9681 3.16702C14.1742 2.30319 13.2238 1.5956 12.1449 1.02308C10.8886 0.387213 9.49389 0.0233532 8 0ZM7.99899 1.24135C10.0806 1.24135 11.768 2.92426 11.768 5.00027C11.768 7.07627 10.0806 8.75918 7.99899 8.75918C5.9174 8.75918 4.22999 7.07626 4.22999 5.00027C4.22999 2.92426 5.9174 1.24135 7.99899 1.24135Z"
          fill={fillColor}
        />
      );
    }
    case 'download': {
      return (
        <path
          {...props}
          d="M0 14H12V12.3529H0V14ZM12 4.94118H8.57143V0H3.42857V4.94118H0L6 10.7059L12 4.94118Z"
          fill={fillColor}
        />
      );
    }
    case 'img-placeholder': {
      return (
        <>
          <path
            {...props}
            fill={'transparent'}
            d="M153.111 2H20.8889C10.4568 2 2 10.4196 2 20.8056V152.445C2 162.831 10.4568 171.251 20.8889 171.251H153.111C163.543 171.251 172 162.831 172 152.445V20.8056C172 10.4196 163.543 2 153.111 2Z"
            stroke="#D2D2D2"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            {...props}
            fill={'transparent'}
            d="M53.944 67.8195C61.768 67.8195 68.1106 61.5049 68.1106 53.7153C68.1106 45.9258 61.768 39.6111 53.944 39.6111C46.12 39.6111 39.7773 45.9258 39.7773 53.7153C39.7773 61.5049 46.12 67.8195 53.944 67.8195Z"
            stroke="#D2D2D2"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            {...props}
            fill={'transparent'}
            d="M172 114.834L124.778 67.8196L20.8892 171.251"
            stroke="#D2D2D2"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      );
    }
    case 'arrow-outline': {
      return (
        <path
          {...props}
          d="M6.00003 12L7.41003 10.59L2.83003 6L7.41004 1.41L6.00004 -1.23266e-07L3.48568e-05 6L6.00003 12Z"
          fill={fillColor}
        />
      );
    }
    case 'pause': {
      return (
        <path
          {...props}
          d="M0 10V0H2.4V10H0ZM5.6 0H8V10H5.6V0Z"
          fill={fillColor}
        />
      );
    }
    case 'play': {
      return (
        <path
          {...props}
          d="M2.23944 9.75057L6.38275 5.78673C6.77204 5.41435 6.81714 4.76027 6.48345 4.3258C6.45255 4.28552 6.41885 4.24795 6.38275 4.21342L2.23944 0.249641C1.85018 -0.122753 1.26415 -0.0724539 0.930508 0.36194C0.7863 0.549737 0.707031 0.788934 0.707031 1.03623L0.707031 8.96392C0.707031 9.53613 1.12264 10 1.63532 10C1.85691 10 2.07119 9.91153 2.23944 9.75057Z"
          fill={fillColor}
        />
      );
    }
    case 'instagram': {
      return (<>
        <path
          {...props}
          d="M35 70C54.33 70 70 54.33 70 35C70 15.67 54.33 0 35 0C15.67 0 0 15.67 0 35C0 54.33 15.67 70 35 70Z"
          fill={fillColor}
        />
        <path
          {...props}
          d="M55.9002 37.8074V52.2191H47.5401V38.8056C47.5401 35.4366 46.3548 33.1282 43.2977 33.1282C40.9893 33.1282 39.6168 34.6879 38.9929 36.1853C38.7434 36.7468 38.7434 37.4954 38.7434 38.2441V52.2815H30.3833C30.3833 52.2815 30.5081 29.5097 30.3833 27.1389H38.7434V30.6951L38.681 30.7575H38.7434V30.6951C39.8664 29.0106 41.8628 26.515 46.2924 26.515C51.7826 26.515 55.9002 30.0712 55.9002 37.8074ZM21.6489 14.9731C18.779 14.9731 16.9073 16.8448 16.9073 19.3403C16.9073 21.7735 18.7166 23.7075 21.5241 23.7075H21.5865C24.5188 23.7075 26.328 21.7735 26.328 19.3403C26.2656 16.8448 24.4564 14.9731 21.6489 14.9731ZM17.4065 52.2191H25.7665V27.0765H17.4065V52.2191Z"
          fill="white"
        />
      </>
      );
    }
    case 'facebook': {
      return (<>
        <path
          {...props}
          d="M35 70C54.33 70 70 54.33 70 35C70 15.67 54.33 0 35 0C15.67 0 0 15.67 0 35C0 54.33 15.67 70 35 70Z"
          fill={fillColor}
        />
        <path
          {...props}
          d="M43.7968 36.3724H37.5579V59.269H28.0749V36.3724H23.5829V28.3243H28.0749V23.146C28.0749 19.4027 29.8217 13.6006 37.6203 13.6006H44.6078V21.3992H39.492C38.6809 21.3992 37.4955 21.8359 37.4955 23.5828V28.3243H44.5455L43.7968 36.3724Z"
          fill="white"
        />
      </>
      );
    }
    case 'vk': {
      return (<>
        <path
          {...props}
          d="M35 70C54.33 70 70 54.33 70 35C70 15.67 54.33 0 35 0C15.67 0 0 15.67 0 35C0 54.33 15.67 70 35 70Z"
          fill={fillColor}
        />
        <path
          {...props}
          d="M33.6898 50.3474H36.4349C36.4349 50.3474 37.246 50.285 37.6827 49.7859C38.057 49.3492 38.057 48.6005 38.057 48.6005C38.057 48.6005 37.9946 44.9196 39.7415 44.3581C41.426 43.7966 43.6096 47.9142 45.918 49.4739C47.6649 50.6593 48.975 50.4098 48.975 50.4098L55.0891 50.3474C55.0891 50.3474 58.2709 50.1602 56.7736 47.6023C56.6488 47.4151 55.9002 45.7306 52.2192 42.2992C48.3511 38.7431 48.9126 39.3046 53.5294 33.1281C56.3369 29.3848 57.4599 27.0764 57.1479 26.0782C56.836 25.1424 54.7148 25.3919 54.7148 25.3919L47.7896 25.4543C47.7896 25.4543 47.2905 25.3919 46.9162 25.6415C46.5419 25.891 46.2923 26.3901 46.2923 26.3901C46.2923 26.3901 45.1693 29.3224 43.672 31.7556C40.615 36.9962 39.3672 37.2458 38.8681 36.9338C37.6827 36.1852 37.9946 33.8768 37.9946 32.2547C37.9946 27.2012 38.7433 25.08 36.4973 24.5185C35.7486 24.3313 35.1871 24.2065 33.2531 24.2065C30.7576 24.2065 28.6987 24.2065 27.5133 24.768C26.7023 25.1424 26.1408 26.0158 26.5151 26.0782C26.9519 26.1406 28.0125 26.3278 28.574 27.0764C29.2602 28.0122 29.2602 30.1959 29.2602 30.1959C29.2602 30.1959 29.697 36.1852 28.3244 36.8714C27.3886 37.3705 26.1408 36.3723 23.3957 31.6308C21.9608 29.1976 20.9002 26.5149 20.9002 26.5149C20.9002 26.5149 20.713 26.0158 20.3387 25.7663C19.9019 25.4543 19.2781 25.3295 19.2781 25.3295L12.7273 25.3919C12.7273 25.3919 11.729 25.3919 11.3547 25.8286C11.0428 26.203 11.3547 27.014 11.3547 27.014C11.3547 27.014 16.4706 39.055 22.3351 45.1067C27.6381 50.7217 33.6898 50.3474 33.6898 50.3474Z"
          fill="white"
        />
      </>
      );
    }
    case 'twiter': {
      return (<>
        <path
          {...props}
          d="M35 70C54.33 70 70 54.33 70 35C70 15.67 54.33 0 35 0C15.67 0 0 15.67 0 35C0 54.33 15.67 70 35 70Z"
          fill={fillColor}
        />
        <path
          {...props}
          d="M56.4617 25.1425C54.9644 25.8288 53.3422 26.2655 51.6578 26.4527C53.4046 25.3921 54.7148 23.77 55.3387 21.8359C53.7166 22.7717 51.9073 23.5204 50.0356 23.8947C48.5383 22.2726 46.3547 21.2744 43.9216 21.2744C39.3048 21.2744 35.5615 25.0177 35.5615 29.6345C35.5615 30.3208 35.6239 30.9446 35.7487 31.5685C28.8235 31.1942 22.6471 27.8876 18.5294 22.8341C17.7807 24.0819 17.4064 25.5168 17.4064 27.0142C17.4064 29.9464 18.9037 32.5044 21.1497 34.0017C19.7772 33.9393 18.467 33.565 17.344 32.9411C17.344 33.0035 17.344 33.0035 17.344 33.0659C17.344 37.1211 20.2139 40.4901 24.082 41.2388C23.3957 41.4259 22.6471 41.5507 21.8984 41.5507C21.3369 41.5507 20.8378 41.4883 20.3387 41.4259C21.3993 44.7325 24.5187 47.1657 28.1373 47.2281C25.2674 49.4741 21.6488 50.7842 17.7184 50.7842C17.0321 50.7842 16.4082 50.7218 15.7219 50.6594C19.4029 53.0302 23.8324 54.4028 28.574 54.4028C43.984 54.4028 52.4064 41.6755 52.4064 30.5703C52.4064 30.196 52.4064 29.8217 52.4064 29.5097C53.9037 28.3243 55.3387 26.827 56.4617 25.1425Z"
          fill="white"
        />
      </>
      );
    }
    case 'bracket': {
      return (<>
        <path
          {...props}
          d="M7.19128 -6.16314e-08C6.87461 -7.43596e-08 6.54128 0.111554 6.39128 0.247012L4.37461 2.62151L2.37461 0.247012C2.24128 0.111554 1.89128 -2.74662e-07 1.57461 -2.8739e-07L0.0246096 -3.49691e-07L3.52461 4L5.24128 4L8.72461 0L7.19128 -6.16314e-08Z"
          fill={fillColor}
        />
      </>
      );
    }
    case 'circular-preloader': {
      return (<>
        <path
          {...props}
          d="M2.20799 13.4917C2.37363 13.8603 2.2091 14.2933 1.8405 14.459C1.4719 14.6246 1.03881 14.4601 0.873162 14.0915C0.300253 12.8166 0 11.4285 0 10C0 4.47726 4.47726 0 10 0C15.5227 0 20 4.47726 20 10C20 15.5227 15.5227 20 10 20C7.65339 20 5.42972 19.1881 3.65267 17.7281C3.34042 17.4715 3.29526 17.0105 3.55179 16.6982C3.80833 16.386 4.26941 16.3408 4.58166 16.5973C6.09934 17.8442 7.9955 18.5366 10 18.5366C14.7145 18.5366 18.5366 14.7145 18.5366 10C18.5366 5.28549 14.7145 1.46341 10 1.46341C5.28549 1.46341 1.46341 5.28549 1.46341 10C1.46341 11.2208 1.71947 12.4046 2.20799 13.4917Z"
          fill={fillColor}
        />
      </>
      );
    }
    case 'user-icon': {
      return (<>
        <circle
          cx="19"
          cy="19"
          r="19"
          fill="#AEAEAE"
        />
        <path
          {...props}
          d="M27 28V26C27 24.9391 26.5786 23.9217 25.8284 23.1716C25.0783 22.4214 24.0609 22 23 22H15C13.9391 22 12.9217 22.4214 12.1716 23.1716C11.4214 23.9217 11 24.9391 11 26V28"
          stroke="#F5F5F5"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill={'#AEAEAE'}
        />
        <path
          {...props}
          d="M19 18C21.2091 18 23 16.2091 23 14C23 11.7909 21.2091 10 19 10C16.7909 10 15 11.7909 15 14C15 16.2091 16.7909 18 19 18Z"
          stroke="#F5F5F5"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill={'#AEAEAE'}
        />
      </>
      );
    }
    case 'page-preloader': {
      return (<>
        <path
          {...props}
          d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
          fill={fillColor}
        >
          <animateTransform
            attributeType="xml"
            attributeName="transform"
            type="rotate"
            from="0 25 25"
            to="360 25 25"
            dur="0.6s"
            repeatCount="indefinite"
          />
        </path>
      </>
      );
    }
    case 'calendar': {
      return (
        <path
          {...props}
          d="M16 2H15V0H13V2H5V0H3V2H2C0.89 2 0.01 2.9 0.01 4L0 18C0 19.1 0.89 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM16 7V18H2V7H16ZM9 9H4V14H9V9Z"
          fillRule="evenodd"
          clipRule="evenodd"
          fill={fillColor}
        />
      );
    }
    default: {
      return <path />;
    }
  }
};

export const getViewBox = (name) => {
  switch (name) {
    case 'delete': return '0 0 14 18';
    case 'cross-delete': return '0 0 20 20';
    case 'add': return '0 0 28 28';
    case 'arrow': return '0 0 16 16';
    case 'eye': return '0 0 16 10';
    case 'download': return '0 0 12 14';
    case 'img-placeholder': return '0 0 174 174';
    case 'arrow-outline': return '0 0 8 12';
    case 'pause': return '0 0 8 10';
    case 'play': return '0 0 7 10';
    case 'instagram': return '0 0 70 70';
    case 'facebook': return '0 0 70 70';
    case 'vk': return '0 0 70 70';
    case 'twiter': return '0 0 70 70';
    case 'bracket': return '0 0 9 4';
    case 'circular-preloader': return '0 0 20 20';
    case 'page-preloader': return '0 0 50 50';
    case 'user-icon': return '0 0 38 38';
    case 'calendar': return '0 0 18 20';
    default: return '0 0 24 24';
  }
};
