import request from 'api/request';

import { CREATE_FEEDBACK } from 'api/requestNames';

export const createFeedback = (formData) => (
  request(
    '/api/feedback/create-feedback',
    {
      method: 'POST',
      body: JSON.stringify(formData),
      requestName: CREATE_FEEDBACK,
    },
  )
);
