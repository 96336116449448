import { userRegistrationRules } from './rules';

export function getFieldResult(fieldValue, fieldRule, data) {
  if (fieldRule.compareField) {
    if (!data) {
      console.warn('no data attr');
      return false;
    }
    return fieldRule.validator(fieldValue, data[fieldRule.compareField]);
  }
  return fieldRule.validator(fieldValue);
}

function getFieldErrors(fieldValidationResult) {
  return Object
    .keys(fieldValidationResult)
    .map((errorName) => {
      if (!fieldValidationResult[errorName]) {
        return errorName;
      }
      return null;
    })
    .filter((errorName) => errorName !== null);
}

export function validateField(fieldValue, fieldRules, data) {
  return fieldRules.reduce(
    (acc, fieldRule) => ({
      ...acc,
      [fieldRule.name]: getFieldResult(fieldValue, fieldRule, data),
    }),
    {},
  );
}

export function validateForm(data, rules = userRegistrationRules) {
  return Object.keys(rules).reduce(
    (acc, fieldName) => {
      const fieldValue = data[fieldName];
      const fieldRules = rules[fieldName];
      if (Array.isArray(fieldRules)) {
        return {
          ...acc,
          [fieldName]: validateField(fieldValue, fieldRules, data),
        };
      }
      return {
        ...acc,
        [fieldName]: {
          [fieldRules.name]: getFieldResult(fieldValue, fieldRules, data),
        },
      };
    },
    {},
  );
}

export function getErrors(validationResult) {
  return Object
    .keys(validationResult)
    .reduce(
      (acc, fieldName) => {
        const errorsArray = getFieldErrors(validationResult[fieldName]);
        if (errorsArray.length) {
          return {
            ...acc,
            [fieldName]: errorsArray,
          };
        }
        return acc;
      },
      {},
    );
}
