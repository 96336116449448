export const homePageMenuConfiguration = [
  {
    title: 'Решения',
    link: '/#solutions',
    scroll: {
      behavior: 'smooth',
      block: 'center',
    },
  },
  {
    title: 'Как это работает?',
    link: '/#how-it-works',
    scroll: {
      behavior: 'smooth',
      block: 'center',
    },
  },
  {
    title: 'О компании',
    link: '/#about',
    scroll: {
      behavior: 'smooth',
      block: 'center',
    },
  },
  {
    title: 'Задать вопрос',
    link: '/#ask-question',
    scroll: {
      behavior: 'smooth',
      block: 'center',
    },
  },
];

export const userMenuConfiguration = [
  {
    title: 'Мои проекты',
    link: '/surveys',
    scroll: {},
  },
  {
    title: 'Решения',
    link: '/create',
    scroll: {},
  },
  {
    title: 'Список расходов',
    link: '/expense',
    scroll: {},
  },
  {
    title: 'Мой профиль',
    link: '/profile',
    scroll: {},
  },
];

export const managerMenuConfiguration = [
  {
    title: 'Список заказов',
    link: '/surveys',
    scroll: {},
  },
  {
    title: 'Решения',
    link: '/create',
    scroll: {},
  },
  {
    title: 'Пример отчетности',
    link: '/settings/surveys/examples',
    scroll: {},
  },
];
