import * as solutionTypes from 'store/solution/types';

export function getSolutionDetails(solutionData, subOption) {
  return {
    type: solutionTypes.GET_SOLUTION_DETAILS,
    payload: solutionData,
    subOption,
  };
}

export function setSolutionDetails(details) {
  return {
    type: solutionTypes.SET_SOLUTION_DETAILS,
    payload: details,
  };
}

export function submitSolutionSetupForm(rules) {
  return {
    type: solutionTypes.SUBMIT_SOLUTION_SETUP_FORM,
    payload: rules,
  };
}

export function updateSolutionData(data) {
  return {
    type: solutionTypes.UPDATE_SOLUTION_DATA,
    payload: data,
  };
}

export function resetSolutionCreateData() {
  return {
    type: solutionTypes.RESET_SOLUTION_CREATE_DATA,
  };
}

export function uploadFieldNameUploadFiles(name) {
  return {
    type: solutionTypes.UPDATE_FIELD_NAME_UPLOAD_FILES,
    payload: name,
  };
}

export function updateSolutionCreateData(data) {
  return {
    type: solutionTypes.UPDATE_SOLUTION_CREATE_DATA,
    payload: data,
  };
}

export function setSubmittedSolutionData(bool) {
  return {
    type: solutionTypes.SET_SUBMITTED_SOLUTION_DATA,
    payload: bool,
  };
}

export function setSubmittedSolutionSuccess(bool) {
  return {
    type: solutionTypes.SET_SUBMITTED_SOLUTION_SUCCESS,
    payload: bool,
  };
}

export function updateSurveyData(data) {
  return {
    type: solutionTypes.UPDATE_SURVEY_DATA,
    payload: data,
  };
}

export function updateSurveyStatus(data) {
  return {
    type: solutionTypes.UPDATE_SURVEY_STATUS,
    payload: data,
  };
}

export function updateSurveyManager(data) {
  return {
    type: solutionTypes.UPDATE_SURVEY_MANAGER,
    payload: data,
  };
}

export function getSurveyManagers() {
  return {
    type: solutionTypes.GET_SURVEY_MANAGERS,
  };
}

export function getSurveyStatuses() {
  return {
    type: solutionTypes.GET_SURVEY_STATUSES,
  };
}

export function getCompanyNames() {
  return {
    type: solutionTypes.GET_COMPANY_NAMES,
  };
}

export function getCompanyNamesSuccess(payload) {
  return {
    type: solutionTypes.GET_COMPANY_NAMES_SUCCESS,
    payload,
  };
}

export function getSolutionList(payload) {
  return {
    type: solutionTypes.GET_SOLUTION_LIST,
    payload,
  };
}

export function fetchSurveyData(data) {
  return {
    type: solutionTypes.FETCH_SURVEY_DATA,
    payload: data,
  };
}

export function fetchSurveyList() {
  return {
    type: solutionTypes.FETCH_SURVEY_LIST,
  };
}

export function setSurveyList(data) {
  return {
    type: solutionTypes.SET_SURVEY_LIST,
    payload: data,
  };
}

export function updateSurveyList(payload) {
  return {
    type: solutionTypes.UPDATE_SURVEY_LIST,
    payload,
  };
}

export function setSurveyListFilter(data) {
  return {
    type: solutionTypes.SET_SURVEY_LIST_FILTER,
    payload: data,
  };
}

export function uploadFileSolutionData(data) {
  return {
    type: solutionTypes.UPLOAD_FILE_SOLUTION_DATA,
    payload: data,
  };
}

export function uploadSurveyReportFile(file) {
  return {
    type: solutionTypes.UPLOAD_SURVEY_REPORT_FILE,
    payload: file,
  };
}

export function deleteSurveyReportFile(id) {
  return {
    type: solutionTypes.DELETE_SURVEY_REPORT_FILE,
    payload: id,
  };
}

export function attachReportExampleFile(file) {
  return {
    type: solutionTypes.ATTACH_REPORT_EXAMPLE_FILE,
    payload: file,
  };
}

export function deleteReportExampleFile(id) {
  return {
    type: solutionTypes.DELETE_REPORT_EXAMPLE_FILE,
    payload: id,
  };
}

export function deleteFileSolutionData(data) {
  return {
    type: solutionTypes.DELETE_FILE_SOLUTION_DATA,
    payload: data,
  };
}

export function uploadFileSolutionDataSuccess(data) {
  return {
    type: solutionTypes.UPLOAD_FILE_SOLUTION_DATA_SUCCESS,
    payload: data,
  };
}

export function uploadingReportFile(bool) {
  return {
    type: solutionTypes.UPLOADING_REPORT_FILE,
    payload: bool,
  };
}

export function setUploadingReportFile(bool) {
  return {
    type: solutionTypes.SET_UPLOADING_REPORT_FILE,
    payload: bool,
  };
}

export function setSolutionSettingExampleType(type) {
  return {
    type: solutionTypes.SET_SOLUTION_SETTING_EXAMPLE_TYPE,
    payload: {
      type,
    },
  };
}

