import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import { StyledWrapperSolutionCard } from 'pages/HomePage/SolutionCard/styled';

export const StyledCreatePage = styled('div')`
  & ${StyledWrapperSolutionCard} {
    margin-bottom: 58px;
    margin-top: 36px;

    ${media.md`
      margin-top: 0;
    `}

    ${media.tablet`
      margin-bottom: 66px;
    `}
  }
`;
