import {
  StyledCabinetLinkWrapper,
  StyledSignInLink,
  StyledDividerCabinetLink,
  StyledRegistrationLink,
} from './styled';

import React from 'react';

export const CabinetLinks = () => (
  <StyledCabinetLinkWrapper>
    <StyledSignInLink
      to={'/sign-in'}
    >
      Войти
    </StyledSignInLink>
    <StyledDividerCabinetLink />
    <StyledRegistrationLink
      to={'/registration'}
      active={'active'}
    >
      Регистрация
    </StyledRegistrationLink>
  </StyledCabinetLinkWrapper>
);
