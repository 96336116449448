import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

const TableContainer = styled.div`
  width: 100%;

  overflow: auto;

  ${media.sm`
    padding-bottom: 64px;
  `}

  ${media.desktop`
    padding-bottom: 78px;
  `}
`;

const Table = styled.table`
  width: 1170px;

  border-collapse: collapse;
`;

const HeaderRow = styled.tr``;

const HeaderCell = styled.th`
  vertical-align: top;
  text-align: left;
  padding-bottom: 20px;
  border-bottom: 1px solid ${(props): string => props.theme.colors.borderGrey};
`;

const Row = styled.tr`
  &:hover {
    background-color: ${(props): string => props.theme.colors.lightGrey};
  }
`;

const Cell = styled.td`
  max-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px;
  padding: 13px .5em;
`;

export const TableToList = styled.div`
  margin-bottom: 88px;

  width: 100%;

  overflow-y: hidden
`;

export const TableToListHeader = styled.div`
  font-weight: 600;
  line-height: 19px;

  padding-bottom: 18px;
  border-bottom: 1px solid ${(props): string => props.theme.colors.borderGrey};
  margin-bottom: 16px;
`;

export const TableToListRow = styled.div`
  width: 100%;
  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
  & > * {
    display: block;
    width: 100%;
    &:first-child {
      margin-bottom: 8px;
    }
  }
  ${media.xs`
    &:not(:last-of-type) {
      margin-bottom: ${(props): string => props.marginBottom ? '32px' : '20px'};
    }
  `}
`;

export const StyledNotFountItemMessage = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: ${(props): string => props.theme.colors.darkGrey};

  ${media.tablet`
    padding-bottom: 96px;
  `}

  ${media.desktop`
    margin-top: -14px;
  `}
`;

export {
  Row,
  Cell,
  Table,
  HeaderRow,
  HeaderCell,
  TableContainer,
};

