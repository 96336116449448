import produce from 'immer';
import * as errorsTypes from './types';

const initialState = {
  requestErrors: [],
  validationErrors: {},
};

const setRequestError = (state, action) => {
  state.requestErrors.push(action.error);
};

const setRequestErrorsList = (state, action) => {
  state.requestErrors.push(...Object.keys(action.errors || {}));
};

const clearRequestErrors = (state) => {
  state.requestErrors = [];
};

const setValidationErrors = (state, action) => {
  state.validationErrors = action.errors;
};

const clearValidationErrors = (state) => {
  state.validationErrors = {};
};

const errorsReducersList = Object.freeze({
  [errorsTypes.SET_REQUEST_ERROR]: setRequestError,
  [errorsTypes.SET_REQUEST_ERRORS_LIST]: setRequestErrorsList,
  [errorsTypes.CLEAR_REQUEST_ERRORS]: clearRequestErrors,
  [errorsTypes.SET_VALIDATION_ERRORS]: setValidationErrors,
  [errorsTypes.CLEAR_VALIDATION_ERRORS]: clearValidationErrors,
});

const ErrorsReducer = produce((state = initialState, action) => {
  if (errorsReducersList[action.type]) {
    return errorsReducersList[action.type](state, action);
  }

  return state;
});

export default ErrorsReducer;
