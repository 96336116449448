import styled from 'styled-components';
import { UserInfoStyles } from 'components/UserInfo/styles';
import { H2, H4 } from 'uiComponents/Titles';
import { media } from 'styled-bootstrap-grid';
import { EmptyListContainer, EmptyListImg, EmptyListTitle } from 'components/EmptyList/styles';
import { BreadcrumbsContainer } from 'components/Breadcrumbs/styled';

export const ExpenseListContainer = styled(UserInfoStyles)`
  padding-bottom: 0;

  ${media.tablet`
    padding-bottom: 0;
  `}

  ${EmptyListContainer} {
    padding: 0;
    margin-bottom: 60px;
  }

  ${EmptyListImg} {
    margin-bottom: 64px;
  }

  ${EmptyListTitle} {
    margin-bottom: 35px;
  }

  ${BreadcrumbsContainer} {
    ${media.lg`
      margin-bottom: 60px;
      margin-top: 6px;
    `}
  }
`;

export const ExpenseListTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;

  ${media.tablet`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
  `}

  ${media.desktop`
    margin-bottom: 95px;
  `}

  ${H2} {
    margin-bottom: 38px;
    ${media.tablet`
      margin-bottom: 0;
  `}
  }

  a {
    display: flex;
    align-items: center;
    text-align: right;
    font-size: 16px;
    line-height: 20px;
    color: ${(props) => props.theme.colors.orange};
    text-decoration: none;
    ${media.tablet`
      position: absolute;
      right: 15px;
      top: -80px;
    `}
    ${media.desktop`
       position: initial;
    `}
  }

  img {
    margin-right: 16px;
  }
`;

export const ExpenseListItem = styled.div`
  margin-bottom: 36px;
  width: 100%;

  ${media.tablet`
    width: 600px;
    margin-bottom: 96px;
  `}

  ${media.desktop`

  `}
`;

export const ExpenseListTableRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 17px;
  padding-left: 17px;
  padding-bottom: 16px;
  border-bottom: 3px solid ${(props) => props.theme.colors.darkGrey};

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border: none;
  }

  ${media.tablet`
    padding-left: 17px;
    margin-bottom: 8px;
    padding-bottom: 8px;
    justify-content: flex-start;
  `}

  &:last-child {
    margin-bottom: 0;
  }

`;

export const ExpenseListHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 16px;
  border-bottom: 3px solid ${(props) => props.theme.colors.darkGrey};
  margin-bottom: 17px;
  font-weight: 600;

  img {
    margin-right: 8px;
    transition: 0.1s all;
  }

  ${media.tablet`
     padding-bottom: 17px;
     justify-content: flex-start;
     ${H4}{
      display: block;
      width: 471px;
     }
  `}
  ${media.desktop`
    margin-bottom: 15px;
  `}
`;

export const ExpenseListTable = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 17px;
  font-size: 16px;
  line-height: 20px;

  & ${ExpenseListTableRow} {
    display: ${(props) => props.isShow ? 'flex' : 'none'};
    transition: .3s all;
  }

  & ${ExpenseListHead} {
    img {
      transform: ${(props) => props.isShow ? 'rotate(180deg)' : 'rotate(0)'};
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  ${media.tablet`
    margin-bottom: 32px;
  `}
`;

export const ExpenseListTableCell = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &:nth-child(2) {
    color: ${(props) => props.theme.colors.orange};
    order: 3;
    margin-top: 16px;
    width: 100%;
  }

  & a {
    color: inherit;
    text-decoration: none;
  }

  ${media.tablet`
    ${ExpenseListTableRow} & {
      &:first-child {
        margin-right: 20px;

        width: 63px;
      }
      &:nth-child(2) {
        margin-right: 71px;
        width: 300px;
        order: initial;
        margin-top: 0;
      }
      &:last-child {

      }
    }
  `}

`;

export const ExpenseListTableHead = styled(ExpenseListHead)`
  margin-bottom: 17px;
  border-color: ${(props) => props.theme.colors.lightGrey};

  ${media.tablet`
    margin-bottom: 12px;
  `}
`;

export const ExpenseListTableHeadItem = styled.div`
  display: flex;
  align-items: center;

  ${media.tablet`
    &:hover {
      cursor: pointer;
    }

    &:first-child {
      display: block;
      width: 471px;
    }
  `}
`;

export const ExpenseListHeadSum = styled.div`
  font-weight: 600;
  color: ${(props) => props.theme.colors.orange};
`;

