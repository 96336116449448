import React from 'react';
import * as dayjs from 'dayjs';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Col, Row } from 'styled-bootstrap-grid';

import { SURVEY_FILE_UPLOAD } from 'api/requestNames';

import { getYearsList } from 'helpers/date';

import * as solutionActions from 'store/solution/actions';
import * as solutionSelectors from 'store/solution/selectors';

import { getValidationErrors, getRequestErrors } from 'store/errors/selectors';

import CreateForm from 'components/SurveySetup/forms/CreateForm';
import { solutionsConfig } from 'components/SurveySetup/forms/solutionConfig';
import { AuthorizedField } from 'components/AuthorizedField/AuthorizedField';

import DateInput from 'uiComponents/DateInput';
import FormField from 'uiComponents/Form/Field';
import { StyledTextArea } from 'uiComponents/TeaxtArea';
import { PhoneInput } from 'uiComponents/Input';
import Select from 'uiComponents/Select';
import FileInput from 'components/FileInput';
import { H4 } from 'uiComponents/Titles';

import * as styled from './styled';

const mapStateToProps = (state) => ({
  isSubmittedForm: solutionSelectors.isSubmittedForm(state),
  solutionsDetails: solutionSelectors.getSolutionDetails(state),
  solutionsCreateData: solutionSelectors.getSolutionCreateData(state),
  validationErrors: getValidationErrors(state),
  requestErrors: getRequestErrors(state),
});

const mapDispatchToProps = (dispatch) => ({
  updateSolutionCreateData: (fieldData) => dispatch(solutionActions.updateSolutionCreateData(fieldData)),
});

// prepare ScheduleList to render
// remove bookedDates from time list
const prepareScheduleList = (schedule, bookedDates, value) => {
  const nextDay = dayjs().add(1, 'day').unix();

  return (
    schedule.filter((scheduleItem) => {
      const scheduleDate = `${dayjs(value).format('YYYY-MM-DD')}T${scheduleItem}:00`;

      return (
        dayjs(scheduleDate).unix() > nextDay
        && bookedDates.indexOf(scheduleDate) === -1
      );
    }).map((scheduleItem) => ({
      id: scheduleItem,
      name: scheduleItem,
    }))
  );
};

const prepareBookedDates = (bookedDates, scheduleList) => {
  const nextDay = dayjs().add(1, 'day');
  const endBookingTime = dayjs().add(1, 'day').set('hour', 17);
  const bookedDatesMap = bookedDates.reduce((bookedDatesAccumulator, bookedDateItem) => {
    const preparedDateTime = dayjs(bookedDateItem);
    const preparedDate = preparedDateTime.format('YYYY.MM.DD');
    const preparedTime = preparedDateTime.format('HH:mm');

    if (bookedDatesAccumulator[preparedDate]) {
      bookedDatesAccumulator[preparedDate].push(preparedTime);
    } else {
      bookedDatesAccumulator[preparedDate] = [
        preparedTime,
      ];
    }

    return bookedDatesAccumulator;
  }, {});

  const reservedDays = [];

  if (endBookingTime.unix() < nextDay.unix()) {
    reservedDays.push(nextDay.toDate());
  }

  return Object.keys(bookedDatesMap).filter((bookedDateKey) => (
    scheduleList.every((bookedTime) => (
      bookedDatesMap[bookedDateKey].indexOf(bookedTime) !== -1
    ))
  )).reduce((bookedDatesAccumulator, bookedDateItem) => ([
    ...bookedDatesAccumulator,
    new Date(bookedDateItem),
  ]), reservedDays);
};

@connect(mapStateToProps, mapDispatchToProps)
class ConsultationForm extends CreateForm {
  typeField = 'questionType';

  storeUnsubscribe = null;

  constructor(props) {
    super(props);
    this.state = this.solutionDataConfig.initialFormState;
    this.props.updateSolutionCreateData(this.solutionDataConfig.initialFormState);
  }

  solution = this.props.match.params.solution;

  solutionDataConfig = solutionsConfig.find(((item) => item.name === this.solution));

  render() {
    const {
      dateState: dateStateFromState,
      consultationTime: consultationTimeFromState,
    } = this.state;
    const {
      solutionsDetails: {
        bookedDates = [],
      } = {},
      solutionsCreateData: {
        dateState = dateStateFromState,
        consultationTime = consultationTimeFromState,
      },
    } = this.props;

    const scheduleList = prepareScheduleList(this.solutionDataConfig.scheduleList, bookedDates, dateState.value);
    const bookedDatesExtendList = prepareBookedDates(bookedDates, this.solutionDataConfig.scheduleList);

    return (
      <>
        <Row>
          <Col col={12}>
            <styled.ConsultationTextBlock>
              Если нужно <strong>создать нестандартный проект</strong>, уточнить задачи исследования,
              обсудить методику и&nbsp;анкету, помочь интерпретировать данные.
              <br /><br />
              За последние 25 лет работы в индустрии исследований, мы накопили достаточно знаний и прикладных навыков,
              видели тысячи и тысячи проектов по всему спектру задач и индустрий.
              <br /><br />
              Мы готовы делиться опытом, помочь <strong>с определением задач исследования,
              выбором методики стандартного проекта или созданием специального исследования под ваши задачи.</strong>
              <br /><br />
              Мы также готовы <strong>обсуждать полученные данные и их интерпретацию</strong> в контексте задач проекта
              и&nbsp;бизнеса, обсуждать возможные способы использования исследований в компании.
              <br /><br />
              Укажите удобное время, скажите, о чем нужна консультация, и мы свяжемся с вами в назначенный день и час.
            </styled.ConsultationTextBlock>
          </Col>
        </Row>
        <Row>
          <Col col={12}>
            <styled.ConsultationFormBlock>
              <Row>
                <Col col={12}>
                  <H4>Контактные данные</H4>
                  <styled.ConsultationSubTitle>
                    Укажите, пожалуйста, ваши контактные данные для связи
                  </styled.ConsultationSubTitle>
                </Col>
              </Row>
              <Row>
                <Col
                  col={12}
                  md={6}
                >
                  <AuthorizedField>
                    <FormField>
                      <StyledTextArea
                        id="firstName"
                        name="firstName"
                        placeholder="Ваше имя"
                        onChange={this.inputOnChange}
                        error={this.isValidField('firstName')}
                      />
                    </FormField>
                  </AuthorizedField>
                </Col>
                <Col
                  col={12}
                  md={6}
                >
                  <AuthorizedField>
                    <FormField>
                      <StyledTextArea
                        id="lastName"
                        name="lastName"
                        placeholder="Ваша фамилия"
                        onChange={this.inputOnChange}
                        error={this.isValidField('lastName')}
                      />
                    </FormField>
                  </AuthorizedField>
                </Col>
              </Row>
              <Row>
                <Col
                  col={12}
                  md={6}
                >
                  <AuthorizedField>
                    <FormField>
                      <StyledTextArea
                        id="email"
                        name="email"
                        placeholder="Ваш E-Mail"
                        onChange={this.inputOnChange}
                        error={this.isValidField('email')}
                      />
                    </FormField>
                  </AuthorizedField>
                </Col>
                <Col
                  col={12}
                  md={6}
                >
                  <AuthorizedField>
                    <FormField>
                      <PhoneInput
                        id="phone"
                        name="phone"
                        placeholder="Ваш телефон"
                        onChange={this.inputOnChange}
                        error={this.isValidField('phone')}
                      />
                    </FormField>
                  </AuthorizedField>
                </Col>
              </Row>
            </styled.ConsultationFormBlock>
            <styled.ConsultationFormBlock>
              <Row>
                <Col col={12}>
                  <H4>Календарь</H4>
                  <styled.ConsultationSubTitle>
                    Выберите время из свободных интервалов с 10 до 18 не ранее чем за 24 часа
                  </styled.ConsultationSubTitle>
                </Col>
              </Row>
              <Row>
                <Col
                  col={12}
                  md={6}
                >
                  <AuthorizedField>
                    <FormField>
                      <DateInput
                        name="dateState"
                        dateState={dateState}
                        disabledDays={[
                          { before: dateState.currentDate },
                          { daysOfWeek: [ 0, 6 ] },
                          ...bookedDatesExtendList,
                        ]}
                        yearsList={getYearsList(10)}
                        onChange={this.inputOnChange}
                        error={this.isValidField('dateState')}
                        requestError={
                          this.isRequestError('TimeNotAvailable')
                          && 'Выбранное время уже занято, выберите другое'
                        }
                        placeholder="Выберите день"
                      />
                    </FormField>
                  </AuthorizedField>
                </Col>
                <Col
                  col={12}
                  md={6}
                >
                  <AuthorizedField>
                    <FormField>
                      <Select
                        id="consultationTime"
                        name="consultationTime"
                        placeholder="Выберите время (МСК)"
                        data={scheduleList}
                        value={consultationTime}
                        defaultValue={scheduleList[0] || null}
                        onChange={this.inputOnChange}
                        error={this.isValidField('consultationTime')}
                        disabled={!dateState.value}
                      />
                    </FormField>
                  </AuthorizedField>
                </Col>
              </Row>
            </styled.ConsultationFormBlock>
            <styled.ConsultationFormBlock>
              <Row>
                <Col col={12}>
                  <H4>Тема консультации</H4>
                  <styled.ConsultationSubTitle>Напишите, о чем нужна консультация</styled.ConsultationSubTitle>
                </Col>
              </Row>
              <Row>
                <Col col={12}>
                  <AuthorizedField>
                    <FormField>
                      <StyledTextArea
                        id="consultationTheme"
                        name="consultationTheme"
                        placeholder=""
                        onChange={this.inputOnChange}
                        error={this.isValidField('consultationTheme')}
                      />
                    </FormField>
                  </AuthorizedField>
                  <AuthorizedField>
                    <FileInput
                      name="fileIds"
                      requestName={SURVEY_FILE_UPLOAD}
                      selector={solutionSelectors.uploadingFileIds}
                      multiple={false}
                    />
                  </AuthorizedField>
                </Col>
              </Row>
            </styled.ConsultationFormBlock>
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(ConsultationForm);
