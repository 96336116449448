import React, { Children } from 'react';
import { useHistory } from 'react-router-dom';

import { useSelector } from 'react-redux';

import * as appSelectors from 'store/app/selectors';

export const AuthorizedField = (props) => {
  const {
    children,
  } = props;

  const history = useHistory();

  const isAuthorized = useSelector(appSelectors.authSelector);

  const onFocus = () => {
    if (!isAuthorized) {
      history.push('/sign-in', {
        from: {
          pathname: history.location.pathname,
        },
      });
    }
  };

  return Children.map(children, (child) => React.cloneElement(child, { onFocus }), null);
};
