import React from 'react';
import styled from 'styled-components';
import Icon from 'uiComponents/Icon';

export const AddButtonWrapper = styled.div`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  & svg {
    margin-right: 1em;
  }
  & span {
    font-weight: 600;
    font-size: 14px;
  }
`;

const AddButton = ({ text, color, onClick }) => (
  <AddButtonWrapper onClick={onClick}>
    <Icon
      name="add"
      fill={color}
    />
    <span style={{ color }}>{text}</span>
  </AddButtonWrapper>
);

export default AddButton;
