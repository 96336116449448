import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';

import PropTypes from 'prop-types';

import * as styles from 'uiComponents/Table';

import {
  ListContainer,
} from 'components/SurveysList/styled';

const List = ({ items, options, role, title }) => {

  const [ width, setWidth ] = useState(document.documentElement.clientWidth);

  const onResize = useCallback(() => {
    setWidth(document.documentElement.clientWidth);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, []);

  return (
    <ListContainer>
      {width > 575 && (
        <styles.Table>
          <thead>
            <styles.HeaderRow>
              {options.map((option, optionIndex) => (
                <styles.HeaderCell
                  key={optionIndex}
                  style={{ width: option.width }}
                >{option.title}</styles.HeaderCell>
              ))}
            </styles.HeaderRow>
          </thead>

          <tbody>
            {items.map((item, index) => (
              <styles.Row key={index}>
                {options.map((option, optionIndex) => (
                  <styles.Cell key={optionIndex}>
                    {option.render(item)}
                  </styles.Cell>
                ))}
              </styles.Row>
            ))}
          </tbody>
        </styles.Table>
      )}

      {width <= 575 && (
        <styles.TableToList>
          <styles.TableToListHeader>{title}</styles.TableToListHeader>
          {items.map((item, index) => (
            <styles.TableToListRow
              marginBottom={role}
              key={index}
            >
              {options.map((option, optionIndex) => {
                if (option.mobile) {
                  return (
                    <span key={optionIndex}>{option.render(item)}</span>
                  );
                }
                return null;
              })}
            </styles.TableToListRow>
          ))}
        </styles.TableToList>
      )}
    </ListContainer>
  );
};

List.propTypes = {
  role: PropTypes.bool,
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    mobile: PropTypes.bool,
    render: PropTypes.func.isRequired,
  })).isRequired,
};

export default List;
