import React, { createRef } from 'react';
import DayPicker from 'react-day-picker';
import dayjs from 'dayjs';

import { StyledDatePicker } from 'components/RangeDatePicker/DatePicker/styled';
import { locale } from 'components/RangeDatePicker/DatePicker/locale';
import { localeUtilsRu } from 'components/RangeDatePicker/DatePicker/localeUtilsRu';
import { YearMonthControl } from 'components/RangeDatePicker/DatePicker/YearMonthControl';
import { StyledRangeDatePicker } from 'components/RangeDatePicker/styled';

import Icon from 'uiComponents/Icon';

import { DateInputError } from './styled';

export default class extends React.PureComponent {

  static defaultProps = {
    yearsList: [],
    disabledDates: [],
  }

  state = {
    isOpen: false,
  };

  componentDidMount() {
    document.body.addEventListener('click', this.handleClickPageBody);
  }
  componentWillUnmount() {
    document.body.removeEventListener('click', this.handleClickPageBody);
  }

  styledRangeDatePickerRef = createRef();
  calendarRef = createRef();

  toggleModal = (isOpen = false) => {
    this.setState({ isOpen });
  }

  updateParentComponent = (newState) => {
    const { name, onChange } = this.props;

    onChange({
      target: {
        name: name,
        value: newState,
      },
    });
  };

  changePeriodActiveId = (id) => {
    const { dateState } = this.props;

    this.updateParentComponent({
      ...dateState,
      periodActiveId: id,
    });
  };

  changePresetDayPicker = (value) => {
    const { dateState } = this.props;

    this.updateParentComponent({
      ...dateState,
      value,
    });
  };

  changeYearMonth = (month) => {
    const { dateState } = this.props;

    this.updateParentComponent({
      ...dateState,
      month,
    });
  };

  handleClickPageBody = (e) => {
    if (
      this.styledRangeDatePickerRef.current
      && this.styledRangeDatePickerRef.current.contains(e.target)
    ) {
      return false;
    }

    return this.toggleModal(false);
  };

  render() {
    const {
      placeholder,
      dateState,
      yearsList,
      dateState: {
        currentDate = new Date(),
        value,
      },
      disabledDays,
      error,
      requestError,
    } = this.props;
    const { isOpen } = this.state;
    const currentMounts = dateState.month || currentDate.getMonth();
    const currentDateString = value
      ? dayjs(value).format('DD.MM.YYYY')
      : placeholder;

    return (
      <>
        <StyledRangeDatePicker
          ref={this.styledRangeDatePickerRef}
          error={error}
        >
          <input
            onClick={() => this.toggleModal(!isOpen)}
            type="text"
            value={currentDateString}
            className="input-field"
            readOnly
          />
          <Icon
            className="input-icon"
            fill="#d1d1d1"
            name="calendar"
          />
          <StyledDatePicker
            isVisibleCalendar={isOpen}
            ref={this.calendarRef}
          >
            <DayPicker
              locale="ru"
              // modifiers={{ start: dateState.currentDate }}
              selectedDays={value}
              months={locale.months}
              month={currentMounts}
              weekdaysShort={locale.weekdaysShort}
              localeUtils={localeUtilsRu}
              disabledDays={disabledDays}
              onDayClick={this.changePresetDayPicker}
              captionElement={({ date, localeUtils }) => (
                <YearMonthControl
                  date={date}
                  localeUtils={localeUtils}
                  disabledDays={disabledDays}
                  currentDate={currentDate}
                  onChange={this.changeYearMonth}
                  yearsList={yearsList}
                />
              )}
            />
          </StyledDatePicker>
        </StyledRangeDatePicker>
        {value === null && requestError && (
          <DateInputError>{requestError}</DateInputError>
        )}
      </>
    );
  }
}
