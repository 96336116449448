import React from 'react';
import { StyledCreatePage } from './styled';

import { Col, Container } from 'styled-bootstrap-grid';

import { SolutionCard } from 'pages/HomePage/SolutionCard/SolutionCards';
import { StyledWrapperSolutionCard } from 'pages/HomePage/SolutionCard/styled';

import { fakeCardsData } from 'pages/HomePage/fakeCardsData';

// Сделано разделение, что бы последний элемент выводится отдельно и другой ширины
const solutionsWithoutLastSolution = fakeCardsData.slice(0, fakeCardsData.length - 1);
const lastSolutions = fakeCardsData[fakeCardsData.length - 1];

const Index = () => <StyledCreatePage>
  <Container>
    <StyledWrapperSolutionCard>
      {
        solutionsWithoutLastSolution.map((card) => (
          <Col
            col={12}
            md={6}
            xl={4}
            key={card.id}
          >
            <SolutionCard {...card} />
          </Col>
        ))
      }
      <Col
        md={12}
        xl={8}
        key={lastSolutions.id}
      >
        <SolutionCard {...lastSolutions} />
      </Col>
    </StyledWrapperSolutionCard>
  </Container>

</StyledCreatePage>;

export default Index;
