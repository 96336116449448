import styled from 'styled-components';

import arrowOutline from 'assets/images/arrowOutline.';

export const StyledMenuSwitchTitle = styled.div`
  position: relative;

  margin-bottom: ${({ arrow }) => arrow
    ? '42px'
    : '32px'};
  padding-left: ${({ arrowPosition }) => arrowPosition === 'left'
    ? '30px'
    : '0'};
  padding-top: ${({ borderTop }) => borderTop
    ? '18px'
    : '0'};
  padding-bottom: ${({ borderBottom }) => borderBottom
    ? '18px'
    : '0'};

  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  text-decoration: none;
  color: ${(props) => props.theme.colors.black};

  border-top: ${({ borderTop }) => borderTop ? '1px solid' : 'none'};
  border-bottom: ${({ borderBottom }) => borderBottom ? '1px solid' : 'none'};
  border-color: ${({ theme }) => theme.colors.lightGrey};

  &:hover {
    cursor: pointer;
  }

  &::after {
    position:absolute;
    top: 0;
    bottom: 0;
    right: ${({ arrowPosition }) => arrowPosition === 'right'
    ? 0
    : 'initial'};
    left: ${({ arrowPosition }) => arrowPosition === 'left'
    ? 0
    : 'initial'};

    content: '';
    display: block;

    width: 8px;

    transform: ${({ arrowPosition }) => arrowPosition === 'right'
    ? 'rotate(180deg)'
    : 'rotate(0)'};

    background-image: ${({ arrow }) => arrow ? `url(${arrowOutline}` : 'none'});
    background-repeat: no-repeat;
    background-position: center;
  }
`;
