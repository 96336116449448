import styled, { css } from 'styled-components';
import { BigTitle } from 'uiComponents/Titles';
import {
  Col,
  Container,
  media,
} from 'styled-bootstrap-grid';

import { StyledButton } from 'uiComponents/Button';

const paragraph = css`
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
`;

export const StyledContainerFluid = styled('div')`
  background-color: ${(props) => props.bgColor};
`;

export const StyledHeaderTitle = styled(BigTitle)`
  font-size: 32px;
  line-height: 40px;
  font-weight: bold;
  color: #0D151C;
  margin: 36px 0 28px 0;

  ${media.md`
    margin: 118px 0 28px 0;

    font-size: 48px;
    line-height: 56px;
  `}

  ${media.xl`
    line-height: 74px;
    font-size: 64px;
    margin: 58px 0 28px 0;
  `}
`;

export const StyledHeaderSubtitle = styled('h4')`
  position: relative;

  max-width: 1150px;

  margin: 0;
  padding-bottom: 64px;

  font-weight: normal;
  color: #0D151C;
  font-size: 16px;
  line-height: 24px;

  ${media.md`
    padding-bottom: 64px;
  `}

  ${media.xl`
    font-size: 22px;
    line-height: 32px;
  `}
`;

export const StyledParagraph = styled('p')`
  ${paragraph};
`;

export const StyledSectionTitle = styled('h5')`
  font-weight: bold;
  color: #0D151C;
  font-size: 26px;
  line-height: 34px;
  margin-bottom: 36px;
  margin-top: 0;

  ${media.md`
    font-size: 42px;
    line-height: 50px;
    margin-bottom: 56px;
  `}

  ${media.xl`
    font-size: 42px;
    line-height: 74px;
    margin: 0 0 36px 0;
  `}
`;

export const StyledSectionTitleHowItWork = styled(StyledSectionTitle)`
  margin-bottom: 26px;

  ${media.md`
    margin-bottom: 28px;
  `}

  ${media.xl`
    margin-bottom: 36px;
  `}
`;

export const StyledSectionTitleAbout = styled(StyledSectionTitle)`
  margin-bottom: 28px;
  margin-top: 0;

  line-height: 51px;
  color: #202020;

  ${media.md`
      margin-bottom: 42px;
  `}
`;

export const StyledSectionTitleAskQuestion = styled(StyledSectionTitle)`
  margin-bottom: 28px;
  margin-top: 0;

  font-size: 26px;
  line-height: 32px;
  color: #202020;

  ${media.xl`
    margin-bottom: 32px;
  `}
`;

export const StyledSectionTitleReview = styled(StyledSectionTitle)`
  margin-bottom: 28px;
  margin-top: 0;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  font-size: 26px;
  line-height: 34px;



  ${media.md`
    margin-bottom: 24px;
    font-size: 42px;
    line-height: 51px;
  `}
`;

export const StyledParagraphAboutSection = styled(StyledParagraph)`
  margin: 0 0 20px 0;

  &:last-child {
    margin-bottom: 80px;
  }
`;

export const StyledParagraphReviewSection = styled(StyledParagraph)`
  margin: 0 0 44px 0;
`;

export const StyledFooterAboutSection = styled(Col)`
  ${paragraph};

  strong {
    font-weight: 700;
  }

  span {
    color: #b7b7b7;
  }
`;

export const StyledReviewSectionContainer = styled(Container)`
  padding-top: 96px;
  padding-bottom: 96px;

  ${media.md`
    padding-top: 100px;
    padding-bottom: 56px;
  `}

  ${media.xl`
    padding-top: 200px;
    padding-bottom: 156px;
  `}
`;

export const StyledAboutSectionContainerFluid = styled(StyledContainerFluid)`
  padding: 65px 0 0;

  ${media.xl`
    padding: 64px 0 0 0;
  `}
`;

export const StyledAboutAskQuestionSectionContainerFluid = styled(Container)`
  padding: 48px 0 60px;
  min-width: 100%;

  background: ${(props) => props.bgColor};

  ${media.xl`
    padding: 64px 0 64px;
  `}
`;

export const StyledLogosWrapper = styled('div')`
  margin: 0 -4px;

  display: flex;
  flex-wrap: wrap;

  ${media.tablet`
    margin: 0 -12px;
  `}

  ${media.xl`
    margin-left: 82px
  `}
`;

export const StyledLogoWrapper = styled('div')`
  margin-bottom: 17px;
  padding: 0 4px;

  height: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  flex: 1 0 20%;

  ${media.tablet`
    padding: 0 12px;
  `}

  ${media.xl`

  `}
`;

export const StyledLogoWrapperItem = styled('img')`
  max-width: 100%;
  width: 100%;
  height: auto;

  display: block;
`;

export const StyledIconsRow = styled.div`
  margin-bottom: 36px;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: transparent;
  }

  ${media.md`
    margin-bottom: 48px;
  `}

  svg {
    width: 43px;
    height: 43px;

    & path:first-child {
      transition: .3s;
    }

    &:hover path:first-child {
      fill: #7B7B7B;
    }

    ${media.md`
      width: 70px;
      height: 70px;
    `}
  }

  .icon {
    margin-right: 39px;
    ${media.md`
      margin-right: 64px;
    `}
    ${media.xl`
      margin-right: 64px;
    `}

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const StyledSocialNetworkSlogan = styled('div')`
  padding: 0 30px;

  color: ${(props) => props.theme.colors.darkGrey};
  font-weight: normal;
  text-align: center;
  font-size: 16px;
  line-height: 24px;

  ${media.md`
    padding: 0;

    width: 100%;

    font-size: 16px;
    line-height: 19px;
  `}
`;

export const StyledSocialNetworkSection = styled(Container)`
  padding: 64px 0 96px;

  ${media.xl`
    padding: 102px 0 96px 0;
  `}
`;

export const StyledCookiesContainerFluid = styled(StyledContainerFluid)`
  position: fixed;
  bottom: 0;
  left: 0;

  width: 100%;

  padding: 40px 0;

  color: #fff;
  z-index: 100;
`;

export const StyledCookiesText = styled('p')`
  margin: 0;

  font-size: 14px;
  line-height: 22px;

  .mobile-text {
    ${media.tablet`
      display: none;
    `}
  }

  .desktop-text {
    display: none;

    ${media.tablet`
      display: block;
    `}
  }
`;

export const StyledCookiesButton = styled(StyledButton)`
  padding: 13px 14px;

  height: 100%;

  font-weight: bold;
  color: #fff;

  border-color: #fff;

  ${media.tablet`
    padding: 13px 35px;
  `}
`;
