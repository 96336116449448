import styled from 'styled-components';

import {
  TextAreaRow,
  LabelQuestionRow,
} from 'components/SurveySetup/DynamicFieldsBlock/styled';

const FormQuestionsField = styled.div`
  & label {
    display: inline-block;
    font-weight: 600;
    line-height: 22px;
    margin-bottom: 0;
  }

  & ${LabelQuestionRow} {
    label {
      margin-bottom: ${(props) => props.isRanked ? '16px' : '0'};
    }
  }
  & p {
    font-size: 14px;
    color: ${({ theme }) => theme.colors.orange};
    margin: 1em 0 0 0;
  }
  margin-bottom: 18px;

  border-bottom: ${(props) => props.isRanked ? '0' : '1px solid #ECEBED'};

  &.flex {
    display: flex;
    align-items: center;
  }

  span:first-child {
    flex: 0 0 50px;
  }
  span:last-child {
    flex: 1 0 70px;
    &:not(.attached-files-custom__file-extension){
      margin-left: 10px;
    }
    margin-left: 0px;
  }

  ${TextAreaRow} {
    margin-top: 16px;
  }

  ${TextAreaRow}:last-child {
     margin-bottom: ${(props) => props.isRanked ? '18px' : '48px'};
  }
`;

export default FormQuestionsField;
