import React, { useEffect, useRef, useState } from 'react';

import { useDispatch } from 'react-redux';

import { useLocation } from 'react-router-dom';

import * as userActions from 'store/user/actions';

import Icon from 'uiComponents/Icon';

import {
  HeaderMenuWrapper,
  HeaderUserIcon,
  HeaderDropdown,
  SingOutItem,
} from 'components/layouts/MainLayout/Header/AvatarDropdown/styled';

import { MenuItems } from 'components/layouts/MainLayout/Header/MenuItems';
import { MenuSwitchTitle } from 'components/layouts/MainLayout/Header/MenuSwitchTitle';

export const AvatarDropdown = ({ menuItems }) => {
  const dispatch = useDispatch();

  const location = useLocation();

  const isHomePage = location.pathname === '/';

  const [ isOpen, setOpenState ] = useState(false);

  const [ isShowUserMenu, setIsShowUserMenu ] = useState(false);

  const button = useRef(null);

  const dropdown = useRef(null);

  const handleClickSignOutButton = () => {
    dispatch(userActions.signOut('/'));
  };

  const handleClickOutside = ({ target }) => {
    if (isOpen && !target.closest('.header-dropdown')) {
      setOpenState(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => document.removeEventListener('click', handleClickOutside);
  });

  const handleClickMenuSwitchTitle = () => {
    setIsShowUserMenu((prevState) => !prevState);
  };

  const handleClickMobileLink = () => {
    setOpenState(false);
  };

  return (
    <HeaderMenuWrapper>
      <HeaderUserIcon
        onClick={() => setOpenState(!isOpen)}
        ref={button}
      >
        <Icon
          className={'icon'}
          name={'user-icon'}
          width={38}
          height={38}
        />
      </HeaderUserIcon>
      <HeaderDropdown
        isOpen={isOpen}
        ref={dropdown}
        className="header-dropdown"
      >
        <MenuSwitchTitle
          isShowUserMenu={isShowUserMenu}
          handleClickMenuSwitchTitle={handleClickMenuSwitchTitle}
        />

        <MenuItems
          menuItems={menuItems}
          isShowUserMenu={isShowUserMenu}
          handleClickMobileLink={handleClickMobileLink}
        />

        <SingOutItem
          isHomePage={isHomePage}
          onClick={handleClickSignOutButton}
        >Выход</SingOutItem>
      </HeaderDropdown>
    </HeaderMenuWrapper>
  );
};
