export const NO_UPLOAD_ERRORS = [
  'TimeNotAvailable',
];

export const ERROR_TEXTS = Object.freeze({
  FileIsToLarge: {
    text: 'Превышен максимально допустимый размер файла (10 МБ)',
  },
  InvalidFileFormat: {
    text: 'Некорректный формат файла',
    subText: 'Разрешённые форматы: ',
  },
  InvalidVideoDuration: {
    text: 'Превышена максимально допустимая продолжительность видео (2 мин)',
  },
  FileUploadForbidden: {
    text: 'Для указанного решения загрузка файлов запрещена',
  },
});
