import { all, fork } from 'redux-saga/effects';

import UserSaga from 'store/user/saga';
import SolutionSaga from 'store/solution/saga';

function* root() {
  yield all([
    fork(UserSaga),
    fork(SolutionSaga),
  ]);
}

export default root;
