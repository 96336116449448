import React from 'react';
import { useSelector } from 'react-redux';
import { getSolutions } from 'store/solution/selectors';
import SolutionBlock from 'components/SolutionsList/SolutionBlock';
import { SolutionsEmpty } from 'components/SolutionsList/styled';

const SolutionsList = () => {
  const solutions = useSelector(getSolutions);
  return (
    <>
      {solutions.length === 0 && <SolutionsEmpty />}
      {solutions.length > 0 && solutions.map((solution) => (
        <SolutionBlock solution={solution} />
      ))}
    </>
  );
};

export default SolutionsList;
