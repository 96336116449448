import React, { useState, useEffect } from 'react';
import { Col, Row } from 'styled-bootstrap-grid';
import { useSelector, useDispatch } from 'react-redux';

import { profileDataSelector } from 'store/user/selectors';
import { authSelector } from 'store/app/selectors';
import { fetchUserData } from 'store/user/actions';

import { PRIVACY_POLICY } from 'constants/staticDocs';

import { Button } from 'uiComponents/Button/Button';
import Checkbox from 'uiComponents/Checkbox';

import { createFeedback } from 'api/common';

import { getFieldResult } from 'services/validation';
import { defaultRules } from 'services/validation/rules';

import {
  StyledFormBlock,
  StyledFormBlockInputPhone,
  StyledFormBlockInput,
  StyledFormBlockTextArea,
  StyledFormBlockSuccess,
  StyledFormBlockCheckedAgreement,
} from './styled';
import { StyledDownloadLink } from 'components/SurveySetup/NameSurveyBlock/styled';

const FORM_BLOCK_RULES_OBJECT = Object.freeze({
  name: defaultRules.emptyString,
  email: defaultRules.email,
  phone: defaultRules.emptyString,
  question: defaultRules.emptyString,
  condition: defaultRules.checked,
});

export const FormBlock = () => {
  const profileData = useSelector(profileDataSelector);
  const isUserAuth = useSelector(authSelector);
  const dispatch = useDispatch();
  const [ name, setName ] = useState(profileData.firstName);
  const [ email, setEmail ] = useState(profileData.email);
  const [ phone, setPhone ] = useState(profileData.phoneNumber);
  const [ question, setQuestion ] = useState('');
  const [ condition, setCondition ] = useState(false);
  const [ success, setSuccess ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ inValidation, setValidation ] = useState(false);
  const [ errorsList, updateErrorsList ] = useState([]);

  const validateFieldsList = () => (
    Object.entries({
      name,
      email,
      phone,
      question,
      condition,
    }).reduce((errorsAccumulator, [ key, value ]) => {
      if (FORM_BLOCK_RULES_OBJECT[key]) {
        const error = !getFieldResult(value, FORM_BLOCK_RULES_OBJECT[key]);

        if (error) {
          errorsAccumulator.push(key);
        }
      }

      return errorsAccumulator;
    }, [])
  );

  const onSubmit = () => {
    if (!loading && !success) {
      const currentErrorsList = validateFieldsList();

      if (currentErrorsList.length) {
        setValidation(true);
      } else {
        setLoading(true);
        createFeedback({
          name,
          email,
          phone,
          question,
        }).then(() => {
          setSuccess(true);
          setLoading(false);
        }).catch(() => {
          setSuccess(true);
          setLoading(false);
        });
      }
    }
  };

  useEffect(() => {
    if (isUserAuth && (!profileData || !profileData.id)) {
      dispatch(fetchUserData());
    }
  }, []);

  useEffect(() => {
    setName(profileData.firstName);
    setEmail(profileData.email);
    setPhone(profileData.phoneNumber);
  }, [ profileData ]);

  useEffect(() => {
    if (inValidation) {
      updateErrorsList(
        validateFieldsList(),
      );
    }
  }, [ name, email, phone, question, condition, inValidation ]);

  return (
    <StyledFormBlock>
      <Row>
        <Col
          col={12}
          md={6}
          xl={4}
        >
          <StyledFormBlockInput
            placeholder={'Имя'}
            name={'name'}
            value={name}
            onChange={({ target }) => setName(target.value)}
            error={errorsList.includes('name')}
          />
        </Col>
        <Col
          col={12}
          md={6}
          xl={4}
        >
          <StyledFormBlockInput
            placeholder={'E-Mail'}
            name={'email'}
            value={email}
            onChange={({ target }) => setEmail(target.value)}
            error={errorsList.includes('email')}
          />
        </Col>
        <Col
          col={12}
          xl={4}
        >
          <StyledFormBlockInputPhone
            placeholder={'Телефон'}
            name={'phone'}
            mask={'+7\ (999) 999 99 99'}
            value={phone}
            onChange={({ target }) => setPhone(target.value)}
            error={errorsList.includes('phone')}
          />
        </Col>
      </Row>
      <Row>
        <Col col={12}>
          <StyledFormBlockTextArea
            placeholder={'Ваш вопрос'}
            name={'aks'}
            value={question}
            onChange={({ target }) => setQuestion(target.value)}
            error={errorsList.includes('question')}
          />
        </Col>
      </Row>
      <Row
        justifyContent={'between'}
        alignItems={'center'}
      >
        <Col
          col={12}
          md={7}
          xl={7}
        >
          <Checkbox
            className={'checkbox-container'}
            labelText={(
              <StyledFormBlockCheckedAgreement>
                Подтверждаю согласие с
                {' '}
                <StyledDownloadLink
                  target="_blank"
                  href={PRIVACY_POLICY}
                >политикой конфиденциальности</StyledDownloadLink>
                {' '}
                сайта
              </StyledFormBlockCheckedAgreement>
            )}
            value={condition}
            onChange={() => setCondition(!condition)}
            error={errorsList.includes('condition')}
          />
        </Col>
        <Col
          col={12}
          md={5}
          xl={3}
        >
          {!success && (
            <Button
              viewType={'ghost'}
              extendType={'hovered-ghost'}
              onClick={onSubmit}
              isLoading={loading}
            >
              Отправить
            </Button>
          )}
          {success && (
            <StyledFormBlockSuccess>Вопрос отправлен</StyledFormBlockSuccess>
          )}
        </Col>
      </Row>
    </StyledFormBlock>
  );
};
