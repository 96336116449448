import React, { useEffect, useState } from 'react';

import { StyledYearMonthControl } from './styled';

import { MultiSelect } from 'uiComponents/MultiSelect';

import { fromMonth } from '../../DatePicker';

export const YearMonthControl = ({ date, localeUtils, onChange, yearsList, currentDate }) => {
  const [ yearMonth, setYearMonth ] = useState({
    year: date.getFullYear(),
    month: date.getMonth(),
  });

  let currentYear = null;
  let currentMonth = null;

  if (currentDate) {
    currentYear = currentDate.getFullYear();
    currentMonth = currentDate.getMonth();

  }

  useEffect(() => {
    setYearMonth({
      year: date.getFullYear(),
      month: date.getMonth(),
    });
  }, [ date ]);

  const years = [];

  if (yearsList && yearsList.length) {
    years.push(...yearsList);
  } else {
    for (let i = fromMonth.getFullYear(); i >= 2000; i--) {
      years.push(i);
    }
  }

  const monthProceededArray = localeUtils.getShortMonths().reduce((monthsMap, month, monthIndex) => {
    if (
      (currentYear === null && currentMonth === null)
      || currentYear !== yearMonth.year || monthIndex >= currentMonth
    ) {
      monthsMap.push({
        value: monthIndex,
        label: month,
      });
    }

    return monthsMap;
  }, []);

  const yearProceededArray = years.map((year) => ({
    value: year,
    label: year,
  }));

  const handleChange = (selectedOption, opt) => {
    const changedDate = {
      ...yearMonth,
      [opt.name]: selectedOption.value,
    };

    onChange(new Date(changedDate.year, changedDate.month));
  };

  const getSelectedOption = (name) => {
    if (name === 'month') {
      return monthProceededArray.find((monthProceededArrayItem) => (
        monthProceededArrayItem.value === yearMonth.month
      ));
    }

    return yearProceededArray.find((item) => item.value === yearMonth.year);
  };

  return (
    <StyledYearMonthControl>
      <form className="wrapper">
        <MultiSelect
          name="month"
          indicatorName="arrow-outline"
          indicatorFill="#d2d2d2"
          isSearchable={false}
          onChange={handleChange}
          value={getSelectedOption('month')}
          options={monthProceededArray}
          isMulti={false}
        />
        <MultiSelect
          name="year"
          indicatorName="arrow-outline"
          indicatorFill="#d2d2d2"
          isSearchable={false}
          onChange={handleChange}
          value={getSelectedOption('year')}
          options={yearProceededArray}
          isMulti={false}
        />
      </form>
    </StyledYearMonthControl>
  );
};
