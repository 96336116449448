import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import * as appSelectors from 'store/app/selectors';
import * as userSelectors from 'store/user/selectors';

import {
  Row,
  Col,
} from 'styled-bootstrap-grid';

import {
  StyledHeader,
  StyledNav,
  StyledNavList,
  StyledNavItem,
  StyledNavLink,
  StyledLogo,
  HeaderLinkButton,
  HeaderFlexContainer,
  StyledColNav,
} from './styled';

import { HeaderMobileMenu } from 'components/layouts/MainLayout/Header/HeaderMobileMenu';

import {
  homePageMenuConfiguration,
  userMenuConfiguration,
  managerMenuConfiguration,
} from 'components/layouts/MainLayout/Header/menuConfig';

import { Button } from 'uiComponents/Button/Button';

import { CabinetLinks } from 'components/layouts/MainLayout/Header/CabinetLinks';
import { AvatarDropdown } from 'components/layouts/MainLayout/Header/AvatarDropdown';

import { scrollInToView } from 'helpers/scrollInToView';

import bigLogo from 'assets/images/logo.png';

export const Header = () => {
  const isAuthenticated = useSelector(appSelectors.authSelector);

  const isManager = useSelector(userSelectors.isManager);

  const location = useLocation();

  const isHomePage = location.pathname === '/';

  const getMenuConfig = () => {
    if (!isAuthenticated || isHomePage) {
      return homePageMenuConfiguration;
    }

    if (!isManager) {
      return userMenuConfiguration;
    }

    return managerMenuConfiguration;
  };

  return (
    <StyledHeader as="header">
      <Row>
        <Col
          col={8}
          md={4}
          xl={3}
        >
          <StyledLogo to="/">
            <img
              src={bigLogo}
              alt="B2BLogo"
            />
          </StyledLogo>
        </Col>

        <StyledColNav xl={6}>
          <StyledNav>
            <StyledNavList>
              {
                getMenuConfig().map((item) => (
                  <StyledNavItem key={item.title}>
                    <StyledNavLink
                      to={item.link}
                      scroll={(el) => scrollInToView(el, item)}
                    >
                      {item.title}
                    </StyledNavLink>
                  </StyledNavItem>
                ))
              }
            </StyledNavList>
          </StyledNav>
        </StyledColNav>

        <Col
          col={4}
          md={8}
          xl={3}
        >
          <HeaderFlexContainer>
            {(isAuthenticated && !isManager) && (
              <HeaderLinkButton to="/create">
                <Button viewType="ghost">
                  + Создать опрос
                </Button>
              </HeaderLinkButton>
            )}

            {!isAuthenticated && (
              <CabinetLinks />
            )}

            {isAuthenticated && (
              <AvatarDropdown menuItems={getMenuConfig()} />
            )}

            <HeaderMobileMenu
              isAuthenticated={isAuthenticated}
              menuItems={getMenuConfig()}
            />
          </HeaderFlexContainer>
        </Col>
      </Row>
    </StyledHeader>
  );
};
