import styled from 'styled-components';

import { media } from 'styled-bootstrap-grid';

import { TableContainer } from 'uiComponents/Table';

import { PaginationButtons } from 'components/Pagination/styles';

export const UserPollListPaginationButtons = styled(PaginationButtons)`
  margin-bottom: 96px;

  ${media.xs`
      display: none;
  `}

  ${media.sm`
      margin-top: 64px;
  `}

  ${media.xl`
      margin-top: 0;
  `}
`;

export const ListContainer = styled(TableContainer)`

`;
