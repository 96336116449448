import React from 'react';
import { StyledPasswordRecoveryConfirmationPage } from './styled';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import { ContainerBreadcrumbs } from 'components/Breadcrumbs';
import { RegistrationPageTitle } from 'pages/RegistrationPage/styles';
import { getBreadcrumbs } from 'components/Breadcrumbs/index.jsx';

const PasswordRecoveryConfirmationPage = (props) => {
  const breadcrumbs = getBreadcrumbs([ { breadcrumbText: props.breadcrumbText, breadcrumbLink: props.path } ]);

  return (
    <StyledPasswordRecoveryConfirmationPage>
      <Container>
        <Row>
          <Col col={12}>
            <ContainerBreadcrumbs breadcrumbs={breadcrumbs} />
          </Col>
        </Row>
        <Row>
          <Col col={12}>
            <RegistrationPageTitle
              title={'Запрос успешно отправлен'}
              subTitle={'На указанный e-mail поступит письмо для восстановления пароля'}
            />
          </Col>
        </Row>
      </Container>
    </StyledPasswordRecoveryConfirmationPage>
  );
};

export default PasswordRecoveryConfirmationPage;
