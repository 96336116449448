import React from 'react';
import { useSelector } from 'react-redux';
import _get from 'lodash/get';

import {
  getSolutionName, getSolutionReportExamples,
} from 'store/solution/selectors';

import { getSolutionConfig } from 'helpers/configs';

import {
  InfoItem, InfoItemLink,
  InfoItemDownloadLink, InfoItemText,
  InfoItemTitle, SurveyInfoWrapper,
} from 'components/SurveyInfo/styles';

import Icon from 'uiComponents/Icon';

import { theme } from '../../theme';

const MODIFIED_SOLUTIONS = [
  'NPS',
  'CR',
  'ISO',
  'ACSI',
  'Brand',
  'BHT',
];

const solutionInfoModified = {
  info: (
    <>
      Что измеряется?  <br />
      Как работает?  <br />
      Кто участвует в опросе?
    </>
  ),
};

const solutionInfo = {
  howDoWeDoIt: {
    title: 'Как мы это делаем?',
    info: <>
      Что можно тестировать? <br />
      Как работает? <br />
      Кто тестирует?
    </>,
    link: {
      icon: 'eye',
      title: 'Подробнее',
    },
    configField: 'HowDoWeDoItPage',
  },
  questionnaire: {
    title: 'Анкета',
    info: 'В анкете отображены вопросы, которые будут заданы целевой аудитории (кроме дополнительных)',
    link: {
      icon: 'eye',
      title: 'Смотреть анкету',
    },
    configField: 'questionsArray',
  },
  methodology: {
    title: 'Методика',
    info: 'Ознакомьтесь с методикой проекта',
    link: {
      icon: 'eye',
      title: 'Смотреть методику',
    },
    configField: 'MethodologyPage',
  },
  report: {
    title: 'Отчёт',
    info: 'Результаты опроса вы получите в виде оформленного отчета',
    linksList: {
      icon: 'download',
    },
  },
};

const SurveyInfo = () => {
  const solutionName = useSelector(getSolutionName);
  const solutionConfig = getSolutionConfig(solutionName);
  const solutionReportsExamples = useSelector(getSolutionReportExamples);

  if (!solutionName) {
    return null;
  }
  const getSolutionLinks = () => ({
    howDoWeDoIt: `/how-do-we-do-it/${solutionName}`,
    questionnaire: `/questionnaire/${solutionName}`,
    methodology: `/methodology/${solutionName}`,
    report: `/api/solution/${solutionName}/report-example`,
  });

  const renderLinkBlock = (link, title, icon) => (
    <InfoItemDownloadLink href={link}>
      <Icon
        name={icon}
        fill={theme.colors.orange}
      />
      <span>{title}</span>
    </InfoItemDownloadLink>
  );

  const renderLink = (link, infoKey) => {
    if (link.icon === 'download') {
      return renderLinkBlock(getSolutionLinks()[infoKey], link.title, link.icon);
    }

    return (
      <InfoItemLink
        target={'_blank'}
        to={getSolutionLinks()[infoKey]}
      >
        <Icon
          name={link.icon}
          fill={theme.colors.orange}
        />
        <span>{link.title}</span>
      </InfoItemLink>
    );
  };

  const renderLinksList = (link) => (
    solutionReportsExamples.map((reportItem) => (
      renderLinkBlock(
        `/api/file/${reportItem.id}`,
        reportItem.name,
        link.icon,
      )
    ))
  );

  if (solutionConfig.customSolutionInfo) {
    return solutionConfig.customSolutionInfo();
  }

  const isModSolution = Boolean(MODIFIED_SOLUTIONS.find((item) => solutionName === item));
  const newSolutionInfo = { ...solutionInfo };

  if (isModSolution) {
    newSolutionInfo.howDoWeDoIt = {
      ...newSolutionInfo.howDoWeDoIt,
      ...solutionInfoModified,
    };

  }

  const newSolutionInfoList = !solutionConfig
    ? Object.keys(newSolutionInfo)
    : Object.keys(newSolutionInfo).filter((infoKey) => (
      solutionConfig[_get(newSolutionInfo, [ infoKey, 'configField' ])] !== null
    ));

  return (
    <SurveyInfoWrapper>
      {newSolutionInfoList.map((infoKey) => {
        if (newSolutionInfo[infoKey].linksList && (!solutionReportsExamples || !solutionReportsExamples.length)) {
          return null;
        }

        return (
          <InfoItem
            key={infoKey}
            col={12}
            md={6}
            lg={10}
            lgOffset={2}
          >
            <InfoItemTitle>{newSolutionInfo[infoKey].title}</InfoItemTitle>
            <InfoItemText>{newSolutionInfo[infoKey].info}</InfoItemText>
            {newSolutionInfo[infoKey].link && renderLink(newSolutionInfo[infoKey].link, infoKey)}
            {newSolutionInfo[infoKey].linksList && renderLinksList(newSolutionInfo[infoKey].linksList, infoKey)}
          </InfoItem>
        );
      })}
    </SurveyInfoWrapper>
  );
};

export default SurveyInfo;
