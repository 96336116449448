import prefixString from 'helpers/string/prefixString';

const prefix = 'errors';

export const SET_REQUEST_ERROR = prefixString(prefix, 'SET_REQUEST_ERROR');
export const SET_REQUEST_ERRORS_LIST = prefixString(prefix, 'SET_REQUEST_ERRORS_LIST');
export const CLEAR_REQUEST_ERRORS = prefixString(prefix, 'CLEAR_REQUEST_ERRORS');

export const SET_VALIDATION_ERRORS = prefixString(prefix, 'SET_VALIDATION_ERRORS');
export const CLEAR_VALIDATION_ERRORS = prefixString(prefix, 'CLEAR_VALIDATION_ERRORS');

export const SET_ERROR_MODAL = prefixString(prefix, 'SET_ERROR_MODAL');
