import React from 'react';
import SolutionsList from 'components/SolutionsList';
import { ContainerBreadcrumbs } from 'components/Breadcrumbs';
import { Container, Row } from 'styled-bootstrap-grid';
import { getBreadcrumbs } from 'components/Breadcrumbs';

const SolutionsPage = (props) => {
  const breadcrumbs = getBreadcrumbs([ { breadcrumbText: props.breadcrumbText, breadcrumbLink: props.path } ]);
  return (
    <Container>
      <Row>
        <ContainerBreadcrumbs breadcrumbs={breadcrumbs} />
      </Row>
      <SolutionsList />
    </Container>
  );
};
export default SolutionsPage;
