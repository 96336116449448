import produce from 'immer';
import { load } from '../../localStorage';
import * as appTypes from 'store/app/types';

const initialState = {
  auth: load('user') && !!load('user').id,
  requestNames: {},
};

const AppReducer = produce((state = initialState, action) => {
  switch (action.type) {
    case appTypes.AUTH: {
      state.auth = action.payload;
      return state;
    }
    case appTypes.SET_REQUEST_NAME: {
      state.requestNames = {
        ...state.requestNames,
        ...action.payload,
      };
      return state;
    }
    case appTypes.REMOVE_REQUEST_NAME: {
      state.requestNames = action.payload;
      return state;
    }
    default: {
      return state;
    }
  }
});

export default AppReducer;
