import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'styled-bootstrap-grid';

import * as userSelectors from 'store/user/selectors';
import * as solutionActions from 'store/solution/actions';
import { getSurveyData } from 'store/solution/selectors';

import { SURVEY_GET_SURVEY_DATA } from 'api/requestNames';

import { usePreloader } from 'hooks/usePreloader';

import { PagePreloader } from 'components/PagePreloader/PagePreloader';
import { getBreadcrumbs } from 'components/Breadcrumbs/index.jsx';
import { SurveyContainer } from 'components/SurveyReview/styles';
import { ContainerBreadcrumbs } from 'components/Breadcrumbs';
import { SurveyReview } from 'components/SurveyReview';
import { InfoList } from 'components/SurveyReview/infoBlock';

const SurveyReviewPage = () => {
  const routerParams = useParams();

  const dispatch = useDispatch();

  const isManager = useSelector(userSelectors.isManager);
  const { name } = useSelector(getSurveyData);

  const [ surveyDataLoaded, surveyGlobalDataLoaded ] = usePreloader(SURVEY_GET_SURVEY_DATA);

  useEffect(() => {
    dispatch(solutionActions.fetchSurveyData({ surveyId: routerParams.id }));
    isManager && dispatch(solutionActions.getSurveyManagers());
    isManager && dispatch(solutionActions.getSurveyStatuses());
  }, []);

  let breadcrumbs;
  let authText;
  isManager
    ? authText = 'Список заказов'
    : authText = 'Мои опросы';

  if (name !== '') {
    breadcrumbs = getBreadcrumbs([
      { breadcrumbText: authText, breadcrumbLink: '/surveys' }, { breadcrumbText: name, breadcrumbLink: '#' },
    ]);
  }

  if (surveyDataLoaded && surveyGlobalDataLoaded) {
    return <PagePreloader />;
  }

  return (
    <>
      <SurveyContainer>
        <Row>
          <Col col={12}>
            <ContainerBreadcrumbs breadcrumbs={breadcrumbs} />
            <SurveyReview
              isManager={isManager}
              surveyId={routerParams.id}
            />
          </Col>
        </Row>
      </SurveyContainer>
      <InfoList />
    </>
  );
};

export default SurveyReviewPage;
