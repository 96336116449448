import styled from 'styled-components';

export const StyledPagePreloader = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;

  display: block;
  
  text-align: center;
  
  transform: translate(-50%, -50%);
`;
