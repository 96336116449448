import React, { Children } from 'react';

import { StyledAuthorizedLayout } from './styled';

import { Footer } from 'components/layouts/AuthorizedLayout/Footer';
import { Header } from 'components/layouts/MainLayout/Header/Header';

export const AuthorizedLayout = (props) => {
  const {
    children,
    breadcrumbText,
    path,
  } = props;

  return <>
    <Header />
    <StyledAuthorizedLayout>
      {Children.map(children, (child) => React.cloneElement(child, { breadcrumbText, path }), null)}
    </StyledAuthorizedLayout>
    <Footer />
  </>;
};
