import styled from 'styled-components';
import { H2 } from 'uiComponents/Titles';
import { media } from 'styled-bootstrap-grid';

export const UserInfoStyles = styled.div`
  padding-bottom: 60px;
  ${media.tablet`
    padding-bottom: 96px;
  `}
  ${H2} {
  margin-bottom: 36px;

  ${media.tablet`
    margin-bottom: 61px;
  `}

  ${media.desktop`
    margin-bottom: 120px;
  `}
  }

  .edit-button {
    transition: .3s;
    &:hover {
      background-color: transparent;
      border: 2px solid ${(props) => props.theme.colors.orange};
      color: ${(props) => props.theme.colors.orange};
    }
  }
`;

export const UserInfoListStyles = styled.div`
  margin-bottom: 36px;

  ${media.tablet`
    margin-bottom: 64px;
  `}
`;

export const UserInfoValue = styled.div`
  font-size: 16px;
  line-height: 20px;
  padding-left: 17px;
`;

export const UserInfoItem = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-of-type) {
    margin-bottom: ${(props) => !props.isEdit ? '4px' : '34px'};
  }

  &:nth-child(1) {
    margin-bottom: ${(props) => !props.isEdit ? '15px' : '30px'};
  }

  &:nth-child(6) {
    margin-top: ${(props) => !props.isEdit ? '19px' : '0px'};
  }

  & input {

    ${media.md`

    `}
  }

  ${media.tablet`
    & input {
      width: 370px;
    }
  `}
`;

export const UserInfoLabel = styled.div`

  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  flex: 0 0 87px;

  ${media.tablet`
    flex: 0 0 162px;
  `}

  ${media.desktop`
    flex: 0 0 170px;
  `}

`;
