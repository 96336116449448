import styled from 'styled-components';

export const StyledRangeDatePicker = styled.div`
  position: relative;

  .input-icon {
    position: absolute;
    top: 50%;
    right: 13px;

    color: #d1d1d1;
    line-height: 1;

    transform: translateY(-50%);
    pointer-events: none;
  }

  .input-field {
    width: 100%;
    min-height: 48px;

    box-sizing: border-box;

    padding: 10px 0 10px 15px;

    border-radius: 4px;
    border: 0.5px solid ${(props) => props.error ? props.theme.colors.orange : props.theme.colors.darkGrey};

    background: #fff;

    font-size: 16px;
    line-height: 20px;
    font-family: Inter, sans-serif;
    color: hsl(0, 0%, 20%);

    &::placeholder{
      color: ${(props) => props.error ? props.theme.colors.orange : props.theme.colors.darkGrey};
      font-size: inherit;
      line-height: inherit;
    }
  }
`;
