import { ThemeConsumer } from 'styled-components';
import {
  FileInputWrapper,
  UploadFilePreloader,
} from 'components/FileInput/styled';

import Icon from 'uiComponents/Icon';
import React from 'react';

const FileInputCustom = ({
  isDisabledInputTypeFile,
  hasFiles,
  onCheckAuth,
  onChangeFile,
  multiple,
  renderInnerLabel,
  renderValidateOrErrorMessageContainer,
  files,
  loading,
  onDownloadFile,
  showConfirmationPopup,
  emptyFiles,
  isValidField,
  ...props
}) => {
  const currentFile = files[0];

  return (
    <FileInputWrapper
      {...props}
      disabled={isDisabledInputTypeFile}
      tabIndex={-1}
      hasFiles={hasFiles}
    >
      {emptyFiles && (
        <label>
          <input
            type="file"
            onClick={(e) => onCheckAuth(e)}
            onChange={onChangeFile}
            multiple={multiple}
            hidden
            disabled={isDisabledInputTypeFile}
          />
          <ThemeConsumer>
            {(theme) => (
              <>
                {renderInnerLabel(theme)}
                {renderValidateOrErrorMessageContainer(theme)}
              </>
            )}
          </ThemeConsumer>
        </label>
      )}
      <div className="attached-files">
        {currentFile && (
          <div
            className="attached-files-custom__file"
            key={currentFile}
          >
            <div className="attached-files-custom__text">
              <span className="attached-files-custom__file-name">{currentFile.fileName}</span>
              <span className="attached-files-custom__file-extension">{currentFile.ext}</span>
            </div>
            <div className="attached-files-custom__icons-block">
              <ThemeConsumer>
                {(theme) => (
                  <>
                    {loading(currentFile._id)
                      ? <UploadFilePreloader />
                      : <>
                        <Icon
                          className="attached-files__icon attached-files__download"
                          fill={theme.colors.orange}
                          name="download"
                          width={16}
                          height={18}
                          onClick={() => onDownloadFile(currentFile._id)}
                        />
                        <Icon
                          className="attached-files__icon attached-files__remove"
                          fill={theme.colors.darkGrey}
                          name="cross-delete"
                          width={16}
                          height={16}
                          onClick={() => showConfirmationPopup(0, currentFile._id)}
                          disabled={isDisabledInputTypeFile}
                        />
                      </>}
                  </>
                )}
              </ThemeConsumer>
            </div>
          </div>
        )}
      </div>
    </FileInputWrapper>
  );
};

export default FileInputCustom;
