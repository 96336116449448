import React from 'react';
import ReactTooltip from 'react-tooltip';

import {
  StyledFormBlockTitle,
  TextTitle,
  Subtitle,
  TextHint,
} from './styled';

export const FormBlockTitle = (props) => {

  const {
    title,
    subtitle,
    textHint,
    dataTip,
    dataTipFor,
  } = props;

  return (
    <StyledFormBlockTitle>
      {title && (
        <TextTitle>
          {title}
          {textHint && (
            <TextHint
              data-for={dataTipFor}
              data-tip={''}
            >
              {textHint}
            </TextHint>
          )}
        </TextTitle>
      )}
      {subtitle && (
        <Subtitle dangerouslySetInnerHTML={{ __html: subtitle }} />
      )}
      {textHint && (
        <ReactTooltip
          id={dataTipFor}
          type="light"
          effect="solid"
          place="bottom"
          delayHide={300}
          delayShow={300}
          multiline
          className="subtitle-tooltip"
          getContent={() => dataTip}
        />
      )}
    </StyledFormBlockTitle>
  );
};
