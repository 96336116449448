import styled from 'styled-components';

export const StyledPage = styled('div')`
  color: #202020;
  padding-bottom: 96px;

  .block {
    margin-bottom: 36px;
  }

  .HowDoWeDo-title {
    margin-bottom: 36px;
  }

  .title {
    margin-bottom: 36px;
    font-weight: bold;
    font-size: 26px;
    line-height: 32px;
    margin-top: 0;
  }

  .subtitle {
    margin-bottom: 20px;

    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
  }

  .text {
    margin-bottom: 20px;

    font-size: 16px;
    line-height: 20px;
  }
`;
