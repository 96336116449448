import styled from 'styled-components';
import { H4 } from 'uiComponents/Titles/styles';

export const QuestionsBlock = styled.div`
  padding-top: 120px;
`;

export const QuestionItem = styled.div`
  margin-bottom: 30px;
`;

export const QuestionsTitle = styled(H4)`
  margin-bottom: 25px;
`;
