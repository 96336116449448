import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import Checkbox from 'uiComponents/Checkbox';
import { Button } from 'uiComponents/Button/Button';

import check from 'assets/images/check.png';
import greyCheck from 'assets/images/greyCheck.png';

export const RegistrationFormWrapper = styled.div`
  margin-bottom: 2em;
  ${media.md`
    margin-bottom: 96px;
  `}
`;

export const PrivatePolicyCheckbox = styled(Checkbox)`
  margin-bottom: 36px;
  margin-top: 2em;
  ${media.md`
    margin-bottom: 64px;
  `}

  & a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.orange};
  }
`;

export const TooltipValidationList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;

export const TooltipValidationListItem = styled.li`
  display: flex;
  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
  &:before {
    flex: 0 0 16px;
    content: '';
    display: inline-block;
    margin-right: 1em;
    background-image: url("${({ valid }) => valid ? check : greyCheck}");
    width: 16px;
    height: 16px;
  }
`;

export const StyledRegisterButton = styled(Button)`
  ${media.tablet`
    &:hover {
      background-color: ${({ isLoading, theme }) => isLoading ? theme.colors.orange : '#fff' };
      color: ${({ theme }) => theme.colors.orange};
    }
  `}
`;

export const ErrorText = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.orange};
  min-height: 20px;
  margin-top: 5px;
  margin-left: ${(props) => props.additionalMargin ? '50px' : '0'};
`;
