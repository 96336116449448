import styled, { css } from 'styled-components';
import { media, Col } from 'styled-bootstrap-grid';

import {
  Container,
} from 'styled-bootstrap-grid';

import { StyledButton } from 'uiComponents/Button';

import { CustomHashLink } from 'uiComponents/Link/CustomHashLink';

const cssLink = css`
  text-transform: uppercase;
  text-decoration: none;
  font-weight: bold;
  font-size: 10px;
  line-height: 43px;
  color: #1C2534;
  text-align: right;
  letter-spacing: 1px;
`;

export const StyledHeader = styled(Container)`
  padding: 18px 15px;
  ${media.md`
    padding: 57px 15px;
  `}
`;

export const StyledLogo = styled(CustomHashLink)`
  height: 36px;
  width: 172px;

  display: block;

  ${media.md`
    height: 43px;
    width: 200px;
  `}

  img {
    width: 100%;
  }
`;

export const HeaderLinkButton = styled(CustomHashLink)`
  outline: none;
  & ${StyledButton} {
    display: none;
    width: 200px;
    height: 48px;
    background-color: transparent;
    color: ${({ theme }) => theme.colors.orange};
    white-space: nowrap;
    &:hover {
      background-color: ${({ theme }) => theme.colors.orange};
      color: white;
    }
    ${media.md`
      display: inline-block;
    `}
  }
`;

export const StyledNavLink = styled(CustomHashLink)`
  ${cssLink};

  font-weight: 600;
  font-size: 14px;
  color: #888888;
  text-transform: initial;

  &:hover {
    color: #555555;
  }
`;

export const StyledColNav = styled(Col)`
  display: none;

  ${media.xl`
    display: block;
  `}
`;

export const StyledNav = styled('nav')`
  display: none;

  ${media.xl`
    margin-left: -30px;

    display: block;
  `}
`;

export const StyledNavList = styled('ul')`
  padding: 0;
  margin: 0;

  display: flex;

  list-style-type: none;
`;

export const StyledNavItem = styled('li')`
  position: relative;

  margin-right: 40px;

  &:last-child {
    margin-right: 0;
  }
`;

export const HeaderFlexContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: flex-end;
`;
