import React from 'react';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import { PageTitle } from 'uiComponents/Titles';

export const MethodologyNamePage = () => (
  <Container>
    <Row>
      <Col col={12}>
        <PageTitle title="Методология" />
      </Col>
    </Row>
    <Row>
      <Col col={12}>
        <div className="block">
          <h3 className="title">
            Методика оценки названия включает три основных элемента:
          </h3>

          <h4 className="subtitle">
            1. Измерение функциональных свойств названия
          </h4>

          <p className="text">
            Потребителям предлагается оценить по шкале
            от 1 до 5 такие характеристики предложения, как:
          </p>

          <ul className="list">
            <li className="list__item">Благозвучие</li>
            <li className="list__item">Запоминаемость</li>
            <li className="list__item">Легкость воспроизведения названия</li>
          </ul>

          <h4 className="subtitle">
            2. Оценка привлекательности
          </h4>

          <p className="text">
            Потребителям предлагается оценить по шкале от 1 до 5 общую привлекательность названия.
            Дополнительная диагностика с открытыми вопросами позволяет
            также определить причины предпочтения или отторжения отдельных вариантов названия.
          </p>

          <h4 className="subtitle">
            3. Оценка коммуникационной ценности
          </h4>

          <p className="text">
            Оцениваются соответствие названия категории и марке.
            Опционально (по дополнительному запросу) оцениваются отдельные,
            как правило, эмоциональные характеристики названия.
          </p>
        </div>
      </Col>
    </Row>
  </Container>
);
