import styled from 'styled-components';
import { Col, media } from 'styled-bootstrap-grid';
import { Link } from 'react-router-dom';

import logoLight from 'assets/images/logo-light.png';
import mobileLogo from 'assets/images/mobileLogo.png';

export const FooterStyled = styled.footer`
  background-color: #414141;
  padding: 60px 0;

  ${media.tablet`
    padding: 78px 0 96px 0;
  `}

  ${media.desktop`
    padding: 78px 0 130px 0;
  `}
`;

export const FooterItem = styled(Col)`
  &:nth-child(2){
    margin-bottom: 48px;
    color: ${(props): string => props.theme.colors.lightGrey};
  }

  &:nth-child(3) {
    color: ${(props): string => props.theme.colors.darkGrey};
  }

  ${media.tablet`
    &:nth-child(2) {
      margin-bottom: 60px;
    }
  `}
  ${media.desktop`
    &:nth-child(2) {
      margin-bottom: 0;
    }
  `}
`;

export const FooterLogo = styled(Link)`
  width: 36px;
  height: 36px;

  display: block;
 
  background-image:url('${mobileLogo}');
  background-repeat: no-repeat;
  background-size: contain;
  ${media.md`
    width: 241px;
    height: 52px;

    background-image: url("${logoLight}");
  `}
`;

export const FooterLink = styled.div`
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 18px;

    ${media.tablet`
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 19px;
    `}

    &:last-child {
      margin-bottom: 0;
    }

    & a {
      color: inherit;
      text-decoration: none;
    }

`;
