import React from 'react';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import { ContainerBreadcrumbs } from 'components/Breadcrumbs';
import { PageTitle } from 'uiComponents/Titles';
import PasswordRecoveryRequestForm from 'components/PasswordRecoveryForm';
import { PasswordRecoveryPageWrapper } from 'pages/PassordRecoveryPage/styles';
import { usePreloader } from 'hooks/usePreloader';
import { USER_PASSWORD_RECOVERY_REQUEST } from 'api/requestNames';
import { getBreadcrumbs } from 'components/Breadcrumbs/index.jsx';

const PasswordRecoveryPage = (props) => {
  const [ isStartPasswordRecovery ] = usePreloader(USER_PASSWORD_RECOVERY_REQUEST, {
    initialState: false,
  });

  const breadcrumbs = getBreadcrumbs([ { breadcrumbText: props.breadcrumbText, breadcrumbLink: props.path } ]);

  return (
    <PasswordRecoveryPageWrapper>
      <Container>
        <Row>
          <Col col={12}>
            <ContainerBreadcrumbs breadcrumbs={breadcrumbs} />
          </Col>
        </Row>
        <Row>
          <Col>
            <PageTitle
              title={'Восстановление пароля'}
              subTitle={'Введите вашу почту для восстановления пароля'}
            />
          </Col>
        </Row>
        <Row>
          <Col col={12}>
            <PasswordRecoveryRequestForm isStartPasswordRecovery={isStartPasswordRecovery} />
          </Col>
        </Row>
      </Container>
    </PasswordRecoveryPageWrapper>
  );
};

export default PasswordRecoveryPage;
