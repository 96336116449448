import React from 'react';

import { Col, Container, Row } from 'styled-bootstrap-grid';
import { PageTitle } from 'uiComponents/Titles';

export const HowDoWeDoItNamePage = () => (
  <Container>
    <Row>
      <Col col={12}>
        <PageTitle
          className="HowDoWeDo-title"
          title="Как мы это делаем?"
        />
      </Col>
    </Row>
    <Row>
      <Col col={12}>
        <div className="block">
          <h3 className="title">Что?</h3>

          <p className="text">
            Тестирование позволяет обоснованно выбрать из нескольких вариантов наилучшее название для продукта,
            услуги, мероприятия или коммуникационной компании.
            Тестирование основано на оценке как функциональных (например, благозвучие),
            так и коммуникационных свойств предлагаемых вариантов названий.
          </p>
        </div>

        <div className="block">
          <h3 className="title">Как?</h3>

          <p className="text">
            Опрос клиентов компании по структурированной, стандартной анкете.
            Метод предполагает выборку не менее 50 респондентов.
            В тестирование можно включить до четырех вариантов названий.
          </p>
        </div>

        <div className="block">
          <h3 className="title">Кто?</h3>

          <p className="text">
            Пользователи продукта или услуги, люди принимающие решения о использовании сервиса или продукта,
            руководители маркетинга и PR.
          </p>

          <p className="text">Возможно два варианта:</p>

          <p className="text">
            1. <strong>Панель В2В Research</strong> - тогда продукт должен быть достаточно популярен чтобы
            <strong> как минимум 20% малого и среднего бизнеса пользовались </strong>
            им и были способны дать свою оценку
            <br />
            2. <strong>Собственная база Клиента</strong>. Мы готовим ссылку, Вы рассылаете ее
            своим клиентам и получаете готовый отчет.
          </p>
        </div>
      </Col>
    </Row>
  </Container>
);
