import React from 'react';
import { withRouter } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { Col, Row } from 'styled-bootstrap-grid';
import { connect } from 'react-redux';

import FormField from 'uiComponents/Form/Field';
import { Input } from 'uiComponents/Input/inputStyles';

import { PasswordResetFormWrapper, StyledResetButton } from 'components/PasswordResetPageForm/styled';
import { TooltipValidationList, TooltipValidationListItem } from 'components/RegistrationForm/styled';

import { validateField } from 'services/validation';
import { userRegistrationRules } from 'services/validation/rules';

import * as errorsSelectors from 'store/errors/selectors';
import * as userActions from 'store/user/actions';
import * as errorsActions from 'store/errors/actions';

const messages = {
  rules: {
    passwordLength: 'Не менее 8 символов',
    passwordChars: 'Содержит буквы верхнего и нижнего регистра',
    passwordNumbers: 'Содержит цифры',
  },
};

const mapStateToProps = (state) => ({
  validationErrors: errorsSelectors.getValidationErrors(state),
  requestErrors: errorsSelectors.getRequestErrors(state),
});

const mapDispatchToProps = (dispatch) => ({
  submitForm: (formData, tokenId) => dispatch(userActions.userPasswordResetSubmit(formData, tokenId)),
  clearValidationErrors: () => dispatch(errorsActions.clearValidationErrors()),
  clearRequestErrors: () => dispatch(errorsActions.clearRequestErrors()),
});

@connect(mapStateToProps, mapDispatchToProps)
class PasswordResetPageForm extends React.Component {
  static defaultProps = {
    validationErrors: {},
  };

  constructor(props) {
    super(props);
    this.tokenId = new URLSearchParams(props.location.search).get('tokenId');
  }

  state = {
    password: '',
    repeatPassword: '',
  };

  // todo: Вынести в обёртку
  componentWillUnmount() {
    this.props.clearRequestErrors();
  }

  handleInputChange = ({ target }) => {
    this.setState({
      ...this.state,
      [target.name]: target.value,
    });
  };

  submitForm = () => {
    this.props.submitForm(this.state, this.tokenId);
  };

  render() {
    const passwordValidation = validateField(this.state.password, userRegistrationRules.password);

    const { isStartPasswordReset } = this.props;

    return (
      <PasswordResetFormWrapper>
        <Row>
          <Col
            col={12}
            md={6}
            xl={4}
          >
            <FormField>
              <label htmlFor="password">Введите новый пароль</label>
              <Input
                id={'password'}
                type={'password'}
                name={'password'}
                placeholder={'Пароль'}
                defaultValue={this.state.password}
                onChange={this.handleInputChange}
                data-tip={'password'}
                data-for={'passwordTooltip'}
                error={'password' in this.props.validationErrors}
              />
            </FormField>
          </Col>
          <Col
            col={12}
            md={6}
            xl={4}
          >
            <FormField>
              <label htmlFor="repeatPassword">Подтвердите новый пароль</label>
              <Input
                id={'repeatPassword'}
                type={'password'}
                name={'repeatPassword'}
                placeholder={'Пароль'}
                defaultValue={this.state.repeatPassword}
                onChange={this.handleInputChange}
                error={'repeatPassword' in this.props.validationErrors}
              />
            </FormField>
          </Col>
        </Row>
        <Row>
          <Col
            col={12}
            md={6}
            xl={4}
          >
            <StyledResetButton
              isLoading={isStartPasswordReset}
              onClick={this.submitForm}
            >
              Сохранить
            </StyledResetButton>
          </Col>
        </Row>

        <ReactTooltip
          id={'passwordTooltip'}
          effect={'solid'}
          type={'light'}
          place={'right'}
          delayHide={300}
          delayShow={300}
          multiline
          getContent={() => (
            <TooltipValidationList>
              {Object.keys(passwordValidation).map((key) => (
                <TooltipValidationListItem
                  valid={passwordValidation[key]}
                  key={key}
                >
                  {messages.rules[key]}
                </TooltipValidationListItem>
              ))}
            </TooltipValidationList>
          )}
        />
      </PasswordResetFormWrapper>
    );
  }
}

export default withRouter(PasswordResetPageForm);
