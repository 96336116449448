import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

export const infoBlockConsultationCustomViewWrapper = styled.div`
  padding: 25px;
  margin-bottom: 50px;

  background: ${({ theme }) => theme.colors.disabledGray};

  ${media.sm`
    padding: 42px 56px;
    margin: 0 -55px 150px;
  `}
`;

export const infoBlockConsultationCustomViewTitle = styled.div`
  margin-bottom: 18px;
  padding-bottom: 8px;
  border-bottom: 1px solid #ECEBED;

  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
`;

export const infoBlockConsultationCustomViewMain = styled.div`
  ${media.tablet`
    display: flex;

    justify-content: flex-start;
    align-items: flex-start;
  `};
`;

export const infoBlockConsultationCustomViewImageBlock = styled.div`
  width: 200px;
  height: 200px;

  min-width: 200px;
  min-height: 200px;

  margin-right: 0;
  margin-bottom: 35px;

  border-radius: 50%;

  overflow: hidden;

  ${media.sm`
    margin-right: 56px;
    margin-bottom: 0;
  `}
`;

export const infoBlockConsultationCustomViewImage = styled.img`
  width: 100%;
  max-height: 100%;
`;

export const infoBlockConsultationCustomViewInfo = styled.div`
  font-size: 16px;
  line-height: 19px;
`;

export const infoBlockConsultationCustomViewInfoTitle = styled.div`
  margin-bottom: 20px;

  font-weight: 600;

`;

export const infoBlockConsultationCustomViewInfoDescription = styled.div`
  max-width: 512px;
  margin-bottom: 20px;

  color: ${({ theme }) => theme.colors.deepGrey};
`;

