import styled from 'styled-components';
import { StyledSelect } from 'uiComponents/MultiSelect/styled';

export const StyledYearMonthControl = styled.div`
  display: table-caption;

  margin-bottom: 10px;

  .wrapper {
    display: flex;

    justify-content: center;
  }

  ${StyledSelect} {
    width: 95px;
  }

  .react-select__control {
    border: none;
  }

  .indicator-icon {
    margin-right: 8px;

    transform: rotate(-90deg);
  }

  .react-select__single-value {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
  }
`;
