import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Modal from 'uiComponents/Modal';
import { Button } from 'uiComponents/Button/Button';

import { getSolutionsSuccessModalShow } from 'store/solution/selectors';
import { setSubmittedSolutionSuccess } from 'store/solution/actions';

import {
  SuccessModalTitle,
  SuccessModalDescription,
  SuccessModalContainer,
} from './styled';

const SuccessModal = () => {
  const isShow = useSelector(getSolutionsSuccessModalShow);
  const dispatch = useDispatch();
  const history = useHistory();

  const onClose = () => {
    dispatch(setSubmittedSolutionSuccess(false));
    history.push('/surveys');
  };

  if (!isShow) {
    return null;
  }

  return (
    <Modal onClose={onClose}>
      <SuccessModalContainer>
        <div>
          <SuccessModalTitle>Ваш запрос взят в работу</SuccessModalTitle>
          <SuccessModalDescription>Мы свяжемся в случае необходимости уточнений.</SuccessModalDescription>
        </div>
        <div>
          <Button onClick={onClose}>Перейти к списку проектов</Button>
        </div>
      </SuccessModalContainer>
    </Modal>
  );
};

export default SuccessModal;
