import userConstants from 'constants/userConstants';

export const getUserState = (state) => state.user;

export const profileDataSelector = (state) => getUserState(state).profileData;

export const costsDataSelector = (state) => getUserState(state).costs;

export const getUserRole = (state) => getUserState(state).profileData.role;
export const isManager = (state) => getUserRole(state) === userConstants.roles.manager;

export const getResendActivationEmailToken = (state) => profileDataSelector(state).resendActivationEmailToken;
