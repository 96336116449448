import generateUUID from 'helpers/generateUUID';

export const fakeCardsData = [
  {

    id: generateUUID(),
    solutionName: 'ISO',
    description: 'Измерение удовлетворенности клиентов в соответствии с стандартом ISO',
    link: '/create/ISO',
  },
  {
    id: generateUUID(),
    solutionName: 'NPS',
    description: 'Оценка лояльности клиентов с использованием индекса NPS',
    link: '/create/NPS',
  },
  {
    id: generateUUID(),
    solutionName: 'ACSI',
    description: 'Оценка удовлетворенности и лояльности клиентов с использованием индекса ACSI',
    link: '/create/ACSI',
  },
  {
    id: generateUUID(),
    solutionName: 'Идея или концепция',
    description: 'Тестирование идеи или концепции продукта',
    link: '/create/Concept',
  },
  {
    id: generateUUID(),
    solutionName: 'Название',
    description: 'Тестирование названия для продукта или услуги',
    link: '/create/Name',
  },
  {
    id: generateUUID(),
    solutionName: 'Реклама AIDA',
    description: 'Тестирование готового рекламного ролика или аниматика',
    link: '/create/AIDA',
  },
  {
    id: generateUUID(),
    solutionName: 'Здоровье марки',
    description: 'Оценка основных составляющих здоровья (ценности) марки',
    link: '/create/Brand',
  },
  {
    id: generateUUID(),
    solutionName: 'Мониторинг здоровья марки',
    description: 'Мониторинг здоровья (ценности) марки',
    link: '/create/BHT',
  },
  {
    id: generateUUID(),
    solutionName: 'Корпоративная репутация',
    description: 'Оценка корпоративной репутации',
    link: '/create/CR',
  },
  {
    id: generateUUID(),
    solutionName: '10 вопросов',
    description: 'Задайте до десяти произвольных вопросов вашим потенциальным клиентам',
    link: '/create/QUESTIONS10',
  },
  {
    id: generateUUID(),
    solutionName: '15 вопросов',
    description: 'Задайте до пятнадцати произвольных вопросов вашим потенциальным клиентам',
    link: '/create/QUESTIONS15',
  },
  {
    id: generateUUID(),
    solutionName: 'Ранжирование',
    description: 'Рейтинг в порядке предпочтения от 2 до 10 элементов – дизайн, названия, короткие текстовые сообщения',
    link: '/create/Ranging',
  },
  {
    id: generateUUID(),
    solutionName: 'Консультация',
    description: (
      'Если нужно создать нестандартный проект, уточнить задачи исследования,'
      + '  обсудить методику и анкету, помочь интерпретировать данные'
    ),
    link: '/create/Consultation',
  },
];
