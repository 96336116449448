import styled from 'styled-components';

export const ModalWrapper = styled.div`
  position: fixed;

  display: flex;

  z-index: 1;

  justify-content: center;
  align-items: center;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
`;

export const ModalShadow = styled.div`
  position: absolute;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.7);
`;

export const ModalContainer = styled.div`
  position: relative;

  background: white;

  width: 100%;
  height: 316px;

  max-width: 520px;

  padding: 60px 42px 64px;

  color: ${({ theme }) => theme.colors.black};
`;
