import { createGlobalStyle } from 'styled-components';

import InterRegular from 'fonts/Inter-Regular.woff2';
import InterSemiBold from 'fonts/Inter-SemiBold.woff2';
import InterBold from 'fonts/Inter-Bold.woff2';
import InterBlack from 'fonts/Inter-Black.woff2';

const GlobalStyles = createGlobalStyle`

  @font-face {
    font-family: 'Inter';
    src: url("${InterRegular}") format('woff2');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Inter';
    src: url("${InterSemiBold}") format('woff2');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Inter';
    src: url('${InterBold}') format('woff2');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Inter';
    src: url('${InterBlack}') format('woff2');
    font-weight: 800;
    font-style: normal;
  }

  html, body, #root {
   height: 100%;
  }

  body {
    margin: 0;
    font-family: Inter, Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
  }
  .__react_component_tooltip {
    background-color: white!important;
    border-radius: 4px!important;
    box-shadow: 0 7px 64px rgba(0, 0, 0, 0.17);
    opacity: 1!important;
    padding: 1.75em!important;
  }
`;

export default GlobalStyles;
