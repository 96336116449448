import React from 'react';
import { Container } from 'styled-bootstrap-grid';

import * as styled from './styled';

const infoBlockConsultationCustomView = () => (
  <Container>
    <styled.infoBlockConsultationCustomViewWrapper>
      <styled.infoBlockConsultationCustomViewTitle>
        C кем я буду говорить?
      </styled.infoBlockConsultationCustomViewTitle>
      <styled.infoBlockConsultationCustomViewMain>
        <styled.infoBlockConsultationCustomViewInfo>
          <styled.infoBlockConsultationCustomViewInfoDescription>
            В&nbsp;зависимости от темы и&nbsp;задачи проекта,<br />
            с&nbsp;вами свяжется один из ведущих специалистов компании,<br />
            тот, кто лучше всего знает тему и индустрию.<br />
          </styled.infoBlockConsultationCustomViewInfoDescription>
          <styled.infoBlockConsultationCustomViewInfoDescription>
            С вами будет говорить человек с&nbsp;опытом работы не менее
            десяти лет и практическими знаниями, полученными в&nbsp;проведении сотен и
            сотен исследовательских проектов.
          </styled.infoBlockConsultationCustomViewInfoDescription>
        </styled.infoBlockConsultationCustomViewInfo>
      </styled.infoBlockConsultationCustomViewMain>
    </styled.infoBlockConsultationCustomViewWrapper>
  </Container>
);

export default infoBlockConsultationCustomView;
