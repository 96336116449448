import React from 'react';

import {
  DeleteIcon,
  TextAreaRow,
  TypeQuestionsRow,
  TypeQuestionsItem,
  TypeQuestionsLabel,
  TypeQuestionsHead,
  LabelQuestionRow,
  LabelAnswerRow,
} from 'components/SurveySetup/DynamicFieldsBlock/styled';

import {
  Col,
  Row,
} from 'styled-bootstrap-grid';

import deleteIcon from 'assets/images/delete.svg';

import FormQuestionsField from 'uiComponents/Form/FieldQuestions';

import { AuthorizedField } from 'components/AuthorizedField/AuthorizedField';

import { StyledTextArea } from 'uiComponents/TeaxtArea';

import { ThemeConsumer } from 'styled-components';

import AddButton from 'uiComponents/Button/AddButton';
import { TYPES, QUESTIONS_SOLUTIONS_TYPES_ITEMS, TYPES_QUESTIONS_NAME } from 'constants/questions';

export const BlockWithAnswersFields = ({
  fields,
  addButtonText,
  maxField,
  textToField,
  updateField,
  placeholder,
  addField,
  showConfirmationPopup,
  updateFieldAnswer,
  updateQuestionType,
  deleteAnswer,
  isValidField: isValidFieldFunction,
}) => {
  const isValidField = isValidFieldFunction('answerOptions');

  return (
    <>
      {fields.length !== 0 && (
        <Row>
          <Col col={12}>
            {fields.map((question, index) => {
              const isOpenType = Boolean(question.questionType === TYPES.open);
              /* -----------------------------Блок с вопросом--------------------------------- */
              return ([
                <FormQuestionsField key={`${question.id}-form`}>
                  <LabelQuestionRow>
                    <Col
                      col={11}
                      xs={10}
                      sm={11}
                    >
                      <label htmlFor={question.id}>{textToField} #{index + 1}</label>
                    </Col>
                  </LabelQuestionRow>
                  <TypeQuestionsRow>
                    <Col
                      col={11}
                      xs={10}
                      sm={11}
                    >
                      {/* -----------------------------Блок с выбором типа вопроса--------------------------------- */}
                      <TypeQuestionsHead>{'Выберите тип вопроса:'}</TypeQuestionsHead>
                      {QUESTIONS_SOLUTIONS_TYPES_ITEMS.map((key) => {
                        const isActive = Boolean(question.questionType === key);
                        return ([
                          <TypeQuestionsLabel
                            htmlFor={`${question.id} - ${key}`}
                            active={isActive}
                            key={`${question.id} - label`}
                          >
                            {TYPES_QUESTIONS_NAME[key]}
                          </TypeQuestionsLabel>,
                          <TypeQuestionsItem
                            key={`${question.id} - ${key}`}
                            id={`${question.id} - ${key}`}
                            value={key}
                            onClick={(e) => updateQuestionType(e, question.id)}
                            type={'checkbox'}
                          />,
                        ]
                        );
                      })}
                    </Col>
                  </TypeQuestionsRow>
                  <TextAreaRow>
                    <Col
                      col={11}
                      xs={12}
                      sm={12}
                      md={11}
                    >
                      <AuthorizedField>
                        <StyledTextArea
                          id={question.id}
                          name={question.id}
                          defaultValue={question.text}
                          onChange={updateField}
                          placeholder={placeholder}
                          error={isValidField}
                        />
                      </AuthorizedField>

                    </Col>
                    <Col
                      col={1}
                      xs={0}
                      md={1}
                    >
                      <DeleteIcon
                        src={deleteIcon}
                        alt="delete"
                        onClick={() => showConfirmationPopup(question.id)}
                      />
                    </Col>
                  </TextAreaRow>
                  {/* ---------------------------Блок с ответами--------------------------------- */}
                  <LabelAnswerRow key={`${question.id} - answerLabel`}>
                    <Col
                      col={11}
                      xs={10}
                      sm={11}
                    >
                      <label htmlFor={question.id}>{'Ответы'}</label>
                    </Col>
                  </LabelAnswerRow>
                  {!isOpenType && question.answerOptions.map((answer, indexAnswer) => {
                    const hasDeleteIcon = question.answerOptions.length !== 1;

                    return (
                      <TextAreaRow key={`${question.id}-${answer.id}`}>
                        <Col
                          col={11}
                          xs={12}
                          sm={12}
                          md={11}
                        >
                          <AuthorizedField>
                            <StyledTextArea
                              id={answer.id}
                              name={answer.id}
                              defaultValue={answer.text}
                              onChange={(e) => updateFieldAnswer(e, question.id, answer.id)}
                              placeholder={`Введите ответ #${indexAnswer + 1}`}
                              error={isValidField}
                            />
                          </AuthorizedField>

                        </Col>
                        <Col
                          col={1}
                          xs={0}
                          md={1}
                        >
                          {hasDeleteIcon && (
                            <DeleteIcon
                              src={deleteIcon}
                              alt="delete"
                              onClick={() => deleteAnswer(question.id, answer.id)}
                            />
                          )}
                        </Col>
                      </TextAreaRow>
                    );
                  })}
                  {isOpenType && (
                    <TextAreaRow>
                      <Col
                        col={11}
                        xs={12}
                        sm={12}
                        md={11}
                      >
                        {'В данном типе вопроса количество и содержание ответов заполняет респондент'}
                      </Col>
                    </TextAreaRow>
                  )}
                </FormQuestionsField>,
              ]);
            })}
          </Col>
        </Row>
      )}

      {fields.length < maxField && (
        <Row>
          <Col>
            <ThemeConsumer>
              {(theme) => (
                <AddButton
                  text={addButtonText}
                  color={theme.colors.orange}
                  onClick={addField}
                />
              )}
            </ThemeConsumer>
          </Col>
        </Row>
      )}
    </>
  );
};
