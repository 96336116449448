import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import { Col, Row } from 'styled-bootstrap-grid';

import { SURVEY_ADDITIONAL_FILE_UPLOAD } from 'api/requestNames';

import CreateForm from 'components/SurveySetup/forms/CreateForm';
import { solutionsConfig } from 'components/SurveySetup/forms/solutionConfig';
import { StyledWrapperFormBlock } from 'components/SurveySetup/styled';
import { FormBlockTitle } from 'components/SurveySetup/FormBlockTitle/FormBlockTitle';
import { AuthorizedField } from 'components/AuthorizedField/AuthorizedField';
import FileInput from 'components/FileInput';
import AudienceForm from 'components/AudienceForm';

import FormField from 'uiComponents/Form/Field';
import { StyledTextArea } from 'uiComponents/TeaxtArea';

import * as solutionActions from 'store/solution/actions';
import * as solutionSelectors from 'store/solution/selectors';
import { getValidationErrors } from 'store/errors/selectors';
import { additionalFileId } from 'store/solution/selectors';
import AdditionalQuestion from 'components/AdditionalQuestion';

const mapStateToProps = (state) => ({
  isSubmittedForm: solutionSelectors.isSubmittedForm(state),
  validationErrors: getValidationErrors(state),
});

const mapDispatchToProps = (dispatch) => ({
  updateSolutionCreateData: (fieldData) => dispatch(solutionActions.updateSolutionCreateData(fieldData)),
});

@connect(mapStateToProps, mapDispatchToProps)
class ConceptForm extends CreateForm {
  constructor(props) {
    super(props);
    this.state = this.solutionDataConfig.initialFormState;
    this.props.updateSolutionCreateData(this.solutionDataConfig.initialFormState);
  }

  solution = this.props.match.params.solution;

  solutionDataConfig = solutionsConfig.find(((item) => item.name === this.solution));

  render() {
    const {
      productDescription,
      concept,
      productPrice,
      audienceId,
      geographyId,
    } = this.state;

    return (
      <>
        <Row>
          <Col col={12}>
            <StyledWrapperFormBlock>
              <FormBlockTitle
                title="Продукт/услуга"
                subtitle="Опишите, пожалуйста, идею или концепцию какого продукта
                или услуги Вы бы хотели протестировать?"
                textHint="Зачем это нужно?"
                dataTipFor="productDescription"
                dataTip={(
                  <div>
                    Респондентам будет предложено ответить на вопрос: «Используете ли Вы в своей профессиональной
                    деятельности следующие продукты или услуги: /НАЗВАНИЕ ПРОДУКТА ИЛИ УСЛУГИ/?»
                    Таким образом, мы отберем для участия только тех респондентов, которые являются
                    пользователями необходимых продуктов и услуг.
                  </div>
                )}
              />
              <AuthorizedField>
                <FormField>
                  <StyledTextArea
                    id="productDescription"
                    name="productDescription"
                    placeholder="Например, оборудование для неразрушающего контроля или услуги по подбору персонала"
                    defaultValue={productDescription}
                    onChange={this.inputOnChange}
                    error={this.isValidField('productDescription')}
                  />
                </FormField>
              </AuthorizedField>
            </StyledWrapperFormBlock>
          </Col>
        </Row>
        <Row>
          <Col col={12}>
            <StyledWrapperFormBlock>
              <FormBlockTitle
                title="Идея/концепция"
                subtitle="Опишите, пожалуйста, идею или концепцию, которую Вы бы хотели протестировать,
                так, как Вы бы описали ее в рекламе или анонсе. Описание должно отвечать
                на следующие вопросы: В чем суть товара или услуги? В чем выгода, полезность, новизна? "
              />
              <AuthorizedField>
                <FormField>
                  <StyledTextArea
                    id="concept"
                    name="concept"
                    placeholder="Введите идею / концепцию"
                    defaultValue={concept}
                    onChange={this.inputOnChange}
                    error={this.isValidField('concept')}
                  />
                </FormField>
              </AuthorizedField>
              <AuthorizedField>
                <FileInput
                  name="additionalFileId"
                  requestName={SURVEY_ADDITIONAL_FILE_UPLOAD}
                  selector={additionalFileId}
                  multiple={false}
                />
              </AuthorizedField>
            </StyledWrapperFormBlock>
          </Col>
        </Row>
        <Row>
          <Col col={12}>
            <StyledWrapperFormBlock>
              <FormBlockTitle
                title="Цена"
                subtitle="Укажите, пожалуйста, цену за тестируемый продукт или услугу, если она есть:"
                dataTipFor="productPrice"
                textHint="Зачем это нужно?"
                dataTip={(
                  <div>
                    Респондентам будет предложено ответить на вопрос: «Принимая во внимание цену этого предложения,
                    какое утверждение, на Ваш взгляд, лучше всего описывает соотношение «цена – качество» для него?»
                  </div>
                )}
              />
              <AuthorizedField>
                <FormField>
                  <StyledTextArea
                    id="productPrice"
                    name="productPrice"
                    placeholder="Введите цену"
                    defaultValue={productPrice}
                    onChange={this.inputOnChange}
                  />
                </FormField>
              </AuthorizedField>
            </StyledWrapperFormBlock>
          </Col>
        </Row>
        <Row>
          <Col col={12}>
            <AudienceForm
              onChange={this.setAudienceData}
              data={{
                audienceId,
                geographyId,
              }}
              optionalDescription="Мы будем проводить опрос среди тех компаний, которые пользуются
               указанными вами продуктами или услугами."
            />
          </Col>
        </Row>
        <AdditionalQuestion />
      </>
    );
  }
}

export default withRouter(ConceptForm);
