import styled from 'styled-components';

import { Link } from 'react-router-dom';

import { H2 } from 'uiComponents/Titles';

export const EmptyListTitle = styled(H2)`
  margin-bottom: 30px;
`;

export const EmptyListContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & > ${EmptyListTitle} {
    margin-bottom: 35px;
  }
`;

export const EmptyListImg = styled.img`
  margin-bottom: 50px;
`;

export const EmptyListText = styled.div`
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: ${(props) => props.theme.colors.darkGrey};
`;

export const EmptyListLink = styled(Link)`
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: ${(props) => props.theme.colors.orange};
  text-decoration: none;
`;

