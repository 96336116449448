import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import UserReducer from 'store/user/reducer';
import ErrorsReducer from 'store/errors/reducer';
import SolutionReducer from 'store/solution/reducer';
import AppReducer from 'store/app/reducer';

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  user: UserReducer,
  solution: SolutionReducer,
  errors: ErrorsReducer,
  app: AppReducer,
});

export default createRootReducer;
