import _get from 'lodash/get';

import { TYPES } from 'constants/questions';

export const defaultRules = {
  success: {
    validator: () => true,
    name: 'success',
  },
  emptyString: {
    validator: (str) => str.trim().length > 0,
    name: 'empty',
  },
  checked: {
    validator: (checked) => checked,
    name: 'checked',
  },
  passwordLength: {
    validator: (str) => str.match(/[0-9a-zA-Z!@#$%^&*]{8,}/g) !== null,
    name: 'passwordLength',
  },
  passwordNumbers: {
    validator: (str) => str.match(/(?=.*[0-9])/g) !== null,
    name: 'passwordNumbers',
  },
  passwordChars: {
    validator: (str) => str.match(/(?=.*[a-z])/g) !== null && str.match(/(?=.*[A-Z])/g) !== null,
    name: 'passwordChars',
  },
  email: {
    validator: (str) => (/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/).test(str),
    name: 'email',
  },
  singleDate: {
    validator: (date) => (
      defaultRules.isNull.validator(date.value)
    ),
    name: 'date',
  },
  equalPass: {
    validator: (str1, str2) => str1 === str2,
    name: 'equalPasswords',
    compareField: 'password',
  },
  emptyArray: {
    validator: (arr) => arr.length > 0,
    name: 'emptyArray',
  },
  emptyObject: {
    validator: (obj) => Object.keys(obj).length !== 0,
    name: 'emptyObject',
  },
  objectElementLength: {
    validator: (obj) => Object.keys(obj).length >= 2,
    name: 'emptyObject',
  },
  firstElementIsEmptyString: {
    validator: (value) => value && value[0].length > 0,
    name: 'firstElementIsEmptyString',
  },
  arrayElementEmptyString: {
    validator: (value) => {
      if (!value.length) {
        return false;
      }

      return !value.some((item) => (
        item.hasOwnProperty('text')
        && !item.text.length
      ));

    },
    name: 'arrayElementEmptyString',
  },
  rankingItems: {
    validator: (value) => (
      Object.values(value).filter((item) => (
        _get(item, [ TYPES.text, 'length' ], 0)
        || _get(item, [ TYPES.image, 'file', 'size' ], 0)
      )).length >= 2
    ),
    name: 'rankingItems',
  },
  questionsList: {
    validator: (value) => (
      value && value.length &&
      (value.filter((question) => (
        question.text
        && (
          question.questionType === TYPES.open
          || (
            question.answerOptions
            && question.answerOptions.length
            && (
              question.answerOptions.filter((answerItem) => (
                _get(answerItem, 'text.length', 0) !== 0
              ))
            ).length >= 2
          )
        )
      ))).length >= 1
    ),
    name: 'questionsList',
  },
  isNull: {
    validator: (value) => value !== null,
    name: 'isNull',
  },
  isInteger: {
    validator: (value) => value.trim().length > 0 && Number.isInteger(Number(value)),
    name: 'isNumber',
  },
  isRangeNumber: {
    validator: (value) => (
      value.trim().length > 0 && Number.isInteger(Number(value))
      && Number(value) > 0 && Number(value) < 100
    ),
    name: 'isRangeNumber',
  },
};

export const userRegistrationRules = {
  firstName: defaultRules.emptyString,
  lastName: defaultRules.emptyString,
  companyName: defaultRules.emptyString,
  email: defaultRules.emptyString,
  phoneNumber: defaultRules.emptyString,
  privatePolicy: defaultRules.checked,
  password: [
    defaultRules.passwordLength,
    defaultRules.passwordChars,
    defaultRules.passwordNumbers,
  ],
  repeatPassword: [
    defaultRules.passwordLength,
    defaultRules.equalPass,
  ],
};

export const userSignInRules = {
  login: defaultRules.emptyString,
  password: defaultRules.emptyString,
};

export const userPasswordRecoveryRules = {
  email: defaultRules.emptyString,
};

export const userPasswordResetRules = {
  password: [
    defaultRules.passwordLength,
    defaultRules.passwordChars,
    defaultRules.passwordNumbers,
  ],
  repeatPassword: [
    defaultRules.passwordLength,
    defaultRules.equalPass,
  ],
};

export const defaultSurveyCreateRules = {
  name: defaultRules.emptyString,
  agreementCheckbox1: defaultRules.checked,
  agreementCheckbox2: defaultRules.checked,
  agreementCheckbox3: defaultRules.checked,
};

export const surveyNPSCreateRules = {
  ...defaultSurveyCreateRules,
  targetCompanyName: defaultRules.emptyString,
  period: defaultRules.emptyString,
  characteristics: defaultRules.arrayElementEmptyString,
  audienceId: defaultRules.isNull,
  geographyId: defaultRules.isNull,
};

export const surveyACSICreateRules = {
  ...defaultSurveyCreateRules,
  audienceId: defaultRules.isNull,
  geographyId: defaultRules.isNull,
  targetCompanyName: defaultRules.emptyString,
  period: defaultRules.emptyString,
  characteristics: defaultRules.arrayElementEmptyString,
};

export const surveyAIDACreateRules = {
  ...defaultSurveyCreateRules,
  fileIds: defaultRules.emptyObject,
  productDescription: defaultRules.emptyString,
  targetCompanyName: defaultRules.emptyString,
  geographyId: defaultRules.isNull,
  audienceId: defaultRules.isNull,
};

export const surveyBHTCreateRules = {
  ...defaultSurveyCreateRules,
  productName: defaultRules.emptyString,
  productDescription: defaultRules.emptyString,
  period: defaultRules.emptyString,
  competitors: defaultRules.arrayElementEmptyString,
  statements: defaultRules.arrayElementEmptyString,
  audienceId: defaultRules.isNull,
  geographyId: defaultRules.isNull,
  healthMeasuringCount: defaultRules.isRangeNumber,
  healthMeasuringPeriod: defaultRules.isRangeNumber,
};

export const surveyBrandCreateRules = {
  ...defaultSurveyCreateRules,
  productName: defaultRules.emptyString,
  productDescription: defaultRules.emptyString,
  period: defaultRules.emptyString,
  competitors: defaultRules.arrayElementEmptyString,
  statements: defaultRules.arrayElementEmptyString,
  audienceId: defaultRules.isNull,
  geographyId: defaultRules.isNull,
};

export const surveyConceptCreateRules = {
  ...defaultSurveyCreateRules,
  productDescription: defaultRules.emptyString,
  concept: defaultRules.emptyString,
  productPrice: defaultRules.emptyString,
  audienceId: defaultRules.isNull,
  geographyId: defaultRules.isNull,
};

export const surveyCRCreateRules = {
  ...defaultSurveyCreateRules,
  productDescription: defaultRules.emptyString,
  targetCompanyName: defaultRules.emptyString,
  competitors: defaultRules.arrayElementEmptyString,
  audienceId: defaultRules.isNull,
  geographyId: defaultRules.isNull,
};

export const surveyISOCreateRules = {
  ...defaultSurveyCreateRules,
  targetCompanyName: defaultRules.emptyString,
  period: defaultRules.emptyString,
  characteristics: defaultRules.arrayElementEmptyString,
  audienceId: defaultRules.isNull,
  geographyId: defaultRules.isNull,
};

export const surveyNameCreateRules = {
  ...defaultSurveyCreateRules,
  productName: defaultRules.emptyString,
  audienceId: defaultRules.isNull,
  geographyId: defaultRules.isNull,
  names: defaultRules.arrayElementEmptyString,
};

export const surveyQuestionsCreateRules = {
  ...defaultSurveyCreateRules,
  questions: defaultRules.questionsList,
  audienceId: defaultRules.isNull,
  geographyId: defaultRules.isNull,
};

export const surveyRangingCreateRules = {
  ...defaultSurveyCreateRules,
  rankingItems: defaultRules.rankingItems,
  characteristics: defaultRules.arrayElementEmptyString,
  audienceId: defaultRules.isNull,
  geographyId: defaultRules.isNull,
};

export const surveyConsultationCreateRules = {
  agreementCheckbox1: defaultRules.checked,
  email: defaultRules.email,
  phone: defaultRules.emptyString,
  firstName: defaultRules.emptyString,
  lastName: defaultRules.emptyString,
  consultationTheme: defaultRules.emptyString,
  consultationTime: defaultRules.emptyString,
  dateState: defaultRules.singleDate,
};
