import React from 'react';
import { ThemeConsumer } from 'styled-components';
import {
  Col,
  Row,
} from 'styled-bootstrap-grid';

import {
  RANKED_TYPES_ITEMS,
  TYPES,
  TYPES_QUESTIONS_NAME,
} from 'constants/questions';

import {
  DeleteIcon,
  TextAreaRow,
  TypeQuestionsItem,
  TypeQuestionsLabel,
  TypeQuestionsHead,
  LabelQuestionRow,
  FormBelow,
} from 'components/SurveySetup/DynamicFieldsBlock/styled';

import deleteIcon from 'assets/images/delete.svg';

import FormQuestionsField from 'uiComponents/Form/FieldQuestions';
import AddButton from 'uiComponents/Button/AddButton';
import { StyledTextArea } from 'uiComponents/TeaxtArea';

import FileInput from 'components/FileInput';
import { AuthorizedField } from 'components/AuthorizedField/AuthorizedField';

export const BlockRankedFields = ({
  fields,
  addButtonText,
  maxAnswers,
  updateField,
  showConfirmationPopup,
  isValidField,
  addField,
  addFileItem,
  isRanked,
  updateQuestionType,
  type,
}) => {
  const hasError = isValidField('rankingItems');
  const isTypeText = Boolean(type === TYPES.text);

  return (
    <>
      {fields.length !== 0 && (
        <>
          <Row>
            <Col col={12}>
              <FormQuestionsField isRanked>
                <LabelQuestionRow>
                  <Col col={12}>
                    <label>{'Элементы ранжирования'}</label>
                  </Col>
                </LabelQuestionRow>
                <TypeQuestionsHead>{'Выберите тип элементов, которые Вы бы хотели проранжировать:'}</TypeQuestionsHead>
                {RANKED_TYPES_ITEMS.map((key) => {
                  const isActive = Boolean(type === key);

                  return ([
                    <TypeQuestionsLabel
                      htmlFor={`${key}`}
                      active={isActive}
                      key={`${key}-label`}
                    >
                      {TYPES_QUESTIONS_NAME[key]}
                    </TypeQuestionsLabel>,
                    <TypeQuestionsItem
                      key={`${key}-checkbox`}
                      id={`${key}`}
                      value={key}
                      onClick={updateQuestionType}
                      type={'checkbox'}
                    />,
                  ]);
                })}
              </FormQuestionsField>
            </Col>
          </Row>
          <Row>
            <Col col={12}>
              <FormQuestionsField
                isRanked={isRanked}
              >
                {fields.map((answer) => {
                  const hasDeleteIcon = !(fields.length === 2);
                  const emptyFiles = !answer.Image;

                  return (
                    <TextAreaRow key={`$${answer.id}-TextAreaRow`}>
                      <Col
                        col={11}
                        xs={12}
                        sm={12}
                        md={11}
                      >
                        {isTypeText && (
                          <AuthorizedField key={`${answer.id}-StyledTextArea`}>
                            <StyledTextArea
                              id={answer.id}
                              name={answer.id}
                              defaultValue={answer.text}
                              onChange={(e) => updateField(e)}
                              placeholder="Введите элемент"
                            />
                          </AuthorizedField>
                        )}
                        {!isTypeText && (
                          <AuthorizedField key={`$${answer.id}-FileInput`}>
                            <FileInput
                              itemId={answer.id}
                              addFileItem={addFileItem}
                              emptyFiles={emptyFiles}
                              deleteItem={() => updateField({ target: { id: answer.id } })}
                              validate
                              multiple={false}
                            />
                          </AuthorizedField>
                        )}
                      </Col>
                      <Col
                        col={1}
                        xs={0}
                        md={1}
                      >
                        {hasDeleteIcon && (
                          <DeleteIcon
                            src={deleteIcon}
                            alt="delete"
                            onClick={() => showConfirmationPopup(answer.id)}
                          />
                        )}
                      </Col>
                    </TextAreaRow>
                  );
                })}
              </FormQuestionsField>
            </Col>
          </Row>
        </>
      )}
      {maxAnswers > fields.length && (
        <FormBelow>
          <Col col={12}>
            <ThemeConsumer>
              {(theme) => (
                <AddButton
                  text={addButtonText}
                  color={theme.colors.orange}
                  onClick={addField}
                />
              )}
            </ThemeConsumer>

            {hasError && (
              <span
                style={{ color: '#FF2C2C', flex: 3, 'margin-top': '3px' }}
                className={'validate-or-error-message-container'}
              >
                {'Вы должны ввести от 2 до 10 элементов ранжирования'}
              </span>
            )}
          </Col>
        </FormBelow>
      )}
    </>
  );
};
