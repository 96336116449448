import React from 'react';
import { useParams } from 'react-router';

import { SurveySetupFormWrapper } from 'components/SurveySetup/styled';
import { solutionsConfig } from 'components/SurveySetup/forms/solutionConfig';

const SurveySetupForm = () => {
  const { solution } = useParams();

  const solutionDataConfig = solutionsConfig.find(((item) => item.name === solution));

  const Form = solutionDataConfig.Form;

  return (
    <SurveySetupFormWrapper>
      <Form />
    </SurveySetupFormWrapper>
  );
};

export default SurveySetupForm;
