import * as errorsTypes from './types';

export function setRequestError(error) {
  return {
    type: errorsTypes.SET_REQUEST_ERROR,
    error,
  };
}

export function setRequestErrorsList(errors) {
  return {
    type: errorsTypes.SET_REQUEST_ERRORS_LIST,
    errors,
  };
}

export function clearRequestErrors() {
  return {
    type: errorsTypes.CLEAR_REQUEST_ERRORS,
  };
}

export function setValidationErrors(validationErrors) {
  return {
    type: errorsTypes.SET_VALIDATION_ERRORS,
    errors: validationErrors,
  };
}

export function clearValidationErrors() {
  return {
    type: errorsTypes.CLEAR_VALIDATION_ERRORS,
  };
}

