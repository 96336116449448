import React from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'styled-bootstrap-grid';

import ReactTooltip from 'react-tooltip';

import { validateField } from 'services/validation';
import { userRegistrationRules } from 'services/validation/rules';

import { TERMS_OF_USE } from 'constants/staticDocs';

import {
  PrivatePolicyCheckbox,
  RegistrationFormWrapper,
  TooltipValidationList,
  TooltipValidationListItem,
  StyledRegisterButton, ErrorText,
} from 'components/RegistrationForm/styled';

import { Input, InputMaskStyled } from 'uiComponents/Input/inputStyles';
import { MultiSelect } from 'uiComponents/MultiSelect';

import * as userActions from 'store/user/actions';
import * as errorsSelectors from 'store/errors/selectors';

import FormField from 'uiComponents/Form/Field';
import { ThemeConsumer } from 'styled-components';
import { StyledDownloadLink } from 'components/SurveySetup/NameSurveyBlock/styled';

const messages = {
  rules: {
    passwordLength: 'Не менее 8 символов',
    passwordChars: 'Содержит буквы верхнего и нижнего регистра',
    passwordNumbers: 'Содержит цифры',
  },
};

const mapDispatchToProps = (dispatch) => ({
  submitForm: (registrationData, errorCb) => dispatch(userActions.userRegistrationSubmit(registrationData, errorCb)),
});

const mapStateToProps = (state) => ({
  validationErrors: errorsSelectors.getValidationErrors(state),
  requestErrors: errorsSelectors.getRequestErrors(state),
});

const REQUEST_ERROR_LIST = Object.freeze({
  'UserIsAlreadyRegistered': {
    name: 'email',
    text: 'Пользователь, с такой почтой, уже зарегистрирован',
  },
  'UserWithSamePhoneAlreadyRegistered': {
    name: 'phoneNumber',
    text: 'Пользователь, с таким телефоном, уже зарегистрирован',
  },
  'InvalidUserName': {
    name: 'email',
    text: 'Некорректное имя пользователя',
  },
  'InvalidPassword': {
    name: 'password',
    text: 'Некорректный пароль',
  },
});

const CONTRACT_DATA = [
  { name: 'Я хочу получить шаблон договора', value: true },
  { name: 'Уже есть договор с В2В Research', value: false },
];

@connect(mapStateToProps, mapDispatchToProps)
class RegistrationForm extends React.Component {
  state = {
    firstName: '',
    lastName: '',
    companyName: '',
    email: '',
    phoneNumber: '',
    repeatPassword: '',
    password: '',
    shouldSendContractSample: true,
    privatePolicy: false,
  };

  componentDidUpdate(prevProps) {
    const { requestErrors } = this.props;

    if (prevProps.requestErrors[0] !== requestErrors[0]) {
      this.setRequestErrorType(requestErrors[0]);
    }
  }

  phone = React.createRef();

  handleInputChange = ({ target }) => {
    const { submitError } = this.state;
    let newValue = target.value;
    if (target.name === 'phone') {
      newValue = this.phone.current.getInputValue();
    }

    if (submitError && submitError.name === target.name) {
      delete this.state.submitError;
    }

    this.setState({
      ...this.state,
      [target.name]: newValue,
    });
  };

  handleRegistrationFieldChange = (selectedData, fieldName) => {
    this.setState({
      ...this.state,
      [fieldName]: selectedData,
    });
  };

  submitForm = () => {
    const {
      requestErrors,
    } = this.props;

    const { submitError } = this.state;
    if (submitError) {
      delete this.state.submitError;
    }

    const requestError = this.setRequestErrorType(requestErrors[0]);

    this.props.submitForm(this.state, requestError);
  };

  setRequestErrorType = (error) => {
    if (REQUEST_ERROR_LIST[error]) {
      this.setState({
        submitError: REQUEST_ERROR_LIST[error],
      });
    }
  };

  render() {
    const {
      isStartRegistration,
    } = this.props;
    const { submitError } = this.state;

    const passwordValidation = validateField(this.state.password, userRegistrationRules.password);

    return (
      <RegistrationFormWrapper>
        <Row>
          <Col
            col={12}
            md={6}
            xl={4}
          >
            <FormField>
              <label htmlFor="firstName">Введите имя</label>
              <Input
                id={'firstName'}
                name={'firstName'}
                placeholder={'Имя'}
                defaultValue={this.state.firstName}
                onChange={this.handleInputChange}
                error={'firstName' in this.props.validationErrors || submitError && submitError.name === 'firstname'}
              />
              <ErrorText>
                {submitError && submitError.name === 'firstname' ? submitError.text : ''}
              </ErrorText>
            </FormField>
          </Col>
          <Col
            col={12}
            md={6}
            xl={4}
          >
            <FormField>
              <label htmlFor="lastName">Введите фамилию</label>
              <Input
                id={'lastName'}
                name={'lastName'}
                placeholder={'Фамилия'}
                defaultValue={this.state.lastName}
                onChange={this.handleInputChange}
                error={'lastName' in this.props.validationErrors}
              />
            </FormField>
          </Col>
        </Row>
        <Row>
          <Col
            col={12}
            md={6}
            xl={4}
          >
            <FormField>
              <label htmlFor="email">Введите ваш рабочий E-Mail</label>
              <Input
                id={'email'}
                name={'email'}
                placeholder={'E-Mail'}
                defaultValue={this.state.email}
                onChange={this.handleInputChange}
                error={'email' in this.props.validationErrors || submitError && submitError.name === 'email'}
              />
              <ErrorText>
                {submitError && submitError.name === 'email' ? submitError.text : ''}
              </ErrorText>
            </FormField>
          </Col>
          <Col
            col={12}
            md={6}
            xl={4}
          >
            <FormField>
              <label htmlFor="phoneNumber">Введите телефон</label>
              <InputMaskStyled
                ref={this.phone}
                id={'phoneNumber'}
                name={'phoneNumber'}
                placeholder={'+7 (___) - ___ - __ - __'}
                mask={'+7\ (999) - 999 - 99 - 99'}
                onChange={this.handleInputChange}
                defaultValue={this.state.phoneNumber}
                error={'phoneNumber' in this.props.validationErrors
                || submitError && submitError.name === 'phoneNumber'
                }
              />
              <ErrorText>
                {submitError && submitError.name === 'phoneNumber' ? submitError.text : ''}
              </ErrorText>
            </FormField>
          </Col>
        </Row>
        <Row>
          <Col
            col={12}
            md={6}
            xl={4}
          >
            <FormField>
              <label htmlFor="password">Введите пароль</label>
              <Input
                id={'password'}
                type={'password'}
                name={'password'}
                placeholder={'Пароль'}
                defaultValue={this.state.password}
                onChange={this.handleInputChange}
                data-tip={'password'}
                data-for={'passwordTooltip'}
                error={'password' in this.props.validationErrors || submitError && submitError.name === 'password'}
              />
              <ErrorText>
                {submitError && submitError.name === 'password' ? submitError.text : ''}
              </ErrorText>
            </FormField>
          </Col>
          <Col
            col={12}
            md={6}
            xl={4}
          >
            <FormField>
              <label htmlFor="repeatPassword">Введите пароль повторно</label>
              <Input
                id={'repeatPassword'}
                type={'password'}
                name={'repeatPassword'}
                placeholder={'Пароль'}
                defaultValue={this.state.repeatPassword}
                onChange={this.handleInputChange}
                error={'repeatPassword' in this.props.validationErrors}
              />
            </FormField>
          </Col>
        </Row>
        <Row>
          <Col
            col={12}
            md={6}
            xl={4}
          >
            <FormField>
              <label htmlFor="companyName">Введите наименование компании</label>
              <Input
                id={'companyName'}
                name={'companyName'}
                placeholder={'Компания'}
                defaultValue={this.state.companyName}
                onChange={this.handleInputChange}
                error={'companyName' in this.props.validationErrors}
              />
            </FormField>
          </Col>
          <Col
            col={12}
            md={6}
            xl={4}
          >
            <FormField>
              <label htmlFor="contractTemplate">Шаблон договора</label>
              <MultiSelect
                id="contractTemplate"
                name="contractTemplate"
                isSearchable={false}
                defaultValue={CONTRACT_DATA.find((contract) => contract.value === this.state.shouldSendContractSample)}
                getOptionValue={(option) => option.value}
                getOptionLabel={(option) => option.name}
                options={CONTRACT_DATA}
                onChange={(selectData) => (
                  this.handleRegistrationFieldChange(selectData.value, 'shouldSendContractSample')
                )}
              />
            </FormField>
          </Col>
        </Row>
        <Row>
          <Col
            col={12}
            md={6}
          >
            <PrivatePolicyCheckbox
              labelText={(
                <>
                Я подтверждаю согласие на обработку моих персональных данных в соответствии с условиями
                  <StyledDownloadLink
                    target="_blank"
                    href={TERMS_OF_USE}
                  > Пользовательского соглашения
                  </StyledDownloadLink>
                </>
              )}
              id={'privatePolicy'}
              name={'privatePolicy'}
              defaultChecked={this.state.privatePolicy}
              onChange={this.handleRegistrationFieldChange}
              error={'privatePolicy' in this.props.validationErrors}
            />
          </Col>
        </Row>
        <Row>
          <Col
            col={12}
            md={6}
            xl={4}
          >
            <ThemeConsumer>
              {(theme) => (
                <StyledRegisterButton
                  isLoading={isStartRegistration}
                  disabled={isStartRegistration}
                  colorDisabled={theme.colors.orange}
                  onClick={this.submitForm}
                >
                  Зарегистрироваться
                </StyledRegisterButton>
              )}
            </ThemeConsumer>
          </Col>
        </Row>

        <ReactTooltip
          id={'passwordTooltip'}
          effect={'solid'}
          type={'light'}
          place={'right'}
          delayHide={300}
          delayShow={300}
          multiline
          getContent={() => (
            <TooltipValidationList>
              {Object.keys(passwordValidation).map((key) => (
                <TooltipValidationListItem
                  valid={passwordValidation[key]}
                  key={key}
                >
                  {messages.rules[key]}
                </TooltipValidationListItem>
              ))}
            </TooltipValidationList>
          )}
        />
      </RegistrationFormWrapper>
    );
  }
}

export default RegistrationForm;
