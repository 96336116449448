import React from 'react';

import { Container, Row, Col } from 'styled-bootstrap-grid';
import { ContainerBreadcrumbs } from 'components/Breadcrumbs';

import { PageTitle } from 'uiComponents/Titles';

import SignInForm from 'components/SignInForm';
import { getBreadcrumbs } from 'components/Breadcrumbs/index.jsx';

import { usePreloader } from 'hooks/usePreloader';
import { USER_SIGN_IN } from 'api/requestNames';

const SignInPage = (props) => {
  const [ isAuthorizing ] = usePreloader(USER_SIGN_IN, {
    initialState: false,
  });

  const breadcrumbs = getBreadcrumbs([ { breadcrumbText: props.breadcrumbText, breadcrumbLink: props.path } ]);

  return (
    <Container>
      <Row>
        <Col col={12}>
          <ContainerBreadcrumbs
            breadcrumbs={breadcrumbs}
          />
        </Col>
      </Row>
      <Row>
        <Col col={12}>
          <PageTitle
            title={'Авторизация'}
            subTitle={'Войдите в систему, используя свой логин и пароль'}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <SignInForm isAuthorizing={isAuthorizing} />
        </Col>
      </Row>
    </Container>
  );
};

export default SignInPage;
