import produce from 'immer';
import * as userTypes from 'store/user/types';
import { load } from 'localStorage';

const initialState = {
  profileData: {
    id: null,
    firstName: null,
    lastName: null,
    email: null,
    phoneNumber: null,
    companyName: null,
    role: (load('user') && load('user').role) || null,
  },
  costs: {
    yearCosts: [],
  },
};

const UserReducer = produce((state = initialState, action) => {
  switch (action.type) {
    case userTypes.FETCH_USER_DATA_SUCCESS: {
      state.profileData = {
        ...state.profileData,
        ...action.payload,
      };
      return state;
    }
    case userTypes.UPDATE_USER_DATA: {
      return {
        ...state,
        profileData: {
          ...state.profileData,
          ...action.payload,
        },
      };
    }
    case userTypes.UPDATING_USER_DATA: {
      return {
        ...state,
        updating: action.payload,
      };
    }
    case userTypes.CLEAR_USER_DATA: {
      state.profileData = {
        ...initialState.profileData,
        role: null,
      };
      return state;
    }
    case userTypes.FETCH_COST_DATA_SUCCESS: {
      state.costs = action.payload;
      return state;
    }
    default: {
      return state;
    }
  }
});

export default UserReducer;
