import { Col, Row } from 'styled-bootstrap-grid';
import { DynamicFieldsBlock } from 'components/SurveySetup/DynamicFieldsBlock';
import { BlockWithoutDefaultField } from 'components/SurveySetup/DynamicFieldsBlock/BlockWithoutDefaultField';
import React from 'react';

const AdditionalQuestion = () => (
  <Row>
    <Col col={12}>
      <DynamicFieldsBlock
        blockTitle={{
          title: 'Добавьте дополнительные вопросы',
        }}
        keyName="text"
        name="additionalQuestions"
        confirmActionMessage="Вы уверены, что хотите удалить вопрос?"
        addButtonText="Добавить вопрос"
        textToField="Вопрос"
        defaultState={[]}
        component={BlockWithoutDefaultField}
      />
    </Col>
  </Row>
);

export default AdditionalQuestion;
