import request from 'api/request';
import { stringify } from 'query-string';

import {
  SURVEY_GET_SURVEY_LIST,
  SURVEY_CREATE,
  SURVEY_GET_SURVEY_DATA,
  SURVEY_GET_MANAGERS,
  SURVEY_GET_STATUSES,
  SURVEY_GET_COMPANY_NAMES,
  SURVEY_FILE_UPLOAD,
  SURVEY_GET_SOLUTION_LIST,
} from 'api/requestNames';

export function create(solutionData) {
  return request(
    '/api/survey/create',
    {
      method: 'POST',
      contentType: 'application/json-patch+json',
      body: JSON.stringify(solutionData),
      requestName: SURVEY_CREATE,
    },
  );
}

export function getSurveyData(surveyId, userRole, addToRequestNameList) {
  return request(
    `/api/${userRole}/surveys/${surveyId}`,
    {
      method: 'GET',
      requestName: SURVEY_GET_SURVEY_DATA,
      addToRequestNameList,
    },
  );
}

export function attachFileReport(formData) {
  return request(
    '/api/manager/surveys/attach-report',
    {
      method: 'POST',
      body: formData,
      contentType: false,
    },
  );
}

export function deleteFileReport(formData) {
  return request(
    '/api/manager/surveys/delete-report',
    {
      method: 'DELETE',
      contentType: 'application/json-patch+json',
      body: JSON.stringify(formData),
    },
  );
}

export function attachReportExampleFile(formData) {
  return request(
    '/api/manager/solutions/attach-report-example',
    {
      method: 'POST',
      body: formData,
      contentType: false,
    },
  );
}

export function deleteReportExampleFile(data) {
  return request(
    '/api/manager/solutions/delete-report-example',
    {
      method: 'DELETE',
      contentType: 'application/json-patch+json',
      body: JSON.stringify(data),
    },
  );
}

export function updateStatus(formData) {
  return request(
    '/api/manager/surveys/update-status',
    {
      method: 'POST',
      contentType: 'application/json-patch+json',
      body: JSON.stringify(formData),
    },
  );
}

export function updateManager(formData) {
  return request(
    '/api/manager/surveys/attach-manager',
    {
      method: 'POST',
      contentType: 'application/json-patch+json',
      body: JSON.stringify(formData),
    },
  );
}

export function getManagers() {
  return request(
    '/api/manager/list', {
      method: 'GET',
      requestName: SURVEY_GET_MANAGERS,
    });
}

export function getStatuses() {
  return request(
    '/api/survey/statuses', {
      method: 'GET',
      requestName: SURVEY_GET_STATUSES,
    });
}

export function getCompanyNames() {
  return request(
    '/api/reference/companies', {
      method: 'GET',
      requestName: SURVEY_GET_COMPANY_NAMES,
    });
}

export function getSolutionList() {
  return request(
    '/api/solution/list', {
      method: 'GET',
      requestName: SURVEY_GET_SOLUTION_LIST,
    });
}

export function getSurveyList(filters, userRole, isGlobalLoading) {
  const apiParams = stringify(filters, {
    skipNull: true,
    skipEmptyString: true,
  });
  return request(
    `/api/${userRole}/surveys?${apiParams}`,
    {
      method: 'GET',
      requestName: SURVEY_GET_SURVEY_LIST,
      isGlobalLoading,
    },
  );
}

export function fileUpload(formData, requestName) {
  return request(
    '/api/file',
    {
      method: 'POST',
      body: formData,
      contentType: false,
      requestName: requestName || SURVEY_FILE_UPLOAD,
    },
  );
}
