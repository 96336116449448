import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { CustomHashLink } from 'uiComponents/Link/CustomHashLink';

export const Breadcrumbs = styled.div`
  display: inline-block;

  width: 100%;

  text-transform: uppercase;
  color: ${(props) => props.theme.colors.darkGrey};
  font-size: 10px;
  line-height: 12px;
  font-weight: 600;

  text-decoration: none;
  ${media.tablet`
    font-size: 10px;
    line-height: 13px;
  `}
`;

export const Breadcrumb = styled(CustomHashLink)`
  position: relative;

  display: inline;

  margin: 0 10px 0 10px;

  text-decoration: none;
  color: #AEAEAE;

  &:before {
    content: '<';

    position: absolute;

    left: -10px;
    top: 0;
  }

  &:first-of-type {
    margin: 0 10px 0 0;

    &:before {
      content: '';
    }
  }

  &:nth-child(2) {
    display: ${({ isCreatePage }) => isCreatePage ? 'none' : 'inline'};

    ${media.tablet`
      display: inline;
    `}
  }
`;

export const BreadcrumbsContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 32px;
  ${media.tablet`
    margin-top: 16px;
    margin-bottom: 64px;
  `}
`;
