import React from 'react';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import { PageTitle } from 'uiComponents/Titles';
import { Link } from 'react-router-dom';

export const MethodologyBHTPage = () => (
  <Container>
    <Row>
      <Col col={12}>
        <PageTitle title="Методология" />
      </Col>
    </Row>
    <Row>
      <Col col={12}>
        <div className="block">
          <p className="text">
            Подробнее о методологии можно прочитать
            <Link
              to="/methodology/Brand"
              className="link"
            > здесь</Link>
          </p>
        </div>
      </Col>
    </Row>
  </Container>
);
