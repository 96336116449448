import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DateUtils } from 'react-day-picker';

import {
  StyledFilterHeader,
  StyledFilterItemHeader,
  StyledFilterBlockWrapper,
  StyledFilterBlock,
  StyledFilterItem,
} from './styled';

import { MultiSelect } from 'uiComponents/MultiSelect';

import RangeDateInput from 'components/RangeDatePicker';

import * as solutionSelectors from 'store/solution/selectors';
import * as solutionActions from 'store/solution/actions';
import * as solutionReducer from 'store/solution/reducer';

import { fromMonth } from 'components/RangeDatePicker/DatePicker';

export const currentDate = new Date();

const FilterBlock = () => {
  const companyNameMultiSelectRef = useRef(null);
  const solutionListMultiSelectRef = useRef(null);
  const managersMultiSelectRef = useRef(null);
  const statusesMultiSelectRef = useRef(null);

  const dispatch = useDispatch();

  const companyNames = useSelector(solutionSelectors.getCompanyNames);
  const solutionList = useSelector(solutionSelectors.getSolutionList);
  const managers = useSelector(solutionSelectors.getManagers);
  const statuses = useSelector(solutionSelectors.getStatuses);
  const surveyListFilters = useSelector(solutionSelectors.getSurveyListFilters);

  const initialDatePickerState = {
    dateOfCreation: {
      from: surveyListFilters.createdDateFrom ? new Date(surveyListFilters.createdDateFrom) : '',
      to: surveyListFilters.createdDateFrom ? new Date(surveyListFilters.createdDateTo) : '',
      month: fromMonth,
      currentDate,
      periodActiveId: null,
    },
    completedDate: {
      from: surveyListFilters.createdDateFrom ? new Date(surveyListFilters.completedDateFrom) : '',
      to: surveyListFilters.createdDateFrom ? new Date(surveyListFilters.completedDateTo) : '',
      month: fromMonth,
      currentDate,
      periodActiveId: null,
    },
  };

  const getInitialManager = () => {
    let manager = [];
    manager = managers.find((item) => (
      item.id === surveyListFilters.managerId
    ));

    return manager && manager.email;
  };

  const [ datePickerState, setDatePickerState ] = useState(initialDatePickerState);

  const changeDatePickerRange = (day, name) => {
    const range = DateUtils.addDayToRange(day, {
      from: datePickerState[name].from,
      to: datePickerState[name].to,
    });

    setDatePickerState((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        ...range,
      },
    }));
  };

  const changePresetDayPicker = (getValue, name, type) => {
    const { from, to } = getValue(initialDatePickerState.dateOfCreation.currentDate, type);

    setDatePickerState((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        from,
        to,
      },
    }));
  };

  const changeYearMonth = (month, name) => {
    setDatePickerState((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        month,
      },
    }));
  };

  const changePeriodActiveId = (id, name) => {
    setDatePickerState((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        periodActiveId: id,
      },
    }));
  };

  const resetDatePicker = (name) => {
    setDatePickerState((prevState) => ({
      ...prevState,
      [name]: {
        from: null,
        to: null,
        month: fromMonth,
      },
    }));
  };

  const [ isOpenFilterBlock, setIsOpenFilterBlock ] = useState(true);

  const dataToMultiSelector = (data, options = {}) => {
    const defaultOption = {
      value: null,
      label: '-',
    };

    return [
      defaultOption,
      ...data.map((item) => ({
        value: item[options.value] || item,
        label: item[options.label] || item,
      })),
    ];
  };

  const onChangeFilter = (selectData, filterName) => {
    dispatch(solutionActions.setSurveyListFilter({
      filterData: {
        page: 1,
        [filterName]: selectData.value,
      },
      isGlobalLoading: false,
    }));
  };

  const onResetFilter = () => {
    dispatch(solutionActions.setSurveyListFilter({
      filterData: solutionReducer.initialState.surveysList.filters,
    }));

    companyNameMultiSelectRef.current.state.value = null;
    solutionListMultiSelectRef.current.state.value = null;
    managersMultiSelectRef.current.state.value = null;
    statusesMultiSelectRef.current.state.value = null;

    setDatePickerState(initialDatePickerState);
  };

  const handleClickFilterHideButton = () => {
    setIsOpenFilterBlock((prevState) => !prevState);
  };

  return (
    <>
      <StyledFilterHeader>
        <StyledFilterItemHeader isOpenFilterBlock={isOpenFilterBlock}>
          <span className="filter-control">
            <button
              onClick={handleClickFilterHideButton}
              className="filter-control__button filter-control__hide-button"
            >
              Фильтры
            </button>
            <button
              onClick={onResetFilter}
              className="filter-control__button filter-control__reset-button"
            >
            (Сбросить)
            </button>
          </span>
        </StyledFilterItemHeader>
      </StyledFilterHeader>

      <StyledFilterBlockWrapper isOpenFilterBlock={isOpenFilterBlock}>
        <StyledFilterBlock>

          <StyledFilterItem
            col={12}
            md={6}
            xl={3}
          >
            <div className="label">Компания</div>
            <MultiSelect
              ref={companyNameMultiSelectRef}
              options={dataToMultiSelector(companyNames)}
              placeholder="Компания"
              onChange={(selectData) => onChangeFilter(selectData, 'companyName')}
              defaultValue={surveyListFilters.companyName
                ? { label: surveyListFilters.companyName, value: surveyListFilters.companyName }
                : ''
              }
            />
          </StyledFilterItem>

          <StyledFilterItem
            col={12}
            md={6}
            xl={3}
          >
            <div className="label">Тип опроса</div>
            <MultiSelect
              ref={solutionListMultiSelectRef}
              options={dataToMultiSelector(solutionList, {
                value: 'code',
                label: 'name',
              })}
              placeholder="Тип опроса"
              onChange={(selectData) => onChangeFilter(selectData, 'solutionId')}
            />
          </StyledFilterItem>

          <StyledFilterItem
            col={12}
            md={6}
            xl={3}
          >
            <div className="label">Дата создания</div>
            <RangeDateInput
              name="dateOfCreation"
              changePeriodActiveId={changePeriodActiveId}
              datePickerState={datePickerState.dateOfCreation}
              changePresetDayPicker={changePresetDayPicker}
              changeDatePickerRange={changeDatePickerRange}
              changeYearMonth={changeYearMonth}
              resetDatePicker={resetDatePicker}
              setIsOpenFilterBlock={setIsOpenFilterBlock}
              placeholder="За все время"
            />
          </StyledFilterItem>

          <StyledFilterItem
            col={12}
            md={6}
            xl={3}
          >
            <div className="label">Дата исполнения</div>
            <RangeDateInput
              name="completedDate"
              changePeriodActiveId={changePeriodActiveId}
              datePickerState={datePickerState.completedDate}
              changePresetDayPicker={changePresetDayPicker}
              changeDatePickerRange={changeDatePickerRange}
              changeYearMonth={changeYearMonth}
              resetDatePicker={resetDatePicker}
              placeholder="За все время"
            />
          </StyledFilterItem>

          <StyledFilterItem
            col={12}
            md={6}
            xl={3}
          >
            <div className="label">Менеджер</div>
            <MultiSelect
              ref={managersMultiSelectRef}
              options={dataToMultiSelector(managers, {
                value: 'id',
                label: 'email',
              })}
              placeholder="Менеджер"
              onChange={(selectData) => onChangeFilter(selectData, 'managerId')}
              defaultValue={surveyListFilters.managerId
                ? { label: getInitialManager(), value: surveyListFilters.managerId }
                : ''
              }
            />
          </StyledFilterItem>

          <StyledFilterItem
            col={12}
            md={6}
            xl={3}
          >
            <div className="label">Статус</div>
            <MultiSelect
              ref={statusesMultiSelectRef}
              options={dataToMultiSelector(statuses, {
                value: 'id',
                label: 'name',
              })}
              placeholder="Статус"
              onChange={(selectData) => onChangeFilter(selectData, 'statusId')}
              defaultValue={surveyListFilters.statusId
                ? { label: surveyListFilters.statusId, value: surveyListFilters.statusId }
                : ''
              }
            />
          </StyledFilterItem>

        </StyledFilterBlock>
      </StyledFilterBlockWrapper>
    </>
  );
};

export default FilterBlock;
