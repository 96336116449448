import styled from 'styled-components';
import { PasswordRecoveryFormWrapper } from 'components/PasswordRecoveryForm/styled';
import { media } from 'styled-bootstrap-grid';

export const PasswordRecoveryPageWrapper = styled.div`
  & ${PasswordRecoveryFormWrapper} {
    margin-bottom: 2em;
    ${media.md`
        margin-bottom: 96px;
    `}
  }
`;
