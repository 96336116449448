import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Col, Row } from 'styled-bootstrap-grid';

import generateUUID from 'helpers/generateUUID';

import { TYPES } from 'constants/questions';

import AudienceForm from 'components/AudienceForm';

import CreateForm from 'components/SurveySetup/forms/CreateForm';
import { solutionsConfig } from 'components/SurveySetup/forms/solutionConfig';
import { DynamicFieldsBlock } from 'components/SurveySetup/DynamicFieldsBlock';
import { BlockWithAnswersFields } from 'components/SurveySetup/DynamicFieldsBlock/BlockWithAnswersFields';

import * as solutionActions from 'store/solution/actions';
import * as solutionSelectors from 'store/solution/selectors';

import { getValidationErrors } from 'store/errors/selectors';

const mapStateToProps = (state) => ({
  isSubmittedForm: solutionSelectors.isSubmittedForm(state),
  validationErrors: getValidationErrors(state),
});

const mapDispatchToProps = (dispatch) => ({
  updateSolutionCreateData: (fieldData) => dispatch(solutionActions.updateSolutionCreateData(fieldData)),
});

@connect(mapStateToProps, mapDispatchToProps)
class FifteenQuestionsForm extends CreateForm {
  typeField = 'questionType';

  constructor(props) {
    super(props);
    this.state = this.solutionDataConfig.initialFormState;
    this.props.updateSolutionCreateData(this.solutionDataConfig.initialFormState);
  }

  solution = this.props.match.params.solution;

  solutionDataConfig = solutionsConfig.find(((item) => item.name === this.solution));
  render() {
    const {
      audienceId,
      geographyId,
    } = this.state;
    return (
      <>
        <Row>
          <Col col={12}>
            <DynamicFieldsBlock
              keyName="text"
              name="questions"
              confirmActionMessage="Вы уверены, что хотите удалить вопрос?"
              addButtonText="Добавить вопрос"
              textToField="Вопрос"
              maxField={15}
              maxAnswers={10}
              component={BlockWithAnswersFields}
              placeholder="Введите вопрос"
              defaultType={TYPES.single}
              nameField={'answerOptions'}
              typeField={this.typeField}
              defaultState={[
                {
                  text: '',
                  id: generateUUID(),
                  questionType: TYPES.single,
                  answerOptions: [
                    {
                      id: generateUUID(),
                      text: '',
                    },
                  ],
                },
              ]}
              hasAnswer
            />
          </Col>
        </Row>
        <Row>
          <Col col={12}>
            <AudienceForm
              onChange={this.setAudienceData}
              data={{
                audienceId,
                geographyId,
              }}
              optionalDescription="Мы будем проводить опрос репрезентативной выборки всех компаний малого
               и среднего бизнеса. Напишите нам, если вы хотите добавить какие-либо еще критерии отбора."
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(FifteenQuestionsForm);
