import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { StyledButton } from 'uiComponents/Button';
import { Button } from 'uiComponents/Button/Button';

export const AccountActivationPageWrapper = styled.div`
  & ${StyledButton} {
    margin-bottom: 2em;
    ${media.lg`
      margin-bottom: 30px;
    `}
  }
`;

export const StyledActivationButton = styled(Button)`
  ${media.tablet`
    &:hover {
      background-color: ${({ isLoading, theme }) => isLoading ? theme.colors.orange : '#fff' };
      color: ${({ theme }) => theme.colors.orange};
    }
  `}
`;
