import React from 'react';
import * as styles from 'components/EmptyList/styles';
import list from './img/list.png';

export const EmptyList = (props) => {
  const {
    title = 'Заголовок',
    text = 'Подзаголовок',
    link,
    linkText,
  } = props;
  return (
    <styles.EmptyListContainer>
      <styles.EmptyListImg src={list} />
      <styles.EmptyListTitle>{title}</styles.EmptyListTitle>
      <styles.EmptyListText>
        {text}
      </styles.EmptyListText>
      {link && linkText
        ? (
          <styles.EmptyListLink to={link}>
            {linkText}
          </styles.EmptyListLink>
        )
        : null}
    </styles.EmptyListContainer>
  );
};
