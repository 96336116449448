import styled from 'styled-components';
import { CustomTextareaAutosize } from './CustomTextareaAutosize';

export const StyledTextArea = styled(CustomTextareaAutosize)`
  outline: none;
  padding: 14px 16px;

  width: 100%;

  border: 1px solid ${(props) => props.error ? props.theme.colors.orange : props.theme.colors.darkGrey};
  border-radius: 6px;

  background: white;

  color: black;
  font-size: 16px;
  line-height: 20px;

  resize: none;

  overflow: hidden;

  &::placeholder{
    color: ${(props) => props.error ? props.theme.colors.orange : props.theme.colors.darkGrey};
    font-size: inherit;
    line-height: inherit;
  }

  &:focus::placeholder {
    color: transparent;
  }

  &:disabled {
    background: ${(props) => props.theme.colors.disabledGray};
  }
`;
