import styled from 'styled-components';

import { H2, H4 } from 'uiComponents/Titles';

export const SurveyExamplesSettingsTitleBlock = styled.div`
  margin-bottom: 64px;
`;

export const SurveyExamplesSettingsTitle = styled(H2)`
  margin-bottom: 24px;
`;

export const SurveyExamplesSettingsSection = styled.div`
  margin-bottom: 96px;
`;

export const SurveyExamplesSettingsSectionTitleBlock = styled.div`
  margin-bottom: 45px;
`;

export const SurveyExamplesSettingsSectionTitle = styled(H4)`
  margin-bottom: 16px;
`;

export const SurveyExamplesSettingsSelectWrapper = styled.div`
  max-width: 370px;
`;

