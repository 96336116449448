import React from 'react';

import { StyledMenuSwitchTitle } from './styled';

import { useSelector } from 'react-redux';

import { useLocation } from 'react-router-dom';

import * as appSelectors from 'store/app/selectors';
import * as userSelectors from 'store/user/selectors';

export const MenuSwitchTitle = ({ isShowUserMenu, handleClickMenuSwitchTitle }) => {
  const location = useLocation();

  const isAuthenticated = useSelector(appSelectors.authSelector);

  const isHomePage = location.pathname === '/';

  const isManager = useSelector(userSelectors.isManager);

  if (!isAuthenticated || !isHomePage || isManager) {
    return null;
  }

  return (
    <StyledMenuSwitchTitle
      arrow
      arrowPosition={isShowUserMenu ? 'left' : 'right'}
      borderTop
      borderBottom
      onClick={handleClickMenuSwitchTitle}
    >
      {isShowUserMenu ? 'Назад' : 'Личный кабинет'}
    </StyledMenuSwitchTitle>
  );
};
