import styled from 'styled-components';
import FormField from 'uiComponents/Form/Field';

import { FileInputWrapper } from 'components/FileInput/styled';
import { media } from 'styled-bootstrap-grid';

export const SurveySetupFormWrapper = styled.div`
  & ${FormField} {
    margin-bottom: 1em;
  }
  & ${FileInputWrapper} {
    margin-bottom: 2em;
  }
`;

export const StyledWrapperFormBlock = styled('div')`
  margin-bottom: 60px;

  ${media.md`
    margin-bottom: 64px;
  `}
`;

export const StyledWrapperFormBlockWithFileInput = styled(StyledWrapperFormBlock)`
  @media (max-width: 576px) {
    & {
      margin-bottom: 60px;
      label {
        margin-bottom: 15px;
      }
    }
  }
`;

