import styled from 'styled-components';

export const SolutionsEmpty = styled.div`
  display: flex;
  height: 100%;
  width: 100%;

  &:before {
    content: 'У вас нет доступных решений';
    font-weight: 600;
    font-size: 2em;
  }
`;

export const SolutionBlockWrapper = styled.div`
  display: block;
  padding: 32px;
  & span {
    font-size: 32px;
    font-weight: 600;
  }
`;
