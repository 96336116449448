import React from 'react';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import { PageTitle } from 'uiComponents/Titles';

export const HowDoWeDoItACSIPage = () => (
  <Container>
    <Row>
      <Col col={12}>
        <PageTitle
          className="HowDoWeDo-title"
          title={'Как мы это делаем?'}
        />
      </Col>
    </Row>
    <Row>
      <Col col={12}>
        <div className="block">
          <h3 className="title">Что?</h3>

          <p className="text">
            <strong>Оценка удовлетворенности и лояльности клиентов </strong>
              с использованием <strong>индекса ACSI </strong>
              (American Customer Satisfaction Index) связывает удовлетворенность, соответствие
              ожиданий от сервиса и реальную работу компании в единый
            <strong> индикатор-индекс лояльности</strong>.
              Дополнительно, метод позволяет компании сформировать рекомендации
              по улучшению своей операционной работы.
          </p>
        </div>

        <div className="block">
          <h3 className="title">Как?</h3>

          <p className="text">
              Опрос клиентов компании по структурированной, стандартной анкете. Метод
              предполагает выборку не менее 50 респондентов.
          </p>
        </div>
        <div className="block">
          <h3 className="title">Кто?</h3>

          <p className="text">
              Пользователи продукта или услуги, люди принимающие решения о использовании
              сервиса или продукта. Возможно два варианта:
          </p>

          <p className="text">
              1. <strong>Панель В2В Research</strong> - тогда продукт должен быть достаточно популярен чтобы
            <strong> как минимум 20% малого и среднего бизнеса пользовались </strong>
              им и были способны дать свою оценку
            <br />
              2. <strong>Собственная база Клиента</strong>. Мы готовим ссылку, Вы рассылаете ее
              своим клиентам и получаете готовый отчет.
          </p>
        </div>
      </Col>
    </Row>
  </Container>
);
