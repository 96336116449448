import * as userTypes from 'store/user/types';

export function signIn(signInFormData) {
  return {
    type: userTypes.SIGN_IN,
    payload: signInFormData,
  };
}

export function signOut(redirectTo) {
  return {
    type: userTypes.SIGN_OUT,
    payload: { redirectTo },
  };
}

export function fetchUserData() {
  return {
    type: userTypes.FETCH_USER_DATA,
  };
}

export function clearUserData() {
  return {
    type: userTypes.CLEAR_USER_DATA,
  };
}

export function fetchUserDataSuccess(profileData) {
  return {
    type: userTypes.FETCH_USER_DATA_SUCCESS,
    payload: profileData,
  };
}

export function updateUserData(formData, callback) {
  return {
    type: userTypes.UPDATE_USER_DATA,
    payload: formData,
    callback,
  };
}

export function userPasswordRecoverySubmit(passwordRecoveryFormData) {
  return {
    type: userTypes.PASSWORD_RECOVERY_SUBMIT,
    payload: passwordRecoveryFormData,
  };
}

export function userPasswordResetSubmit(formData, tokenId) {
  return {
    type: userTypes.PASSWORD_RESET_SUBMIT,
    payload: formData,
    tokenId,
  };
}

export function userRegistrationSubmit(registrationFormData, errorCb) {
  return {
    type: userTypes.REGISTRATION_FORM_SUBMIT,
    payload: registrationFormData,
    errorCb,
  };
}

export function sendAccountActivationEmail() {
  return {
    type: userTypes.SEND_ACCOUNT_ACTIVATION_MAIL,
  };
}

export function fetchCostData() {
  return {
    type: userTypes.FETCH_COST_DATA,
  };
}

export function fetchCostDataSuccess(data) {
  return {
    type: userTypes.FETCH_COST_DATA_SUCCESS,
    payload: data,
  };
}
