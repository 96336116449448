import styled from 'styled-components';

import { H2 } from 'uiComponents/Titles';

import {
  media,
  Row,
} from 'styled-bootstrap-grid';

export const StyledSearchBlock = styled(Row)`
  margin-bottom: 24px;

  ${media.md`
    margin-bottom: 64px;
  `}
`;

export const Title = styled(H2)``;

export const StyledSearchBox = styled.input`
  width: 100%;

  padding: 14px 16px;

  border: 0.5px solid #AEAEAE;
  border-radius: 6px;

  color: #202020;
  font-size: 16px;
  line-height: 20px;

  background-color: #FFFFFF;
  box-shadow: 0 7px 64px rgba(0, 0, 0, 0.02);

  ::placeholder {
    color: #AEAEAE;
  }

  ${media.xs`
    display: none;
  `}

  ${media.sm`
    display: none;
  `}

  ${media.tablet`
    display: block;
  `}

  ${media.desktop`
    margin-top: 0;
  `}`
;
