import React, { useState } from 'react';
import { useParams } from 'react-router';
import { string, func, number } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import _get from 'lodash/get';

import { TYPES } from 'constants/questions';

import * as solutionSelectors from 'store/solution/selectors';
import * as errorsSelectors from 'store/errors/selectors';
import * as solutionActions from 'store/solution/actions';

import { solutionsConfig } from 'components/SurveySetup/forms/solutionConfig';
import { FormBlockTitle } from 'components/SurveySetup/FormBlockTitle/FormBlockTitle';
import ConfirmAction from 'components/ConfirmAction';
import { SOLUTIONS } from 'constants/solutions';

import { useStateWithCallback } from 'hooks/useStateWithCallback';
import { validateField } from 'services/validation';
import generateUUID from 'helpers/generateUUID';

import {
  StyledDynamicFieldsBlock,
} from './styled';

export const DynamicFieldsBlock = ({
  blockTitle,
  keyName = 'text',
  name = 'characteristics',
  placeholder,
  maxAnswers = 10,
  confirmActionMessage = 'Подтвердите действие',
  addButtonText = 'Добавить вопрос',
  textToField,
  maxField = 3,
  defaultState = [],
  component,
  hasAnswer = false,
  defaultType = TYPES.text,
  nameField = 'items',
  typeField = 'type',
  toggleType = Function.prototype,
  type,
}) => {
  const dispatch = useDispatch();

  const { solution } = useParams();

  const isSubmittedForm = useSelector(solutionSelectors.isSubmittedForm);

  const getValidationErrors = useSelector(errorsSelectors.getValidationErrors);

  const solutionDataConfig = solutionsConfig.find(((item) => item.name === solution));

  const [ isShowConfirmationPopup, setIsShowConfirmationPopup ] = useState(false);

  const [ idDeletedField, setIdDeletedField ] = useState(null);

  const [ fields, setFields ] = useStateWithCallback(defaultState, () => {
    dispatch(solutionActions.updateSolutionCreateData({
      [name]: fields.map((item) => item),
    }));
  });
  const isRanked = Boolean(solution === SOLUTIONS.Ranging);

  const addField = () => {
    let question = {
      [keyName]: '',
      id: generateUUID(),
    };

    if (hasAnswer) {
      question = {
        ...question,
        [typeField]: defaultType,
        [nameField]: [
          {
            id: generateUUID(),
            text: '',
          },
        ],
      };
    }

    setFields((prevState) => [
      ...prevState,
      question,
    ]);
  };

  const updateField = ({ target }) => {
    const updateFieldIndex = fields.findIndex((item) => item.id === target.id);
    const newFields = [ ...fields ];

    newFields[updateFieldIndex] = {
      ...newFields[updateFieldIndex],
      [keyName]: target.value || '',
      id: target.id,
    };

    setFields(newFields);
  };

  const addItem = (questionIndex, field) => {
    const newField = JSON.parse(JSON.stringify(field));

    newField[questionIndex][nameField].push({
      id: generateUUID(),
      [keyName]: '',
    });

    Object.preventExtensions(newField);

    setFields(newField);
  };

  const updateFieldAnswer = (e, questionId, answerId) => {
    const questionIndex = fields.findIndex((item) => item.id === questionId);

    const answerOptions = fields[questionIndex][nameField];
    const answersLength = fields[questionIndex][nameField].length - 1;
    const answersIndex = answerOptions.findIndex((item) => item.id === answerId);

    const newFields = answerOptions.map((item, index) => {
      if (index === answersIndex) {
        return {
          ...item,
          [keyName]: e.target.value,
        };
      }
      return item;
    });
    const result = fields.map((item, index) => {
      if (questionIndex === index) {
        return {
          ...item,
          [nameField]: newFields,
        };
      }
      return item;
    });

    if (answersLength === answersIndex && maxAnswers > answersLength + 1 && !isRanked) {
      addItem(questionIndex, result);
    } else {
      setFields(result);
    }

  };

  const addFileItem = (file, id) => {
    const updateFieldIndex = fields.findIndex((item) => item.id === id);
    const newFields = [ ...fields ];

    newFields[updateFieldIndex] = {
      ...newFields[updateFieldIndex],
      [keyName]: file[0],
    };

    setFields(newFields);
  };

  const updateQuestionType = (e, questionId) => {
    const updateFieldIndex = fields.findIndex((item) => item.id === questionId);
    const newKeyName = e.target.value;
    let newFields = [ ...fields ];
    let emptyField = [ {
      id: generateUUID(),
      [keyName]: '',
    } ];

    if (isRanked) {
      emptyField = [ {
        id: generateUUID(),
        [newKeyName]: '',
      },
      {
        id: generateUUID(),
        [newKeyName]: '',
      },
      ];
      newFields = emptyField;
    } else {
      newFields[updateFieldIndex] = {
        ...newFields[updateFieldIndex],
        [typeField]: e.target.value,
        [nameField]: emptyField,
      };
    }

    setFields(newFields);
    toggleType(e);
  };

  const deleteField = (id) => {
    setIsShowConfirmationPopup((prevState) => !prevState);

    setFields((prevState) => (
      prevState.filter((item) => item.id !== id)
    ));
  };

  const deleteAnswer = (questionId, answerId) => {
    const questionIndex = fields.findIndex((item) => item.id === questionId);

    const newAnswers = fields[questionIndex][nameField].filter((item) => item.id !== answerId);

    const newFields = fields.map((item, index) => {
      if (questionIndex === index) {
        return {
          ...item,
          [nameField]: newAnswers,
        };
      }
      return item;
    });
    setFields(newFields);
  };

  const showConfirmationPopup = (id) => {
    setIsShowConfirmationPopup((prevState) => !prevState);
    setIdDeletedField(id);
  };

  const isValidField = (customField = '') => {

    if (isSubmittedForm) {
      const fieldRule = _get(solutionDataConfig, [ 'createSurveyRules', customField ],
        _get(solutionDataConfig, [ 'createSurveyRules', name ], ''),
      );

      if (fieldRule) {
        return (
          Object.values(
            validateField(
              fields,
              [ fieldRule ],
            ),
          ).includes(false)
        );
      }

      console.warn('validation rule is missing for field: ', customField || name);
    }

    return name in getValidationErrors;
  };

  const blockComponentProps = {
    fields,
    addButtonText,
    textToField,
    placeholder,
    maxField,
    maxAnswers,
    name,
    addField,
    updateField,
    deleteField,
    deleteAnswer,
    isValidField,
    showConfirmationPopup,
    updateFieldAnswer,
    updateQuestionType,
    addItem,
    addFileItem,
    isRanked,
    toggleType,
    type,
  };

  const BlockComponent = component;

  return (
    <StyledDynamicFieldsBlock>
      {blockTitle && (
        <FormBlockTitle {...blockTitle} />
      )}

      <BlockComponent {...blockComponentProps} />

      <ConfirmAction
        isShow={isShowConfirmationPopup}
        message={confirmActionMessage}
        onConfirmedAction={() => deleteField(idDeletedField)}
        onNotConfirmedAction={() => showConfirmationPopup(null)}
      />
    </StyledDynamicFieldsBlock>
  );
};

DynamicFieldsBlock.propTypes = {
  title: string,
  subtitle: string,
  keyName: string,
  name: string,
  addButtonText: string,
  textToField: string,
  placeholder: string,
  maxField: number,
  component: func,
  confirmActionMessage: string,
};
