import React from 'react';
import omit from 'lodash/omit';
import { Box, CheckboxContainer, Label } from './styles';

const ControlledCheckbox = ({
  id,
  className,
  checked,
  defaultChecked,
  labelText,
  onChange,
  ...props
}) => {
  const handleClick = ({ target }) => {
    onChange(target.checked, props.name);
  };

  return (
    <CheckboxContainer className={className}>
      <input
        {...omit(props, 'error')}
        type={'checkbox'}
        id={id}
        checked={checked}
        onClick={handleClick}
        hidden
      />
      <Box
        htmlFor={id}
        active={checked}
        disabled={props.disabled}
        error={props.error}
      />
      {labelText && (
        <Label htmlFor={id}>
          {labelText}
        </Label>
      )}
    </CheckboxContainer>
  );
};

export default ControlledCheckbox;
