import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { updateUserData } from 'store/user/actions';

import { ThemeConsumer } from 'styled-components';

import { Col, Row } from 'styled-bootstrap-grid';

import { Button } from 'uiComponents/Button/Button';

import { UserInfoList } from 'components/UserInfo/UserInfoList';
import { H2 } from 'uiComponents/Titles';

import { usePreloader } from 'hooks/usePreloader';
import { USER_UPDATE_PROFILE } from 'api/requestNames';

export const UserInfo = () => {
  const [ isEdit, setEdit ] = useState(false);

  const dispatch = useDispatch();

  const [ updating ] = usePreloader(USER_UPDATE_PROFILE, {
    initialState: false,
  });

  const onSubmit = (data) => {
    dispatch(updateUserData({ ...data }, () => setEdit(false)));
  };

  const renderButton = () => {
    if (!isEdit) {
      return (
        <Button
          key="edit"
          className="edit-button"
          onClick={() => setEdit(true)}
        >
          Редактировать
        </Button>
      );
    }
    return <ThemeConsumer>
      {(theme) => (
        <Button
          isLoading={updating}
          disabled={updating}
          colorDisabled={theme.colors.orange}
          key="save"
          form="profile-form"
          type="submit"
        >
          Сохранить
        </Button>
      )}
    </ThemeConsumer>;
  };

  return (
    <React.Fragment>
      <H2>{!isEdit ? 'Мой профиль' : 'Редактировать профиль'}</H2>
      <UserInfoList
        isEdit={!isEdit}
        onSubmit={onSubmit}
      />
      <Row>
        <Col
          xs={12}
          sm={6}
          md={6}
          lg={4}
        >
          {renderButton()}
        </Col>
      </Row>
    </React.Fragment>
  );
};
