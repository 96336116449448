import styled from 'styled-components';
import { Col, Row, media } from 'styled-bootstrap-grid';
import { Link } from 'react-router-dom';

export const InfoItem = styled(Col)`
  display: flex;
  flex-direction: column;

  ${media.lg`
    ${(props) => props.mobileFullSize && `
      flex: auto;
      max-width: none;
    `}
  `}
   ${media.md`
    ${(props) => props.mobileFullSize && `
      flex: auto;
      max-width: none;
    `}
  `}
`;

export const InfoItemTitle = styled.span`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: inline-block;
  padding-bottom: 8px;
  border-bottom: 1px solid #ECEBED;
`;

export const InfoItemText = styled.span`
  margin: 8px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.deepGrey}

  ${media.xs`
    margin-bottom: 18px;
  `}
`;

export const InfoItemTextGray = styled(InfoItemText)``;

export const InfoItemLink = styled(Link)`
  display: ${({ type }) => type === 'ghost' ? 'block' : 'inline-flex'};
  align-items: center;
  font-weight: ${({ type }) => type === 'ghost' ? 'normal' : '600'};
  font-size: ${({ type }) => type === 'ghost' ? '16px' : '14px'};
  line-height: ${({ type }) => type === 'ghost' ? '19px' : '17px'};
  color: ${({ theme, type }) => type === 'ghost' ? theme.colors.darkGrey : theme.colors.orange};
  text-decoration: ${({ type }) => type === 'ghost' ? 'none' : 'underline'};
  & svg {
    width: 1em;
    height: 1em;
    margin-right: 1em;
  }
`;

export const InfoItemDownloadLink = styled.a`
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.orange};
  & svg {
    width: 1em;
    height: 1em;
    margin-right: 1em;
  }

  & + & {
    margin-top: 8px;
  }
`;

export const InfoItemImageBlock = styled.div`
  width: 205px;
  height: 205px;

  min-width: 205px;
  min-height: 205px;

  margin-top: 18px;
  margin-bottom: 18px;

  border: 0;
  border-radius: 50%;

  overflow: hidden;
`;

export const InfoItemImage = styled.img`
  width: 100%;
  height: 100%;
`;

export const InfoItemSubTitle = styled.div`
  margin-bottom: 11px;

  color: #000;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
`;

export const InfoItemIndependentBlock = styled.div`
  display: flex;

  justify-content: flex-start;

  ${media.lg`
    display: block;
  `}
  ${media.xs`
    display: block;
  `}
`;
export const InfoItemIndependentInfoBlock = styled.div`
  align-self: center;

  margin-left: 56px;

  ${media.lg`
    align-self: flex-start;

    margin-left: 0;
  `}
   ${media.xs`
    margin-left: 0;
  `}
`;

export const SurveyInfoWrapper = styled(Row)`
  margin-top: 0;

  ${media.lg`

  `}

  & ${InfoItem} {
    margin-bottom: 60px;

    ${media.xs`
      margin-bottom: 36px;

      &:last-child {
        margin-bottom: 60px;
      }
    `}
  }

  & ${InfoItemLink} {
    text-decoration: none;
  }

  & ${InfoItemDownloadLink} {
    text-decoration: none;
  }
`;
