import styled from 'styled-components';
import { UserInfoLabel, UserInfoItem } from 'components/UserInfo/styles';
import { H2 } from 'uiComponents/Titles';
import { Container, media } from 'styled-bootstrap-grid';
import { StyledPreloader } from 'uiComponents/Preloader';

export const SurveyContainer = styled(Container)`
  margin-bottom: 60px;

  ${media.tablet`
    margin-bottom: 120px;
  `}
`;

export const InterviewTableContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${H2} {
    margin-bottom: 0;
  }
`;

export const InterviewTableValue = styled.div`
  ${media.tablet`
    line-height: 20px;
  `}
  ${media.desktop`
    width: 1000px;
  `}
`;

export const InterviewReportTableValue = styled('div')`
  width: 100%;

  display: flex;
  flex-direction: column;

  ${media.tablet`
    position: relative;

    flex: 1 0;
  `}

  ${media.desktop`
    flex-direction: row;
    align-items: center;
  `}
`;

export const InterviewTableValueInner = styled.div`
  margin-bottom: 16px;

  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const InterviewUploadInput = styled.input`
`;

export const InterviewUploadLabel = styled.label`
  display: inline-flex;
  align-items: center;


  color: #FF3D00;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  img  {
    margin-right: 16px;
    margin-left: -4px;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const FileNameSpan = styled.span`
  max-width: 245px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${media.xs`
    max-width: 170px;

    margin-right: 3px;

    text-overflow: clip;
  `}
`;

export const FileList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const FileBlock = styled.a`
  margin-bottom: 11px;

  display: flex;
  align-items: center;

  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: ${(props) => props.theme.colors.black};

  text-decoration: none;

  & img {
    margin-left: 34px;
  }
`;

export const InterviewDownloadDiv = styled.div`
  ${media.xs`
    display: block;

    width: 290px;

    margin-top: 26px;
  `}

  ${media.sm`
    margin-top: 24px;
  `}

  ${media.tablet`
    display: inline;

    position: absolute;
    top: 3px;
    left: 223px;

    width: 260px;

    margin-top: 0;
  `}

  ${media.desktop`
    position: static;

    width: auto;

    flex: 0 1 auto;

    margin-left: 100px;
  `}
`;

export const InterviewDownloadLink = styled.a`
  text-decoration: none;
  color: #FF3D00;

  ${media.desktop`
    display: inline-flex;
    align-items: center;
  `}

  img {
    margin-right: 12px;
  }
`;

export const InterviewDownloadDeleteButton = styled.div`
  display: inline-block;

  width: 18px;
  height: 18px;

  margin-left: 34px;

  cursor: pointer;
`;

export const InterviewDownloadName = styled.span`
  display: inline-block;

  width: 255px;

  margin-right: 34px;

  vertical-align: top;

  color: #202020;
  font-weight: 600;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const LoadPdfContainer = styled.div`
  width: 100%;
`;

export const MultiplyInterviewLoader = styled.div`
  width: 100%;
  height: 33px;

  margin-top: 40px;
  padding-left: 12px;
`;

export const MultiplyInterviewDownloadLinksList = styled.div`
  width: 100%;

  margin-bottom: 40px;
`;

export const MultiplyInterviewDownloadLinkContainer = styled.div`
  display: flex;

  align-items: center;
  justify-content: flex-start;

  width: 100%;

  margin-bottom: 20px;
`;

export const MultiplyInterviewDownloadLinkContainerBlock = styled.div`
  display: flex;

  justify-content: flex-start;

  align-items: center;

  width: 100%;

  margin-right: 34px;
  border-radius: 6px;

  box-shadow: 0 7px 64px rgba(0,0,0, .02);

  background: #FFFFFF;
`;

export const InterviewDownloadSpan = styled.span`
  font-weight: 400;
  color: #AEAEAE;
`;

export const SelectText = styled.span`
  padding-left: 8px;
`;

export const SelectImage = styled.img`
  vertical-align: middle;
`;

export const InterviewTableLabel = styled(UserInfoLabel)`
  margin-bottom: 7px;

  width: 100%;

  flex: 0 0 auto;

  line-height: 23px;

  ${media.tablet`
    margin-bottom: 0;

    width: 161px;

    line-height: 19px;
  `}

  ${media.desktop`
    width: 170px;
  `}
`;

export const InterviewTableRow = styled(UserInfoItem)`
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  &:not(:last-of-type){
    padding-bottom: 8px;
    margin-bottom: 7px;
    border-bottom: 1px solid #ECEBED;
    ${media.tablet`
      padding-bottom: 16px;
      margin-bottom: 16px;
    `}
    ${media.desktop`
      align-items: baseline;
    `}

  }

  &:nth-child(6){
    margin-top: 0;
  }

  ${media.tablet`
    flex-direction: row;
    justify-content: flex-start;
  `}

  & ${StyledPreloader} {
    margin-top: 5px;
  }

  ${({ marginTop }) => marginTop && `
    margin-top: 104px !important;
  `}
  ${({ marginBottom }) => marginBottom && `
    margin-bottom: 102px !important;
  `}
  ${({ withoutBorder }) => withoutBorder && `
    border-bottom: none !important;
  `}
`;

export const InterviewTableItem = styled.div`
  margin-bottom: 60px;

  ${media.tablet`
    &:not(:first-of-type) {
      margin-bottom: 120px;
    }
  `}

  ${media.desktop`
    margin-bottom: 120px;
  `}

  &:first-child {
    margin-bottom: 120px;

    ${InterviewTableLabel}{
      display: none;
      ${media.desktop`
        display: block;
      `}
    }
    ${InterviewTableRow}{
      margin-bottom: 0;
      ${media.desktop`
        align-items: center;
      `}
      ${InterviewTableLabel}{
        margin-bottom: 15px;
        ${media.desktop`
          margin-bottom: 0;
        `}
      }
    }
  }

  &:nth-child(2){
    ${InterviewTableRow}:last-child {
      ${InterviewTableValue}{
        font-weight: 600;
        padding-left: 4px;
        margin-top: 16px;
        color: ${(props) => !props.noFile ? props.theme.colors.darkGrey : props.theme.colors.orange};
        ${media.tablet`
          position: relative;

          margin-top: 0;
        `}
        img{
          margin-right: 10px;
        }
      }
    }
  }

  &:nth-child(3){
    ${InterviewTableRow}{
      padding-bottom: 10px;
      margin-bottom: 30px;
      &:last-child{
        padding: 0;
        margin-bottom: 0;
      }
      ${media.tablet`
        padding-bottom: 16px;
        margin-bottom: 18px;
        justify-content: flex-start;
      `}
      ${media.desktop`
        align-items: flex-start;
      `}
      ${InterviewTableValue}{
        ${media.tablet`
           width: 576px;
        `}
        ${media.desktop`
          width: 1000px;
        `}
      }
      &:nth-child(2){
      ${InterviewTableValue}{
        div{
          display: flex;
          &:first-child{
            ${media.tablet`
              margin-top: 0;
            `}
          }
          &:last-child{
            margin-top: 0;
            margin-bottom: 0;
          }
          }
          img{
            width: 16px;
            height: 18px;
          }
        }
      }

      }
    }
    p{
      font-size: 16px;
      line-height: 20px;
      margin-top: 20px;
      margin-bottom: 0;

      ${media.tablet`
        margin-top: 0;

        &:not(:last-of-type){
          margin-bottom: 18px;
        }
      `}
    }
  }

  &:nth-child(4){
    ${InterviewTableRow} {
      flex-direction: column;

      ${media.tablet`
        flex-direction: row;
      `}

      ${InterviewTableLabel} {
        margin-bottom: 15px;

        ${media.tablet`
          margin-bottom: 0;

          width: 161px;
        `}
        ${media.desktop`
           width: 170px;
        `}
      }
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const InterviewClientInfoTableRow = styled('div')`
  display: flex;
  flex-direction: column;

  margin-bottom: 60px;
  margin-top: -90px;

  ${media.tablet`
    margin-bottom: 120px;
  `}
`;

export const InterviewClientDownloadLinksContainer = styled.div`
  display: flex;

  align-items: center;
  justify-content: flex-start;

  width: 100%;

  margin-bottom: 16px;
`;

export const InterviewClientDownloadLink = styled.a`
  text-decoration: none;
  color: #FF3D00;

  margin-right: 93px;

  ${media.desktop`
    display: inline-flex;
    align-items: center;
  `}

  img {
    margin-right: 12px;
  }
`;

export const InterviewClientDownloadName = styled.span`
  display: inline-block;

  vertical-align: top;
`;
