import styled from 'styled-components';
import { media, Row } from 'styled-bootstrap-grid';
import { H2 } from 'uiComponents/Titles';
import { TableContainer } from 'uiComponents/Table';
import { PaginationButtons } from 'components/Pagination/styles';
import { RouterLink as Link } from 'uiComponents/Link/index';

export const Title = styled(H2)`
  ${media.xs`
    margin-bottom: 24px;

    font-size: 26px;
  `}

  ${media.sm`
    margin-bottom: 31px;

    font-size: 26px;
  `}

  ${media.md`
    margin-bottom: 31px;

    font-size: 44px;
  `}

  ${media.lg`
    margin-bottom: 40px;
  `}
`;

export const ManagerOrderListPaginationButtons = styled(PaginationButtons)`

  ${media.xs`
    display: none;
  `}

  ${media.sm`
    margin-bottom: 60px;
  `}

  ${media.md`
    margin-bottom: 120px;
  `}
`;

export const ListContainer = styled(TableContainer)`
  margin-bottom: 66px;
`;

export const sortHeader = styled.div`
  position: relative;
`;

export const sortImg = styled.img`
  position: absolute;
  top: 3px;
  left: -20px;
`;

export const Tooltip = styled.div`
    display: flex;
    align-items: center;
`;

export const TooltipText = styled.div`
    padding-left: 12px;
`;

export const CellBlock = styled.div`
    max-width: 165px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    color: #FF3D00;

    ${media.xs`
      max-width: 100%;
    `}
`;

export const SpecialVersionText = styled.span`
  padding-left: 7px;

  color: #FF3D00;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
`;

export const SpecialVersionRow = styled(Row)`
  margin-bottom: 60px;
  ${media.sm`
    display: none;
  `}
`;

export const RedLink = styled(Link)`
  text-decoration: none;
`;
