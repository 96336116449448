import React from 'react';
import { history } from 'store';
import * as styles from 'components/Breadcrumbs/styled';

export const Breadcrumbs = (props) => {
  const { breadcrumbs } = props;

  const isCreatePage = history.location.pathname.split('/')[1] === 'create';

  if (breadcrumbs === undefined) { return null; }

  return (
    <styles.Breadcrumbs className={props.className}>
      {
        breadcrumbs.map((dataItem, dataIndex) => (
          <styles.Breadcrumb
            isCreatePage={isCreatePage}
            key={dataIndex}
            to={dataItem.breadcrumbLink}
          >
            &nbsp;{dataItem.breadcrumbText}
          </styles.Breadcrumb>
        ))
      }
    </styles.Breadcrumbs>
  );
};

export const ContainerBreadcrumbs = (props) => {
  const { breadcrumbs } = props;
  return (
    <styles.BreadcrumbsContainer>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
    </styles.BreadcrumbsContainer>
  );
};

export function getBreadcrumbs(items) {
  const breadcrumbsList = [ { breadcrumbText: 'Главная', breadcrumbLink: '/' } ];
  items.map((item) => {
    breadcrumbsList.push(item);
  });
  return breadcrumbsList;
}
