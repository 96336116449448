import styled from 'styled-components';

export const StyledRangeSelector = styled.div`
  padding: 0 0 16px 0;
  margin: 0 0 20px 0;

  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey };

  .range-row {
    display: flex;
    align-items: center;
  }

  .range-row__items {
    display: flex;
    align-items: center;
  }

  .range-row__label {
    font-size: 14px;
    line-height: 17px;
  }
`;

export const StyledPeriodButton = styled.button`
  position: relative;

  padding: 3px 6px;
  margin: 0 7px;

  display: block;

  background-color: ${({ active, theme }) => active ? theme.colors.orange : 'transparent' };
  color: ${({ active, theme }) => active ? '#fff' : theme.colors.orange };

  font-size: 14px;
  line-height: 17px;
  font-family: Inter, Helvetica, Arial, sans-serif;

  border: none;
  border-radius: 4px;

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;;
  }

  &::after {
    content: '';

    position: absolute;
    top: 50%;
    right: -7px;

    transform: translateY(-50%);

    display: block;
    height: 50%;
    width: 1px;
    background-color: ${({ theme }) => theme.colors.borderGrey };
  }

  &:last-child {
    &::after {
      content: none;
    }
  }
`;
