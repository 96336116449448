import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

export const BlockWithCheckBoxListContainer = styled.div`
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  margin-bottom: 20px;

  ${media.md`
    margin-bottom: 24px;
  `}
`;

export const BlockWithCheckBoxListItem = styled.div`
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
`;
