import React from 'react';
import { useSelector } from 'react-redux';
import _get from 'lodash/get';

import * as solutionSelectors from 'store/solution/selectors';
import {
  InterviewTableItem,
  InterviewTableRow,
  InterviewTableLabel,
  InterviewTableValue,
} from 'components/SurveyReview/styles';

import { TYPES_QUESTIONS_NAME } from 'constants/questions';

import { QuestionsBlock, QuestionItem, QuestionsTitle } from './styles';

const QuestionsList = () => {
  const questions = useSelector(solutionSelectors.getQuestions);

  if (!questions || !questions.length) {
    return null;
  }

  return (
    <QuestionsBlock>
      {questions.map((question) => {
        const questionType = question.questionType || '';

        return (
          <QuestionItem key={question.order}>
            <QuestionsTitle>{`Вопрос #${question.order}:`}</QuestionsTitle>
            <div>
              <InterviewTableItem>
                <InterviewTableRow>
                  <InterviewTableLabel>Текст вопроса:</InterviewTableLabel>
                  <InterviewTableValue>{question.text}</InterviewTableValue>
                </InterviewTableRow>
                <InterviewTableRow>
                  <InterviewTableLabel>Тип вопроса:</InterviewTableLabel>
                  <InterviewTableValue>{TYPES_QUESTIONS_NAME[questionType]}</InterviewTableValue>
                </InterviewTableRow>
                {Boolean(_get(question, 'answerOptions.length', 0)) && (
                  <InterviewTableRow>
                    <InterviewTableLabel>Ответы:</InterviewTableLabel>
                    <InterviewTableValue>
                      <InterviewTableItem>
                        {question.answerOptions.map((answer, answerIndex) => (
                          <InterviewTableRow key={`answer-${answerIndex}`}>
                            <InterviewTableLabel>{`Ответ #${answerIndex + 1}:`}</InterviewTableLabel>
                            <InterviewTableValue>{answer.text}</InterviewTableValue>
                          </InterviewTableRow>
                        ))}
                      </InterviewTableItem>
                    </InterviewTableValue>
                  </InterviewTableRow>
                )}
              </InterviewTableItem>
            </div>
          </QuestionItem>
        );
      })}
    </QuestionsBlock>
  );
};

export default QuestionsList;
