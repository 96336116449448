import styled, { css, keyframes } from 'styled-components';

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const StyledButton = styled.button`
  width: 100%;

  box-sizing: border-box;

  padding: 13px 35px;

  background-color: ${(props) => props.disabled
    ? (props.colorDisabled || props.theme.colorsborderGrey)
    : props.theme.colors.orange};

  border: 2px solid ${(props) => props.disabled
    ? (props.colorDisabled || props.theme.colorsborderGrey)
    : props.theme.colors.orange};
  border-radius: 6px;

  color: white;
  text-align: center;
  font-size: 17px;
  font-weight: 600;
  line-height: 18px;
  text-transform: ${(props) => props.textTransform};
  cursor: pointer;
  transition: all .3s;

  ${(props) => props.viewType === 'ghost' && !props.isLoading
    ? css`
      background-color: transparent;
      color: ${props.disabled ? props.theme.colorsborderGrey : `${props.theme.colors.orange}`};
      border: 2px solid ${props.disabled ? props.theme.colorsborderGrey : props.theme.colors.orange};

      ${({ extendType, disabled }) => extendType === 'hovered-ghost' && !disabled && `
        font-weight: bold;
        transition: .3s;

        &:hover {
          background-color: #FF3D00;
          color: #fff;
        }
      `}

      `
    : null};

  .circular-preloader {
    width: 18px;
    height: auto;

    margin: 0 auto;

    display: flex;

    animation: ${spin} 1s linear infinite;
  }
`;

