export const getSolutionState = (state) => state.solution;

export const getSolutionData = (state) => getSolutionState(state).solutionData;
export const getSolutions = (state) => getSolutionState(state).items;

export const getSolutionCreateData = (state) => getSolutionState(state).solutionCreateData;

export const getAdditionalQuestions = (state) => getSolutionState(state).solutionCreateData.additionalQuestions;
export const getCheckedCharacteristics = (state) => getSolutionState(state).solutionCreateData.checkedCharacteristics;
export const getCharacteristics = (state) => getSolutionState(state).solutionCreateData.characteristics;
export const getStatements = (state) => getSolutionState(state).solutionCreateData.statements;
export const getCompetitors = (state) => getSolutionState(state).solutionCreateData.competitors;
export const getProductPrice = (state) => getSolutionState(state).solutionCreateData.productPrice;
export const getProductNames = (state) => getSolutionState(state).solutionCreateData.names;
export const getSolutionsSuccessModalShow = (state) => getSolutionState(state).solutionsSuccessModalShow;

export const getSolutionDetails = (state) => getSolutionData(state).details;
export const getSolutionName = (state) => getSolutionData(state).name;
export const uploadingFileIds = (state) => getSolutionCreateData(state).fileIds;
export const uploadingFileItems = (state) => getSolutionCreateData(state).items;
export const additionalFileId = (state) => getSolutionCreateData(state).additionalFileId;
export const getSolutionReportExamples = (state) => (getSolutionDetails(state) || {}).reportExamples || [];

export const isSubmittedForm = (state) => getSolutionCreateData(state).isSubmitted;
export const getNamesFieldUploadingFiles = (state) => getSolutionCreateData(state).namesFieldUploadingFiles;
export const getCreatedQuestions = (state) => getSolutionCreateData(state).questions;
export const getRankingItems = (state) => getSolutionCreateData(state).rankingItems;

export const getSurveyData = (state) => getSolutionState(state).surveyData;
export const getSurveyListData = (state) => getSolutionState(state).surveysList;

export const getSurveyId = (state) => getSurveyData(state).id;
export const getReportFileId = (state) => getSurveyData(state).reportFileId;
export const getReportUploadDate = (state) => getSurveyData(state).reportUploadDate;
export const getReportsList = (state) => getSurveyData(state).reportFiles;
export const isUploadingFile = (state) => getSurveyData(state).uploadingFile;
export const getManagers = (state) => getSurveyData(state).managers;
export const getStatuses = (state) => getSurveyData(state).statuses;
export const getQuestions = (state) => getSurveyData(state).questions;

export const getSurveyListItems = (state) => getSurveyListData(state).items;
export const getSurveyListFilters = (state) => getSurveyListData(state).filters;
export const getSurveyListTotal = (state) => getSurveyListData(state).total;
export const getSurveyListTotalFound = (state) => getSurveyListData(state).totalFound;
export const getCompanyNames = (state) => getSurveyListData(state).companyNames;
export const getSolutionList = (state) => getSurveyListData(state).solutionList;

export const getSurveySettings = (state) => getSolutionState(state).settings || {};
export const getExamplesSurveyTypeSelector = (state) => getSurveySettings(state).examplesSurveyType || null;
