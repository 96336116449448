import React from 'react';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import { PageTitle } from 'uiComponents/Titles';

export const HowDoWeDoItAIDAPage = () => (
  <Container>
    <Row>
      <Col col={12}>
        <PageTitle
          className="HowDoWeDo-title"
          title="Как мы это делаем?"
        />
      </Col>
    </Row>
    <Row>
      <Col col={12}>
        <div className="block">
          <h3 className="title">Что?</h3>

          <p className="text">
              Тестирование проводится на основе модели AIDA,
              в которой оцениваются внимание, привлекаемое рекламой,
              интерес, привлекательность рекламируемого продукта, намерение использовать
              и рекомендовать рекламируемый товар.
          </p>
        </div>

        <div className="block">
          <h3 className="title">Как?</h3>

          <p className="text">
              Опрос клиентов компании по структурированной,
              стандартной анкете. Метод предполагает выборку не менее 50 респондентов.
              В тестирование можно включить готовый ролик или аниматик.
          </p>
        </div>
        <div className="block">
          <h3 className="title">Кто?</h3>

          <p className="text">
              Пользователи продукта или услуги, люди принимающие решения о использовании
              сервиса или продукта. Возможно два варианта:
          </p>

          <p className="text">
              1. <strong>Панель В2В Research</strong> - тогда категория должна быть достаточно популярной,
            <strong> как минимум 20% малого и среднего бизнеса пользовались </strong>
              подобными продуктами и способны дать своюо ценку
            <br />
              2. <strong>Собственная база Клиента</strong>. Мы готовим ссылку, Вы рассылаете ее
              своим клиентам и получаете готовый отчет.
          </p>
        </div>
      </Col>
    </Row>
  </Container>
);
