import styled from 'styled-components';

export const documentBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  height: 48px;

  padding: 12px 0;

  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
`;

export const ReportsUploaderList = styled.div`
  margin-bottom: 24px;
`;

export const ReportsUploaderListItem = styled.div`
  display: flex;

  align-items: flex-start;
  justify-content: flex-start;

  margin-bottom: 16px;
`;

export const ReportsUploaderListItemName = styled.div`
  width: 277px;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const ReportsUploaderListItemContent = styled(documentBlock)`
  width: 100%;
`;

export const ReportsUploaderListItemIcon = styled.div`
  width: 18px;

  margin-left: 35px;

  cursor: pointer;
`;

export const ReportsUploaderAddButton = styled.label`
  display: inline-flex;

  align-items: center;
  justify-content: flex-start;

  margin-left: -5px;

  cursor: pointer;
`;

export const ReportsUploaderAddButtonIcon = styled.img`
  margin-right: 16px;
`;
