import styled, { css } from 'styled-components';
import { CheckboxContainer, Label } from 'uiComponents/Checkbox/styles';
import { RouterLink, DownloadLink } from 'uiComponents/Link';
import { Button } from 'uiComponents/Button/Button';
import { media } from 'styled-bootstrap-grid';

export const StyledNameSurveyBlock = styled('div')`
  & .SurveyNameInput {
    margin-bottom: 0!important;
  }
`;

export const StyledNameSurvey = styled('h6')`
  margin: 0 0 32px 0;

  font-weight: bold;
  font-size: 26px;
  line-height: 32px;
  color: ${(props) => props.theme.colors.black};
`;

export const CheckboxWrapper = styled('div')`
  margin-top: 18px;

  ${media.md`
    margin-bottom: 56px;
  `}

  ${media.lg`
    margin-bottom: 0;
  `}

  & ${CheckboxContainer} {
    margin-bottom: 18px;

    ${media.lg`
      margin-bottom: 16px;
    `}
  }

  & .NameSurveyBlock {
    ${media.lg`
      margin-bottom: 18px;
    `}
  }

  & ${Label} {
    color: ${(props) => props.theme.colors.black};
  }
`;

export const StyledLink = styled(RouterLink)`
  text-decoration: none;
`;

export const StyledDownloadLink = styled(DownloadLink)`
  text-decoration: none;
`;

export const StyledStartSurveyButton = styled(Button)`
  ${media.xs`
    margin-bottom: 20px;
  `}

  ${media.tablet`
    &:hover {
    ${(props) => props.disabled
    ? css`
      background-color: {$theme.colors.borderGrey};
      color: #fff;
    `
    : css`
      background-color: ${({ isLoading, theme }) => isLoading ? theme.colors.orange : '#fff' };
      color: ${({ theme }) => theme.colors.orange};
    `}
    }
  `}
`;
