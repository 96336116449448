import styled from 'styled-components';

const FormField = styled.div`
  & label {
    display: inline-block;
    font-weight: 600;
    margin-bottom: 1em;
    line-height: 22px;
    font-size: 18px;
  }
  & p {
    font-size: 14px;
    color: ${({ theme }) => theme.colors.orange};
    margin: 1em 0 0 0;
  }
  margin-bottom: 2em;

  &.flex {
    display: flex;
    align-items: center;
  }

  span:first-child {
    flex: 0 0 50px;
  }
  span:last-child {
    flex: 0 0 70px;

    margin-left: 10px;
  }
`;

export default FormField;
