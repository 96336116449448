import React from 'react';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import { PageTitle } from 'uiComponents/Titles';

export const MethodologyNPSPage = () => (
  <Container>
    <Row>
      <Col col={12}>
        <PageTitle title={'Методология'} />
      </Col>
    </Row>
    <Row>
      <Col col={12}>
        <div className="block">
          <p className="text">
            <strong>Оценка лояльности клиентов</strong> с использованием <strong>индекса NPS </strong>
            <br />
                (Net Promoter Score) широко используется в исследовательской индустрии благодаря своей
                эффективности и простоте.
          </p>

          <p className="text">
                Среди организаций, подтверждено использующих метод и результаты исследований
                (в том числе в официальных документах и отчетах), - крупнейшие международные и российские
                компании - GE, Allianz, P&G, Apple, eBay, Amazon, Ростелеком, Ozon, Мегафон, МТС, РОСНО, МИАН,
                Аэрофлот, практически все компании банковского и страхового сектора.
          </p>

          <p className="text">
                Основоположником метода считается Фредерик Райхельд (Frederick F. Reichheld), который
                впервые анонсировал его в статье <strong>The One Number You Need to Grow</strong>,
                опубликованной в журнале Harvard Business Review в декабре 2003 года.
          </p>
        </div>

        <div className="block">
          <h3 className="title">Методика оценки лояльности клиентов NPS включает три шага:</h3>

          <h4 className="subtitle">1. Непосредственное измерение индекса NPS</h4>

          <p className="text">Потребителям предлагается ответить на вопрос «Какова вероятность того,
                что Вы порекомендуете компанию своим друзьям/знакомым/коллегам?»
                по&nbsp;шкале от 0 до 10, где 0 соответствует ответу
                «Ни в коем случае не буду рекомендовать», а 10 — «Обязательно порекомендую».
          </p>

          <p className="text">
                На основе полученных оценок все потребители разделяются на 3 группы:
          </p>

          <p className="text">
                - 9-10 баллов — промоутеры (promoters) компании,
            <br />
                - 7-8 баллов — нейтральные,
            <br />
                - 0-6 баллов — критики (detractors).
          </p>

          <p className="text">
                Индекс NPS рассчитывается как разница между долей промоутеров и критиков:
          </p>

          <h4
            className="subtitle"
            style={{ textAlign: 'center' }}
          >
            NPS = % промоутеров — % критиков
          </h4>

          <h4 className="subtitle">2. Качественная диагностика</h4>

          <p className="text">
                Для формирования рекомендаций по улучшению работы сервиса компании участникам
                исследования задается второй вопрос: “Почему Вы так считаете?”
          </p>

          <p className="text">
                Ответы на этот вопрос позволяют компаниям определить наиболее важные факторы, влияющие
                на формирование потребительской лояльности.
          </p>

          <p className="text">
            <strong>3. Оценка точек взаимодействия</strong> помогает компании понять слабые и сильные стороны своего
                сервиса и сформировать рекомендации по улучшению своей операционной работы.
          </p>

          <p className="text">
                Для этого участников исследования просят оценить удовлетворенность отдельными элементами
                сервиса компании.
          </p>
        </div>
      </Col>
    </Row>
  </Container>
);
