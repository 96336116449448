import React, { useState, useEffect } from 'react';

import { array, string, func } from 'prop-types';

import {
  StyledDropdown, StyledDropList, StyledDropListItem,
  StyledSelectedOption,
} from './styles';

import {
  SelectImage,
  SelectText,
} from '../../components/SurveyReview/styles';

import arrowDown from '../../assets/images/arrowDown.png';
import ConfirmAction from 'components/ConfirmAction';

export const Dropdown = (props) => {
  const {
    options = [],
    value = '-',
    onChangeOption,
    type,
    orderNumber,
  } = props;

  const [ dropListOpened, setDropListOpened ] = useState(false);
  const [ isShowConfirmationPopup, setIsShowConfirmationPopup ] = useState(false);
  const [ selectedOption, setSelectedOption ] = useState('');

  const showConfirmationPopup = (option) => {
    setSelectedOption(option);
    setIsShowConfirmationPopup(!isShowConfirmationPopup);
  };

  const onChange = (item) => {
    onChangeOption(item);

    setDropListOpened(false);
    setIsShowConfirmationPopup(false);
  };

  const onClickPageBody = (e) => {
    if (!e.target.closest('.drop-list')) {
      setDropListOpened(false);
    }
  };

  const onOpenDropdown = () => {
    setDropListOpened(true);
  };

  const templateConfirmMessage = () => {
    if (type === 'status') {
      return `Вы уверены, что хотите сменить статус заказа №${orderNumber} на "${selectedOption.name}"`;
    } else if (type === 'manager') {
      return `Вы уверены, что хотите назначить менеджером заказа №${orderNumber} "${selectedOption.name}"`;
    }
    return '';
  };

  useEffect(() => {
    document.body.addEventListener('click', onClickPageBody);

    return () => {
      document.body.removeEventListener('click', onClickPageBody);
    };
  }, []);

  return (
    <>
      <StyledDropdown>
        <StyledSelectedOption onClick={onOpenDropdown}>
          <SelectImage src={arrowDown} />
          <SelectText>
            {value.name}
          </SelectText>
        </StyledSelectedOption>

        {options.length !== 0 && (
          <StyledDropList
            dropListOpened={dropListOpened}
            className="drop-list"
          >
            {options.map((option) => (
              <StyledDropListItem
                active={option.id === value.id}
                onClick={() => showConfirmationPopup(option)}
                key={option.id}
              >
                {option.name}
              </StyledDropListItem>))}
          </StyledDropList>
        )}
      </StyledDropdown>

      <ConfirmAction
        isShow={isShowConfirmationPopup}
        message={templateConfirmMessage()}
        onConfirmedAction={() => onChange(selectedOption)}
        onNotConfirmedAction={() => showConfirmationPopup('')}
      />
    </>
  );
};

Dropdown.propType = {
  options: array,
  value: string,
  onChangeOption: func,
};
