import React from 'react';
import { useParams } from 'react-router-dom';

import { getBreadcrumbs } from 'components/Breadcrumbs/index.jsx';

import { StyledPage } from './styled';
import { solutionsConfig } from 'components/SurveySetup/forms/solutionConfig';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import { ContainerBreadcrumbs } from 'components/Breadcrumbs';
import { PageTitle } from 'uiComponents/Titles';

export default (props) => {
  const { solutionName } = useParams();

  const solutionDataConfig = solutionsConfig.find(((item) => item.name === solutionName));
  const solutionTitle = Object.values(solutionsConfig).find((item) => item.name === solutionName);

  const breadcrumbs = getBreadcrumbs([
    { breadcrumbText: 'решения', breadcrumbLink: '/create' },
    { breadcrumbText: solutionTitle ? solutionTitle.title : solutionName, breadcrumbLink: `/create/${solutionName }` },
    { breadcrumbText: props.breadcrumbText, breadcrumbLink: '#' },
  ]);
  return (
    <StyledPage>
      <Container>
        <Row>
          <Col col={12}>
            <ContainerBreadcrumbs breadcrumbs={breadcrumbs} />
          </Col>
        </Row>
        <Row>
          <Col col={12}>
            <PageTitle title="Анкета" />
          </Col>
        </Row>
        <Row>
          <Col col={12}>
            <ul className="question-list">
              {
                solutionDataConfig.questionsArray.map((item, index) => (
                  <li
                    key={index}
                    className="question-list__item"
                    /* eslint-disable-next-line react/no-danger */
                    dangerouslySetInnerHTML={{ __html: item }}
                  />
                ))
              }
            </ul>
          </Col>
        </Row>
      </Container>
    </StyledPage>
  );
};
