import { locale } from './locale';

const formatDay = (d) => `${locale.weekdaysLong[d.getDay()]},
  ${d.getDate()} ${locale.months[d.getMonth()]} ${d.getFullYear()}`;

const formatMonthTitle = (d) => `${locale.months[d.getMonth()]} ${d.getFullYear()}`;

const formatWeekdayShort = (i) => locale.weekdaysShort[i];

const formatWeekdayLong = (i) => locale.weekdaysShort[i];

const getFirstDayOfWeek = () => 1;

const getMonths = () => locale.months;

const getShortMonths = () => locale.monthsShort;

export const localeUtilsRu = {
  formatDay,
  formatMonthTitle,
  formatWeekdayShort,
  formatWeekdayLong,
  getFirstDayOfWeek,
  getMonths,
  getShortMonths,
};
