import React from 'react';
import { Col, Row } from 'styled-bootstrap-grid';
import { connect } from 'react-redux';

import FormField from 'uiComponents/Form/Field';
import { Input } from 'uiComponents/Input/inputStyles';

import { PasswordRecoveryFormWrapper, StyledRecoveryButton } from 'components/PasswordRecoveryForm/styled';

import getErrorMessage from 'helpers/errors/getErrorMessage';

import * as errorsSelectors from 'store/errors/selectors';
import * as userActions from 'store/user/actions';
import * as errorsActions from 'store/errors/actions';

import { ThemeConsumer } from 'styled-components';

const mapStateToProps = (state) => ({
  validationErrors: errorsSelectors.getValidationErrors(state),
  requestErrors: errorsSelectors.getRequestErrors(state),
});

const mapDispatchToProps = (dispatch) => ({
  submitForm: (formData) => dispatch(userActions.userPasswordRecoverySubmit(formData)),
  clearValidationErrors: () => dispatch(errorsActions.clearValidationErrors()),
  clearRequestErrors: () => dispatch(errorsActions.clearRequestErrors()),
});

@connect(mapStateToProps, mapDispatchToProps)
class PasswordRecoveryRequestForm extends React.Component {
  static defaultProps = {
    validationErrors: {},
  };

  state = {
    email: '',
  };

  // todo: Вынести в обёртку
  componentWillUnmount() {
    this.props.clearRequestErrors();
  }

  handleInputChange = ({ target }) => {
    this.setState({
      ...this.state,
      [target.name]: target.value,
    });
  };

  submitForm = () => {
    this.props.submitForm(this.state);
  };

  render() {
    const {
      requestErrors,
      isStartPasswordRecovery,
    } = this.props;

    return (
      <PasswordRecoveryFormWrapper>
        <Row>
          <Col
            col={12}
            md={6}
            xl={4}
          >
            <FormField>
              <label htmlFor="email">Введите E-Mail</label>
              <Input
                id={'email'}
                type={'email'}
                name={'email'}
                placeholder={'E-Mail'}
                defaultValue={this.state.email}
                onChange={this.handleInputChange}
                error={'email' in this.props.validationErrors}
              />
              {requestErrors.includes('UserNotFound') > 0 && (
                <p>
                  {requestErrors.map((requestError) => (
                    <span key={requestError}>{getErrorMessage(requestError)}</span>
                  ))}
                </p>
              )}
            </FormField>
          </Col>
        </Row>
        <Row>
          <Col
            col={12}
            md={6}
            xl={4}
          >
            <ThemeConsumer>
              {(theme) => (
                <StyledRecoveryButton
                  isLoading={isStartPasswordRecovery}
                  disabled={isStartPasswordRecovery}
                  colorDisabled={theme.colors.orange}
                  onClick={this.submitForm}
                >
                  Отправить письмо
                </StyledRecoveryButton>
              )}
            </ThemeConsumer>
          </Col>
        </Row>
      </PasswordRecoveryFormWrapper>
    );
  }
}

export default PasswordRecoveryRequestForm;
