import React from 'react';
import _isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';

import { SOLUTIONS } from 'constants/solutions';

import * as solutionActions from 'store/solution/actions';

import * as solutionSelectors from 'store/solution/selectors';

import {
  FileBlock,
  FileNameSpan,
  InterviewTableContainer,
  InterviewTableItem,
  InterviewTableLabel,
  InterviewTableRow,
  InterviewClientInfoTableRow,
  InterviewTableValue,
  InterviewTableValueInner,
  FileList,
  InterviewClientDownloadLinksContainer,
  InterviewClientDownloadLink,
  InterviewClientDownloadName,
} from 'components/SurveyReview/styles';
import LoadPdf from 'components/SurveyReview/LoadPdf';
import QuestionsList from 'components/QuestionsList';

import { Dropdown } from 'uiComponents/Dropdown/Dropdown';
import { H2 } from 'uiComponents/Titles';

import downloadIcon from 'assets/images/download.png';

import data from './fieldsList';
import { getSurveyName } from 'helpers/solutions';

const solutionConstructor = {
  [SOLUTIONS.ACSI]: {
    fields: [ 'targetCompanyName', 'period', 'characteristics' ],
  },
  [SOLUTIONS.ISO]: {
    fields: [ 'targetCompanyName', 'period', 'characteristics' ],
  },
  [SOLUTIONS.NPS]: {
    fields: [ 'targetCompanyName', 'period', 'characteristics' ],
  },
  [SOLUTIONS.Concept]: {
    fields: [ 'productDescription', 'concept', 'additionalFile', 'productPrice' ],
  },
  [SOLUTIONS.Name]: {
    fields: [ 'productName', 'names' ],
  },
  [SOLUTIONS.AIDA]: {
    fields: [ 'files', 'productDescription', 'targetCompanyName' ],
  },
  [SOLUTIONS.Brand]: {
    fields: [ 'targetCompanyName', 'productDescription', 'competitors', 'period', 'statements' ],
  },
  [SOLUTIONS.BTH]: {
    fields: [
      'targetCompanyName',
      'productDescription',
      'competitors', 'period',
      'statements',
      'files',
      'healthMeasuringCount',
      'healthMeasuringPeriod',
    ],
  },
  [SOLUTIONS.CR]: {
    fields: [ 'productDescription', 'targetCompanyName', 'competitors' ],
  },
  [SOLUTIONS.Ranging]: {
    fields: [ 'rankingType', 'rankingItems', 'characteristics', 'rankingFiles' ],
  },
  [SOLUTIONS.Consultation]: {
    fields: [
      'firstName', 'lastName', 'email', 'phone',
      /* 'consultationTheme',*/ 'consultationDate',
      'consultationTime', 'filesWithBypass',
    ],
  },
};

function fileName(str) {
  return str.substring(0, str.lastIndexOf('.'));
}

function fileExpansion(str) {
  return str.substring(str.lastIndexOf('.'));
}

const TableRow = ({ name, value, stylesProps = {} }) => (
  <InterviewTableRow {...stylesProps}>
    <InterviewTableLabel>
      {name}
    </InterviewTableLabel>
    <InterviewTableValue>
      {value}
    </InterviewTableValue>
  </InterviewTableRow>
);

export const SurveyReview = (props) => {
  const {
    surveyId,
    isManager,
  } = props;

  const reportFileId = useSelector(solutionSelectors.getReportFileId);

  const surveyData = useSelector(solutionSelectors.getSurveyData);

  const {
    id,
    price,
    status,
    creator,
    manager,
    managers,
    statuses,
    solution,
    createdDate,
    companyName,
    completedDate,
    reportFiles,

    description,
    additionalQuestions,

    audience,
    geography,
    companyTurnover,
  } = surveyData;

  const dispatch = useDispatch();

  const onChangeStatus = (dataOption) => {
    dispatch(solutionActions.updateSurveyStatus({
      ...dataOption,
      surveyId,
      isGlobalLoading: false,
    }));
  };

  const onChangeManager = (dataOption) => {
    dispatch(solutionActions.updateSurveyManager({
      ...dataOption,
      surveyId,
    }));
  };

  const getDataFromArray = (itemName, arr) => (
    arr && arr.length > 0 && (
      <TableRow
        name={itemName}
        value={arr.map((item, index) => (
          <InterviewTableValueInner key={index + itemName}>{index + 1}. {item}</InterviewTableValueInner>
        ))}
      />
    )
  );

  const renderFields = (fieldType) => {
    if (!data[fieldType]) {
      console.warn('undefined field of type: ', fieldType);
      return null;
    }

    const dataType = data[fieldType].source || fieldType;
    const fieldDate = useSelector(solutionSelectors.getSurveyData)[dataType];
    const solutionName = useSelector(solutionSelectors.getSolutionName);

    if (data[fieldType].bypassIf && data[fieldType].bypassIf(fieldDate)) {
      return null;
    }

    switch (fieldType) {
      case 'firstName':
      case 'lastName':
      case 'email':
      case 'phone':
      case 'consultationTheme':
        return (
          <TableRow
            name={data[fieldType].fieldName}
            value={fieldDate}
            stylesProps={data[fieldType].stylesProps}
          />
        );
      case 'targetCompanyName':
      case 'productName':
      case 'productDescription':
      case 'concept':
      case 'productPrice':
      case 'period':
      case 'healthMeasuringCount':
      case 'rankingType':
      case 'rankingItems':
      case 'consultationDate':
      case 'consultationTime':
        return (
          <TableRow
            name={data[fieldType].fieldName}
            value={data[fieldType].value(fieldDate)}
            stylesProps={data[fieldType].stylesProps}
          />
        );
      case 'healthMeasuringPeriod':
        return (
          <TableRow
            name={data[fieldType].fieldName}
            value={(
              <>
                Раз в <strong>{data[fieldType].value(fieldDate)}</strong> мес.
              </>
            )}
          />
        );
      case 'competitors':
      case 'statements':
      case 'characteristics':
      case 'names':
        return (
          getDataFromArray(data[fieldType].fieldName, fieldDate)
        );
      case 'files':
      case 'filesWithBypass':
      case 'rankingFiles':
        return (
          <TableRow
            name={data[fieldType].fieldName(solutionName)}
            value={(
              <FileList>
                {fieldDate.map((file) => (
                  <FileBlock
                    href={`/api/file/${file.id}`}
                    target={'_blank'}
                    key={file.id}
                  >
                    <FileNameSpan>{fileName(file.name)}&nbsp;</FileNameSpan>
                    <span>{fileExpansion(file.name)}</span>
                    <img
                      src={downloadIcon}
                      alt={file.name}
                    />
                  </FileBlock>
                ))}
              </FileList>
            )}
          />
        );
      case 'additionalFile':
        return (
          fieldDate &&
          <TableRow
            name={data[fieldType].fieldName}
            value={(
              <FileList>
                <FileBlock
                  href={`/api/file/${fieldDate.id}`}
                  target={'_blank'}
                  key={fieldDate.id}
                >
                  <FileNameSpan>{fileName(fieldDate.name)}&nbsp;</FileNameSpan>
                  <span>{fileExpansion(fieldDate.name)}</span>
                  <img
                    src={downloadIcon}
                    alt={fieldDate.name}
                  />
                </FileBlock>
              </FileList>
            )}
          />
        );
      default: return null;
    }
  };

  const renderReportFileRow = () => {
    let innerBlock;

    if (!isManager) {
      innerBlock = (
        <InterviewTableValue {...(reportFileId ? { noFile: reportFileId } : {})}>
          {!reportFiles.length
            ? 'Отчет не загружен'
            : reportFiles.map((reportItem) => (
              <InterviewClientDownloadLinksContainer>
                <InterviewClientDownloadLink
                  href={`/api/file/${reportItem.id}`}
                  target={'_blank'}
                >
                  <img
                    src={downloadIcon}
                    alt={data.result.userValue}
                  />{data.result.userValue}
                </InterviewClientDownloadLink>
                <InterviewClientDownloadName>
                  {reportItem.name}
                </InterviewClientDownloadName>
              </InterviewClientDownloadLinksContainer>
            ))
          }
        </InterviewTableValue>
      );
    } else {
      innerBlock = (
        <LoadPdf data={data} />
      );
    }

    return (
      <InterviewTableRow>
        <InterviewTableLabel>
          {data.result.fieldName}
        </InterviewTableLabel>
        {innerBlock}
      </InterviewTableRow>
    );
  };

  const managersOptions = managers.map((item) => ({
    ...item,
    name: item.email,
  }));

  const solutionName = useSelector(solutionSelectors.getSolutionName);
  const audienceInfo = data.audience.value(audience);
  const geographyInfo = data.geography.value(geography);
  const surveyName = getSurveyName(solutionName, surveyData);

  return (
    <InterviewTableContainer>
      <InterviewTableItem>
        <TableRow
          name={data.name.fieldName}
          value={(
            <H2>{data.name.value(surveyName)}</H2>
          )}
        />
      </InterviewTableItem>

      {isManager && (
        <InterviewClientInfoTableRow>
          <TableRow
            name={data.companyName.fieldName}
            value={data.companyName.value(companyName)}
            stylesProps={data.companyName.stylesProps}
          />
          <TableRow
            name={data.clientName.fieldName}
            value={data.clientName.value(creator)}
            stylesProps={data.clientName.stylesProps}
          />
          <TableRow
            name={data.clientPhone.fieldName}
            value={data.clientPhone.value(creator)}
            stylesProps={data.clientPhone.stylesProps}
          />
          <TableRow
            name={data.clientEmail.fieldName}
            value={data.clientEmail.value(creator)}
            stylesProps={data.clientEmail.stylesProps}
          />
        </InterviewClientInfoTableRow>
      )}

      <InterviewTableItem>
        <TableRow
          name={data.status.fieldName}
          value={
            isManager
              ? (
                <Dropdown
                  onChangeOption={onChangeStatus}
                  value={data.status.value(status)}
                  options={statuses}
                  type={'status'}
                  orderNumber={id}
                />
              )
              : data.status.value(status.name)
          }
        />

        <TableRow
          name={data.id.fieldName}
          value={data.id.value(id)}
        />

        {isManager && (
          <TableRow
            name={data.manager.fieldName}
            value={(
              <Dropdown
                onChangeOption={onChangeManager}
                value={data.manager.value(manager)}
                options={managersOptions}
                type={'manager'}
                orderNumber={id}
              />
            )}
          />
        )}

        <TableRow
          name={data.solution.fieldName}
          value={data.solution.value(solution)}
        />

        <TableRow
          name={data.createdDate.fieldName}
          value={data.createdDate.value(createdDate)}
        />

        <TableRow
          name={data.completedDate.fieldName}
          value={data.completedDate.value(completedDate)}
        />

        <TableRow
          name={data.price.fieldName}
          value={data.price.value(price)}
        />

        {renderReportFileRow()}
      </InterviewTableItem>

      <InterviewTableItem>
        {description && (
          <InterviewTableRow>
            <InterviewTableLabel>
              {data.description.fieldName}
            </InterviewTableLabel>
            <InterviewTableValue dangerouslySetInnerHTML={{ __html: description }} />
          </InterviewTableRow>
        )}

        {Object.keys(solutionConstructor).map((solutionItem) => {
          if (solutionItem === solutionName) {
            return solutionConstructor[solutionName].fields.map((field) => (
              renderFields(field)
            ));
          }

          return null;
        })}

        {!!(geographyInfo || audienceInfo || (additionalQuestions && additionalQuestions.length)
        || !_isEmpty(companyTurnover)) && (
          <InterviewTableItem>
            {geographyInfo && (
              <TableRow
                name={data.geography.fieldName}
                value={geographyInfo}
              />
            )}

            {audienceInfo && (
              <TableRow
                name={data.audience.fieldName}
                value={audienceInfo}
              />
            )}

            {additionalQuestions && additionalQuestions.length > 0 && (
              <div>
                {additionalQuestions.map((question) => (
                  <InterviewTableRow key={question.order}>
                    <InterviewTableLabel>
                      {`Дополнительный вопрос #${question.order}:`}
                    </InterviewTableLabel>
                    <InterviewTableValue dangerouslySetInnerHTML={{ __html: question.text }} />
                  </InterviewTableRow>
                ))}
              </div>
            )}
            <QuestionsList />
            {!_isEmpty(companyTurnover) && (
              <TableRow
                name={data.companyTurnover.fieldName}
                value={data.companyTurnover.value(companyTurnover)}
              />
            )}
          </InterviewTableItem>
        )}
      </InterviewTableItem>

    </InterviewTableContainer>
  );
};
