import React, { useCallback, useRef, useState } from 'react';
import omit from 'lodash/omit';

import { Box, CheckboxContainer, Label } from './styles';

const Checkbox = ({
  id,
  className,
  checked,
  defaultChecked,
  labelText,
  onChange,
  ...props
}) => {
  const [ stateChecked, setChecked ] = useState(checked || defaultChecked);

  const input = useRef(null);

  const handleClick = useCallback(
    () => {
      input.current.checked = !stateChecked;
      setChecked(input.current.checked);
      if (onChange) {
        onChange(input.current.checked, props.name);
      }
    },
    [ stateChecked ],
  );

  return (
    <CheckboxContainer className={className}>
      <input
        {...omit(props, 'error')}
        ref={input}
        type={'checkbox'}
        id={id}
        defaultChecked={stateChecked}
        hidden
      />
      <Box
        active={stateChecked}
        disabled={props.disabled}
        onClick={handleClick}
        error={props.error}
      />
      {labelText && (
        <Label
          onClick={handleClick}
        >
          {labelText}
        </Label>
      )}
    </CheckboxContainer>
  );
};

export default Checkbox;
