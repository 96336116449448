import request from 'api/request';
import { SOLUTION_GET_SOLUTION_DETAILS } from 'api/requestNames';

export function getSolutionDetails(solutionId, subOption) {
  return request(
    `/api/solution/${solutionId}`,
    {
      method: 'GET',
      requestName: SOLUTION_GET_SOLUTION_DETAILS,
      ...subOption,
    },
  );
}
