import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import CreateForm from 'components/SurveySetup/forms/CreateForm';
import { solutionsConfig } from 'components/SurveySetup/forms/solutionConfig';
import { StyledWrapperFormBlock } from 'components/SurveySetup/styled';
import { FormBlockTitle } from 'components/SurveySetup/FormBlockTitle/FormBlockTitle';
import { AuthorizedField } from 'components/AuthorizedField/AuthorizedField';
import AudienceForm from 'components/AudienceForm';
import { DynamicFieldsBlock } from 'components/SurveySetup/DynamicFieldsBlock';
import { BlockWithDefaultField } from 'components/SurveySetup/DynamicFieldsBlock/BlockWithDefaultField';

import FormField from 'uiComponents/Form/Field';
import { StyledTextArea } from 'uiComponents/TeaxtArea';

import * as solutionActions from 'store/solution/actions';
import * as solutionSelectors from 'store/solution/selectors';
import { getValidationErrors } from 'store/errors/selectors';

import { Col, Row } from 'styled-bootstrap-grid';

import generateUUID from 'helpers/generateUUID';
import AdditionalQuestion from 'components/AdditionalQuestion';

const mapStateToProps = (state) => ({
  isSubmittedForm: solutionSelectors.isSubmittedForm(state),
  validationErrors: getValidationErrors(state),
});

const mapDispatchToProps = (dispatch) => ({
  updateSolutionCreateData: (fieldData) => dispatch(solutionActions.updateSolutionCreateData(fieldData)),
});

@connect(mapStateToProps, mapDispatchToProps)
class CRForm extends CreateForm {
  constructor(props) {
    super(props);
    this.state = this.solutionDataConfig.initialFormState;
    this.props.updateSolutionCreateData(this.solutionDataConfig.initialFormState);
  }

  solution = this.props.match.params.solution;

  solutionDataConfig = solutionsConfig.find(((item) => item.name === this.solution));

  render() {
    const {
      productDescription,
      targetCompanyName,
      audienceId,
      geographyId,
    } = this.state;

    return (
      <>
        <Row>
          <Col col={12}>
            <StyledWrapperFormBlock>
              <FormBlockTitle
                title="Товары/услуги"
                subtitle="Опишите, пожалуйста, какие товары или услуги предлагает Ваша компания?"
                textHint="Зачем это нужно?"
                dataTipFor="productDescription"
                dataTip={(
                  <>
                    Респондентам будет предложено ответить на вопрос: «Используете ли Вы в своей
                    профессиональной деятельности следующие продукты или услуги: /НАЗВАНИЕ ПРОДУКТА ИЛИ УСЛУГИ/?»
                    Таким образом, мы отберем для участия только тех респондентов, которые являются пользователями
                    необходимых продуктов и услуг.
                  </>
                )}
              />
              <AuthorizedField>
                <FormField>
                  <StyledTextArea
                    id="productDescription"
                    name="productDescription"
                    placeholder="Например, оборудование для неразрушающего контроля или услуги по подбору персонала"
                    defaultValue={productDescription}
                    onChange={this.inputOnChange}
                    error={this.isValidField('productDescription')}
                  />
                </FormField>
              </AuthorizedField>
            </StyledWrapperFormBlock>
          </Col>
        </Row>
        <Row>
          <Col col={12}>
            <StyledWrapperFormBlock>
              <FormBlockTitle
                title="Компания"
                subtitle="Укажите название компании / марки, корпоративную репутацию которой Вы бы хотели исследовать:"
              />
              <AuthorizedField>
                <FormField>
                  <StyledTextArea
                    id="targetCompanyName"
                    name="targetCompanyName"
                    placeholder="Введите компанию / марку"
                    defaultValue={targetCompanyName}
                    onChange={this.inputOnChange}
                    error={this.isValidField('targetCompanyName')}
                  />
                </FormField>
              </AuthorizedField>
            </StyledWrapperFormBlock>
          </Col>
        </Row>
        <Row>
          <Col col={12}>
            <DynamicFieldsBlock
              blockTitle={{
                title: 'Компании/Марки конкурентов',
                subtitle: 'Укажите до трех названий компаний / марок-конкурентов:',
              }}
              keyName="text"
              name="competitors"
              confirmActionMessage="Вы уверены, что хотите удалить компанию/марку?"
              addButtonText="Добавить компанию/марку"
              textToField="Компания/бренд"
              placeholder="Введите компанию / марку конкурента"
              defaultState={[
                { text: '', id: generateUUID() },
              ]}
              component={BlockWithDefaultField}
            />
          </Col>
        </Row>
        <Row>
          <Col col={12}>
            <AudienceForm
              onChange={this.setAudienceData}
              data={{
                audienceId,
                geographyId,
              }}
              optionalDescription="Мы будем проводить опрос среди тех компаний, которые пользуются
               указанными вами продуктами или услугами."
            />
          </Col>
        </Row>
        <AdditionalQuestion />
      </>
    );
  }
}

export default withRouter(CRForm);
