export const TYPES = Object.freeze({
  open: 'Open',
  single: 'SingleOption',
  multiple: 'MultipleOption',
  text: 'Text',
  image: 'Image',
});

export const TYPES_QUESTIONS_NAME = Object.freeze({
  [TYPES.single]: 'Один ответ',
  [TYPES.multiple]: 'Несколько ответов',
  [TYPES.open]: 'Открытый вопрос',
  [TYPES.text]: 'Текстовые',
  [TYPES.image]: 'Изображения',
});

export const QUESTIONS_KEYS_LIST = Object.freeze({
  0: TYPES.single,
  1: TYPES.multiple,
  2: TYPES.open,
  3: TYPES.text,
  4: TYPES.image,
});

export const RANKED_TYPES_ITEMS = [
  TYPES.text,
  TYPES.image,
];

export const QUESTIONS_SOLUTIONS_TYPES_ITEMS = [
  TYPES.single,
  TYPES.multiple,
  TYPES.open,
];
