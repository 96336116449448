import React from 'react';
import { StyledPagePreloader } from './styled';
import Icon from 'uiComponents/Icon';
import { ThemeConsumer } from 'styled-components';

export const PagePreloader = () => (
  <StyledPagePreloader>
    <ThemeConsumer>
      {(theme) => (
        <Icon
          fill={theme.colors.orange}
          width={64}
          height={64}
          name={'page-preloader'}
        />
      )}
    </ThemeConsumer>

  </StyledPagePreloader>
);
