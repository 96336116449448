import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { ThemeConsumer } from 'styled-components';

import { sendAccountActivationEmail } from 'store/user/actions';

import { ContainerBreadcrumbs } from 'components/Breadcrumbs';
import { getBreadcrumbs } from 'components/Breadcrumbs/index.jsx';

import { PageTitle } from 'uiComponents/Titles';

import { AccountActivationPageWrapper, StyledActivationButton } from 'pages/AccountActivationPage/styled';

import { usePreloader } from 'hooks/usePreloader';

import { USER_SEND_ACCOUNT_ACTIVATION_EMAIL } from 'api/requestNames';

const AccountActivationPage = (props) => {
  const dispatch = useDispatch();

  const [ isStartActivationAccount ] = usePreloader(USER_SEND_ACCOUNT_ACTIVATION_EMAIL, {
    initialState: false,
  });

  const buttonClickHandler = useCallback(() => dispatch(sendAccountActivationEmail()));
  const breadcrumbs = getBreadcrumbs([ { breadcrumbText: props.breadcrumbText, breadcrumbLink: props.path } ]);

  return (
    <AccountActivationPageWrapper>
      <Container>
        <Row>
          <Col col={12}>
            <ContainerBreadcrumbs breadcrumbs={breadcrumbs} />
          </Col>
        </Row>
        <Row>
          <Col col={12}>
            <PageTitle
              title="Ваш аккаунт не активирован"
              subTitle="Перейдите по ссылке из письма на указанном вами e-mail для активации аккаунта"
            />
          </Col>
        </Row>
        <Row>
          <Col
            col={12}
            md={6}
            xl={4}
          >
            <ThemeConsumer>
              {(theme) => (
                <StyledActivationButton
                  isLoading={isStartActivationAccount}
                  disabled={isStartActivationAccount}
                  colorDisabled={theme.colors.orange}
                  onClick={buttonClickHandler}
                >
                  Отправить письмо повторно
                </StyledActivationButton>
              )}
            </ThemeConsumer>
          </Col>
        </Row>
      </Container>
    </AccountActivationPageWrapper>
  );
};

export default AccountActivationPage;
