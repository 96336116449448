import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { StyledButton } from 'uiComponents/Button';

export const StyledConfirmAction = styled.div`
  top: 0;
  left: 0;
    
  height: 100%;
  width: 100%;
  
  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  z-index: 100;
  
  background-color: rgba(0, 0, 0, 0.5);
  
  .inner-popup {
    padding: 30px 20px;
    
    width: calc(100% - 30px);
        
    display: flex;
    flex-direction: column;
    
    background: #F5F5F5;
    
    ${media.md`
      padding: 60px 42px;

      width: 520px;
    `}
  }
  
  .message {
    margin-bottom: 30px;
  
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    
    color: #202020;
    
    ${media.md`
      margin-bottom: 60px;
  
      font-weight: bold;
      font-size: 32px;
      line-height: 39px;
    `}
  }

  .subMessage {
    margin-bottom: 30px;
  }
  
  .buttons {
    display: flex;
    flex-direction: column;
    
    ${media.md`
      flex-direction: row;
      justify-content: space-between;
    `}
  }
`;

export const ConfirmButton = styled(StyledButton)`
  margin-bottom: 15px;
  
  &:last-child {
    margin-bottom: 0;
  }
  
  ${media.md`
    width: 210px;

    margin-bottom: 0;
  `}
`;

