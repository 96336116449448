import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Container, Row, Col } from 'styled-bootstrap-grid';

import { UserInfo } from 'components/UserInfo';
import { UserInfoStyles } from 'components/UserInfo/styles';
import { ContainerBreadcrumbs } from 'components/Breadcrumbs';
import { fetchUserData } from 'store/user/actions';
import { getBreadcrumbs } from 'components/Breadcrumbs/index.jsx';

import { PagePreloader } from 'components/PagePreloader/PagePreloader';
import { usePreloader } from 'hooks/usePreloader';
import { USER_PROFILE } from 'api/requestNames';

const ProfilePage = (props) => {
  const dispatch = useDispatch();

  const [ isProfileDataLoaded ] = usePreloader(USER_PROFILE);

  useEffect(() => {
    dispatch(fetchUserData());
  }, []);

  if (isProfileDataLoaded) {
    return <PagePreloader />;
  }

  const breadcrumbs = getBreadcrumbs([ { breadcrumbText: props.breadcrumbText, breadcrumbLink: props.path } ]);

  return (
    <UserInfoStyles>
      <Container>
        <Row>
          <Col col={12}>
            <ContainerBreadcrumbs breadcrumbs={breadcrumbs} />
            <UserInfo />
          </Col>
        </Row>
      </Container>
    </UserInfoStyles>
  );
};

export default ProfilePage;
