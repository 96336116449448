import React from 'react';
import { ErrorContainer, ErrorImage, ErrorLink, ErrorText, ErrorTitle } from 'components/ErrorComponent/ErrorStyles';
import errorImg from './img/error.png';

export const ErrorComponent = (props) => {
  const {
    title = 'Страница не найдена',
    text = 'Страницы с данным адресом не существует,',
    link = '/',
    linkText = 'вернитесь на главную страницу',
  } = props;
  return (
    <ErrorContainer>
      <ErrorImage src={errorImg} />
      <ErrorTitle>
        {title}
      </ErrorTitle>
      <ErrorText>
        {text}
      </ErrorText>
      <ErrorLink to={link}>
        {linkText}
      </ErrorLink>
    </ErrorContainer>
  );
};
