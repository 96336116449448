import React from 'react';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import { PageTitle } from 'uiComponents/Titles';

export const MethodologyConceptPage = () => (
  <Container>
    <Row>
      <Col col={12}>
        <PageTitle title="Методология" />
      </Col>
    </Row>
    <Row>
      <Col col={12}>
        <div className="block">
          <h3 className="title">
            Методика оценки включает три основных элемента:
          </h3>

          <h4 className="subtitle">
            1. Измерение привлекательности предложения
          </h4>

          <p className="text">
            Потребителям предлагается оценить по шкале
            от 1 до 5 такие характеристики предложения, как:
          </p>

          <ul className="list">
            <li className="list__item">Привлекательность предложения</li>
            <li className="list__item">Соответствие его требованиям компании</li>
            <li className="list__item">Соответствие его требованиям компании</li>
            <li className="list__item">Соответствие цены его характеристикам и свойствам</li>
          </ul>

          <h4 className="subtitle">
            2. Оценка качества коммуникации
          </h4>

          <p className="text">
            Потребителям предлагается оценить по шкале от 1 до 5 качество коммуникации, описания продукта:
          </p>

          <ul className="list">
            <li className="list__item">Достаточность информации</li>
            <li className="list__item">Интерес, вызываемый описанием</li>
            <li className="list__item">Правдоподобность описания и доверие, которое оно вызывает</li>
          </ul>

          <p className="text">
            Дополнительная диагностика с открытыми вопросами позволяет также определить какой
            информации о предложении не хватает и что еще имело бы смысл рассказать
            (для улучшения привлекательности предложения).
          </p>

          <h4 className="subtitle">
            3. Диагностика
          </h4>

          <p className="text">
            Для формирования рекомендаций по улучшению идеи продукта, участникам исследования
            задаются открытые вопросы диагностики того, что им нравится и не нравится
            в концепции и ее описании.
          </p>

          <p className="text">
            Ответы на этот вопросы позволяют компаниям определить возможные направления
            улучшения продукта и коммуникации о нем.
          </p>
        </div>
      </Col>
    </Row>
  </Container>
);
