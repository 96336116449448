import request from 'api/request';
import {
  USER_COST,
  USER_PROFILE,
  USER_UPDATE_PROFILE,
  USER_SIGN_IN,
  USER_PASSWORD_RECOVERY_REQUEST,
  USER_REGISTER,
  USER_SEND_ACCOUNT_ACTIVATION_EMAIL,
  USER_PASSWORD_RESET_REQUEST,
} from 'api/requestNames';

function register(registrationFormData) {
  return request(
    '/api/user/register/',
    {
      method: 'POST',
      body: JSON.stringify(registrationFormData),
      requestName: USER_REGISTER,
    },
  );
}

function signIn(signInFormData) {
  return request(
    '/api/user/sign-in/',
    {
      method: 'POST',
      body: JSON.stringify(signInFormData),
      requestName: USER_SIGN_IN,
    },
  );
}

function signOut() {
  return request(
    '/api/user/sign-out/',
    {
      method: 'POST',
      body: JSON.stringify({}),
    },
  );
}

function profile() {
  return request(
    '/api/user/profile',
    {
      method: 'GET',
      requestName: USER_PROFILE,
    },
  );
}

function updateProfile(data) {
  return request(
    '/api/user/profile',
    {
      method: 'POST',
      body: JSON.stringify(data),
      requestName: USER_UPDATE_PROFILE,
    },
  );
}

function passwordResetRequest(data) {
  return request(
    '/api/user/password-reset/',
    {
      method: 'POST',
      body: JSON.stringify(data),
      requestName: USER_PASSWORD_RESET_REQUEST,
    },
  );
}

function passwordRecoveryRequest(passwordRecoveryFormData) {
  return request(
    '/api/user/password-reset-request/',
    {
      method: 'POST',
      body: JSON.stringify(passwordRecoveryFormData),
      requestName: USER_PASSWORD_RECOVERY_REQUEST,
    },
  );
}

function cost() {
  return request(
    '/api/client/costs',
    {
      method: 'GET',
      requestName: USER_COST,
    },
  );
}

function sendAccountActivationEmail(formData) {
  return request(
    '/api/user/resend-activation-email',
    {
      method: 'POST',
      body: JSON.stringify(formData),
      requestName: USER_SEND_ACCOUNT_ACTIVATION_EMAIL,
    },
  );
}

export {
  register,
  signIn,
  signOut,
  profile,
  updateProfile,
  passwordResetRequest,
  passwordRecoveryRequest,
  cost,
  sendAccountActivationEmail,
};
