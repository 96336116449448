/* eslint-disable no-console */
import { takeLatest, call, put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { save } from '../../localStorage';

import * as userTypes from 'store/user/types';

import * as userActions from 'store/user/actions';
import * as appActions from 'store/app/actions';
import * as errorsActions from 'store/errors/actions';

import {
  profileDataSelector,
  getResendActivationEmailToken,
} from 'store/user/selectors';

import userConstants from 'constants/userConstants';

import * as userApi from 'api/user';

import { validateForm, getErrors } from 'services/validation';

import {
  userSignInRules,
  userPasswordResetRules,
  userPasswordRecoveryRules,
} from 'services/validation/rules';

import omit from 'lodash/omit';

export function* validation(formData, rules) { // TODO: утащить из саги юзера
  const validationErrors = yield call(getErrors, yield call(validateForm, formData, rules));
  yield put(errorsActions.setValidationErrors(validationErrors));
  return validationErrors;
}

export function* sendRegistrationData(action) {
  yield put(errorsActions.clearRequestErrors());

  try {
    const validationErrors = yield call(validation, action.payload);

    if (!Object.keys(validationErrors).length) {
      const { success, error } = yield call(userApi.register, action.payload);

      if (success) {
        yield put(push('/registration-confirmation'));
      }

      if (error) {
        yield put(errorsActions.setRequestError(error));
      }
    }

  } catch (e) {
    console.warn(e);
  }
}

export function* signInRequest(action) {
  yield put(errorsActions.clearRequestErrors());

  try {
    const validationErrors = yield call(validation, action.payload, userSignInRules);

    if (!Object.keys(validationErrors).length) {
      const { success, result, error } = yield call(userApi.signIn, omit(action.payload,
        [ 'repeatPassword', 'privatePolicy' ]));

      if (success) {
        yield put(appActions.auth(true));

        yield put(userActions.fetchUserDataSuccess(result));
        yield put(userActions.fetchUserData());

        save('user', result);

        const redirectPath = yield select((store) =>
          store.router.location.state
            ? store.router.location.state.from && store.router.location.state.from.pathname
            : '/surveys',
        );

        if (userConstants.roles.manager === result.role) {
          yield put(push('/surveys'));
        } else {
          yield put(push(redirectPath));
        }
      }

      if (error) {
        if (error === 'EmailNotConfirmed') {
          yield put(userActions.fetchUserDataSuccess(result));
          yield put(push('/activation'));
        }

        yield put(errorsActions.setRequestError(error));
      }
    }
  } catch (e) {
    console.warn(e);
  }
}

export function* signOutRequest(action) {
  try {
    const { success, error } = yield call(userApi.signOut);

    if (success) {
      const {
        redirectTo = '',
      } = action.payload;
      const redirectPath = redirectTo && typeof redirectTo === 'string'
        ? redirectTo
        : null;

      yield put(appActions.auth(false));

      save('user', null);

      yield put(userActions.clearUserData());

      yield put(push(redirectPath || '/'));
    }

    if (error) {
      yield put(errorsActions.setRequestError(error));
    }
  } catch (e) {
    console.warn(e);
  }
}

export function* sendPasswordRecoveryData(action) {
  yield put(errorsActions.clearRequestErrors());

  try {
    const validationErrors = yield call(validation, action.payload, userPasswordRecoveryRules);

    if (!Object.keys(validationErrors).length) {
      const { success, error } = yield call(userApi.passwordRecoveryRequest, action.payload);

      if (success) {
        yield put(push('/password-recovery-confirmation'));
      }

      if (error) {
        yield put(errorsActions.setRequestError(error));
      }
    }

  } catch (e) {
    console.warn(e);
  }
}

export function* sendPasswordResetData(action) {
  yield put(errorsActions.clearRequestErrors());

  try {
    const validationErrors = yield call(validation, action.payload, userPasswordResetRules);

    if (!Object.keys(validationErrors).length) {
      const data = {
        newPassword: action.payload.password,
        tokenId: action.tokenId,
      };

      const { success, error } = yield call(userApi.passwordResetRequest, data);

      if (success) {
        yield put(push('/sign-in'));
      }

      if (error) {
        yield put(errorsActions.setRequestError(error));
      }
    }
  } catch (e) {
    console.warn(e);
  }
}

export function* sendAccountActivationEmail() {
  yield put(errorsActions.clearRequestErrors());

  const resendActivationEmailToken = yield select(getResendActivationEmailToken);

  try {

    const { success, error } = yield call(userApi.sendAccountActivationEmail, {
      token: resendActivationEmailToken,
    });

    if (success) {
      yield put(push('/registration-confirmation'));
    }

    if (error) {
      yield put(errorsActions.setRequestError(error));
    }

  } catch (e) {
    console.warn(e);
  }
}

export function* fetchUserData() {
  try {
    const { success, result, error } = yield call(userApi.profile);

    if (success) {
      yield put(userActions.fetchUserDataSuccess({ ...result }));
    }

    if (error) {
      yield put(errorsActions.setRequestError(error));
    }
  } catch (e) {
    console.warn('SAGA fetchUserData error', e);
  }
}

export function* updateUserData(action) {
  try {
    const profileData = yield select(profileDataSelector);

    const { success, error } = yield call(userApi.updateProfile, profileData);

    if (success) {
      action.callback && action.callback();
    }

    if (error) {
      yield put(errorsActions.setRequestError(error));
    }
  } catch (e) {
    console.warn('SAGA updateUserData error', e);
  }
}

export function* fetchCostData() {
  try {
    const { success, result, error } = yield call(userApi.cost);

    if (success) {
      yield put(userActions.fetchCostDataSuccess({ ...result }));
    }

    if (error) {
      yield put(errorsActions.setRequestError(error));
    }
  } catch (e) {
    console.warn('SAGA fetchUserData error', e);
  }
}

export default function* userSaga() {
  yield takeLatest(userTypes.REGISTRATION_FORM_SUBMIT, sendRegistrationData);
  yield takeLatest(userTypes.SIGN_IN, signInRequest);
  yield takeLatest(userTypes.SIGN_OUT, signOutRequest);
  yield takeLatest(userTypes.PASSWORD_RESET_SUBMIT, sendPasswordResetData);
  yield takeLatest(userTypes.PASSWORD_RECOVERY_SUBMIT, sendPasswordRecoveryData);

  yield takeLatest(userTypes.FETCH_USER_DATA, fetchUserData);
  yield takeLatest(userTypes.UPDATE_USER_DATA, updateUserData);
  yield takeLatest(userTypes.FETCH_COST_DATA, fetchCostData);
  yield takeLatest(userTypes.SEND_ACCOUNT_ACTIVATION_MAIL, sendAccountActivationEmail);
}
