import { DefaultTheme } from 'styled-components';

export const theme: Partial<DefaultTheme> = {
  colors: {
    orange: '#FF3D00',
    lightGrey: '#F5F5F5',
    darkGrey: '#AEAEAE',
    borderGrey: '#D2D2D2',
    deepGrey: '#2E2E2E',
    disabledGray: '#F5F5F5',
    black: '#202020',
  },
};
