import styled from 'styled-components';
import { media, Col } from 'styled-bootstrap-grid';
import { Link } from 'react-router-dom';

export const InfoBlockStyles = styled.div`
  background-color: ${(props): string => props.theme.colors.lightGrey};
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  padding: 63px 0;
  margin-top: 60px;

  ${media.tablet`
    margin-top: 120px;
    padding: 63px 0 96px 0;

    flex-direction: row;
    flex-wrap: wrap;
  `}

  ${media.desktop`

  `}

  ${media.xl`
    margin: 120px auto 96px auto;
    padding: 63px 0;

    max-width: 1280px;
  `}
`;

export const InfoBlockItem = styled(Col)`
  display: flex;
  flex-direction: column;
  margin-bottom: 36px;

  ${media.tablet`
    margin-bottom: 56px;
    padding: 0 32px;
  `}

  ${media.desktop`
    padding: 0 80px;
  `}
  ${media.xl`
    padding: 0 15px;

    height: 131px;
  `}

  ${media.xl`
    margin-bottom: 0;
  `}

  &:nth-child(3) {
    ${media.tablet`
      margin-bottom: 0;
    `}
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const InfoBlockTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid #ECEBED;
`;

export const InfoBlockLink = styled(Link)`
  font-size: 14px;
  line-height: 17px;
  color: ${(props): string => props.theme.colors.orange};
  text-decoration: none;
  font-weight: 600;
`;

export const InfoBlockText = styled.div`
  margin-bottom: 17px;

  font-size: 16px;
  line-height: 19px;
  color: ${(props): string => props.theme.colors.darkGrey};

  ${media.desktop`

  `}
`;

export const InfoBlockDownloadLink = styled('a')`
  font-size: 14px;
  line-height: 17px;
  color: ${(props): string => props.theme.colors.orange};
  text-decoration: none;
  font-weight: 600;
`;

export const InfoBlockIcon = styled.img`
  margin-right: 14px;
`;

export const InfoBlockLinkWrapper = styled.div`
  margin-top: auto;

  display: flex;
  align-items: center;
`;
