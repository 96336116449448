import React, { forwardRef } from 'react';
import { InputMaskStyled } from 'uiComponents/Input/inputStyles';

export const PhoneInput = forwardRef((props, ref) => (
  <InputMaskStyled
    {...props}
    inputRef={ref}
    mask={'+7\ (999) 999 99 99'}
  />
));
