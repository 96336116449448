import styled from 'styled-components';
import { Input, InputMaskStyled } from 'uiComponents/Input/inputStyles';
import { StyledTextArea } from 'uiComponents/TeaxtArea';
import { StyledButton } from 'uiComponents/Button';
import { Box, Label } from 'uiComponents/Checkbox/styles';
import { media } from 'styled-bootstrap-grid';

export const StyledFormBlock = styled('div')`
  .checkbox-container {
    align-items: center;
    margin-bottom: 36px;

    ${media.md`
      margin-bottom: 0;
    `}
    & ${Box} {
      flex: 0 0 auto;
      margin-right: 27px;
    }

    & ${Label} {
      font-size: 12px;
      line-height: 20px;

      ${media.md`
        font-size: 16px;
        line-height: 20px;
      `}
    }
  }
`;

export const StyledFormBlockInput = styled(Input)`
  margin-bottom: 18px;

  border: 1px solid ${(props) => props.error ? props.theme.colors.orange : props.theme.colors.darkGrey};

  &::placeholder{
    color: ${(props) => props.error ? props.theme.colors.orange : props.theme.colors.black};
    font-size: inherit;
    line-height: inherit;
  }
`;

export const StyledFormBlockInputPhone = styled(InputMaskStyled)`
  margin-bottom: 18px;

  border: 1px solid ${(props) => props.error ? props.theme.colors.orange : props.theme.colors.darkGrey};

  &::placeholder{
    color: ${(props) => props.error ? props.theme.colors.orange : props.theme.colors.black};
    font-size: inherit;
    line-height: inherit;
  }
`;

export const StyledFormBlockTextArea = styled(StyledTextArea)`
  margin-bottom: 40px;

  min-height: 180px;

  ${(props) => props.error && `
    border: 1px solid ${props.theme.colors.orange};
 `};

  &::placeholder{
    color: ${(props) => props.error ? props.theme.colors.orange : props.theme.colors.black};
    font-size: inherit;
    line-height: inherit;
  }
`;

export const StyledFormBlockSubmitButton = styled(StyledButton)`
  font-weight: bold;
  transition: .3s;

  &:hover {
    background-color: ${(props) => props.theme.colors.orange};
    color: #fff;
  }
`;

export const StyledFormBlockSuccess = styled.div`
  text-align: center;

  color: #FF3D00;
  font-size: 17px;
  line-height: 18px;
  font-weight: bold;
`;

export const StyledFormBlockCheckedAgreement = styled.span`
  font-size: 14px;
  line-height: 18px;
`;
