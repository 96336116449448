export const load = (item) => {
  try {
    const serializedState = localStorage.getItem(item);

    if (serializedState === null) {
      return false;
    }

    return JSON.parse(serializedState);
  } catch (e) {
    console.warn('localStorage load error', e);
    return undefined;
  }
};

export const save = (item, value) => {
  try {
    const serializedState = JSON.stringify(value);

    localStorage.setItem(item, serializedState);
  } catch (e) {
    console.warn('localStorage save error', e);
  }
};
