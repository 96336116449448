import styled from 'styled-components';
import { IPaginationButtonProps } from 'components/Pagination/index';

export const PaginationButton = styled.button<IPaginationButtonProps>`
  width: 32px;
  height: 32px;
  padding: 0;
  text-align: center;

  color: inherit;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props): string => props.active ? props.theme.colors.orange : props.theme.colors.lightGrey};
  border-radius: 8px;
  background-color: white;

  cursor:pointer;

  &:hover {
    border-color: ${(props): string => props.active ? props.theme.colors.orange : props.theme.colors.darkGrey};
  }
`;

export const PaginationButtons = styled.div<{ disabled: boolean }>`
  color: ${(props): string => props.disabled ? props.theme.colors.lightGrey : props.theme.colors.orange};

  width: 100%;

  & > ${PaginationButton}:not(:last-of-type) {
    margin-right: 8px;
  }
`;

