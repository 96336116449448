import React, { HTMLAttributes } from 'react';
import {
  PageTitleWrapper,
  PageSubTitle,
  H1,
  H2,
  H3,
  H4,
  BigTitle,
  H4Small,
} from 'uiComponents/Titles/styles';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

interface PageTitleProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
  subTitle?: string;
}

export const TitleWithSub: React.FC<PageTitleProps> = ({
  title,
  subTitle,
  subTitleColor,
  className,
}) => (
  <PageTitleWrapper className={className}>
    <H2>{title}</H2>
    {subTitle && (
      <PageSubTitle
        color={subTitleColor}
        dangerouslySetInnerHTML={{ __html: subTitle }}
      />
    )}
  </PageTitleWrapper>
);

export const PageTitle = styled(TitleWithSub)`
  margin-bottom: 102px;
  ${media.md`
      margin-bottom: 60px;
  `}
  ${media.xl`
      margin-bottom: 95px;
  `}
`;

export {
  H1,
  H2,
  H3,
  H4,
  BigTitle,
  H4Small,
};
