import styled from 'styled-components';

export const SuccessModalTitle = styled.div`
  margin-bottom: 18px;

  font-size: 32px;
  font-weight: bold;
  line-height: 39px;
`;

export const SuccessModalDescription = styled.div`
  font-size: 16px;
  line-height: 19px;
`;

export const SuccessModalContainer = styled.div`
  display: flex;

  flex-direction: column;
  justify-content: space-between;

  height: 100%;
`;

