import React, { Children } from 'react';
import { StyledMainLayout } from './styled';
import { Header } from 'components/layouts/MainLayout/Header/Header';
import { Footer } from 'components/layouts/MainLayout/Footer/Footer';

export const MainLayout = (props) => {
  const {
    children,
    breadcrumbText,
    path,
  } = props;

  return <>
    <Header />
    <StyledMainLayout>
      {Children.map(children, (child) => React.cloneElement(child, { breadcrumbText, path }), null)}
    </StyledMainLayout>
    <Footer />
  </>;
};
