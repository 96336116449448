import React from 'react';
import Icon from 'uiComponents/Icon';
import { StyledButton } from './index';

export const Button = ({
  isLoading,
  children,
  ...styledProps
}) => (
  <StyledButton
    {...styledProps}
    isLoading={isLoading}
  >
    {isLoading && (
      <Icon
        name="circular-preloader"
        className="circular-preloader"
      />
    )}
    {!isLoading && children}
  </StyledButton>
);
