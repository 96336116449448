import React, { useState } from 'react';
import * as styles from './ExpenseListStyles';
import arrow from 'assets/images/arrowDown.png';
import { divideNumberIntoDigits } from 'helpers/divideNumberIntoDigits';
import { Link } from 'react-router-dom';

export const ExpenseTable = (props) => {
  const { month: {
    month,
    totalValue,
    costs,
  } } = props;

  const [ isActive, setActive ] = useState(false);

  return (
    <styles.ExpenseListTable
      isShow={isActive}
    >
      <styles.ExpenseListTableHead>
        <styles.ExpenseListTableHeadItem onClick={() => setActive(!isActive)}>
          <img
            src={arrow}
            alt={month}
          />{month}
        </styles.ExpenseListTableHeadItem>

        <styles.ExpenseListTableHeadItem>
          {divideNumberIntoDigits(totalValue)} руб
        </styles.ExpenseListTableHeadItem>
      </styles.ExpenseListTableHead>

      {costs.map((rowItem) => (
        <styles.ExpenseListTableRow key={rowItem.surveyId}>
          <styles.ExpenseListTableCell>
            {`#${rowItem.surveyId}`}
          </styles.ExpenseListTableCell>
          <styles.ExpenseListTableCell>
            <Link to={`/surveys/${rowItem.surveyId}`}>
              {rowItem.name}
            </Link>
          </styles.ExpenseListTableCell>
          <styles.ExpenseListTableCell>
            {divideNumberIntoDigits(rowItem.value)} руб
          </styles.ExpenseListTableCell>
        </styles.ExpenseListTableRow>
      ))}
    </styles.ExpenseListTable>
  );
};
