import React, { useState, useEffect, useRef } from 'react';

import { getFormattedDate } from 'helpers/date';

import Icon from 'uiComponents/Icon';

import DatePicker from './DatePicker';

import {
  StyledRangeDatePicker,
} from 'components/RangeDatePicker/styled';

export const RangeDateInput = ({
  name,
  placeholder,
  changePeriodActiveId,
  changePresetDayPicker,
  changeDatePickerRange,
  changeYearMonth,
  resetDatePicker,
  datePickerState,
}) => {
  const styledRangeDatePickerRef = useRef(null);

  const [ isVisibleCalendar, setVisibleCalendar ] = useState(false);

  const handleClickInputField = () => setVisibleCalendar((prevState) => !prevState);

  const handleClickPageBody = (e) => {
    if (
      styledRangeDatePickerRef.current
      && styledRangeDatePickerRef.current.contains(e.target)
    ) {
      return false;
    }

    return setVisibleCalendar(false);
  };

  useEffect(() => {
    document.body.addEventListener('click', handleClickPageBody);

    return () => {
      document.body.removeEventListener('click', handleClickPageBody);
    };
  }, []);

  return (
    <StyledRangeDatePicker ref={styledRangeDatePickerRef}>
      <input
        onClick={handleClickInputField}
        type="text"
        value={getFormattedDate(datePickerState, placeholder)}
        className="input-field"
        readOnly
      />
      <Icon
        className="input-icon"
        fill="#d1d1d1"
        name="calendar"
      />
      <DatePicker
        name={name}
        changePeriodActiveId={changePeriodActiveId}
        isVisibleCalendar={isVisibleCalendar}
        datePickerState={datePickerState}
        changePresetDayPicker={changePresetDayPicker}
        changeDatePickerRange={changeDatePickerRange}
        changeYearMonth={changeYearMonth}
        resetDatePicker={resetDatePicker}
        setVisibleCalendar={setVisibleCalendar}
      />
    </StyledRangeDatePicker>
  );
};

export default RangeDateInput;
