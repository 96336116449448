import styled from 'styled-components';

import { Container } from 'styled-bootstrap-grid';
import { EmptyListContainer } from 'components/EmptyList/styles';

export const StyledSurveysListPage = styled(Container)`
  ${EmptyListContainer} {
    margin-bottom: 60px;
  }
`;
