/* eslint @typescript-eslint/no-unused-vars: 0 */
/* eslint no-unused-vars: 0 */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { authSelector } from 'store/app/selectors';

export const ProtectedRoute = (props) => {
  const {
    children,
    layout: Layout,
    ...restProps
  } = props;

  const isAuthenticated = useSelector(authSelector);

  return (
    <Route
      {...restProps}
      render={(renderProps) => {
        if (isAuthenticated) {
          return <Layout {...restProps}>
            {restProps.render()}
          </Layout>;
        }

        return (
          <Redirect
            to={{
              pathname: '/sign-in',
              state: {
                from: renderProps.location,
                fromName: restProps.name,
              },
            }}
          />
        );
      }}
    />
  );
};
