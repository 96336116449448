import React from 'react';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import { ContainerBreadcrumbs } from 'components/Breadcrumbs';
import { PasswordResetPageWrapper } from 'pages/PasswordResetPage/styles';
import PasswordResetPageForm from 'components/PasswordResetPageForm';
import { PageTitle } from 'uiComponents/Titles';
import { usePreloader } from 'hooks/usePreloader';
import { USER_PASSWORD_RESET_REQUEST } from 'api/requestNames';
import { getBreadcrumbs } from 'components/Breadcrumbs/index.jsx';

const PasswordResetPage = (props) => {
  const breadcrumbs = getBreadcrumbs([ { breadcrumbText: props.breadcrumbText, breadcrumbLink: props.path } ]);

  const [ isStartPasswordReset ] = usePreloader(USER_PASSWORD_RESET_REQUEST, {
    initialState: false,
  });

  return (
    <PasswordResetPageWrapper>
      <Container>
        <Row>
          <Col>
            <ContainerBreadcrumbs breadcrumbs={breadcrumbs} />
          </Col>
        </Row>
        <Row>
          <Col>
            <PageTitle title={'Ввод нового пароля'} />
          </Col>
        </Row>
        <Row>
          <Col>
            <PasswordResetPageForm isStartPasswordReset={isStartPasswordReset} />
          </Col>
        </Row>
      </Container>
    </PasswordResetPageWrapper>
  );
};

export default PasswordResetPage;
