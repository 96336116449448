import styled from 'styled-components';
import { TitleWithSub } from 'uiComponents/Titles';
import { media } from 'styled-bootstrap-grid';

export const RegistrationPageTitle = styled(TitleWithSub)`
  margin-bottom: 102px;

  ${media.md`
      margin-bottom: 96px;
  `}
`;
