import { store } from '../appEntry';
import { signOut } from 'store/user/actions';
import * as appActions from 'store/app/actions';

function setRequestNames(requestName, addToRequestNameList = true, isGlobalLoading = true) {
  const {
    dispatch,
  } = store;

  if (addToRequestNameList && isGlobalLoading) {
    dispatch(appActions.setRequestName({
      [requestName]: { isGlobalLoading },
    }));
  }
}

function removeRequestNames(requestName) {
  const {
    dispatch,
    getState,
  } = store;

  const newRequestNames = { ...getState().app.requestNames };
  delete newRequestNames[requestName];

  dispatch(appActions.removeRequestName(newRequestNames));
}

function requestStatusHandler(response) {
  const {
    dispatch,
  } = store;

  const {
    status,
    message,
  } = response;

  if (status === 401 || status === 403) {
    dispatch(signOut('/sign-in'));

    return Promise.reject(message);
  }

  return response;
}

function request(url, options) {
  const REQUEST_DEFAULT_OPTIONS = {
    method: 'GET',
    contentType: 'application/json',
    body: {},
  };

  const {
    contentType,
    method,
    body,
    requestName,
    isGlobalLoading,
    addToRequestNameList,
  } = { ...REQUEST_DEFAULT_OPTIONS, ...options };

  const fetchConfig = {
    headers: {},
    method,
  };

  if (contentType) {
    fetchConfig.headers['Content-Type'] = contentType;
  }

  if (method !== 'GET') {
    fetchConfig.body = body;
  }

  setRequestNames(requestName, addToRequestNameList, isGlobalLoading);

  return fetch(url, fetchConfig)
    .then(requestStatusHandler)
    .then((response) => response.json())
    .then((data) => {
      removeRequestNames(requestName);
      return data;
    });
}

export default request;
