import produce from 'immer';

import * as solutionTypes from 'store/solution/types';

import { load } from '../../localStorage';

export const initialState = {
  items: [],
  solutionData: {
    id: null,
    name: null,
    details: {
      basePrice: null,
      audiences: [],
      geographies: [],
    },
  },
  solutionCreateData: {
    isSubmitted: false,
    namesFieldUploadingFiles: [],
  },
  surveyData: {
    files: [],
    additionalQuestions: [],
    id: null,
    name: '',
    status: {},
    statuses: [],
    solution: {},
    createdDate: '',
    completedDate: '',
    price: '',
    description: '',
    audience: {},
    geography: {},
    companyTurnover: {},
    reportFileId: null,
    reportUploadDate: null,
    manager: null,
    managers: [],
    uploadingFile: false,
    healthMeasuringCount: null,
    healthMeasuringPeriod: null,
    names: [],
  },
  surveysList: {
    filters: {
      page: 1,
      count: 50,
      companyName: load('filterData').companyName || null,
      solutionId: load('filterData').solutionId || null,
      managerId: load('filterData').managerId || null,
      statusId: load('filterData').statusId || null,
      search: '',
      createdDateFrom: load('filterData').createdDateFrom || null,
      createdDateTo: load('filterData').createdDateTo || null,
      completedDateFrom: load('filterData').completedDateFrom || null,
      completedDateTo: load('filterData').completedDateTo || null,
    },
    companyNames: [],
    solutionList: [],
    total: null,
    items: [],
    totalFound: null,
    solutionsSuccessModalShow: false,
  },
  settings: {
    examplesSurveyType: null,
  },
  filterData: localStorage.getItem('filterData') || null,
};

const SolutionReducer = produce((state = initialState, action) => {
  switch (action.type) {

    case solutionTypes.SET_SOLUTION_DETAILS: {
      state.solutionData.details = action.payload.details;
      state.solutionData.id = action.payload.solutionData.solutionId;
      state.solutionData.name = action.payload.solutionData.solutionName;
      return state;
    }

    case solutionTypes.RESET_SOLUTION_CREATE_DATA:
      state.solutionCreateData = initialState.solutionCreateData;
      return state;

    case solutionTypes.UPDATE_SOLUTION_CREATE_DATA: {
      state.solutionCreateData = {
        ...state.solutionCreateData,
        ...action.payload,
      };
      return state;
    }

    case solutionTypes.SET_SUBMITTED_SOLUTION_SUCCESS: {
      state.solutionsSuccessModalShow = action.payload;
      return state;
    }

    case solutionTypes.UPDATE_FIELD_NAME_UPLOAD_FILES: {
      state.solutionCreateData = {
        ...state.solutionCreateData,
        namesFieldUploadingFiles: action.payload,
      };
      return state;
    }

    case solutionTypes.SET_SUBMITTED_SOLUTION_DATA:
      state.solutionCreateData.isSubmitted = action.payload;
      return state;

    case solutionTypes.UPDATE_SURVEY_DATA: {
      state.surveyData = {
        ...state.surveyData,
        ...action.payload,
      };
      return state;
    }

    case solutionTypes.SET_UPLOADING_REPORT_FILE: {
      state.surveyData.uploadingFile = action.payload;
      return state;
    }

    case solutionTypes.UPDATE_SOLUTION_DATA: {
      state.solutionData = {
        ...state.solutionData,
        ...action.payload,
      };
      return state;
    }

    case solutionTypes.UPDATE_SURVEY_LIST:
      state.surveysList = {
        ...state.surveysList,
        ...action.payload,
      };
      return state;

    case solutionTypes.SET_SURVEY_LIST: {
      state.surveysList.items = action.payload.items;
      state.surveysList.total = action.payload.total;
      state.surveysList.totalFound = action.payload.totalFound;
      return state;
    }

    case solutionTypes.SET_SURVEY_LIST_FILTER: {
      state.surveysList.filters = {
        ...state.surveysList.filters,
        ...action.payload.filterData,
      };

      localStorage.setItem('filterData', JSON.stringify(state.surveysList.filters));

      return state;
    }

    case solutionTypes.UPLOAD_FILE_SOLUTION_DATA_SUCCESS: {
      const {
        name,
        fileIds,
      } = action.payload;

      state.solutionCreateData[name] = fileIds;
      return state;
    }

    case solutionTypes.DELETE_FILE_SOLUTION_DATA: {
      const {
        name,
        fileIds,
      } = action.payload;

      state.solutionCreateData[name] = fileIds;
      return state;
    }

    case solutionTypes.GET_COMPANY_NAMES_SUCCESS: {
      state.surveysList.companyNames = action.payload.companies;
      return state;
    }

    case solutionTypes.SET_SOLUTION_SETTING_EXAMPLE_TYPE: {
      state.settings.examplesSurveyType = action.payload.type;
      return state;
    }
    default: return state;
  }
});

export default SolutionReducer;

