import styled from 'styled-components';

export const ConsultationTextBlock = styled.div`
  margin-bottom: 62px;

  font-size: 16px;
  line-height: 22px;
`;

export const ConsultationSubTitle = styled.div`
  margin-top: -30px;
  margin-bottom: 49px;

  color: #2E2E2E;
`;

export const ConsultationFormBlock = styled.div`
  margin-bottom: 43px;
`;

