import styled from 'styled-components';
import Select from 'react-select';

export const StyledSelect = styled(Select)`
   .indicator-icon {
     margin-right: 15px;

     width: 13px;
     height: auto;
   }
`;
