import React, { useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Container } from 'styled-bootstrap-grid';

import * as appSelectors from 'store/app/selectors';
import * as userSelectors from 'store/user/selectors';

import * as userActions from 'store/user/actions';

import {
  StyledLogo,
} from 'components/layouts/MainLayout/Header/styled';

import { CabinetLinks } from 'components/layouts/MainLayout/Header/CabinetLinks';

import { MenuItems } from 'components/layouts/MainLayout/Header/MenuItems';

import { MenuSwitchTitle } from 'components/layouts/MainLayout/Header/MenuSwitchTitle';

import {
  HeaderMenuHead,
  HeaderMobileMenuButton, HeaderMobileMenuClose,
  HeaderMobileMenuWrapper, StyledMobileCreateSurveyLink, StyledMobileSignOutLink,
} from 'components/layouts/MainLayout/Header/HeaderMobileMenu/styled';

export const HeaderMobileMenu = ({ menuItems }) => {
  const dispatch = useDispatch();

  const isAuthenticated = useSelector(appSelectors.authSelector);

  const isManager = useSelector(userSelectors.isManager);

  const [ isOpen, setOpenStatus ] = useState(false);

  const [ isShowUserMenu, setIsShowUserMenu ] = useState(false);

  const menu = useRef(null);

  const menuContainer = useRef(null);

  const handleClickInsideItem = ({ target }) => {
    if (target !== menuContainer.current
      && target !== menu.current
      && target.closest('div') === menuContainer.current
    ) {
      setOpenStatus(false);
    }
  };

  const handleClickSignOutButton = () => {
    dispatch(userActions.signOut('/'));
  };

  useEffect(() => {
    document.querySelector('body').style.overflow = isOpen ? 'hidden' : '';
  }, [ isOpen ]);

  useEffect(() => {
    document.addEventListener('click', handleClickInsideItem);

    return () => document.removeEventListener('click', handleClickInsideItem);
  }, [ menu ]);

  const handleClickMenuSwitchTitle = () => {
    setIsShowUserMenu((prevState) => !prevState);
  };

  return (
    <>
      <HeaderMobileMenuButton
        isOpen={isOpen}
        onClick={() => setOpenStatus(!isOpen)}
      />
      <HeaderMobileMenuWrapper
        isOpen={isOpen}
        ref={menu}
        id={'mobileMenu'}
      >
        <Container ref={menuContainer}>
          <HeaderMenuHead>
            <StyledLogo to={'/'} />
            <HeaderMobileMenuClose
              onClick={() => setOpenStatus(!isOpen)}
              isOpen={isOpen}
            />
          </HeaderMenuHead>

          {!isAuthenticated && (
            <CabinetLinks />
          )}

          {(isAuthenticated && !isManager) && (
            <StyledMobileCreateSurveyLink to="/create">
              + Создать опрос
            </StyledMobileCreateSurveyLink>
          )}

          <MenuSwitchTitle
            isShowUserMenu={isShowUserMenu}
            handleClickMenuSwitchTitle={handleClickMenuSwitchTitle}
          />

          <MenuItems
            menuItems={menuItems}
            isShowUserMenu={isShowUserMenu}
            handleClickMobileLink={() => setOpenStatus(!isOpen)}
          />

          {isAuthenticated && (
            <StyledMobileSignOutLink onClick={handleClickSignOutButton}>
              Выход
            </StyledMobileSignOutLink>
          )}
        </Container>
      </HeaderMobileMenuWrapper>
    </>
  );
};
