import * as appTypes from 'store/app/types';

export function auth(isAuth) {
  return {
    type: appTypes.AUTH,
    payload: isAuth ? isAuth : false,
  };
}

export function setRequestName(name) {
  return {
    type: appTypes.SET_REQUEST_NAME,
    payload: name,
  };
}

export function removeRequestName(name) {
  return {
    type: appTypes.REMOVE_REQUEST_NAME,
    payload: name,
  };
}
