import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'styled-bootstrap-grid';

import {
  SURVEY_GET_MANAGERS,
  SURVEY_GET_SOLUTION_LIST, SURVEY_GET_STATUSES,
  SURVEY_GET_SURVEY_LIST,
} from 'api/requestNames';

import { SurveysList } from 'components/SurveysList';

import { ContainerBreadcrumbs } from 'components/Breadcrumbs';

import FilterBlock from 'pages/SurveysListPage/FilterBlock/FilterBlock';

import * as solutionSelectors from 'store/solution/selectors';
import { isManager } from 'store/user/selectors';

import * as solutionActions from 'store/solution/actions';

import { SpecialVersionRow, SpecialVersionText } from 'components/ManagerOrderList/styles';

import { PagePreloader } from 'components/PagePreloader/PagePreloader';

import { usePreloader } from 'hooks/usePreloader';

import pcImage from 'assets/images/pcImage.';
import { getBreadcrumbs } from 'components/Breadcrumbs';

import { SearchBlock } from './SearchBlock';

import { StyledSurveysListPage } from 'pages/SurveysListPage/styled';
import * as solutionReducer from 'store/solution/reducer';

const SurveysListPage = (props) => {
  const dispatch = useDispatch();

  const isManagerRole = useSelector(isManager);

  const [ fetchingSurveyList ] = usePreloader(SURVEY_GET_SURVEY_LIST);
  const [ fetchingSolutionList ] = usePreloader(SURVEY_GET_SOLUTION_LIST);
  const [ fetchingManagers ] = usePreloader(SURVEY_GET_MANAGERS);
  const [ fetchingStatuses ] = usePreloader(SURVEY_GET_STATUSES);

  const surveysListTotal = useSelector(solutionSelectors.getSurveyListTotal);

  let authText;
  isManagerRole
    ? authText = 'Список заказов'
    : authText = 'Мои опросы';

  const breadcrumbs = getBreadcrumbs([ { breadcrumbText: authText, breadcrumbLink: props.path } ]);

  const hasSurveys = surveysListTotal !== 0;

  useEffect(() => {
    dispatch(solutionActions.fetchSurveyList());
    dispatch(solutionActions.getSolutionList());
    dispatch(solutionActions.getSurveyStatuses());

    if (isManagerRole) {
      dispatch(solutionActions.getCompanyNames());
      dispatch(solutionActions.getSurveyManagers());
    }

    return () => {
      dispatch(solutionActions.setSurveyListFilter({
        filterData: solutionReducer.initialState.surveysList.filters,
      }));
    };
  }, []);

  const loading = (
    fetchingSurveyList && fetchingSolutionList
    && fetchingManagers && fetchingStatuses
  );

  if (loading) {
    return <PagePreloader />;
  }

  return (
    <StyledSurveysListPage>
      <Row>
        <Col>
          <ContainerBreadcrumbs breadcrumbs={breadcrumbs} />
        </Col>
      </Row>

      {hasSurveys && (
        <SearchBlock />
      )}

      {hasSurveys && (
        <SpecialVersionRow>
          <Col
            xs={12}
          >
            <img
              src={pcImage}
              alt="Версия для компьютера"
            />
            <SpecialVersionText>Версия для компьютера</SpecialVersionText>
          </Col>
        </SpecialVersionRow>
      )}

      {isManagerRole && (
        <FilterBlock />
      )}

      <Row>
        <Col col={12}>
          <SurveysList isManager={isManagerRole} />
        </Col>
      </Row>
    </StyledSurveysListPage>
  );
};

export default SurveysListPage;
