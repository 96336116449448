import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import * as userActions from 'store/user/actions';

import { Container, Row, Col } from 'styled-bootstrap-grid';
import { ContainerBreadcrumbs } from 'components/Breadcrumbs';
import { ExpenseListContainer } from 'components/ExpenseList/ExpenseListStyles';
import ExpenseList from 'components/ExpenseList';
import { PagePreloader } from 'components/PagePreloader/PagePreloader';

import { usePreloader } from 'hooks/usePreloader';
import { USER_COST } from 'api/requestNames';
import { getBreadcrumbs } from 'components/Breadcrumbs/index.jsx';

const ExpenseListPage = (props) => {
  const dispatch = useDispatch();

  const breadcrumbs = getBreadcrumbs([ { breadcrumbText: props.breadcrumbText, breadcrumbLink: props.path } ]);

  const [ isDataCostLoaded ] = usePreloader(USER_COST);

  useEffect(() => {
    dispatch(userActions.fetchCostData());
  }, []);

  if (isDataCostLoaded) {
    return <PagePreloader />;
  }

  return (
    <ExpenseListContainer>
      <Container>
        <Row>
          <Col col={12}>
            <ContainerBreadcrumbs breadcrumbs={breadcrumbs} />
            <ExpenseList />
          </Col>
        </Row>
      </Container>
    </ExpenseListContainer>
  );
};

export default ExpenseListPage;
