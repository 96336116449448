import React from 'react';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import { PageTitle } from 'uiComponents/Titles';

export const MethodologyAIDAPage = () => (
  <Container>
    <Row>
      <Col col={12}>
        <PageTitle title="Методология" />
      </Col>
    </Row>
    <Row>
      <Col col={12}>
        <div className="block">
          <p className="text">
            <strong>Тестирование рекламы</strong> позволяет до запуска полномасштабной
            кампании оценить коммуникационную ценность и эффективность рекламного материала
          </p>

          <p className="text">
            Модель AIDA (Awareness Interest Desire Action), разработанная еще в 1898 году
            одним из основоположником рекламной индустрии Эльмо Левисом (Elmo Lewis).
          </p>

          <p className="text">
            Согласно модели, успешная реклама должна повышать знание о марке и продукте,
            вызывать интерес, создавать правильное и позитивное впечатление о продукте,
            вызывать желание использовать рекламируемый товар и рекомендовать его.
          </p>

          <p className="text">
            Модель AIDA изначально разрабатывалась для структуризации коммерческих переговоров,
            и мы считаем, что на <strong>корпоративном рынка, эта модель наилучшим образом соответствует
            процессу принятия решений</strong> и позволяет объективно оценить
            эффективность рекламных материалов.
          </p>
        </div>

        <div className="block">
          <p className="text">В <strong>модели</strong> используются следующие показатели:</p>

          <div className="MethodologyTable">
            <div className="MethodologyRow">
              <h4 className="subtitle">Внимание</h4>

              <p className="text">
                Насколько реклама выделяется из общего потока сообщений и привлекает внимание к продукту?
              </p>
            </div>
            <div className="MethodologyRow">
              <h4 className="subtitle">Интерес</h4>

              <p className="text">
                Насколько реклама интересна и привлекательна?
              </p>
            </div>
            <div className="MethodologyRow">
              <h4 className="subtitle">Вовлеченность</h4>

              <p className="text">
                Насколько реклама вызывает желание узнать больше о продукте, насколько ей можно доверять?
              </p>
            </div>
            <div className="MethodologyRow">
              <h4 className="subtitle">Действие</h4>

              <p className="text">
                Насколько реклама вызывает желание использовать и рекомендовать продукт?
              </p>
            </div>

          </div>
        </div>

        <div className="block">
          <p className="text">
            <strong>Качественная диагностика </strong>позволяет разрабатывать рекомендаций
            по улучшению рекламного материала. Участникам исследования задается вопросы
            о качестве передачи идеи и отдельных привлекательных и неприятных моментах рекламы:
          </p>

          <ul className="list">
            <li className="list__item">Какова была основная идея этой рекламы?</li>
            <li className="list__item">Что Вам особенно понравилось в этой рекламе?</li>
            <li className="list__item">Что Вам не понравилось в этой рекламе?</li>
          </ul>
          <p className="text">
            Ответы на эти вопросы позволяют компаниям определить насколько правильно
            и эффективно переданы сообщения, запланированные в рекламной кампании
            и что можно сделать, чтобы улучшить рекламный материал.
          </p>

        </div>
      </Col>
    </Row>
  </Container>
);
