import { HashLink } from 'react-router-hash-link';
import React from 'react';

export const CustomHashLink = (props) => {
  const {
    arrow,
    arrowPosition,
    borderTop,
    borderBottom,
    isCreatePage,
    ...restProps
  } = props;

  return <HashLink {...restProps} />;
};
