import React from 'react';

import {
  InfoItem,
  InfoItemTextGray,
  InfoItemTitle,
  SurveyInfoWrapper,
  InfoItemIndependentBlock,
  InfoItemIndependentInfoBlock,
} from 'components/SurveyInfo/styles';

const ConsultationSolutionInfo = () => (
  <SurveyInfoWrapper>
    <InfoItem
      col={12}
      md={6}
      lg={10}
      lgOffset={2}
      mobileFullSize
    >
      <InfoItemTitle>C кем я буду говорить?</InfoItemTitle>
      <InfoItemIndependentBlock>
        <InfoItemIndependentInfoBlock>
          <InfoItemTextGray>
            В&nbsp;зависимости от темы и&nbsp;задачи проекта, с&nbsp;вами свяжется один из ведущих
            специалистов компании, тот, кто лучше всего знает тему и индустрию.
          </InfoItemTextGray>
          <InfoItemTextGray>
            С вами будет говорить человек с&nbsp;опытом работы не менее
            десяти лет и практическими знаниями, полученными в&nbsp;проведении сотен и
            сотен исследовательских проектов.
          </InfoItemTextGray>
        </InfoItemIndependentInfoBlock>
      </InfoItemIndependentBlock>
    </InfoItem>
  </SurveyInfoWrapper>
);

export default ConsultationSolutionInfo;
