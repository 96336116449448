import * as dayjs from 'dayjs';

import NPSForm from 'components/SurveySetup/forms/NPS';
import ACSIForm from 'components/SurveySetup/forms/ACSI';
import AIDAForm from 'components/SurveySetup/forms/AIDA';
import BHTForm from 'components/SurveySetup/forms/BHT';
import BrandForm from 'components/SurveySetup/forms/Brand';
import ConceptForm from 'components/SurveySetup/forms/Concept';
import CRForm from 'components/SurveySetup/forms/CR';
import ISOForm from 'components/SurveySetup/forms/ISO';
import NameForm from 'components/SurveySetup/forms/Name';
import TenQuestionsForm from 'components/SurveySetup/forms/10-Questions';
import FifteenQuestionsForm from 'components/SurveySetup/forms/15-Questions';
import RangingForm from 'components/SurveySetup/forms/Ranging';
import ConsultationForm from 'components/SurveySetup/forms/Consultation';

import { surveyNPSCreateRules } from 'services/validation/rules';
import { surveyACSICreateRules } from 'services/validation/rules';
import { surveyAIDACreateRules } from 'services/validation/rules';
import { surveyBHTCreateRules } from 'services/validation/rules';
import { surveyBrandCreateRules } from 'services/validation/rules';
import { surveyConceptCreateRules } from 'services/validation/rules';
import { surveyCRCreateRules } from 'services/validation/rules';
import { surveyISOCreateRules } from 'services/validation/rules';
import { surveyNameCreateRules } from 'services/validation/rules';
import { surveyQuestionsCreateRules } from 'services/validation/rules';
import { surveyRangingCreateRules } from 'services/validation/rules';
import { surveyConsultationCreateRules } from 'services/validation/rules';

import { HowDoWeDoItNPSPage } from 'pages/how-do-we-do-it/pages/NPS';
import { HowDoWeDoItACSIPage } from 'pages/how-do-we-do-it/pages/ACSI';
import { HowDoWeDoItAIDAPage } from 'pages/how-do-we-do-it/pages/AIDA';
import { HowDoWeDoItBHTPage } from 'pages/how-do-we-do-it/pages/BHT';
import { HowDoWeDoItBrandPage } from 'pages/how-do-we-do-it/pages/Brand';
import { HowDoWeDoItConceptPage } from 'pages/how-do-we-do-it/pages/Concept';
import { HowDoWeDoItCRPage } from 'pages/how-do-we-do-it/pages/CR';
import { HowDoWeDoItISOPage } from 'pages/how-do-we-do-it/pages/ISO';
import { HowDoWeDoItNamePage } from 'pages/how-do-we-do-it/pages/Name';
import { HowDoWeDoItDIYPage } from 'pages/how-do-we-do-it/pages/DIY';
import { HowDoWeDoItDIYExtendedPage } from 'pages/how-do-we-do-it/pages/DIYExtended';

import { MethodologyNPSPage } from 'pages/methodology-pages/pages/NPS';
import { MethodologyACSIPage } from 'pages/methodology-pages/pages/ACSI';
import { MethodologyAIDAPage } from 'pages/methodology-pages/pages/AIDA';
import { MethodologyBHTPage } from 'pages/methodology-pages/pages/BHT';
import { MethodologyBrandPage } from 'pages/methodology-pages/pages/Brand';
import { MethodologyConceptPage } from 'pages/methodology-pages/pages/Concept';
import { MethodologyCRPage } from 'pages/methodology-pages/pages/CR';
import { MethodologyISOPage } from 'pages/methodology-pages/pages/ISO';
import { MethodologyNamePage } from 'pages/methodology-pages/pages/Name';

import ConsultationSolutionInfo from 'components/SurveySetup/forms/Consultation/SolutionInfo';

import { TYPES } from 'constants/questions';
import { prepareConsultationNameString } from 'helpers/solutions';
import { defaultRules } from 'services/validation/rules';

import { fromMonth } from 'components/RangeDatePicker/DatePicker';

export const solutionsConfig = [
  {
    name: 'NPS',
    title: 'NPS. Лояльность клиентов',
    subtitle: `
    Исследование лояльности клиентов с помощью индекса NPS (Net Promoter Score).
    `,
    Form: NPSForm,
    createSurveyRules: surveyNPSCreateRules,
    HowDoWeDoItPage: HowDoWeDoItNPSPage,
    questionsArray: [
      'Насколько вероятно, что Вы порекомендуете компанию другим компаниям или коллегам для решения их бизнес-задач?',
      'Почему Вы так считаете?',
      'Если бы вы могли изменить что-либо одно в работе компании, что бы вы сделали?',
      'Оцените свою удовлетворенность работой компании по следующим характеристикам.',
    ],
    MethodologyPage: MethodologyNPSPage,
    initialFormState: {
      fileIds: {},
      targetCompanyName: '',
      period: '',
      audienceId: null,
      geographyId: null,
    },
  },
  {
    name: 'ACSI',
    title: 'ACSI. Удовлетворенность и лояльность клиентов',
    subtitle: `
    Исследование удовлетворенности и лояльности клиентов
    с помощью индекса ACSI(American Customer Satisfaction Index).
    `,
    Form: ACSIForm,
    createSurveyRules: surveyACSICreateRules,
    HowDoWeDoItPage: HowDoWeDoItACSIPage,
    questionsArray: [
      'Насколько Вы в целом удовлетворены сотрудничеством с компанией?',
      'Насколько сотрудничество с компанией соответствовало Вашим ожиданиям?',
      'Насколько компания по Вашему мнению лучше или хуже, чем конкуренты?',
      'Если бы Вы могли изменить что-либо одно в работе компании, что бы Вы сделали?',
      'Оцените свою удовлетворенность работой компании по следующим характеристикам.',
    ],
    MethodologyPage: MethodologyACSIPage,
    initialFormState: {
      fileIds: {},
      targetCompanyName: '',
      period: '',
      audienceId: null,
      geographyId: null,
    },
  },
  {
    name: 'AIDA',
    title: 'Тестирование рекламы',
    subtitle: `
    Тестирование готового рекламного ролика
    или аниматика до запуска полномасштабной рекламной компании.
    `,
    Form: AIDAForm,
    createSurveyRules: surveyAIDACreateRules,
    HowDoWeDoItPage: HowDoWeDoItAIDAPage,
    questionsArray: [
      'Скажите, пожалуйста, какая марка рекламировалась в ролике, который вы только что видели?',
      'Какое из этих высказываний больше всего соответствует этому ролику?',
      'Какова была основная идея этой рекламы?',
      'На Ваш взгляд, насколько эта реклама отличается от тех, которые вы видели ранее?',
      'Скажите, пожалуйста, насколько Вам понятна эта реклама?',
      'Скажите, пожалуйста, насколько эта реклама вызвала у Вас интерес?',
      'Насколько, в целом, Вам нравится или не нравится эта реклама?',
      'Что Вам особенно <span>понравилось</span> в этой рекламе?',
      'Что Вам <span>не понравилось</span> в этой рекламе?',
      'Насколько то, о чем рассказывает реклама, актуально и соответствует потребностям вашей компании?',
      'Насколько это видео вызывает доверие?',
      'Насколько вероятно, что Вы бы захотели узнать больше об этом предложении,' +
      'получить о нем дополнительную информацию?',
      'Основываясь на том, что Вы увидели, скажите, пожалуйста, ' +
      'насколько вероятно, что Вы будете использовать этот продукт в своей компании?',
      'Вы бы рассказали об этом видео своим друзьям, если бы увидели ролик в реальной жизни?',
    ],
    MethodologyPage: MethodologyAIDAPage,
    initialFormState: {
      fileIds: {},
      productDescription: '',
      targetCompanyName: '',
      audienceId: null,
      geographyId: null,
    },
  },
  {
    name: 'BHT',
    title: 'Мониторинг здоровья марки (BHT)',
    subtitle: `
    <strong>Периодический мониторинг</strong>
    основных составляющих
    <strong>здоровья (ценности) марки</strong>
    в конкурентном окружении –
    <strong>знания, использования, предпочтения, доверия,
    функционального превосходства и эмоциональной ценности</strong>,
    а также <strong>знания рекламной коммуникации компании</strong>.
    `,
    Form: BHTForm,
    createSurveyRules: surveyBHTCreateRules,
    HowDoWeDoItPage: HowDoWeDoItBHTPage,
    questionsArray: [
      'Какая марка продукта первой приходит Вам на память?',
      'Какие еще марки продукта Вы знаете?',
      'Какие из этих марок продукта вы знаете, слышали о них?',
      'Продукты каких марок Вы использовали за указанный период?',
      'Продукты каких марок Вы используете чаще остальных?',
      'А какие еще марки продукта Вы могли бы использовать вместо марки, которую Вы используете чаще остальных?',
      'Используя шкалу от 1 до 10, оцените, пожалуйста, насколько марка соответствует Вашим потребностям?',
      'Насколько «своей», близкой и понятной Вы считаете марку?',
      'Пожалуйста, для каждого утверждения выберите марки, которые в наибольшей степени ему соответствуют.',
      'Рекламу каких из этих марок продукта Вы видели за последние 4 недели?',
      'Где вы видели рекламу марки за последние 4 недели?',
      'Видели ли вы за последние 4 недели именно эту рекламу марки?',
    ],
    MethodologyPage: MethodologyBHTPage,
    initialFormState: {
      fileIds: {},
      productName: '',
      productDescription: '',
      period: '',
      audienceId: null,
      geographyId: null,
      labelKey: 'labelBHT',
      healthMeasuringCount: '',
      healthMeasuringPeriod: '',
    },
  },
  {
    name: 'Brand',
    title: 'Здоровье марки',
    subtitle: `
    Количественная оценка основных составляющих
     <strong>здоровья (ценности) марки</strong> в конкурентном окружении –
     <strong>знания, использования, предпочтения, доверия, функционального превосходства и имиджа.</strong>
    `,
    Form: BrandForm,
    createSurveyRules: surveyBrandCreateRules,
    HowDoWeDoItPage: HowDoWeDoItBrandPage,
    questionsArray: [
      'Какая марка продукта первой приходит Вам на память? ',
      'Какие еще марки продукта Вы знаете? ',
      'Какие из этих марок продукта вы знаете, слышали о них?',
      'Продукты каких марок Вы использовали за указанный период?',
      'Продукты каких марок Вы используете чаще остальных? ',
      'А какие еще марки продукта Вы могли бы использовать вместо марки, которую Вы используете чаще остальных?',
      'Используя шкалу от 1 до 10, оцените, пожалуйста, насколько марка соответствует Вашим потребностям?',
      'Насколько «своей», близкой и понятной Вы считаете марку?',
      'Пожалуйста, для каждого утверждения выберите марки, которые в наибольшей степени ему соответствуют.',
    ],
    MethodologyPage: MethodologyBrandPage,
    initialFormState: {
      fileIds: {},
      productName: '',
      productDescription: '',
      period: '',
      audienceId: null,
      geographyId: null,
    },
  },
  {
    name: 'Concept',
    title: 'Тестирование идеи / концепции',
    subtitle: `
    Тестирование на ранней стадии разработки
    <strong>идеи продукта</strong> (в виде текстового описания) или готовой
    <strong>концепции</strong>,
    включающей описание, изображение продукта и цену.
    `,
    Form: ConceptForm,
    createSurveyRules: surveyConceptCreateRules,
    HowDoWeDoItPage: HowDoWeDoItConceptPage,
    questionsArray: [
      'Скажите, пожалуйста, насколько Вам понятно это описание?',
      'Насколько вероятно, что Вы бы захотели узнать больше об этом предложении,' +
      'получить о нем дополнительную информацию?',
      'Основываясь на том, что Вы увидели и прочитали, скажите, пожалуйста, насколько вероятно, ' +
      'что Вы будете использовать этот продукт в своей компании?',
      'Насколько в целом Вам нравится это предложение?',
      'Что Вам особенно <span>нравится</span> в этом предложении?',
      'Что Вам <span>не нравится</span> в этом предложении?',
      'Принимая во внимание цену этого предложения, какое утверждение, на Ваш взгляд, ' +
      'лучше всего описывает соотношение «цена – качество» для него?',
      'На Ваш взгляд, насколько это предложение является новым, оригинальным, отличным от того, ' +
      'что есть сейчас предлагается на рынке?',
      'Насколько это предложение подходит Вам, отвечает потребностям и запросам Вашей компании?',
      'Насколько правдоподобным Вам кажется то, о чем говорится в описании этого предложения?',
      'На Ваш взгляд, как данное предложение отвечает Вашим потребностям и запросам по сравнению ' +
      'с другими предложениями рынка?',
      '(Опционально) Принимая во внимание все то, что Вы сегодня узнали об этом предложении, - достаточно ли ' +
      'Вам этой информации для того, чтобы решить, будете ли Вы рассматривать использование этого продукта или нет?',
      'Что Вы хотели бы еще узнать об этом предложении?',
    ],
    MethodologyPage: MethodologyConceptPage,
    initialFormState: {
      fileIds: {},
      additionalFileId: {},
      productDescription: '',
      concept: '',
      productPrice: '',
      audienceId: null,
      geographyId: null,
    },
  },
  {
    name: 'CR',
    title: 'Корпоративная репутация',
    subtitle: `
    Количественная <strong>оценка</strong> основных составляющих
    <strong>корпоративной репутации</strong> – знания, осведомленности,
    доверия, желания рекомендовать компанию, а также
    <strong>имиджа</strong> организации и ее коммуникационных активностей.
    `,
    Form: CRForm,
    createSurveyRules: surveyCRCreateRules,
    HowDoWeDoItPage: HowDoWeDoItCRPage,
    questionsArray: [
      'Мы покажем Вам названия нескольких компаний. Скажите, насколько Вы знаете каждую компанию?',
      'Что из перечисленного лучше всего описывает Ваше мнение о каждой компании в целом?',
      (
        'Принимая во внимание все факторы, которые Вам кажутся важными,'
          + ' <br class="questionnaire-wrap"> скажите, в какой степени '
          + 'Вы доверяете или не доверяете каждой из перечисленных компаний?'
      ),
      'Какое из перечисленных высказываний лучше всего описывает Ваше мнение и восприятие каждой компании в целом?',
      'Пожалуйста, для каждого утверждения выберите компании, которые в наибольшей степени ему соответствуют.',
      'Как бы Вы оценили качество информационных кампаний от каждой из них?',
      'Если бы Вы могли изменить что-либо одно в работе компании, что бы Вы сделали?',
    ],
    MethodologyPage: MethodologyCRPage,
    initialFormState: {
      fileIds: {},
      productDescription: '',
      targetCompanyName: '',
      audienceId: null,
      geographyId: null,
    },
  },
  {
    name: 'ISO',
    title: 'ISO 9001:2000: Удовлетворённость клиентов',
    subtitle: `
    Измерение <b>удовлетворенности клиентов в соответствии со стандартом ISO 9001:2000</b>.
    `,
    Form: ISOForm,
    createSurveyRules: surveyISOCreateRules,
    HowDoWeDoItPage: HowDoWeDoItISOPage,
    questionsArray: [
      'Оцените, пожалуйста, насколько для Вас важны следующие критерии в работе с компанией?',
      'Оцените свою удовлетворенность работой компании по следующим характеристикам',
      'Если бы Вы могли изменить что-либо одно в работе компании, что бы Вы сделали?',
    ],
    MethodologyPage: MethodologyISOPage,
    initialFormState: {
      fileIds: {},
      targetCompanyName: '',
      period: '',
      audienceId: null,
      geographyId: null,
    },
  },
  {
    name: 'Name',
    title: 'Тестирование названий',
    subtitle: `
    Выбор из нескольких вариантов наиболее подходящего названия для продукта,
    услуги, мероприятия или коммуникационной компании.
    `,
    Form: NameForm,
    createSurveyRules: surveyNameCreateRules,
    HowDoWeDoItPage: HowDoWeDoItNamePage,
    questionsArray: [
      'Насколько легко или сложно запомнить это название?',
      'Насколько легко или сложно произносить это название?',
      'Скажите, пожалуйста, насколько это название приятно звучит?',
      'Скажите, пожалуйста, насколько Вам в целом нравится или не нравится это название?',
      'Что Вам особенно <span>нравится</span> в этом названии?',
      'Что Вам <span>не нравится</span> в этом названии?',
      'По Вашему мнению, насколько это название подходит для категории?',
    ],
    MethodologyPage: MethodologyNamePage,
    initialFormState: {
      fileIds: {},
      productName: '',
      audienceId: null,
      geographyId: null,
    },
  },
  {
    name: 'QUESTIONS10',
    title: '10 вопросов',
    subtitle: `
    Задайте до десяти произвольных вопросов вашим потенциальным клиентам
    `,
    Form: TenQuestionsForm,
    createSurveyRules: surveyQuestionsCreateRules,
    HowDoWeDoItPage: HowDoWeDoItDIYPage,
    questionsArray: null,
    MethodologyPage: null,
    initialFormState: {
      questions: [],
      audienceId: null,
      geographyId: null,
    },
  },
  {
    name: 'QUESTIONS15',
    title: '15 вопросов',
    subtitle: `
    Задайте до пятнадцати произвольных вопросов вашим потенциальным клиентам
    `,
    Form: FifteenQuestionsForm,
    createSurveyRules: surveyQuestionsCreateRules,
    HowDoWeDoItPage: HowDoWeDoItDIYExtendedPage,
    questionsArray: null,
    MethodologyPage: null,
    initialFormState: {
      questions: [],
      audienceId: null,
      geographyId: null,
    },
  },
  {
    name: 'Ranging',
    title: 'Ранжирование',
    subtitle: `
    Определение самых перспективных направлений и трендов на рынке
    `,
    Form: RangingForm,
    filesValidation: defaultRules.emptyObject,
    createSurveyRules: surveyRangingCreateRules,
    HowDoWeDoItPage: null,
    questionsArray: null,
    MethodologyPage: null,
    initialFormState: {
      checkedCharacteristics: [
        { id: 'attractive ', text: 'Привлекательность', checked: false },
        { id: 'unique ', text: 'Уникальность', checked: false },
        { id: 'intention ', text: 'Намерение использовать', checked: false },
      ],
      rankingType: TYPES.text,
      rankingItems: [],
      fileIds: {},
      audienceId: null,
      geographyId: null,
    },
  },
  {
    name: 'Consultation',
    title: 'Консультация',
    nameLabel: 'Детали консультации',
    nameValueGenerator: prepareConsultationNameString,
    subtitle: null,
    Form: ConsultationForm,
    createSurveyRules: surveyConsultationCreateRules,
    byPassAgreements: [
      'agreementCheckbox2',
      'agreementCheckbox3',
    ],
    HowDoWeDoItPage: null,
    questionsArray: null,
    MethodologyPage: null,
    customSolutionInfo: ConsultationSolutionInfo,
    scheduleList: [
      '10:00',
      '11:00',
      '12:00',
      '13:00',
      '14:00',
      '15:00',
      '16:00',
      '17:00',
    ],
    initialFormState: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      consultationTheme: '',
      consultationTime: '',
      consultationDateTime: '',
      dateState: {
        month: fromMonth,
        currentDate: new Date(dayjs().add(1, 'day').toISOString()),
        value: null,
      },
      fileIds: {},
    },
  },
];
