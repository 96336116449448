import React from 'react';
import PropTypes from 'prop-types';
import { SolutionBlockWrapper } from 'components/SolutionsList/styled';

const SolutionBlock = ({ solution }) => (
  <SolutionBlockWrapper>
    <span>{solution.name}</span>
  </SolutionBlockWrapper>
);

SolutionBlock.propTypes = {
  solution: PropTypes.object,
};

export default SolutionBlock;
