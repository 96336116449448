import React from 'react';
import { useSelector } from 'react-redux';

import * as userSelector from 'store/user/selectors';

import * as styles from 'components/ExpenseList/ExpenseListStyles';
import * as titles from 'uiComponents/Titles';
// import download from 'assets/images/download.png';
import { ExpenseTable } from 'components/ExpenseList/ExpenseTable';
import { divideNumberIntoDigits } from 'helpers/divideNumberIntoDigits';
import { EmptyList } from 'components/EmptyList';

const ExpenseList = () => {
  const { yearCosts } = useSelector(userSelector.costsDataSelector);

  if (!yearCosts.length) {
    return (
      <EmptyList
        title={'Список расходов пуст'}
        text={'На странице появятся данные по опросам, когда хоть один их них будет в статусе Исполнен'}
      />
    );
  }

  return (
    <>
      <styles.ExpenseListTitle>
        <titles.H2>
          Список расходов
        </titles.H2>
        {/* <a
          href=""
          download
        >
          <img
            src={download}
            alt="Скачать"
          />
          Скачать перечень расходов
        </a>*/}
      </styles.ExpenseListTitle>
      <React.Fragment>
        {yearCosts.map((dataItem, yearId) => (
          <styles.ExpenseListItem key={yearId}>
            <styles.ExpenseListHead>
              <titles.H4>
                {dataItem.year}
              </titles.H4>
              <styles.ExpenseListHeadSum>
                {divideNumberIntoDigits(dataItem.totalValue)} руб
              </styles.ExpenseListHeadSum>
            </styles.ExpenseListHead>

            {dataItem.monthCosts.map((month, monthId) => (
              <ExpenseTable
                key={monthId}
                month={month}
              />
            ))}
          </styles.ExpenseListItem>
        ))}
      </React.Fragment>
    </>
  );
};

export default ExpenseList;
