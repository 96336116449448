import styled from 'styled-components';
import { CustomHashLink } from 'uiComponents/Link/CustomHashLink';

export const StyledMobileLink = styled(CustomHashLink)`
  margin-bottom: 32px;

  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  text-decoration: none;
  color: ${(props) => props.theme.colors.black};
`;

export const StyledMobileListLinks = styled.div`
  min-width: 100%;

  display: ${({ isManager, isHomePage }) => (isManager && isHomePage) ? 'none' : 'flex'};
  flex-direction: column;

  transform: ${({ isShowUserMenu }) => isShowUserMenu ? 'translateX(-100%)' : 'translateX(0)'};

  transition: transform .3s;
`;

export const StyledMobileMenuWrapper = styled.div`
  display: flex;

  overflow-x: hidden;
`;

export const UserMenuListLinks = styled(StyledMobileListLinks)`
  transform: ${({ isShowUserMenu }) => isShowUserMenu ? 'translateX(-100%)' : 'translateX(0)'};
`;
