import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

export const StyledDropdown = styled.div`
  position: relative;
`;

export const StyledDropList = styled.ul`
  position: absolute;
  top: 0;
  left: 0;
  
  padding: 30px 0;
  margin: 0;

  display: ${({ dropListOpened }) => dropListOpened ? 'block' : 'none'};
  
  list-style-type: none;
  
  background-color: white;
  box-shadow: 0 7px 64px rgba(0,0,0,0.17);
  border-radius: 4px;
  
  z-index: 10;
`;

export const StyledDropListItem = styled.li`
  padding: 8px 30px;
  
  color: ${({ active }) => active ? '#AEAEAE' : '#000'};
  
  pointer-events: ${({ active }) => active ? 'none' : 'auto'};
  
  white-space: pre;

  ${media.tablet`
    &:hover {
      background-color: rgba(0, 0, 0, 0.03);
      
      cursor: pointer;
    }
  `}
`;

export const StyledSelectedOption = styled.span`
  ${media.tablet`
    &:hover {
      cursor: pointer;
    }
  `}
`;
