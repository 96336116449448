import styled from 'styled-components';
import { H4 } from 'uiComponents/Titles';
import {
  media,
} from 'styled-bootstrap-grid';

export const AudienceFormWrapper = styled.div`
   ${media.md`
    margin-bottom: 64px;
  `}

  & ${H4} {
    margin-bottom: 48px;
  }

  margin-bottom: 60px;
`;

