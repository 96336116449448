import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _get from 'lodash/get';

import * as solutionSelectors from 'store/solution/selectors';
import { getRequestErrors, getValidationErrors } from 'store/errors/selectors';
import { authSelector } from 'store/app/selectors';
import { deleteFileSolutionData, uploadFileSolutionData } from 'store/solution/actions';
import { clearRequestErrors } from 'store/errors/actions';

import { uploadingFileIds } from 'store/solution/selectors';

import { checkAllExtension } from 'helpers/checkExtension';

import { solutionsConfig } from 'components/SurveySetup/forms/solutionConfig';
import ConfirmAction from 'components/ConfirmAction';

import Icon from 'uiComponents/Icon';

import generateUUID from 'helpers/generateUUID';
import { validateField } from 'services/validation';

import { SURVEY_FILE_UPLOAD } from 'api/requestNames';

import { SOLUTIONS, SOLUTIONS_SETTINGS } from 'constants/solutions';
import { NO_UPLOAD_ERRORS, ERROR_TEXTS } from 'constants/errors';

import FileInputMain from './FileInputMain/index';
import FileInputCustom from './FileInputCustom/index';

const allowedMaxFileSize = 1024 * 1024 * 2048;

const FileInput = (props) => {
  const {
    checkExtension = checkAllExtension,
    name = 'fileIds',
    requestName = SURVEY_FILE_UPLOAD,
    selector = uploadingFileIds,
    multiple = true,
    validate = false,
    addFileItem = Function.prototype,
    deleteItem = Function.prototype,
    itemId,
    emptyFiles = false,
  } = props;

  const { solution } = useParams();

  const dispatch = useDispatch();

  const history = useHistory();

  const solutionDataConfig = solutionsConfig.find(((item) => item.name === solution));

  const isAuthenticated = useSelector(authSelector);

  const isSubmittedForm = useSelector(solutionSelectors.isSubmittedForm);

  const namesFieldUploadingFiles = useSelector(solutionSelectors.getNamesFieldUploadingFiles);

  const solutionName = useSelector(solutionSelectors.getSolutionName);

  const requestErrors = useSelector(getRequestErrors);

  const validationErrors = useSelector(getValidationErrors);

  const uploadedFileIds = useSelector(selector);

  const [ localValidationErrors, setLocalValidationErrors ] = useState();

  const [ files, setFiles ] = useState([]);

  const [ isShowConfirmationPopup, setIsShowConfirmationPopup ] = useState(false);

  const [ isShowErrorPopup, setIsShowErrorPopup ] = useState(false);

  const [ idDeletedFile, setIdDeletedFile ] = useState({});

  const hasFiles = !!files.length;

  const hasErrors = requestErrors.filter((errorItem) => (
    !NO_UPLOAD_ERRORS.includes(errorItem)
  )).length || localValidationErrors;

  const isDisabledInputTypeFile = namesFieldUploadingFiles.includes(name);
  const isRangingType = Boolean(solution === SOLUTIONS.Ranging);

  const onChangeFile = (e) => {
    const inputFiles = [ ...e.target.files ];

    let validationError = false;

    const newFiles = inputFiles
      .map((file) => {
        if (!checkExtension(file)) {
          validationError = true;

          return false;
        }

        if (file.size > allowedMaxFileSize) {
          validationError = true;

          return false;
        }

        const ext = `.${checkExtension(file)[2]}`;
        const indexOf = file.name.lastIndexOf(ext);
        const fileName = file.name.substr(0, indexOf);

        return {
          file,
          _id: generateUUID(),
          ext,
          fileName,
        };
      }).filter((item) => item);

    if (validationError) {
      setLocalValidationErrors(true);
    } else {
      setLocalValidationErrors(false);
    }

    if (!multiple) {
      setFiles(newFiles);
    } else {
      setFiles(files.concat(newFiles));
    }

    if (itemId) {
      addFileItem(newFiles, itemId);
    }

    setIsShowErrorPopup(true);

    dispatch(uploadFileSolutionData({
      name,
      requestName,
      files: newFiles,
      multiple,
      solutionName,
    }));

    e.target.value = null;
  };

  const onCheckAuth = (e) => {
    if (!isAuthenticated) {
      e.preventDefault();
      history.push('/sign-in');
    }
  };

  const onDeleteFile = () => {
    const newFiles = [
      ...files.slice(0, idDeletedFile.id),
      ...files.slice(idDeletedFile.id + 1),
    ];

    const newUploadedFileIds = { ...uploadedFileIds };
    delete newUploadedFileIds[idDeletedFile._id];

    setFiles(newFiles);
    setIsShowConfirmationPopup(false);
    setIdDeletedFile({});

    if (itemId) {
      deleteItem();
    }
    dispatch(deleteFileSolutionData({
      name,
      fileIds: newUploadedFileIds,
    }));
  };

  const onDownloadFile = (_id) => {
    const file = files.find((item) => item._id === _id).file;

    const reader = new FileReader();
    reader.readAsText(file);

    const blob = new Blob([ file ], { type: file.type });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = file.name;
    link.click();
  };

  const showConfirmationPopup = (index, _id) => {
    setIdDeletedFile({
      id: index,
      _id,
    });
    setIsShowConfirmationPopup(!isShowConfirmationPopup);
  };

  const validField = () => {
    if (!validate) {
      return false;
    }

    if (isSubmittedForm) {
      return Object.values(
        validateField(
          uploadedFileIds,
          [
            solutionDataConfig.filesValidation || solutionDataConfig.createSurveyRules[name],
          ],
        ),
      ).includes(false);
    }

    return name in validationErrors;
  };

  const isValidField = validField();
  const renderValidateOrErrorMessageContainer = (theme) => (
    <span
      style={{ color: theme.colors.orange }}
      className={`validate-or-error-message-container ${solution}`}
    >
      <span className="validate-or-error-message-container__item">
        {isValidField && (
          'Загрузите файл, чтобы продолжить'
        )}
        {(!isValidField && hasErrors) && (
          'Произошла ошибка при загрузке файла'
        )}
      </span>
    </span>
  );

  const resetFileErrorInfo = () => {
    if (itemId) {
      deleteItem();
    }

    dispatch(clearRequestErrors());
    setFiles(files.slice(0, -1));
    setIsShowErrorPopup(false);
  };

  const renderInnerLabel = (theme) => {
    if (isRangingType) {
      return (
        <div className={`inner-label custom-input__label ${isValidField ? 'error' : ''}`}>
          <Icon
            name="attach"
            fill={theme.colors.darkGrey}
            className="attach-icon"
          />
          <span style={{ color: theme.colors.darkGrey }}>
            {hasErrors && (
              'Повторить загрузку'
            )}
            {!hasErrors && (
              'Добавить изображение'
            )}
          </span>
        </div>
      );
    }
    return (
      <div className="inner-label">
        <Icon
          name="attach"
          fill={theme.colors.orange}
          className="attach-icon"
        />
        <span style={{ color: theme.colors.orange }}>
          {hasErrors && (
            'Повторить загрузку'
          )}
          {!hasErrors && (
            'Прикрепить файл'
          )}
        </span>
      </div>
    );
  };

  const loading = (_id) => {
    if (uploadedFileIds[_id]) {
      return _id !== uploadedFileIds[_id]._id;
    }

    return !uploadedFileIds[_id];
  };

  const getComponentView = () => {
    const TABS_TYPES = [
      {
        projects: [ SOLUTIONS.Ranging ],
        component: FileInputCustom,
      },
    ];

    const currentType = TABS_TYPES.find((tabType) => (
      tabType.projects.indexOf(solution) !== -1
    ));

    if (currentType) {
      return currentType.component;
    }

    return FileInputMain;
  };

  const getErrorModalInfo = () => {
    const text = _get(ERROR_TEXTS, [ requestErrors[0], 'text' ], null);
    const errorSubText = _get(ERROR_TEXTS, [ requestErrors[0], 'subText' ], null);
    const allowedFormats = _get(SOLUTIONS_SETTINGS, [ solutionName, 'allowedFormats' ], []);
    const subText = errorSubText
      ? errorSubText + allowedFormats.join(', ')
      : '';
    const isShow = Boolean(text) && isShowErrorPopup;

    return {
      isShow,
      text,
      subText,
    };
  };

  const FilesComponents = getComponentView();
  const errorModalInfo = getErrorModalInfo();

  return (
    <>
      <FilesComponents
        isDisabledInputTypeFile={isDisabledInputTypeFile}
        hasFiles={hasFiles}
        onCheckAuth={onCheckAuth}
        onChangeFile={onChangeFile}
        multiple={multiple}
        renderInnerLabel={renderInnerLabel}
        renderValidateOrErrorMessageContainer={renderValidateOrErrorMessageContainer}
        files={files}
        loading={loading}
        onDownloadFile={onDownloadFile}
        showConfirmationPopup={showConfirmationPopup}
        props={props}
        emptyFiles={emptyFiles}
      />

      <ConfirmAction
        isShow={isShowConfirmationPopup}
        message="Вы уверены, что хотите удалить добавленный файл?"
        onConfirmedAction={() => onDeleteFile(idDeletedFile)}
        onNotConfirmedAction={() => showConfirmationPopup(null)}
      />
      <ConfirmAction
        isShow={errorModalInfo.isShow}
        subMessage={errorModalInfo.subText}
        message={errorModalInfo.text}
        onNotConfirmedAction={resetFileErrorInfo}
      />
    </>
  );
};

export default FileInput;
