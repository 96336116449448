import React, { useState } from 'react';
import { Col, Container, Row } from 'styled-bootstrap-grid';

import { fakeCardsData } from 'pages/HomePage/fakeCardsData';

import { StyledWrapperSolutionCard } from './SolutionCard/styled';
import { SolutionCard } from './SolutionCard/SolutionCards';
import { StyledHowItWorkWrapper } from './HowItWorkItem/styled';
import { HowItWorkItem } from './HowItWorkItem/HowItWorkItem';
import { FormBlock } from './FormBlock/FormBlock';

import { save, load } from 'localStorage';

import MainHowItWorkOne from './images/MainHowItWorkOne.svg';
import MainHowItWorkTwo from './images/MainHowItWorkTwo.svg';
import MainHowItWorkThree from './images/MainHowItWorkThree.svg';
import MainHowItWorkFour from './images/MainHowItWorkFour.svg';

import {
  StyledSectionTitle,
  StyledSectionTitleAbout,
  StyledSectionTitleHowItWork,
  StyledHeaderSubtitle,
  StyledSectionTitleAskQuestion,
  StyledHeaderTitle,
  StyledParagraphAboutSection,
  StyledAboutSectionContainerFluid,
  StyledAboutAskQuestionSectionContainerFluid,
  StyledCookiesContainerFluid,
  StyledCookiesText,
  StyledCookiesButton,
} from './styled';

// Сделано разделение, что бы последний элемент выводится отдельно и другой ширины
const solutionsWithoutLastSolution = fakeCardsData.slice(0, fakeCardsData.length - 1);
const lastSolutions = fakeCardsData[fakeCardsData.length - 1];

const HomePage = () => {
  const [ isCookiesAccepted, setSsCookiesAccepted ] = useState(load('acceptCookies'));

  const acceptCookies = () => {
    setSsCookiesAccepted(true);

    save('acceptCookies', true);
  };

  return (
    <>
      <Container>
        <Row>
          <Col col={12}>
            <StyledHeaderTitle>
              Агентство В2В маркетинговых исследований
            </StyledHeaderTitle>
          </Col>
        </Row>
        <Row>
          <Col col={12}>
            <StyledHeaderSubtitle>
              Онлайн-платформа для&nbsp;B2B исследований.
               Выберите одно из&nbsp;десяти популярных направлений от&nbsp;NPS до&nbsp;оценки корпоративной репутации,
               выберите аудиторию и&nbsp;закажите исследование в&nbsp;несколько кликов.
            </StyledHeaderSubtitle>
          </Col>
        </Row>
        <Row id="solutions">
          <Col col={12}>
            <StyledSectionTitle>
              Выберите решение
            </StyledSectionTitle>
          </Col>
        </Row>
        <StyledWrapperSolutionCard>
          {solutionsWithoutLastSolution.map((card) => (
            <Col
              col={12}
              md={6}
              xl={4}
              key={card.id}
            >
              <SolutionCard {...card} />
            </Col>
          ))}
          <Col
            md={12}
            xl={8}
            key={lastSolutions.id}
          >
            <SolutionCard {...lastSolutions} />
          </Col>
        </StyledWrapperSolutionCard>
        <Row id="how-it-works">
          <Col col={12}>
            <StyledSectionTitleHowItWork>
              Как это работает?
            </StyledSectionTitleHowItWork>
          </Col>
        </Row>
        <StyledHowItWorkWrapper>
          <HowItWorkItem
            image={MainHowItWorkOne}
            imageSubClass={'first'}
            subtitle={(
              <span>
                На&nbsp;этом сайте вы можете заказать В2В исследования на&nbsp;популярные темы
                 без&nbsp;обращения в&nbsp;агентство и&nbsp;получить результаты в&nbsp;течение 1-2&nbsp;дней.
                 Все&nbsp;исследования проводятся с&nbsp;аудиторией СМБ и&nbsp;в&nbsp;некоторых
                 случаях можно разослать анкету по&nbsp;вашему списку. Аудитория взвешена
                 по&nbsp;размеру согласно классификации ФНС(микро и&nbsp;малый бизнес) и&nbsp;по&nbsp;каналам
                 (производство, торговля и&nbsp;услуги). Можно выбрать географии: вся&nbsp;Россия,
                 Москва и&nbsp;города миллионники (все&nbsp;города, включая Москву и&nbsp;СПб)
                 и&nbsp;количество готовых анкет: 50, 100 или&nbsp;200.
              </span>
            )}
            col={12}
          />
          <HowItWorkItem
            image={MainHowItWorkTwo}
            imageSubClass={'second'}
            subtitle={(
              <span>
                Для&nbsp;того чтобы&nbsp;запустить ваше первое исследование, нам необходимо подписать договор.
                 Для&nbsp;этого при&nbsp;регистрации выберите опцию «Я&nbsp;хочу&nbsp;получить шаблон договора»,
                 и&nbsp;мы пришлем вам шаблон, либо&nbsp;позвоните нам по&nbsp;телефону,
                указанному в&nbsp;нижней части любой страницы.
              </span>
            )}
            col={12}
          />
          <HowItWorkItem
            image={MainHowItWorkThree}
            imageSubClass={'third'}
            subtitle={(
              <span>
                Выберете нужное вам исследование (мы называем их&nbsp;«решение»).
                 Если&nbsp;кликнуть на&nbsp;кнопку «Начать» в&nbsp;квадрате с&nbsp;названием, например,
                 Здоровье&nbsp;Марки, вы попадете на&nbsp;страницу этого&nbsp;решения.
                 Справа&nbsp;на&nbsp;странице можно просмотреть методику решения, его анкету,
                 скачать пример отчета и&nbsp;посмотреть ответы на&nbsp;популярные вопросы.
              </span>
            )}
            col={12}
          />
          <HowItWorkItem
            image={MainHowItWorkFour}
            imageSubClass={'fourth'}
            subtitle={(
              <span>
                Прямо с&nbsp;этой страницы можно заполнить параметры будущего исследования и&nbsp;заказать его.
                 Заполните необходимые параметры (например, название тестируемой марки),
                 выберите географию и&nbsp;количество готовых анкет и&nbsp;запускайте ваше исследование.
                 Результаты&nbsp;будут готовы за&nbsp;24&#8209;48 часов.
              </span>
            )}
            col={12}
          />
        </StyledHowItWorkWrapper>
      </Container>
      <StyledAboutSectionContainerFluid bgColor="#F9F9F9">
        <Container>
          <Row id="about">
            <Col col={12}>
              <StyledSectionTitleAbout>
                О компании
              </StyledSectionTitleAbout>
              <StyledParagraphAboutSection>
                Компания В2В Ресерч с&nbsp;2004 года проводит В2В исследования в&nbsp;России и&nbsp;является
                признанным экспертом в&nbsp;этой области. Если у&nbsp;вас есть вопросы по&nbsp;использованию сайта,
                вы&nbsp;можете написать нам в&nbsp;контактной форме на&nbsp;главной странице,
                или позвонить по&nbsp;телефону, указанному в&nbsp;нижней части любой страницы.
                Если у&nbsp;вас есть вопросы по&nbsp;тому, как создать нестандартный проект,
                уточнить задачи исследования, обсудить методику и&nbsp;анкету,
                помочь интерпретировать данные, вы&nbsp;можете воспользоваться кнопкой Консультация.
              </StyledParagraphAboutSection>
            </Col>
          </Row>
        </Container>
      </StyledAboutSectionContainerFluid>
      {/* <StyledReviewSectionContainer>*/}
      {/*  <Row id="reviews">*/}
      {/*    <Col*/}
      {/*      col={12}*/}
      {/*      xl={4}*/}
      {/*    >*/}
      {/*      <AboutSlider />*/}
      {/*    </Col>*/}
      {/*    <Col*/}
      {/*      col={12}*/}
      {/*      xl={8}*/}
      {/*    >*/}
      {/*      <StyledLogosWrapper>*/}
      {/*        {*/}
      {/*          fakeLogosData.map((logo) => (*/}
      {/*            <StyledLogoWrapper*/}
      {/*              key={logo.id}*/}
      {/*            >*/}
      {/*              <StyledLogoWrapperItem*/}
      {/*                src={logo.src}*/}
      {/*                alt={logo.alt}*/}
      {/*              />*/}
      {/*            </StyledLogoWrapper>*/}
      {/*          ))*/}
      {/*        }*/}
      {/*      </StyledLogosWrapper>*/}
      {/*    </Col>*/}
      {/*  </Row>*/}
      {/* </StyledReviewSectionContainer>*/}
      <StyledAboutAskQuestionSectionContainerFluid>
        <Container>
          <Row id="ask-question">
            <Col col={12}>
              <StyledSectionTitleAskQuestion>
                Задать вопрос
              </StyledSectionTitleAskQuestion>
            </Col>
          </Row>
          <Row>
            <Col col={12}>
              <FormBlock />
            </Col>
          </Row>
        </Container>
      </StyledAboutAskQuestionSectionContainerFluid>
      {/* <StyledSocialNetworkSection>
        <StyledIconsRow>
          <Icon
            className="icon"
            name="instagram"
            fill="#D2D2D2"
            width={70}
            height={70}
          />
          <Icon
            className="icon"
            name="facebook"
            fill="#D2D2D2"
            width={70}
            height={70}
          />
          <Icon
            className="icon"
            name="vk"
            fill="#D2D2D2"
            width={70}
            height={70}
          />
          <Icon
            className="icon"
            name="twiter"
            fill="#D2D2D2"
            width={70}
            height={70}
          />
        </StyledIconsRow>
        <StyledSocialNetworkSlogan>Мы в социальных сетях. Присоеденяйся!</StyledSocialNetworkSlogan>
      </StyledSocialNetworkSection>*/}

      {!isCookiesAccepted && (
        <StyledCookiesContainerFluid bgColor="#FF3D00">
          <Container>
            <Row
              alignItems="center"
              mdAlignItems="start"
            >
              <Col
                col={7}
                md={9}
                xl={10}
              >
                <StyledCookiesText>
                  <span className="mobile-text">
                    Сайт использует cookie
                  </span>
                  <span className="desktop-text">
                    Мы собираем и храним информацию о вашем посещении сайта — так называемые cookies.
                    Файлы cookies не собирают и не хранят никакую личную информацию о вас.
                    Используя этот сайт, вы даете согласие на использование cookies.
                    На данном этапе вы можете отказаться от использования cookies,
                    настроив необходимые параметры в своем браузере.
                  </span>
                </StyledCookiesText>
              </Col>
              <Col
                col={5}
                md={3}
                xl={2}
              >
                <StyledCookiesButton
                  onClick={acceptCookies}
                  viewType="ghost"
                >
                  Принять
                </StyledCookiesButton>
              </Col>
            </Row>
          </Container>
        </StyledCookiesContainerFluid>
      )}
    </>
  );
};

export default HomePage;
