import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Col } from 'styled-bootstrap-grid';
import _get from 'lodash/get';

import { getSurveyName } from 'helpers/solutions';

import List from 'components/SurveysList/List';

import { PaginationButton } from 'components/Pagination/styles';
import { UserPollListPaginationButtons } from './styled';

import * as solutionSelectors from 'store/solution/selectors';
import * as solutionActions from 'store/solution/actions';

import { EmptyList } from 'components/EmptyList';
import { DownloadLink, RouterLink as Link } from 'uiComponents/Link';
import * as styles from 'uiComponents/Table';

const renderSurveyLabel = (item) => {
  const solutionName = _get(item, 'solution.name');

  return (
    <Link to={`/surveys/${item.id}`}>
      {getSurveyName(solutionName, item)}
    </Link>
  );
};

const SurveysList = ({ isManager }) => {
  const dispatch = useDispatch();

  const surveysList = useSelector(solutionSelectors.getSurveyListItems);

  const surveysListFilters = useSelector(solutionSelectors.getSurveyListFilters);

  const surveysListTotal = useSelector(solutionSelectors.getSurveyListTotal);

  const surveysListTotalFound = useSelector(solutionSelectors.getSurveyListTotalFound);

  const countPaginationItems = new Array(Math.ceil(surveysListTotalFound / surveysListFilters.count)).fill(0);

  const settingsUser = [
    {
      id: 1,
      title: 'ID',
      width: 100,
      render: (item) => `#${item.id}`,
    },
    {
      id: 2,
      title: 'Название опроса',
      width: 200,
      render: renderSurveyLabel,
      mobile: true,
    },
    {
      id: 3,
      title: 'Тип опроса',
      width: 170,
      render: (item) => item.solution ? item.solution.name : '-',
    },
    {
      id: 4,
      title: 'Дата создания',
      width: 100,
      render: (item) => item.createdDate ? new Date(item.createdDate).toLocaleDateString() : '-',
    },
    {
      id: 5,
      title: 'Дата исполнения',
      width: 130,
      render: (item) => item.completedDate
        ? new Date(item.createdDate).toLocaleDateString()
        : '-',
    },
    {
      id: 6,
      title: 'Аудитория',
      width: 170,
      render: ({ companyTurnover, audience, geography }) => {
        if ((!audience || !audience.name) && (!geography || !geography.name)) {
          return '-';
        }

        return companyTurnover
          ? `${audience.name}/${geography.name}/${companyTurnover.name}`
          : `${audience.name}/${geography.name}`;
      },
    },
    {
      id: 7,
      title: 'Статус',
      width: 175,
      render: (item) => item.status ? item.status.name : '-',
      mobile: true,
    },
    {
      id: 8,
      title: 'Результаты (отчёт)',
      width: 125,
      render: (item) => {
        const link = !item.reportFileId ? null : `/api/file/${item.reportFileId}`;
        const title = !item.reportFileId ? 'Не загружен' : 'Скачать';
        return (
          <DownloadLink
            target={'_blank'}
            disabled={!item.reportFileId}
            href={link}
          >
            {title}
          </DownloadLink>
        );
      },
    },
  ];

  const settingsManager = [
    {
      id: 1,
      title: 'ID',
      width: 100,
      render: (item) => `#${item.id}` ? `#${item.id}` : '-',
    },
    {
      id: 2,
      title: 'Компания',
      width: 170,
      render: (item) => item.companyName ? item.companyName : '-',
    },
    {
      id: 3,
      title: 'Название опроса',
      width: 200,
      render: renderSurveyLabel,
      mobile: true,
    },
    {
      id: 4,
      title: 'Тип опроса',
      width: 170,
      render: (item) => item.solution
        ? item.solution.name
        : '-',
    },
    {
      id: 5,
      title: 'Дата создания',
      width: 100,
      render: (item) => new Date(item.createdDate).toLocaleDateString(),
    },
    {
      id: 6,
      title: 'Дата исполнения',
      width: 100,
      render: (item) => item.completedDate
        ? new Date(item.completedDate).toLocaleDateString()
        : '-',
    },
    {
      id: 7,
      title: 'Менеджер',
      width: 110,
      render: (item) => item.manager ? item.manager.email : '-',
    },
    {
      id: 8,
      title: 'Статус',
      width: 180,
      render: (item) => item.status ? item.status.name : '-',
    },
  ];

  const hasSurveys = surveysListTotal !== 0;
  const hasSurveysListTotalFound = surveysListTotalFound !== 0;

  const onChangePage = (page) => {
    if (page <= 0) {return;}
    if (page > countPaginationItems.length) {return;}

    dispatch(solutionActions.setSurveyListFilter({
      filterData: { page },
      isGlobalLoading: false,
    }));
  };

  const renderPagination = () => {
    if (!hasSurveysListTotalFound) {
      return null;
    }

    return (
      <UserPollListPaginationButtons>
        <PaginationButton onClick={() => onChangePage(1)}>{'<<'}</PaginationButton>
        <PaginationButton onClick={() => onChangePage(surveysListFilters.page - 1)}>{'<'}</PaginationButton>

        {countPaginationItems.map((item, index) => (
          <PaginationButton
            active={surveysListFilters.page === index + 1}
            key={index}
            onClick={() => onChangePage(index + 1)}
          >{index + 1}</PaginationButton>
        ))}

        <PaginationButton onClick={() => onChangePage(surveysListFilters.page + 1)}>{'>'}</PaginationButton>
        <PaginationButton onClick={() => onChangePage(countPaginationItems.length)}>{'>>'}</PaginationButton>
      </UserPollListPaginationButtons>
    );
  };

  const renderNotFountItemMessage = () => {
    if (hasSurveys && !hasSurveysListTotalFound) {
      return (
        <styles.StyledNotFountItemMessage>
          По вашему заросу ничего не найдено
        </styles.StyledNotFountItemMessage>
      );
    }

    return null;
  };

  return (
    <>
      <Row>
        <Col col={12}>
          {hasSurveys && (
            <List
              role={isManager}
              title={isManager ? 'Название опроса' : 'Название и статус опроса'}
              options={isManager ? settingsManager : settingsUser}
              items={surveysList}
            />
          )}

          {renderNotFountItemMessage()}
        </Col>
      </Row>

      {!hasSurveys && (
        <EmptyList
          title="Список опросов пуст"
          text="Вы еще не создали ни одного опроса в системе,"
          link="/create"
          linkText="создайте ваш первый опрос"
        />
      )}

      <Row>
        <Col col={12}>
          {renderPagination()}
        </Col>
      </Row>
    </>
  );
};

SurveysList.propTypes = {
  isManager: PropTypes.bool,
};

export { SurveysList };
