import React from 'react';
import { Container, Row } from 'styled-bootstrap-grid';

import { getCurrentYear } from 'helpers/date';

import {
  TERMS_OF_USE,
  PRIVACY_POLICY,
} from '../../../../constants/staticDocs';

import {
  FooterItem,
  FooterLogo,
  FooterStyled,
  FooterLink,
} from 'components/layouts/AuthorizedLayout/Footer/styles';

export const Footer: React.FC = () => (
  <FooterStyled>
    <Container>
      <Row>
        <FooterItem
          xs={2}
          sm={2}
          md={4}
          lg={3}
        >
          <FooterLogo to={'/surveys'} />
        </FooterItem>
        <FooterItem
          xs={8}
          sm={9}
          md={5}
          lg={3}
          xsOffset={1}
          smOffset={1}
          mdOffset={2}
          lgOffset={1}
        >
          <FooterLink>
            e-mail: <a href="mailto:info@b2bresearch.io">info@b2bresearch.io</a>
          </FooterLink>
          <FooterLink>
            тел. <a href="tel:+7(495) 638-56-61"> +7(495) 638-56-61</a>
          </FooterLink>
          <FooterLink>
              111397, г.&nbsp;Москва, Зеленый&nbsp;проспект, д.20, 10&nbsp;этаж, помещ.&nbsp;I, ком.11a
          </FooterLink>
        </FooterItem>
        <FooterItem
          xs={8}
          sm={8}
          md={5}
          lg={3}
          xsOffset={3}
          smOffset={3}
          mdOffset={6}
          lgOffset={1}
        >
          <FooterLink>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={PRIVACY_POLICY}
            >
              Политика конфиденциальности
            </a>
          </FooterLink>
          <FooterLink>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={TERMS_OF_USE}
            >
              Соглашение об обработке персональных данных
            </a>
          </FooterLink>
          <FooterLink>
            b2bresearch.io © {getCurrentYear()}
          </FooterLink>
        </FooterItem>
      </Row>
    </Container>
  </FooterStyled>
);
