import React, { useCallback } from 'react';
import * as dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';

import { StyledRangeSelector, StyledPeriodButton } from './styled';

import { currentDate } from 'pages/SurveysListPage/FilterBlock/FilterBlock';

dayjs.extend(quarterOfYear);

const PERIOD_TYPE = {
  current: 'current',
  past: 'past',
};

const year = dayjs().get('year');
const month = dayjs().get('month');

const quarter = dayjs().quarter();

const quarterOfYearData = {
  1: [ 0, 1, 2 ],
  2: [ 3, 4, 5 ],
  3: [ 6, 7, 8 ],
  4: [ 9, 10, 11 ],
  5: [ -3, -2, -1 ],
};

const normalizeIndexQuarter = (type = '') => {
  if (type === PERIOD_TYPE.past) {
    if (quarter - 1 === 0) {
      return 5;
    }

    return quarter - 1;
  }

  return quarter;
};

const availablePeriods = [
  {
    ids: [ 1, 2 ],
    title: 'Месяц:',
    getValue: (end, type) => {
      if (type === PERIOD_TYPE.current) {
        return {
          from: new Date(year, month, 1),
          to: currentDate,
        };
      }

      return {
        from: new Date(year, month - 1, 1),
        to: new Date(year, month, 0),
      };
    },
  },
  {
    ids: [ 3, 4 ],
    title: 'Квартал:',
    getValue: (end, type) => {
      if (type === PERIOD_TYPE.current) {
        return {
          from: new Date(year, quarterOfYearData[normalizeIndexQuarter()][0], 1),
          to: currentDate,
        };
      }

      return {
        from: new Date(year, quarterOfYearData[normalizeIndexQuarter(PERIOD_TYPE.past)][0], 1),
        to: new Date(
          year,
          quarterOfYearData[normalizeIndexQuarter(PERIOD_TYPE.past)][2],
          dayjs(new Date(year, quarterOfYearData[normalizeIndexQuarter(PERIOD_TYPE.past)][2])).daysInMonth()),
      };
    },
  },
  {
    ids: [ 5, 6 ],
    title: 'Год:',
    getValue: (end, type) => {
      if (type === PERIOD_TYPE.current) {
        return {
          from: new Date(year, 0, 1),
          to: currentDate,
        };
      }

      return {
        from: new Date(year - 1, 0, 1),
        to: new Date(year - 1, 12, 0),
      };
    },
  },
];

export const RangeSelector = ({
  name,
  onChange,
  changePeriodActiveId,
  periodActiveId,
}) => {
  const onClickButton = useCallback((id, getValue, type) => () => {
    onChange(getValue, name, type);
    changePeriodActiveId(id, name);
  }, []);

  return (
    <StyledRangeSelector>
      {availablePeriods.map((item) => (
        <div
          className="range-row"
          key={item.title}
        >
          <div className="range-row__label">{item.title}</div>
          <div className="range-row__items">
            <StyledPeriodButton
              active={periodActiveId === item.ids[0]}
              onClick={onClickButton(item.ids[0], item.getValue, PERIOD_TYPE.current)}
            >Текущий
            </StyledPeriodButton>
            <StyledPeriodButton
              active={periodActiveId === item.ids[1]}
              onClick={onClickButton(item.ids[1], item.getValue, PERIOD_TYPE.past)}
            >Прошлый
            </StyledPeriodButton>
          </div>
        </div>
      ))}
    </StyledRangeSelector>
  );
};
