import React from 'react';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import { ContainerBreadcrumbs } from 'components/Breadcrumbs';
import RegistrationForm from 'components/RegistrationForm/index';
import { RegistrationPageTitle } from 'pages/RegistrationPage/styles';
import { usePreloader } from 'hooks/usePreloader';

import { USER_REGISTER } from 'api/requestNames';
import { getBreadcrumbs } from 'components/Breadcrumbs/index.jsx';

const RegistrationPage = (props) => {
  const [ isStartRegistration ] = usePreloader(USER_REGISTER, {
    initialState: false,
  });

  const breadcrumbs = getBreadcrumbs([ { breadcrumbText: props.breadcrumbText, breadcrumbLink: props.path } ]);

  return (
    <Container>
      <Row>
        <Col col={12}>
          <ContainerBreadcrumbs breadcrumbs={breadcrumbs} />
        </Col>
      </Row>
      <Row>
        <Col col={12}>
          <RegistrationPageTitle
            title={'Регистрация'}
            subTitle={'Заполните все обязательные поля формы для регистрации в системе'}
          />
        </Col>
      </Row>
      <Row>
        <Col col={12}>
          <RegistrationForm isStartRegistration={isStartRegistration} />
        </Col>
      </Row>
    </Container>
  );
};

export default RegistrationPage;
