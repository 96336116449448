import React, { forwardRef } from 'react';
import { StyledSelect } from './styled';
import Icon from 'uiComponents/Icon';
import { ThemeConsumer } from 'styled-components';

export const MultiSelect = forwardRef((props, ref) => {
  const {
    indicatorName = 'arrow',
    indicatorClassName = 'indicator-icon',
    indicatorFill = 'black',
  } = props;

  const getStyles = (theme) => ({
    control: (provided) => ({
      ...provided,
      borderColor: props.error ? theme.colors.orange : theme.colors.darkGrey,
      boxShadow: 'none',
      '&:hover': {
        borderColor: props.error ? theme.colors.orange : theme.colors.darkGrey,
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: props.isSearchable
        ? '11px 0 11px 15px'
        : '15px 0 15px 15px',
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: '16px',
      lineHeight: '20px',
    }),
    option: (provided, state) => ({
      ...provided,
      padding: '8px 0 8px 15px',
      color: theme.colors.black,
      backgroundColor: state.isSelected
        ? theme.colors.lightGrey
        : 'transparent',
      '&:hover': {
        backgroundColor: theme.colors.lightGrey,
      },
    }),
  });

  return (
    <ThemeConsumer>
      {(theme) => (
        <StyledSelect
          {...props}
          ref={ref}
          styles={getStyles(theme)}
          className="react-select-container"
          classNamePrefix="react-select"
          components={{
            IndicatorSeparator: () => null,
            IndicatorsContainer: () => (
              <Icon
                name={indicatorName}
                fill={indicatorFill}
                className={indicatorClassName}
              />
            ),
          }}
        />
      )}
    </ThemeConsumer>
  );
});
