import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

export const BigTitle = styled.div`
  font-weight: 800;
  font-size: 64px;
  line-height: 78px;

  margin: 0;
`;

export const H1 = styled.h1`
  font-weight: 800;
  font-size: 56px;
  line-height: 68px;

  margin: 0;
`;

export const H2 = styled.h2`
  font-weight: 700;
  font-size: 44px;
  line-height: 54px;
  margin: 0;
  ${media.xs`
    font-size: 26px;
    line-height: 32px;
  `}
`;

export const H3 = styled.h3`
  font-weight: 800;
  font-size: 38px;
  line-height: 46px;

  margin: 0;
`;

export const H4 = styled.h4`
  font-weight: 700;
  font-size: 26px;
  line-height: 32px;

  margin: 0;
`;

export const H4Small = styled.h4`
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;

  margin: 0;
`;

export const PageTitleWrapper = styled.div`
  & ${H2} {
    margin-bottom: 19px;
  }
`;

export const PageSubTitle = styled.span`
  color: ${(props): string => props.color === 'vivid' ? props.theme.colors.black : props.theme.colors.darkGrey};
`;
