import React from 'react';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import { PageTitle } from 'uiComponents/Titles';

export const MethodologyCRPage = () => (
  <Container>
    <Row>
      <Col col={12}>
        <PageTitle title="Методология" />
      </Col>
    </Row>
    <Row>
      <Col col={12}>
        <div className="block">
          <p className="text">
            К <strong>Корпоративной Репутации</strong> в последние десятилетия XX века возник
            повышенный интерес со стороны бизнеса.
            Это, казалось бы, нематериальное понятие превратилось в финансовое
            и стало одним из важнейших активов организации, оцениваемых в ее бухгалтерском балансе.
          </p>

          <p className="text">
            Корпоративная репутация - это коллективное мнение о компании,
            формирующееся с течением времени в сознании целевых групп на основе экспертной оценки экономического,
            социального и экологического аспектов ее деятельности.
          </p>

          <p className="text">
            На основе анализа журнала Fortune можно говорить о том, что корпоративная репутация
            – одно из важнейших стратегических преимуществ компании:
          </p>

          <ul className="list">
            <li className="list__item">
              Хорошая корпоративная репутация увеличивает продолжительность
              получения максимального дохода (эффект отсрочки).
            </li>
            <li className="list__item">
              Хорошая корпоративная репутация может сократить время, которое требуется фирме,
              чтобы достичь финансовых показателей, сопоставимых сосредним уровнем (эффект ведущего показателя).
            </li>
            <li className="list__item">
              Хорошая корпоративная репутация способствует получению и сохранению максимальной прибыли.
            </li>
          </ul>

          <p className="text">
            В нашей <strong>модели</strong> мы используем следующие показатели, наиболее часто встречающиеся
            в исследовательской индустрии и составляющие «репутационную пирамиду» организации:
          </p>

          <div className="MethodologyTable">

            <div className="MethodologyRow">
              <h4 className="subtitle">Знание</h4>

              <p className="text">
                Доля в целевой аудитории слышавших о компании
              </p>
            </div>

            <div className="MethodologyRow">
              <h4 className="subtitle">Осведомленность</h4>

              <p className="text">
                Доля в целевой аудитории хорошо знающих работу компании
              </p>
            </div>

            <div className="MethodologyRow">
              <h4 className="subtitle">Предпочтение</h4>

              <p className="text">
                Доля в целевой аудитории имеющих положительное мнение о компании
              </p>
            </div>

            <div className="MethodologyRow">
              <h4 className="subtitle">Доверие</h4>

              <p className="text">
                Доля в целевой аудитории доверяющих компании
              </p>
            </div>

            <div className="MethodologyRow">
              <h4 className="subtitle">Готовность рекомендовать</h4>

              <p className="text">
                Доля в целевой аудитории готовых рекомендовать компанию
              </p>
            </div>

          </div>

          <p className="text">
            Дополнительно, мы оцениваем <strong>имидж компании</strong> (устойчивый эмоционально-окрашенный образ,
            формирующийся в сознании целевых групп в результате восприятия информации об организации)
            и <strong>ее коммуникационную активность</strong>
          </p>
        </div>
      </Col>
    </Row>
  </Container>
);
