import styled from 'styled-components';
import { media, Row } from 'styled-bootstrap-grid';

export const StyledSolutionCard = styled('div')`
  padding: 56px 25px 48px 25px;
  margin-bottom: 30px;

  display: flex;
  flex-direction: column;

  color: #202020;

  background-color: #d7d7d7;
  box-shadow: inset 0 0 100px rgba(0, 0, 0, 0.05);
  border-radius: 16px;

  ${media.sm`
    height: 326px;
  `}

  ${media.xl`
    padding: 56px 32px 48px 32px;
  `}

  .name {
    margin-bottom: 26px;

    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
  }

  .description {
    margin-bottom: 26px;

    font-weight: 300;
    font-size: 16px;
    line-height: 22px;

    ${media.sm`
      margin-bottom: 0;
    `}
  }

  .link {
    margin-top: auto;

    display: inline-block;
    width: 240px;
    font-weight: bold;
    font-size: 14px;
    line-height: 60px;
    letter-spacing: 2px;
    text-align: center;
    color: #202020;

    text-decoration: none;
    text-transform: uppercase;

    border: 2px solid #202020;
    box-sizing: border-box;
    border-radius: 8px;

    transition: .3s;

    ${media.md`

    `}

    ${media.xl`
      width: 168px;
    `}

    &:hover {
      border-color: #ffffff;

      background-color: #ffffff;
    }
  }
`;

export const StyledWrapperSolutionCard = styled(Row)`
  margin-bottom: 32px;

  ${media.md`
    margin-bottom: 44px;
  `}

  ${media.xl`
    margin-bottom: 44px;
  `}
`;
