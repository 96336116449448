import { ThemeConsumer } from 'styled-components';
import {
  FileInputWrapper,
  UploadFilePreloader,
} from 'components/FileInput/styled';

import Icon from 'uiComponents/Icon';
import React from 'react';

const FileInputMain = ({
  isDisabledInputTypeFile,
  hasFiles,
  onCheckAuth,
  onChangeFile,
  multiple,
  renderInnerLabel,
  renderValidateOrErrorMessageContainer,
  files,
  loading,
  onDownloadFile,
  showConfirmationPopup,
  ...props
}) => (
  <FileInputWrapper
    {...props}
    disabled={isDisabledInputTypeFile}
    tabIndex={-1}
    hasFiles={hasFiles}
  >
    <label>
      <input
        type="file"
        onClick={(e) => onCheckAuth(e)}
        onChange={onChangeFile}
        multiple={multiple}
        hidden
        disabled={isDisabledInputTypeFile}
      />
      <ThemeConsumer>
        {(theme) => (
          <>
            {renderInnerLabel(theme)}
            {renderValidateOrErrorMessageContainer(theme)}
          </>
        )}
      </ThemeConsumer>
    </label>

    <div className="attached-files">
      {files.map((file, index) => (
        <div
          className="attached-files__file"
          key={`${file._}${index}`}
        >
          <div className="attached-files__text">
            <span className="attached-files__file-name">{file.fileName}</span>
            <span className="attached-files__file-extension">{file.ext}</span>
          </div>
          <div className="attached-files__icons-block">
            <ThemeConsumer>
              {(theme) => (
                <>
                  {loading(file._id)
                    ? <UploadFilePreloader />
                    : <>
                      <Icon
                        className="attached-files__icon attached-files__download"
                        fill={theme.colors.orange}
                        name="download"
                        width={16}
                        height={18}
                        onClick={() => onDownloadFile(file._id)}
                      />
                      <Icon
                        className="attached-files__icon attached-files__remove"
                        fill={theme.colors.darkGrey}
                        name="delete"
                        width={14}
                        height={18}
                        onClick={() => showConfirmationPopup(index, file._id)}
                        disabled={isDisabledInputTypeFile}
                      />
                    </>}
                </>
              )}
            </ThemeConsumer>
          </div>
        </div>
      ))}
    </div>
  </FileInputWrapper>);

export default FileInputMain;
