import styled, { css } from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { HashLink as Link } from 'react-router-hash-link';

const cssLink = css`
  text-transform: uppercase;
  text-decoration: none;
  font-weight: bold;
  font-size: 10px;
  line-height: 43px;
  color: #1C2534;
  text-align: right;
  letter-spacing: 1px;
`;

export const StyledCabinetLinkWrapper = styled('div')`
  height: 100%;

  display: none;

  justify-content: flex-end;
  align-items: center;

  margin-left: 30px;

  ${media.md`
    display: flex;
  `}
`;

export const StyledDividerCabinetLink = styled('span')`
  margin: 0 17px;

  width: 1px;
  height: 7px;

  display: block;

  background-color: ${(props) => props.theme.colors.darkGrey};
`;

export const StyledRegistrationLink = styled(Link)`
  ${cssLink};
`;

export const StyledSignInLink = styled(Link)`
  ${cssLink};

  color: ${(props) => props.theme.colors.orange};
`;
