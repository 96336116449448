import styled, { keyframes } from 'styled-components';

const clockwise = keyframes`
  to {
    transform: rotate(360deg) translateZ(0);
  }
`;

const shadowValue1 = '7px';
const shadowValue2 = '10px';

export const StyledPreloader = styled('div')`
  background-color: transparent;
  width:  7px;
  height: 7px;
  border-radius: 100%;
  box-shadow:
     ${shadowValue1} -${shadowValue1} rgba(255 ,61 , 0, 12.5%),
     ${shadowValue2}     0 rgba(255 ,61 , 0, 25%),
     ${shadowValue1}  ${shadowValue1} rgba(255 ,61 , 0, 37.5%),
        0  ${shadowValue2} rgba(255 ,61 , 0, 50%),
    -${shadowValue1}  ${shadowValue1} rgba(255 ,61 , 0, 62.5%),
    -${shadowValue2}     0 rgba(255 ,61 , 0, 70.5%),
    -${shadowValue1} -${shadowValue1} rgba(255 ,61 , 0, 87.5%),
        0 -${shadowValue2} rgba(255 ,61 , 0, 1);
  animation: ${clockwise} 0.75s steps(8, end) infinite;
`;
