import React from 'react';
import { getSvgPath, getViewBox } from 'uiComponents/Icon/getSvgPath';

const Icon = ({
  name = '',
  fill = '#fff',
  width = '24',
  className = '',
  height = '24',
  onClick,
}) => (
  <svg
    onClick={onClick}
    width={width}
    height={height}
    viewBox={getViewBox(name)}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    {getSvgPath(name, { fill })}
  </svg>
);

export default Icon;
