import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import CreateForm from 'components/SurveySetup/forms/CreateForm';
import { solutionsConfig } from 'components/SurveySetup/forms/solutionConfig';
import { StyledWrapperFormBlock } from 'components/SurveySetup/styled';
import { FormBlockTitle } from 'components/SurveySetup/FormBlockTitle/FormBlockTitle';
import { AuthorizedField } from 'components/AuthorizedField/AuthorizedField';
import AudienceForm from 'components/AudienceForm';

import FormField from 'uiComponents/Form/Field';
import { StyledTextArea } from 'uiComponents/TeaxtArea';

import * as solutionActions from 'store/solution/actions';
import * as solutionSelectors from 'store/solution/selectors';
import { getValidationErrors } from 'store/errors/selectors';

import { Col, Row } from 'styled-bootstrap-grid';
import AdditionalQuestion from 'components/AdditionalQuestion';
import { DynamicFieldsBlock } from 'components/SurveySetup/DynamicFieldsBlock';
import generateUUID from 'helpers/generateUUID';
import { BlockWithDefaultField } from 'components/SurveySetup/DynamicFieldsBlock/BlockWithDefaultField';

const mapStateToProps = (state) => ({
  isSubmittedForm: solutionSelectors.isSubmittedForm(state),
  validationErrors: getValidationErrors(state),
});

const mapDispatchToProps = (dispatch) => ({
  updateSolutionCreateData: (fieldData) => dispatch(solutionActions.updateSolutionCreateData(fieldData)),
});

@connect(mapStateToProps, mapDispatchToProps)
class NameForm extends CreateForm {
  constructor(props) {
    super(props);
    this.state = this.solutionDataConfig.initialFormState;
    this.props.updateSolutionCreateData(this.solutionDataConfig.initialFormState);
  }

  solution = this.props.match.params.solution;

  solutionDataConfig = solutionsConfig.find(((item) => item.name === this.solution));

  render() {
    const {
      productName,
      audienceId,
      geographyId,
    } = this.state;

    return (
      <>
        <Row>
          <Col col={12}>
            <StyledWrapperFormBlock>
              <FormBlockTitle
                title="Продукт/услуга"
                subtitle="Опишите, пожалуйста, для какого продукта или услуги Вы бы хотели протестировать названия?"
                textHint="Зачем это нужно?"
                dataTip={(
                  <div>
                    Респондентам будет предложено ответить на вопрос: «Используете ли Вы в своей профессиональной
                    деятельности следующие продукты или услуги: /НАЗВАНИЕ ПРОДУКТА ИЛИ УСЛУГИ/?»
                    Таким образом, мы отберем для участия только тех респондентов,
                    которые являются пользователями необходимых продуктов и услуг.
                  </div>
                )}
              />
              <AuthorizedField>
                <FormField>
                  <StyledTextArea
                    id="productName"
                    name="productName"
                    placeholder="Например, оборудование для неразрушающего контроля или услуги по подбору персонала"
                    defaultValue={productName}
                    onChange={this.inputOnChange}
                    error={this.isValidField('productName')}
                  />
                </FormField>
              </AuthorizedField>
            </StyledWrapperFormBlock>
          </Col>
        </Row>
        <Row>
          <Col col={12}>
            <DynamicFieldsBlock
              blockTitle={{
                title: 'Название',
                subtitle: `
                  Укажите, пожалуйста, названия, которые Вы бы хотели протестировать.<br/>
                   Пожалуйста, введите каждое название в отдельную строку.<br/>
                    Можно указать не более четырех названий.<br/>
                `,
              }}
              keyName="text"
              name="names"
              confirmActionMessage="Вы уверены, что хотите удалить название?"
              placeholder="Введите названия"
              addButtonText="Добавить строку"
              defaultState={[
                { text: '', id: generateUUID() },
              ]}
              component={BlockWithDefaultField}
            />
          </Col>
        </Row>
        <Row>
          <Col col={12}>
            <AudienceForm
              onChange={this.setAudienceData}
              data={{
                audienceId,
                geographyId,
              }}
              optionalDescription="Мы будем проводить опрос среди тех компаний, которые пользуются
               указанными вами продуктами или услугами."
            />
          </Col>
        </Row>
        <AdditionalQuestion />
      </>
    );
  }
}

export default withRouter(NameForm);
