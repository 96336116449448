import styled from 'styled-components';
import { Col, media, Row } from 'styled-bootstrap-grid';
import { StyledParagraph } from 'pages/HomePage/styled';

export const StyledHowItWorkItem = styled(Col)`
  margin-bottom: 32px;

  display: flex;
  flex-direction: column;
  color: #0D151C;

  overflow: hidden;

  ${media.md`
    overflow: initial;
  `}

  ${media.xl`
    //justify-content: space-between;
    //align-items: flex-start;
    //flex-direction: row;
    margin-bottom: 24px;
  `}

  &:nth-child(2n) {
   ${media.xl`
    //flex-direction: row-reverse;
   `}
  }

  &:last-child {
    margin-bottom: 0;
  }

  .icon {
    width: 100%;
    height: auto;

    flex: 0 1 auto;

    margin-bottom: 24px;

    ${media.xl`
      margin-bottom: 0;
    `}

    &--first {
      ${media.md`
        height: 251px;
        width: 530px;

        margin-top: -26px;
        margin-left: -13px;
      `}
    }

    &--second {
      ${media.md`
        width: 470px;
        height: auto;

        margin-top: 1px;
      `}
    }

    &--third {
      ${media.md`
        width: 470px;
        height: auto;

        margin-top: -16px;
      `}
    }

    &--fourth  {
      ${media.md`
        width: 502px;
        height: 251px;

        margin-top: -25px;
        margin-right: -30px;
      `}
    }
  }

  .info {
    flex: 0 1 48.7%;
  }

  .info__title {
    margin-bottom: 32px;

    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
  }
`;

export const StyledHowItWorkWrapper = styled(Row)`
  margin-bottom: 42px;

  ${media.md`
    margin-bottom: 50px;
  `}

  ${media.xl`
    margin-bottom: 74px;
  `}
`;

export const StyledSubtitle = styled(StyledParagraph)`
  margin: 0;
`;
