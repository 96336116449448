import * as dayjs from 'dayjs';
import _get from 'lodash/get';
import { put } from 'redux-saga/effects';

import { CUSTOM_SOLUTIONS_NAMES_LIST, SOLUTIONS } from 'constants/solutions';
import * as solutionActions from 'store/solution/actions';

export const prepareConsultationNameString = (solutionDetails, solutionCreateData) => {
  const consultationTime = solutionCreateData.consultationTime && `, ${solutionCreateData.consultationTime}МСК`;
  const selectedDate = _get(solutionCreateData, 'dateState.value', null);
  const availableDate = selectedDate !== null
    ? dayjs(selectedDate).format('DD/MM/YYYY')
    : '';

  return (
    `Консультация ${availableDate}${consultationTime}`
  );
};

export const getSurveyName = (solutionName, surveyDetail) => (
  CUSTOM_SOLUTIONS_NAMES_LIST[solutionName]
    ? CUSTOM_SOLUTIONS_NAMES_LIST[solutionName](surveyDetail)
    : surveyDetail.name
);

export const SOLUTIONS_CUSTOM_ERROR_HANDLER = Object.freeze({
  [SOLUTIONS.Consultation]: function* consultaionDataUpdate(error, errors, solutionCreateData) {
    if (error === 'TimeNotAvailable') {
      yield put(solutionActions.getSolutionDetails({
        solutionName: SOLUTIONS.Consultation,
      }, { isGlobalLoading: false, withoutClearErrors: true }));
      yield put(solutionActions.updateSolutionCreateData({
        dateState: {
          ...(solutionCreateData.dateState || {}),
          value: null,
        },
        consultationTime: null,
      }));
    }
  },
});
