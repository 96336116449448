import styled from 'styled-components';

import { media } from 'styled-bootstrap-grid';

import {
  StyledMobileLink,
  StyledMobileListLinks,
  UserMenuListLinks,
} from 'components/layouts/MainLayout/Header/MenuItems/styled';

import { StyledMenuSwitchTitle } from 'components/layouts/MainLayout/Header/MenuSwitchTitle/styled';

export const HeaderMenuWrapper = styled.div`
  position: relative;
  display: none;

  ${media.md`
    display: inline-block;
  `}
`;

export const HeaderUserIcon = styled.div`
  width: 38px;
  height: 38px;
  cursor: pointer;
  margin-left: 32px;

  &:hover {
    svg * {
      fill: #7B7B7B;
    }
  }
`;

export const HeaderDropdown = styled.div`
  display: ${(props) => props.isOpen ? 'flex' : 'none'};
  flex-direction: column;
  background-color: white;
  box-shadow: 0 7px 64px rgba(0, 0, 0, 0.17);
  border-radius: 4px;
  padding: 30px;
  width: 200px;
  position: absolute;
  top: 46px;
  right: 0;
  z-index: 10;
  & a {
    text-decoration: none;
    margin-bottom: 1em;
  }
  & ${StyledMenuSwitchTitle} {
    margin-top: -18px;
    margin-bottom: 16px;

    border-top: none;

    font-size: 16px;
    line-height: 19px;

    ${media.xl`
      display: none;
    `}
  }

  & ${StyledMobileLink} {
    font-size: 16px;
    line-height: 19px;
  }

  & ${StyledMobileListLinks} {
    ${media.xl`
      display: ${({ isHomePage }) => isHomePage ? 'flex' : 'none'};
    `}
  }

  & ${UserMenuListLinks} {
    ${media.xl`
      display: flex;
    `}
  }
`;

export const SingOutItem = styled.div`
  padding: 15px 0;

  border-top: 1px solid;
  border-color: ${({ theme }) => theme.colors.lightGrey};

  color: ${({ theme }) => theme.colors.darkGrey};

  ${media.xl`
    padding: ${({ isHomePage }) => isHomePage ? '15px 0' : '0'};

    border-top: ${({ isHomePage }) => isHomePage ? '1px solid' : 'none'};
    border-color: ${({ theme }) => theme.colors.lightGrey};
  `}

  &:hover {
    cursor: pointer;
  }
`;
