import React from 'react';
import { useDispatch } from 'react-redux';

import Icons from 'uiComponents/Icon';

import { attachReportExampleFile, deleteReportExampleFile } from 'store/solution/actions';

import downloadIcon from 'assets/images/download';
import uploadIcon from 'assets/images/upload';

import {
  ReportsUploaderList,
  ReportsUploaderAddButton,
  ReportsUploaderAddButtonIcon,
  ReportsUploaderListItem,
  ReportsUploaderListItemContent,
  ReportsUploaderListItemIcon, ReportsUploaderListItemName,
} from './styles';

const DocumentsList = ({ reportsList }) => {
  const dispatch = useDispatch();

  const onDeleteReport = (id) => {
    dispatch(deleteReportExampleFile(id));
  };

  return (
    <ReportsUploaderList>
      {reportsList.map((reportsListItem) => (
        <ReportsUploaderListItem>
          <ReportsUploaderListItemContent>
            <ReportsUploaderListItemName>
              {reportsListItem.name}
            </ReportsUploaderListItemName>
            <ReportsUploaderListItemIcon>
              <a
                href={`/api/file/${reportsListItem.id}`}
                target={'_blank'}
              >
                <img
                  src={downloadIcon}
                  alt={reportsListItem.name}
                />
              </a>
            </ReportsUploaderListItemIcon>
            <ReportsUploaderListItemIcon>
              <Icons
                name="cross-delete"
                width="18"
                fill="#D2D2D2"
                onClick={() => onDeleteReport(reportsListItem.id)}
              />
            </ReportsUploaderListItemIcon>
          </ReportsUploaderListItemContent>

        </ReportsUploaderListItem>
      ))}
    </ReportsUploaderList>
  );
};

const AddDocumentButton = () => {
  const dispatch = useDispatch();

  const onUpload = ({ target }) => {
    dispatch(attachReportExampleFile(target.files[0]));
  };

  return (
    <div>
      <ReportsUploaderAddButton htmlFor="uploadedReportsInput">
        <ReportsUploaderAddButtonIcon
          src={uploadIcon}
          alt="Добавить пример отчета"
        />
        Загрузить пример отчета
      </ReportsUploaderAddButton>
      <input
        id="uploadedReportsInput"
        type="file"
        accept=".pdf,.xlxs"
        onChange={onUpload}
        hidden
      />
    </div>
  );
};

const ReportsUploader = ({ reportsList }) => {
  const haveReportsList = Boolean(reportsList.length);

  return (
    <div>
      {haveReportsList && (
        <DocumentsList reportsList={reportsList} />
      )}
      <AddDocumentButton />
    </div>
  );
};

export default ReportsUploader;
