import React, { useRef } from 'react';
import DayPicker from 'react-day-picker';
import { useDispatch } from 'react-redux';
import 'react-day-picker/lib/style.css';

import { StyledDatePicker } from './styled';

import { StyledButton } from 'uiComponents/Button';

import { locale } from './locale';
import { localeUtilsRu } from './localeUtilsRu';

import { YearMonthControl } from './YearMonthControl';
import { RangeSelector } from './RangeSelector';

import * as solutionActions from 'store/solution/actions';
import * as dayjs from 'dayjs';

const currentDate = new Date();
export const currentYear = currentDate.getFullYear();
export const fromMonth = new Date(currentYear, currentDate.getMonth());
export const toMonth = new Date(currentYear + 10, 11);

const getFormattedDate = (date) => {
  if (date) {
    return dayjs(date).format('YYYY-MM-DD');
  }

  return null;
};

const reduxDateShape = {
  dateOfCreation: {
    createdDateFrom: 'from',
    createdDateTo: 'to',
  },
  completedDate: {
    completedDateFrom: 'from',
    completedDateTo: 'to',
  },
};

export const DatePicker = ({
  name,
  isVisibleCalendar,
  datePickerState,
  changePeriodActiveId,
  changePresetDayPicker,
  changeDatePickerRange,
  changeYearMonth,
  resetDatePicker,
  setVisibleCalendar,
}) => {
  const dispatch = useDispatch();

  const calendarRef = useRef(null);

  const handleDayClick = (day) => {
    changeDatePickerRange(day, name);
  };

  const handleYearMonthChange = (month) => {
    changeYearMonth(month, name);
  };

  const onSelectRange = () => {
    const obj = {};

    for (const key in reduxDateShape[name]) {
      obj[key] = getFormattedDate(datePickerState[reduxDateShape[name][key]]);
    }

    dispatch(solutionActions.setSurveyListFilter({
      filterData: {
        page: 1,
        ...obj,
      },
      isGlobalLoading: false,
    }));

    setVisibleCalendar(false);
  };

  const { from, to, month, periodActiveId } = datePickerState;

  return (
    <StyledDatePicker
      isVisibleCalendar={isVisibleCalendar}
      ref={calendarRef}
    >
      <RangeSelector
        name={name}
        onChange={changePresetDayPicker}
        changePeriodActiveId={changePeriodActiveId}
        periodActiveId={periodActiveId}
      />

      <DayPicker
        locale="ru"
        modifiers={{ start: from, end: to }}
        selectedDays={[ from, { from, to } ]}
        months={locale.months}
        month={month}
        fromMonth={fromMonth}
        toMonth={toMonth}
        weekdaysShort={locale.weekdaysShort}
        localeUtils={localeUtilsRu}
        disabledDays={{ after: currentDate }}
        onDayClick={handleDayClick}
        captionElement={({ date, localeUtils }) => (
          <YearMonthControl
            date={date}
            localeUtils={localeUtils}
            onChange={handleYearMonthChange}
          />
        )}
      />

      <div className="calendar__controls">
        <StyledButton onClick={onSelectRange}>Выбрать</StyledButton>
        <StyledButton
          viewType="ghost"
          onClick={() => resetDatePicker(name)}
        >Очистить</StyledButton>
      </div>
    </StyledDatePicker>
  );
};

export default DatePicker;
