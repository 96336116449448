import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { PageTitle, H2 } from 'uiComponents/Titles';

export const StyledPage = styled('div')`
  padding: 0 0 60px 0;

  ${PageTitle} {
    margin-bottom: 36px;
  }

  ${H2} {
    margin-bottom: 0;

    font-size: 44px;
    line-height: 54px;
  }

  ${media.lg`
    padding: 0 0 240px 0;
  `}

  .question-list {
    padding: 0;
    margin: 0;

    list-style-type: none;

    counter-reset: list 0;
  }

  .question-list__item {
    margin-bottom: 18px;

    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #202020;

    span {
      text-decoration: underline;
    }

    &::before {
      counter-increment: list;
      content: counter(list) ". ";
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
  .questionnaire-wrap {
    display: none;
    ${media.lg`
      display: block;
    `}
    }
  }

`;
