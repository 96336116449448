import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import * as appSelectors from 'store/app/selectors';

export const usePreloader = (targetRequestName, options = {}) => {
  const {
    initialState = true,
  } = { ...options };

  const requestNames = useSelector(appSelectors.requestNames);

  const [ loading, setLoading ] = useState([ initialState, initialState ]);

  const didMount = useRef(true);
  useEffect(() => {
    if (didMount.current) {
      didMount.current = false;
      return;
    }

    if (requestNames.hasOwnProperty(targetRequestName)) {
      setLoading([ true, requestNames[targetRequestName].isGlobalLoading ]);
    } else {
      setLoading([ false, false ]);
    }
  }, [ didMount, requestNames ]);

  return loading;
};
