import styled from 'styled-components';

export const DateInputError = styled.div`
  margin-top: 10px;

  color: ${(props) => props.theme.colors.orange};
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
`;
