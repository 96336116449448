import { hot } from 'react-hot-loader/root';
import { Switch } from 'react-router-dom';

import React from 'react';

import { ConnectedRouter } from 'connected-react-router';
import Main from 'uiComponents/main';

import ScrollToTop from 'components/ScrollToTop/ScrollToTop';

import { renderRoute } from 'routes';
import { routesConfig } from 'routes/routesConfig';

const App = (props) => (
  <Main>
    <ConnectedRouter history={props.history}>
      <ScrollToTop />
      <Switch>
        {routesConfig.map(renderRoute)}
      </Switch>
    </ConnectedRouter>
  </Main>
);

export default hot(App);
