import { Component } from 'react';

import { validateField } from 'services/validation';

class CreateForm extends Component {
  constructor(props) {
    super(props);
  }

  inputOnChange = ({ target }) => {
    const currentName = target.name;
    const currentValue = target.value;

    this.setState((prevState) => ({
      ...prevState,
      [ currentName ]: currentValue,
    }),
    () => this.props.updateSolutionCreateData({
      [ currentName ]: currentValue,
    }));
  };

  setAudienceData = (audienceData) => {
    this.setState((prevState) => ({
      ...prevState,
      ...audienceData,
    }),
    () => {
      this.props.updateSolutionCreateData(audienceData);
    });
  };

  toggleType = ({ target }) => {

    if (target.value !== this.state[this.typeField]) {
      this.setState((prevState) => ({
        ...prevState,
        [this.typeField]: target.value,
        fileIds: {},
      }),
      () => {
        this.props.updateSolutionCreateData({
          [this.typeField]: target.value,
          fileIds: {},
        });
      });
    }
  }

  isValidField = (fieldName) => {
    if (this.props.isSubmittedForm) {
      return Object.values(
        validateField(
          this.state[fieldName],
          [ this.solutionDataConfig.createSurveyRules[fieldName] ],
        ),
      ).includes(false);
    }

    return fieldName in this.props.validationErrors;
  };

  isRequestError = (errorName) => {
    const { requestErrors } = this.props;

    return requestErrors.includes(errorName);
  }
}

export default CreateForm;
