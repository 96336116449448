import { useState, useEffect, useRef } from 'react';

export const useStateWithCallback = (initialValue, callBack, triggerFirstTime = true) => {
  const didMount = useRef(true);

  const [ state, setState ] = useState(initialValue);

  useEffect(() => {
    if (!triggerFirstTime && didMount.current) {
      didMount.current = false;
      return;
    }

    callBack(state);
  }, [ state ]);

  return [ state, setState ];
};
