export const SOLUTION_GET_SOLUTION_DETAILS = 'solution-getSolutionDetails';

export const SURVEY_CREATE = 'survey-create';
export const SURVEY_GET_SURVEY_LIST = 'survey-getSurveyList';
export const SURVEY_GET_SURVEY_DATA = 'survey-getSurveyData';
export const SURVEY_GET_MANAGERS = 'survey-getManagers';
export const SURVEY_GET_STATUSES = 'survey-getStatuses';
export const SURVEY_FILE_UPLOAD = 'survey-fileUpload';
export const SURVEY_ADDITIONAL_FILE_UPLOAD = 'survey-additionalFileUpload';
export const SURVEY_GET_COMPANY_NAMES = 'survey-getCompanyNames';
export const SURVEY_GET_SOLUTION_LIST = 'survey-getSolutionList';

export const USER_COST = 'user-cost';
export const USER_PROFILE = 'user-profile';
export const USER_UPDATE_PROFILE = 'user-updateProfile';
export const USER_SIGN_IN = 'user-signIn';
export const USER_PASSWORD_RECOVERY_REQUEST = 'user-passwordRecoveryRequest';
export const USER_REGISTER = 'user-register';
export const USER_SEND_ACCOUNT_ACTIVATION_EMAIL = 'user-sendAccountActivationEmail';
export const USER_PASSWORD_RESET_REQUEST = 'user-passwordResetRequest';
export const CREATE_FEEDBACK = 'create-feedback';
