import React from 'react';

import {
  DeleteIcon, LabelRow,
  TextAreaRow,
} from 'components/SurveySetup/DynamicFieldsBlock/styled';

import {
  Col,
  Row,
} from 'styled-bootstrap-grid';

import deleteIcon from 'assets/images/delete.svg';

import FormField from 'uiComponents/Form/Field';

import { AuthorizedField } from 'components/AuthorizedField/AuthorizedField';

import { StyledTextArea } from 'uiComponents/TeaxtArea';

import { ThemeConsumer } from 'styled-components';

import AddButton from 'uiComponents/Button/AddButton';

export const BlockWithoutDefaultField = ({
  fields,
  addButtonText,
  maxField,
  textToField,
  updateField,
  addField,
  showConfirmationPopup,
}) => (
  <>
    {fields.length !== 0 && (
      <Row>
        <Col col={12}>
          {fields.map((question, index) => (
            <FormField key={question.id}>
              <LabelRow>
                <Col
                  col={11}
                  xs={10}
                  sm={11}
                >
                  <label htmlFor={question.id}>{textToField} #{index + 1}</label>
                </Col>
                <Col
                  col={1}
                  xs={2}
                  sm={1}
                >
                  <DeleteIcon
                    src={deleteIcon}
                    alt="delete"
                    onClick={() => showConfirmationPopup(question.id)}
                  />
                </Col>
              </LabelRow>
              <TextAreaRow>
                <Col
                  col={11}
                  xs={12}
                  sm={12}
                  md={11}
                >
                  <AuthorizedField>
                    <StyledTextArea
                      id={question.id}
                      name={question.id}
                      defaultValue={question.text}
                      onChange={updateField}
                    />
                  </AuthorizedField>

                </Col>
                <Col
                  col={1}
                  xs={0}
                  md={1}
                >
                  <DeleteIcon
                    src={deleteIcon}
                    alt="delete"
                    onClick={() => showConfirmationPopup(question.id)}
                  />
                </Col>
              </TextAreaRow>
            </FormField>
          ))}
        </Col>
      </Row>
    )}

    {fields.length < maxField && (
      <Row>
        <Col>
          <ThemeConsumer>
            {(theme) => (
              <AddButton
                text={addButtonText}
                color={theme.colors.orange}
                onClick={addField}
              />
            )}
          </ThemeConsumer>
        </Col>
      </Row>
    )}
  </>
);
