import styled from 'styled-components';
import InputMask from 'react-input-mask';

export const InputWrapper = styled.div`
  position: relative;

  & > input {
    padding: ${(props) => props.hasIcon ? '14px 40px 14px 14px' : ''};
  }
`;

export const Input = styled.input`
  outline: none;
  padding: 14px 16px;

  width: 100%;

  border: 1px solid ${(props) => props.error ? props.theme.colors.orange : props.theme.colors.borderGrey};
  border-radius: 6px;

  background: white;

  color: black;
  font-size: 16px;
  line-height: 20px;

  &::placeholder {
    color: ${(props) => props.error ? props.theme.colors.orange : props.theme.colors.darkGrey};
    font-size: inherit;
    line-height: inherit;
  }

  &:focus::placeholder {
    color: transparent;
  }

  &:disabled {
    background: ${({ theme }) => theme.colors.disabledGray};
  }
`;

export const InputMaskStyled = styled(InputMask)`
  outline: none;
  padding: 14px 16px;

  width: 100%;

  border: 1px solid ${(props) => props.error ? props.theme.colors.orange : props.theme.colors.borderGrey};
  border-radius: 6px;

  background: white;

  color: black;
  font-size: 16px;
  line-height: 20px;

  &::placeholder{
    color: ${(props) => props.error ? props.theme.colors.orange : props.theme.colors.darkGrey};
    font-size: inherit;
    line-height: inherit;
  }

  &:focus::placeholder {
    color: transparent;
  }
`;

