import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import * as solutionSelector from 'store/solution/selectors';

import * as solutionAction from 'store/solution/actions';

import {
  InterviewDownloadLink,
  InterviewTableValue,
  InterviewUploadInput,
  InterviewUploadLabel,
  MultiplyInterviewDownloadLinkContainer,
  InterviewDownloadName,
  MultiplyInterviewDownloadLinkContainerBlock,
  MultiplyInterviewDownloadLinksList,
  MultiplyInterviewLoader,
  LoadPdfContainer, InterviewDownloadDeleteButton,
} from 'components/SurveyReview/styles';

import { StyledPreloader } from 'uiComponents/Preloader';
import Icon from 'uiComponents/Icon';

import uploadIcon from 'assets/images/upload.png';
import downloadIcon from 'assets/images/download';
import deleteIcon from 'assets/images/delete.svg';

const LoadPdf = (props) => {
  const { data } = props;

  const isUploadingFile = useSelector(solutionSelector.isUploadingFile);
  const reportsList = useSelector(solutionSelector.getReportsList);

  const dispatch = useDispatch();

  const onChangeFile = (e) => {
    dispatch(solutionAction.uploadSurveyReportFile(e.target.files[0]));

    e.target.value = null;
  };

  const onDelete = (id) => {
    dispatch(solutionAction.deleteSurveyReportFile(id));
  };

  return (
    <LoadPdfContainer>
      {Boolean(reportsList.length) && (
        <MultiplyInterviewDownloadLinksList>
          {reportsList.map((reportItem) => (
            <MultiplyInterviewDownloadLinkContainer key={reportItem.id}>
              <MultiplyInterviewDownloadLinkContainerBlock>
                <InterviewDownloadName>{reportItem.name}</InterviewDownloadName>
                <InterviewDownloadLink
                  href={`/api/file/${reportItem.id}`}
                  target={'_blank'}
                >
                  <img
                    src={downloadIcon}
                    alt={data.result.userValue}
                  />
                </InterviewDownloadLink>

                <InterviewDownloadDeleteButton
                  src={deleteIcon}
                  alt="delete"
                  onClick={() => onDelete(reportItem.id)}
                >
                  <Icon
                    name="cross-delete"
                    width="18"
                    fill="#D2D2D2"
                  />
                </InterviewDownloadDeleteButton>
              </MultiplyInterviewDownloadLinkContainerBlock>
            </MultiplyInterviewDownloadLinkContainer>
          ))}
        </MultiplyInterviewDownloadLinksList>
      )}
      {isUploadingFile && (
        <MultiplyInterviewLoader>
          <StyledPreloader />
        </MultiplyInterviewLoader>
      )}
      {!isUploadingFile && (
        <InterviewTableValue>
          <InterviewUploadLabel htmlFor="fileUploads">
            <img
              src={uploadIcon}
              alt={data.result.managerValue}
            />{data.result.managerValue}
          </InterviewUploadLabel>
          <InterviewUploadInput
            id="fileUploads"
            type="file"
            accept=".pdf,.xlsx,.doc,.docx,.ppt,.pptx"
            onChange={onChangeFile}
            hidden
          />
        </InterviewTableValue>
      )}
    </LoadPdfContainer>
  );
};

export default LoadPdf;
