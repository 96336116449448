import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { StyledPreloader } from 'uiComponents/Preloader';

export const FileInputWrapper = styled.div`
  margin-bottom: 0px !important;

  &:focus {
    outline: none;
  }

  & label {
    margin-bottom: 0;

    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    cursor: pointer;

    ${media.md`
      flex-direction: row;
      align-items: center;
    `}

    width: 100%;

  }

  .inner-label {
    display: inline-flex;
    align-items: center;

    opacity: ${({ disabled }) => disabled ? 0.3 : 1};

    font-weight: 600;

    span {
      font-size: 14px;
    }
    &.custom-input__label {
       width: 100%;

       border: 0.5px dashed #AEAEAE;
       border-radius: 6px;

       box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.02);

       padding: 12px;
       &.error {
        border-color: #FF3D00;
       }
    }
  }

  .validate-or-error-message-container {
    margin-top: 29px;

    display: block;

    font-weight: 400;
    font-size: 14px;
    line-height: 18px;

    ${media.md`
      margin: 0 0 0 38px;
    `}

    ${media.desktop`
      margin: 0 0 0 46px;
    `}

    &.Ranging {
      position: absolute;
      top: 15px;
      right: 35px;
    }
  }

  .attach-icon {
    margin-right: 8px;
  }

  .attached-files {
    display: flex;
    flex-direction: column;
  }

  .attached-files__file {
    margin-bottom: 11px;

    display: flex;
    justify-content: space-between;

    &:last-child {
      margin-bottom: 0;
    }

    ${media.tablet`
      justify-content: flex-start;
    `}
  }
  .attached-files-custom__file {
    display: flex;

    padding: 12px;

    border: 1px dashed #AEAEAE;
    border-radius: 6px;

    justify-content: center;
    align-items: center;
  }

  .attached-files__text {
    margin-right: 23px;

    display: flex;
    align-items: center;

    flex: 1 1 auto;

    overflow: hidden;

    ${media.tablet`
      flex: 0 1 270px;
    `}
  }
  .attached-files-custom__text {
    flex: 1 0;
    line-height: normal;
  }
  .attached-files__file-name {

    overflow: hidden;

    text-overflow: ellipsis;
    white-space: nowrap;

    font-style: normal;
    font-weight: 600;
    font-size: 14px;

    line-height: 18px;
    color: #202020;
  }

  .attached-files-custom__file-name {
    max-width: 230px;

    overflow: hidden;

    text-overflow: ellipsis;

    white-space: nowrap;

    display: inline-block;

    vertical-align: top;

    margin-top: 1px;
  }

  .attached-files__icons-block {
    display: inline-flex;

    justify-content: flex-end;
    align-items: center;

    flex: 1 0 60px

    ${media.tablet`
      flex: 0 0 60px
    `}
  }
  .attached-files-custom__icons-block {
    align-items: center;

    flex: 1 0 60px;
    justify-content: end;

    ${media.tablet`
      flex: 1 0 60px
    `}
  }
  .attached-files__icon {
    ${media.tablet`
      &:hover {
        cursor: pointer;
      }
    `}
  }

  .attached-files__download {
    margin-right: 31px;
  }

  .attached-files__remove {
    opacity: ${({ disabled }) => disabled ? 0.3 : 1};

    pointer-events: ${({ disabled }) => disabled ? 'none' : 'initial'};
  }
`;

export const UploadFilePreloader = styled(StyledPreloader)`
    margin-right: 31px;

    width: 4px;
    height: 4px;
`;
