export const checkAllExtension = (file) => {
  // eslint-disable-next-line max-len
  const reg = /(.*?).(mkv|mov|avi|mp4|mpeg|ogg|qt|webm|flv|bmp|jpg|jpeg|gif|png|tiff|wav|mp3|aac|txt|xls|xlsx|doc|docx|rtf|pdf)/;

  return file.name.match(reg);
};

export const checkVideoExtension = (file) => {
  const reg = /(.*?).(mov|avi|mp4|mpeg|qt|webm|flv)/;

  return file.name.match(reg);
};

export const checkImageExtension = (file) => {
  const reg = /(.*?).(bmp|jpg|jpeg|gif|png)/;

  return file.name.match(reg);
};

