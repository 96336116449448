import styled from 'styled-components';

import hamburgerIcon from 'assets/images/hamburger';

import { Container, media } from 'styled-bootstrap-grid';

import closeIcon from 'assets/images/closeIcon';

import {
  StyledCabinetLinkWrapper,
  StyledDividerCabinetLink, StyledRegistrationLink, StyledSignInLink,
} from 'components/layouts/MainLayout/Header/CabinetLinks/styled';

import { StyledMobileLink } from 'components/layouts/MainLayout/Header/MenuItems/styled';

export const HeaderMobileMenuWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  display: ${({ isOpen }) => isOpen ? 'block' : 'none'};
  z-index: 10;
  height: 100vh;
  font-size: 20px;
  line-height: 32px;
  padding: 18px 0;
  ${media.md`
    display: none;
  `}

  & > ${Container} {
    display: flex;
    flex-direction: column;
  }

  & ${StyledCabinetLinkWrapper} {
    margin-left: 0;
    margin-bottom: 32px;

    display: flex;
    justify-content: space-between;
  }

  & ${StyledDividerCabinetLink} {
    display: none;
  }

  & ${StyledSignInLink}, ${StyledRegistrationLink} {
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0;
  }
`;

export const HeaderMenuHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 60px;
  padding-right: 13px;

  ${media.tablet`
    margin-bottom: 58px
  `}
`;

export const HeaderMobileMenuButton = styled.div`
  display: block;
  padding: 10px;
  background-image: url("${hamburgerIcon}");
  background-repeat: no-repeat;
  background-size: contain;
  ${media.md`
    display: none;
  `}
`;

export const HeaderMobileMenuClose = styled(HeaderMobileMenuButton)`
  background-image: url("${closeIcon}");
`;

export const StyledMobileCreateSurveyLink = styled(StyledMobileLink)`
  margin-bottom: 45px;

  font-weight: bold;
  color: ${(props) => props.theme.colors.orange};
`;

export const StyledMobileSignOutLink = styled.span`
  margin-top: 22px;
  margin-bottom: 0;

  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  text-decoration: none;
  color: ${(props) => props.theme.colors.darkGrey};
`;
