import { createStore, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';

import rootSaga from 'store/root/saga';
import rootReducer from 'store/root/reducer';

export const history = createBrowserHistory();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();

const middleware = applyMiddleware(
  routerMiddleware(history),
  sagaMiddleware,
);

function configureStore() {
  const store = createStore(
    rootReducer(history),
    composeEnhancers(middleware),
  );

  let sagaTask = sagaMiddleware.run(rootSaga);

  if (module.hot) {
    module.hot.accept('store/root/reducer', () => {
      store.replaceReducer(rootReducer(history));
    });
    module.hot.accept('store/root/saga', () => {
      const getNewSagas = require('./root/saga').default;
      sagaTask.cancel();
      sagaTask.toPromise().then(() => {
        sagaTask = sagaMiddleware.run(getNewSagas);
      });
    });
  }
  return store;
}

export default configureStore;
