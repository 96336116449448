import React from 'react';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import { PageTitle } from 'uiComponents/Titles';

export const MethodologyISOPage = () => (
  <Container>
    <Row>
      <Col col={12}>
        <PageTitle title="Методология" />
      </Col>
    </Row>
    <Row>
      <Col col={12}>
        <div className="block">
          <p className="text">
            <strong>Стандарт ISO 9001:2000 (Системы менеджмента качества)</strong> устанавливает требования
            к системе менеджмента качества любой организации, желающей продемонстрировать
            свою способность стабильно давать продукцию, отвечающую требованиям потребителя
            и способствующую повышению степени удовлетворенности клиентов. Стандарт ISO 9001:2000
            определяет в том числе <strong>и рекомендованную процедуру измерения удовлетворенности клиентов.
            Стандарт также требует</strong> от организации контролировать информацию, связанную с восприятием
            потребителя того, как организация удовлетворяет его запросы.
          </p>

          <p className="text">
            <strong>Методика оценки удовлетворенности клиентов по стандарту ISO 9001:2000 включает три шага:</strong>
          </p>

          <h4 className="subtitle">
            1. Оценка важности отдельных элементов услуги или сервиса
          </h4>

          <p className="text">
            Участникам опроса предлагается оценить по шкале от 1 до 5,
            где 1 - "Совсем не важно", 5 - "Очень важно" набор характеристик работы компании
            (например, качество продукции или маркетинговых коммуникаций).
          </p>

          <h4 className="subtitle">
            2. Оценка удовлетворенности по каждому из элементов сервиса
          </h4>

          <p className="text">
            Участникам опроса предлагается оценить по шкале от 1 до 5,
            где 1 - "Полностью не удовлетворен(а)", 5 - "Полностью удовлетворен(а)"
            тот же набор характеристик работы компании
          </p>

          <h4 className="subtitle">
            3. Расчет общего индекса удовлетворенности
          </h4>

          <p className="text">
            Общий <strong>индекс удовлетворенности</strong> рассчитывается как приведенное к показателю от
            0 до 100 среднее значение удовлетворенности по всем параметрам,
            взвешенное на важность каждой характеристики
          </p>

          <p className="text">
            Индекс удовлетворенности <strong>интерпретируется в соответствии с стандартом по следующей схеме:</strong>
          </p>

          <div className="MethodologyTable">
            <div className="MethodologyRow ISORow">
              <h4 className="subtitle">
                90-100
              </h4>

              <p className="text">
                отличный
              </p>
            </div>
            <div className="MethodologyRow ISORow">
              <h4 className="subtitle">
                85-89
              </h4>

              <p className="text">
                очень хороший
              </p>

            </div>
            <div className="MethodologyRow ISORow">
              <h4 className="subtitle">
                80-84
              </h4>

              <p className="text">
                хороший
              </p>
            </div>
            <div className="MethodologyRow ISORow">
              <h4 className="subtitle">
                75-79
              </h4>

              <p className="text">
                средний
              </p>
            </div>
            <div className="MethodologyRow ISORow">
              <h4 className="subtitle">
                70-74
              </h4>

              <p className="text">
                вызывающий беспокойство
              </p>
            </div>
            <div className="MethodologyRow ISORow">
              <h4 className="subtitle">
                65-69
              </h4>

              <p className="text">
                плохой
              </p>
            </div>
            <div className="MethodologyRow ISORow">
              <h4 className="subtitle">
                >65%
              </h4>

              <p className="text">
                очень плохой
              </p>
            </div>
          </div>

          <p className="text">
            <strong>Дополнительная диагностика</strong> позволяет создать рекомендаций
            по улучшению работы сервиса компании. Для этого участникам исследования задается вопрос:
            «Если бы вы могли изменить что-либо одно в работе компании, что бы вы сделали?»
          </p>
        </div>
      </Col>
    </Row>
  </Container>
);
