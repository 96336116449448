import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import CreateForm from 'components/SurveySetup/forms/CreateForm';
import { solutionsConfig } from 'components/SurveySetup/forms/solutionConfig';
import { StyledWrapperFormBlock } from 'components/SurveySetup/styled';
import { FormBlockTitle } from 'components/SurveySetup/FormBlockTitle/FormBlockTitle';
import { AuthorizedField } from 'components/AuthorizedField/AuthorizedField';
import { DynamicFieldsBlock } from 'components/SurveySetup/DynamicFieldsBlock';
import { BlockWithDefaultField } from 'components/SurveySetup/DynamicFieldsBlock/BlockWithDefaultField';
import AudienceForm from 'components/AudienceForm';

import FormField from 'uiComponents/Form/Field';
import { StyledTextArea } from 'uiComponents/TeaxtArea';

import generateUUID from 'helpers/generateUUID';

import * as solutionActions from 'store/solution/actions';
import * as solutionSelectors from 'store/solution/selectors';
import { getValidationErrors } from 'store/errors/selectors';

import { Col, Row } from 'styled-bootstrap-grid';
import AdditionalQuestion from 'components/AdditionalQuestion';

const mapStateToProps = (state) => ({
  isSubmittedForm: solutionSelectors.isSubmittedForm(state),
  validationErrors: getValidationErrors(state),
});

const mapDispatchToProps = (dispatch) => ({
  updateSolutionCreateData: (fieldData) => dispatch(solutionActions.updateSolutionCreateData(fieldData)),
});

@connect(mapStateToProps, mapDispatchToProps)
class ACSIForm extends CreateForm {
  constructor(props) {
    super(props);
    this.state = this.solutionDataConfig.initialFormState;
    this.props.updateSolutionCreateData(this.solutionDataConfig.initialFormState);
  }

  solution = this.props.match.params.solution;

  solutionDataConfig = solutionsConfig.find(((item) => item.name === this.solution));

  render() {
    const {
      audienceId,
      geographyId,
      targetCompanyName,
      period,
    } = this.state;

    return (
      <>
        <Row>
          <Col col={12}>
            <StyledWrapperFormBlock>
              <FormBlockTitle
                title="Компания"
                subtitle="Укажите название компании / марки, для который
                Вы хотите измерить индекс
                лояльности ACSI:"
              />
              <AuthorizedField>
                <FormField>
                  <StyledTextArea
                    id="targetCompanyName"
                    name="targetCompanyName"
                    placeholder="Введите компанию / марку"
                    defaultValue={targetCompanyName}
                    onChange={this.inputOnChange}
                    error={this.isValidField('targetCompanyName')}
                  />
                </FormField>
              </AuthorizedField>
            </StyledWrapperFormBlock>
          </Col>
        </Row>
        <Row>
          <Col col={12}>
            <StyledWrapperFormBlock>
              <FormBlockTitle
                title="Период"
                subtitle="Укажите, пожалуйста, период, за который респонденты должны были
                 пользоваться услугами или приобретать товары Вашей компании:"
                textHint="Зачем это нужно?"
                dataTip={(
                  <>
                    Респондентам будет предложено ответить на вопрос: «Использовали ли Вы в своей
                    профессиональной деятельности продукты или услуги /НАЗВАНИЕ КОМПАНИИ /
                    МАРКИ/ за /ПЕРИОД/?»
                    Таким образом, мы отберем для участия только тех респондентов, которые были
                    Вашими клиентами за интересующий Вас период времени.
                  </>
                )}
              />
              <AuthorizedField>
                <FormField>
                  <StyledTextArea
                    id="period"
                    name="period"
                    placeholder="Например, последние полгода или последние три месяца"
                    defaultValue={period}
                    onChange={this.inputOnChange}
                    error={this.isValidField('period')}
                  />
                </FormField>
              </AuthorizedField>
            </StyledWrapperFormBlock>
          </Col>
        </Row>
        <Row>
          <Col col={12}>
            <DynamicFieldsBlock
              blockTitle={{
                title: 'Характеристики',
                subtitle: `
                  Укажите до десяти характеристик работы компании / марки, удовлетворенность
                  которыми Вы бы хотели измерить.
                  Пожалуйста, указывайте каждую из характеристик
                  отдельной строкой.
                `,
              }}
              keyName="text"
              name="characteristics"
              confirmActionMessage="Вы уверены, что хотите удалить характеристику?"
              placeholder="Введите характеристику"
              addButtonText="Добавить строку"
              defaultState={[
                { text: '', id: generateUUID() },
              ]}
              component={BlockWithDefaultField}
            />
          </Col>
        </Row>
        <Row>
          <Col col={12}>
            <AudienceForm
              onChange={this.setAudienceData}
              data={{
                audienceId,
                geographyId,
              }}
              optionalDescription="Мы будем проводить опрос среди тех, кто пользовался услугами указанной
               вами компании за определенный вами период."
            />
          </Col>
        </Row>
        <AdditionalQuestion />
      </>
    );
  }
}

export default withRouter(ACSIForm);
