import React from 'react';
import { useSelector } from 'react-redux';
import { ThemeConsumer } from 'styled-components';
import {
  Col,
  Row,
} from 'styled-bootstrap-grid';

import { getSolutionName } from 'store/solution/selectors';

import { SOLUTION_FIELDS, SOLUTIONS } from 'constants/solutions';

import {
  DeleteIcon,
  TextAreaRow,
} from 'components/SurveySetup/DynamicFieldsBlock/styled';
import { AuthorizedField } from 'components/AuthorizedField/AuthorizedField';

import FormField from 'uiComponents/Form/Field';
import { StyledTextArea } from 'uiComponents/TeaxtArea';
import AddButton from 'uiComponents/Button/AddButton';

import deleteIcon from 'assets/images/delete.svg';

export const BlockWithDefaultField = ({
  fields: [ firstFields, ...allFields ], fields,
  updateField,
  addField,
  addButtonText,
  placeholder,
  isValidField,
  showConfirmationPopup,
  name,
  maxField,
}) => {
  const isShowAddButton = (fieldName) => {
    const fieldLength = fields.length;
    const solutionName = useSelector(getSolutionName);

    if (solutionName === 'CR' && SOLUTION_FIELDS[fieldName].name === 'competitors' && fieldLength >= 3) {
      return false;
    }

    if (SOLUTION_FIELDS[fieldName].name && fieldLength >= SOLUTION_FIELDS[fieldName].limit) {
      return false;
    }

    if (solutionName === SOLUTIONS.Ranging && fieldLength >= maxField) {
      return false;
    }

    return true;
  };

  return (
    <>
      <Row>
        <Col col={12}>
          <FormField>
            <TextAreaRow>
              <Col col={12}>
                <AuthorizedField>
                  <StyledTextArea
                    key={firstFields.id}
                    id={firstFields.id}
                    name={firstFields.id}
                    placeholder={placeholder}
                    defaultValue={firstFields.text}
                    onChange={updateField}
                    error={isValidField()}
                  />
                </AuthorizedField>
              </Col>
            </TextAreaRow>
          </FormField>
        </Col>
      </Row>

      {fields.length > 1 && (
        <Row>
          <Col col={12}>
            {allFields.map((question) => (
              <FormField key={question.id}>
                <TextAreaRow showDeleteIcon>
                  <Col
                    col={11}
                    md={11}
                    xs={10}
                  >
                    <StyledTextArea
                      id={question.id}
                      name={question.id}
                      placeholder={placeholder}
                      defaultValue={question.text}
                      onChange={updateField}
                      error={isValidField()}
                    />
                  </Col>
                  <Col
                    col={1}
                    md={1}
                    xs={2}
                  >
                    <DeleteIcon
                      src={deleteIcon}
                      alt="delete"
                      onClick={() => {
                        showConfirmationPopup(question.id);
                      }}
                    />
                  </Col>
                </TextAreaRow>
              </FormField>
            ))}
          </Col>
        </Row>
      )}

      {isShowAddButton(name) && (
        <Row>
          <Col>
            <ThemeConsumer>
              {(theme) => (
                <AddButton
                  text={addButtonText}
                  color={theme.colors.orange}
                  onClick={addField}
                />
              )}
            </ThemeConsumer>
          </Col>
        </Row>
      )}
    </>
  );
};
