import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { useSelector } from 'react-redux';

import * as solutionActions from 'store/solution/actions';
import * as errorActions from 'store/errors/actions';

import * as userSelectors from 'store/user/selectors';

import { ContainerBreadcrumbs } from 'components/Breadcrumbs';
import { NameSurveyBlock } from 'components/SurveySetup/NameSurveyBlock/NameSurveyBlock';
import { getBreadcrumbs } from 'components/Breadcrumbs/index.jsx';
import SurveySetupForm from 'components/SurveySetup';
import { solutionsConfig } from 'components/SurveySetup/forms/solutionConfig';
import { PagePreloader } from 'components/PagePreloader/PagePreloader';
import SurveyInfo from 'components/SurveyInfo';
import SuccessModal from 'components/SurveySetup/modals/SuccessModal';

import { PageTitle } from 'uiComponents/Titles';

import {
  SurveySetupPageWrapper,
} from 'pages/SurveySetupPage/styled';

import { SOLUTION_GET_SOLUTION_DETAILS } from 'api/requestNames';

import { usePreloader } from 'hooks/usePreloader';

const SurveySetupPage = () => {
  const { solution } = useParams();
  const isManager = useSelector(userSelectors.isManager);

  const solutionDataConfig = solutionsConfig.find(((item) => item.name === solution));

  const authText = isManager
    ? 'Список заказов'
    : 'Мои опросы';

  const breadcrumbs = getBreadcrumbs([
    { breadcrumbText: authText, breadcrumbLink: '/surveys' },
    { breadcrumbText: solutionDataConfig.title, breadcrumbLink: '#' },
  ]);

  const [ isLoadingPage ] = usePreloader(SOLUTION_GET_SOLUTION_DETAILS);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(solutionActions.getSolutionDetails({
      solutionName: solutionDataConfig.name,
    }));

    return () => {
      dispatch(solutionActions.resetSolutionCreateData());
      dispatch(errorActions.clearValidationErrors());
    };
  }, []);

  if (isLoadingPage) {
    return <PagePreloader />;
  }

  return (
    <SurveySetupPageWrapper>
      <Container>
        <Row>
          <Col col={12}>
            <ContainerBreadcrumbs breadcrumbs={breadcrumbs} />
          </Col>
        </Row>
        <Row>
          <Col
            col={12}
            lg={8}
          >
            <PageTitle
              title={solutionDataConfig.title}
              subTitle={solutionDataConfig.subtitle}
              subTitleColor="vivid"
            />
            <SurveySetupForm />
          </Col>
          <Col
            col={12}
            lg={4}
          >
            <SurveyInfo />
          </Col>
        </Row>
      </Container>
      <NameSurveyBlock />
      <SuccessModal />
    </SurveySetupPageWrapper>
  );
};

export default SurveySetupPage;
