import styled, { css } from 'styled-components';
import checkImg from 'assets/images/check.png';
import { media } from 'styled-bootstrap-grid';

export const Box = styled.label`
  width: 24px;
  height: 24px;
  background-color: ${(props) => props.disabled ? '#F7F5F9' : 'white'};
  border: 1px solid ${(props) => props.error ? props.theme.colors.orange : props.theme.colors.borderGrey};
  border-radius: 6px;
  box-shadow: 0 7px 64px rgba(0, 0, 0, 0.07);
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) => props.active && css`
      &:after {
      content: '';
      width: 16px;
      height: 16px;
      background-image: url("${checkImg}");
    }
  `}
`;

export const Label = styled.label`
  cursor: pointer;
  color: ${(props) => props.theme.colors.darkGrey};
`;

export const CheckboxContainer = styled.div`
   display: flex;
   align-items: flex-start;

   &.NameSurveyBlock {
    font-size: 14px;
    line-height: 22px;
   }

   &.NameSurveyBlock ${Box} {
    flex: 0 0 24px;

    ${media.xs`
      margin-right: 12px;
    `}

   }

   &:focus {
    outline: none;
   }

   & ${Box} {
    flex: 1 0 auto;
    margin-right: 46px;
   }

  &.checkBoxList ${Box} {
    margin-right: 16px;
  }

  &.checkBoxList ${Label} {
    color: ${({ theme }) => theme.colors.black}
  }
`;

