import React from 'react';
import PropTypes from 'prop-types';

import {
  ModalWrapper,
  ModalShadow,
  ModalContainer,
} from './styled';

const Modal = ({ onClose, children }) => (
  <ModalWrapper>
    <ModalShadow onClick={() => { onClose(); }} />
    <ModalContainer>
      {children}
    </ModalContainer>
  </ModalWrapper>
);

Modal.defaultProps = {
  onClose: Function.prototype,
};

Modal.propTypes = {
  onClose: PropTypes.func,
  children: PropTypes.node,
};

export default Modal;
