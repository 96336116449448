import prefixString from 'helpers/string/prefixString';
const prefix = 'user';

export const SIGN_IN = prefixString(prefix, 'SIGN_IN');

export const SIGN_OUT = prefixString(prefix, 'SIGN_OUT');

export const FETCH_USER_DATA = prefixString(prefix, 'FETCH_USER_DATA');
export const FETCH_USER_DATA_SUCCESS = prefixString(prefix, 'FETCH_USER_DATA_SUCCESS');

export const SEND_ACCOUNT_ACTIVATION_MAIL = prefixString(prefix, 'SEND_ACCOUNT_ACTIVATION_MAIL');

export const UPDATE_USER_DATA = prefixString(prefix, 'UPDATE_USER_DATA');
export const UPDATING_USER_DATA = prefixString(prefix, 'UPDATING_USER_DATA');
export const CLEAR_USER_DATA = prefixString(prefix, 'CLEAR_USER_DATA');

export const PASSWORD_RESET_SUBMIT = prefixString(prefix, 'PASSWORD_RESET_SUBMIT');
export const PASSWORD_RECOVERY_SUBMIT = prefixString(prefix, 'PASSWORD_RECOVERY_SUBMIT');
export const REGISTRATION_FORM_SUBMIT = prefixString(prefix, 'REGISTRATION_FORM_SUBMIT');

export const FETCH_COST_DATA = prefixString(prefix, 'FETCH_COST_DATA');
export const FETCH_COST_DATA_SUCCESS = prefixString(prefix, 'FETCH_COST_DATA_SUCCESS');
