import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { ThemeConsumer } from 'styled-components';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import debounce from 'lodash/debounce';

import { CheckboxWrapper, StyledNameSurvey, StyledNameSurveyBlock, StyledDownloadLink } from './styled';

import { BigContainer, CommonData, CommonDataItem, CommonDataText } from 'pages/SurveySetupPage/styled';
import { Input } from 'uiComponents/Input/inputStyles';

import * as errorsSelectors from 'store/errors/selectors';
import * as solutionSelectors from 'store/solution/selectors';
import * as appSelectors from 'store/app/selectors';
import { submitSolutionSetupForm, updateSolutionCreateData } from 'store/solution/actions';

import { useStateWithCallback } from 'hooks/useStateWithCallback';

import { TERMS_OF_USE, PRIVACY_POLICY } from 'constants/staticDocs';
import { divideNumberIntoDigits } from 'helpers/divideNumberIntoDigits';

import { validateField } from 'services/validation';

import { StyledStartSurveyButton } from 'components/SurveySetup/NameSurveyBlock/styled';
import { AuthorizedField } from 'components/AuthorizedField/AuthorizedField';
import * as solutionConstants from 'components/SurveySetup/forms/solutionConfig';
import Checkbox from 'uiComponents/Checkbox';
import { RouterLink } from 'uiComponents/Link';

import { usePreloader } from 'hooks/usePreloader';

import { SURVEY_CREATE, SURVEY_FILE_UPLOAD, SURVEY_ADDITIONAL_FILE_UPLOAD } from 'api/requestNames';

export const NameSurveyBlock = () => {
  const getValidationErrors = useSelector(errorsSelectors.getValidationErrors);

  const { solution } = useParams();

  const solutionDataConfig = solutionConstants.solutionsConfig.find(((item) => item.name === solution));

  const isAuthorized = useSelector(appSelectors.authSelector);

  const isSubmittedForm = useSelector(solutionSelectors.isSubmittedForm);

  const solutionDetails = useSelector(solutionSelectors.getSolutionDetails);

  const solutionCreateData = useSelector(solutionSelectors.getSolutionCreateData);

  const dispatch = useDispatch();

  const [ fileUploading ] = usePreloader(SURVEY_FILE_UPLOAD, {
    initialState: false,
  });

  const [ additionalFileUploading ] = usePreloader(SURVEY_ADDITIONAL_FILE_UPLOAD, {
    initialState: false,
  });

  const [ isCreating ] = usePreloader(SURVEY_CREATE, {
    initialState: false,
  });

  const [ name, setName ] = useStateWithCallback('',
    () => dispatch(updateSolutionCreateData({ name })),
  );

  const [ agreementCheckboxes, setAgreementCheckboxes ] = useStateWithCallback({
    agreementCheckbox1: false,
    agreementCheckbox2: false,
    agreementCheckbox3: false,
  },
  () => dispatch(updateSolutionCreateData(agreementCheckboxes)),
  );

  const setProjectName = ({ target }) => {
    setName(target.value);
  };

  const nameChangeHandler = useCallback(
    debounce(setProjectName, 300),
    [],
  );

  const onChangeCheckbox = (checked, checkboxName) => {
    setAgreementCheckboxes((prevState) => ({
      ...prevState,
      [checkboxName]: checked,
    }));
  };

  const submitHandler = useCallback(
    () => dispatch(submitSolutionSetupForm({
      rules: solutionDataConfig.createSurveyRules,
    })),
  );

  const isValidName = () => {
    if (isSubmittedForm) {
      return Object.values(validateField(
        name,
        [ solutionDataConfig.createSurveyRules.name ]))
        .includes(false);
    }
    return 'name' in getValidationErrors;
  };

  const isValidCheckbox = (checkboxName) => {
    if (isSubmittedForm) {
      return !validateField(agreementCheckboxes[checkboxName],
        [ solutionDataConfig.createSurveyRules[checkboxName] ]).checked;
    }

    return checkboxName in getValidationErrors;
  };

  const calculateSurveyPrice = () => {
    const audienceId = solutionCreateData.audienceId || 1;
    const geographyId = solutionCreateData.geographyId || 3;

    const additionalQuestionsCoefficient = () => {
      if (solutionCreateData.additionalQuestions) {
        return solutionCreateData.additionalQuestions.filter((item) => (
          item.text.length
        )).length * solutionDetails.additionalQuestionPrice;
      }

      return 0;
    };

    const currentPriceInfo = (solutionDetails.prices || []).find((priceItem) => (
      priceItem.audienceId === audienceId
      && priceItem.geographyId === geographyId
    ));

    return (currentPriceInfo.price || 0) + additionalQuestionsCoefficient();
  };

  const byPassAgreements = solutionDataConfig.byPassAgreements || [];

  return (
    <StyledNameSurveyBlock>
      <BigContainer>
        <Container>
          <Row>
            <Col
              col={12}
              lg={8}
            >
              <StyledNameSurvey>{solutionDataConfig.nameLabel || 'Название опроса'}</StyledNameSurvey>
              <Row>
                <Col col={12}>
                  {!solutionDataConfig.nameValueGenerator && (
                    <AuthorizedField>
                      <Input
                        id="name"
                        name="name"
                        defaultValue={name}
                        onChange={(e) => {
                          e.persist();
                          nameChangeHandler(e);
                        }}
                        placeholder="Введите название опроса"
                        error={isValidName()}
                        className={'SurveyNameInput'}
                      />
                    </AuthorizedField>
                  )}
                  {solutionDataConfig.nameValueGenerator && (
                    <Input
                      value={solutionDataConfig.nameValueGenerator(solutionDetails, solutionCreateData)}
                      disabled
                    />
                  )}
                </Col>
              </Row>
              <Row>
                <Col col={12}>
                  <CheckboxWrapper>
                    {(!byPassAgreements || !byPassAgreements.includes('agreementCheckbox1')) && (
                      <AuthorizedField>
                        <Checkbox
                          onChange={(checked) => onChangeCheckbox(checked, 'agreementCheckbox1')}
                          error={isValidCheckbox('agreementCheckbox1')}
                          className={'NameSurveyBlock'}
                          labelText={<>
                            Я принимаю условия настоящего
                            <StyledDownloadLink
                              target="_blank"
                              href={TERMS_OF_USE}
                            > Пользовательского соглашения </StyledDownloadLink>и
                            <StyledDownloadLink
                              target="_blank"
                              href={PRIVACY_POLICY}
                            > Политики конфиденциальности</StyledDownloadLink>.
                          </>}
                        />
                      </AuthorizedField>
                    )}
                    {(!byPassAgreements || !byPassAgreements.includes('agreementCheckbox2')) && (
                      <AuthorizedField>
                        <Checkbox
                          onChange={(checked) => onChangeCheckbox(checked, 'agreementCheckbox2')}
                          error={isValidCheckbox('agreementCheckbox2')}
                          className={'NameSurveyBlock'}
                          labelText={<>
                            Я согласен с тем, что после подтверждения заказа соответствующая
                            опросная анкета будет автоматически направлена респондентам,
                            в результате чего последующее внесение изменений в характеристики опроса будет невозможно.
                          </>}
                        />
                      </AuthorizedField>
                    )}
                    {(!byPassAgreements || !byPassAgreements.includes('agreementCheckbox3')) && (
                      <AuthorizedField>
                        <Checkbox
                          onChange={(checked) => onChangeCheckbox(checked, 'agreementCheckbox3')}
                          error={isValidCheckbox('agreementCheckbox3')}
                          className={'NameSurveyBlock'}
                          labelText={<>
                            Предоставленная для проведения опроса информация и материалы не нарушают прав,
                            законных интересов третьих лиц и законодательства Российской Федерации.
                          </>}
                        />
                      </AuthorizedField>
                    )}
                  </CheckboxWrapper>
                </Col>
              </Row>
            </Col>
            <Col
              col={12}
              lg={4}
            >
              <Row alignItems="center">
                <Col
                  col={12}
                  xsOrder={2}
                  md={5}
                  mdOrder={2}
                  lg={12}
                  lgOrder={1}
                >
                  <CommonData>
                    <CommonDataItem>
                      <span>Итого:</span>
                      <span>{divideNumberIntoDigits(calculateSurveyPrice())} ₽</span>
                    </CommonDataItem>
                  </CommonData>
                </Col>
                <Col
                  col={12}
                  xsOrder={1}
                  md={7}
                  mdOrder={1}
                  lg={12}
                  lgOrder={2}
                >
                  <ThemeConsumer>
                    {(theme) => (
                      <StyledStartSurveyButton
                        isLoading={isCreating}
                        onClick={submitHandler}
                        disabled={(
                          !isAuthorized || fileUploading || additionalFileUploading
                        )}
                        colorDisabled={theme.colors.borderGrey}
                      >
                        Запустить
                      </StyledStartSurveyButton>
                    )}
                  </ThemeConsumer>
                </Col>
              </Row>
              <Row>
                <Col col={12}>
                  <CommonDataText>
                    Опрос запустится после оплаты
                    и модерации. Модерация занимает до двух рабочих дней.
                    За результатами можно следить на странице <RouterLink to="/surveys">«Мои опросы»</RouterLink>
                  </CommonDataText>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </BigContainer>
    </StyledNameSurveyBlock>
  );
};
