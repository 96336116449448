import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Container, Row, Col } from 'styled-bootstrap-grid';

import { SOLUTIONS_LANG } from 'constants/solutions';

import { getSolutionDetails, getSolutionList, setSolutionSettingExampleType } from 'store/solution/actions';
import {
  getExamplesSurveyTypeSelector,
  getSolutionList as getSolutionListSelector,
  getSolutionReportExamples,
} from 'store/solution/selectors';

import { ContainerBreadcrumbs, getBreadcrumbs } from 'components/Breadcrumbs';

import Select from 'uiComponents/Select';

import ReportsUploader from './ReportsUploader';

import {
  SurveyExamplesSettingsTitleBlock,
  SurveyExamplesSettingsTitle,
  SurveyExamplesSettingsSection,
  SurveyExamplesSettingsSectionTitleBlock,
  SurveyExamplesSettingsSectionTitle, SurveyExamplesSettingsSelectWrapper,
} from './styles';

const prepareSurveysListToSelect = (surveysList) => (
  surveysList.map((surveyItem) => ({
    id: surveyItem.code,
    value: surveyItem.code,
    name: SOLUTIONS_LANG[surveyItem.code],
  }))
);

const SurveyExamplesSettingsPage = (props) => {
  const breadcrumbs = getBreadcrumbs([ { breadcrumbText: props.breadcrumbText, breadcrumbLink: props.path } ]);

  const dispatch = useDispatch();
  const selectedSurvey = useSelector(getExamplesSurveyTypeSelector);
  const surveyList = useSelector(getSolutionListSelector);
  const solutionReportsExamples = useSelector(getSolutionReportExamples);

  const surveysPreparedList = React.useMemo(() => (
    prepareSurveysListToSelect(surveyList)
  ), [ surveyList ]);

  React.useEffect(() => {
    dispatch(getSolutionList());
  }, []);

  React.useEffect(() => {
    if (selectedSurvey !== null) {
      dispatch(getSolutionDetails({
        solutionName: selectedSurvey,
      }, {
        isGlobalLoading: false,
        withoutClearErrors: true,
      }));
    }
  }, [ selectedSurvey ]);

  const onSelectedSurvey = ({ target }) => {
    dispatch(setSolutionSettingExampleType(target.value || null));
  };

  return (
    <Container>
      <Row>
        <Col col={12}>
          <ContainerBreadcrumbs breadcrumbs={breadcrumbs} />
          <SurveyExamplesSettingsTitleBlock>
            <SurveyExamplesSettingsTitle>Примеры отчетности</SurveyExamplesSettingsTitle>
            <div>Эти примеры пользователь увидит в разделе «Отчет»</div>
          </SurveyExamplesSettingsTitleBlock>
          <SurveyExamplesSettingsSection>
            <SurveyExamplesSettingsSectionTitleBlock>
              <SurveyExamplesSettingsSectionTitle>Решение</SurveyExamplesSettingsSectionTitle>
              Выберите решение для просмотра отчетностей
            </SurveyExamplesSettingsSectionTitleBlock>
            <SurveyExamplesSettingsSelectWrapper>
              <Select
                value={selectedSurvey}
                data={surveysPreparedList}
                onChange={onSelectedSurvey}
                placeholder="Выберите решение"
              />
            </SurveyExamplesSettingsSelectWrapper>
          </SurveyExamplesSettingsSection>
          {selectedSurvey && (
            <SurveyExamplesSettingsSection>
              <SurveyExamplesSettingsSectionTitleBlock>
                <SurveyExamplesSettingsSectionTitle>Загруженные отчеты</SurveyExamplesSettingsSectionTitle>
                Допустимые форматы pdf или xlxs.  Название будет отображаться в заголовке документа
              </SurveyExamplesSettingsSectionTitleBlock>
              <ReportsUploader reportsList={solutionReportsExamples} />
            </SurveyExamplesSettingsSection>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default SurveyExamplesSettingsPage;
